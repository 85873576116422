package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import contentingestion.unifiedmodel.*
import kotlinx.datetime.internal.JSJoda.Duration
import kotlinx.datetime.internal.JSJoda.Instant
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.seconds

@Composable
fun scoresForEvent(context: SportsService, eventId: String, autoPolling: Boolean = false) {
    var event by remember { mutableStateOf<SportingEvent?>(null) }
    var homeTeam by remember { mutableStateOf<Team?>(null) }
    var awayTeam by remember { mutableStateOf<Team?>(null) }
    var entities by remember { mutableStateOf<List<Score>?>(null) }
    var polling by remember { mutableStateOf(autoPolling) }

    LaunchedEffect(eventId) {
        event = context.api.fetchEvent(eventId)
        if (event != null) {
            homeTeam = context.api.fetchTeam(event!!.homeTeam!!)
            awayTeam = context.api.fetchTeam(event!!.awayTeam!!)
        }
        entities = context.api.scoresForEvent(eventId)
        console.log("event", event)
        console.log("autoPolling", autoPolling)

        if (polling) {
            do {
                delay(1.seconds)

                console.log("polling....")
                // in case the event changed state
                event = context.api.fetchEvent(eventId)

                // in case we have any new scores
                entities = context.api.scoresForEvent(eventId)

            } while (polling && !eventIsFinal(event, entities))
            polling = false

            // fetch one more time for good measure
            event = context.api.fetchEvent(eventId)
        }
    }


    data class BoxScoreRow(
        val teamName: String,
        val imageUrl: String?,
        val periodValues: List<String>,
        val finalValue: String
    )

    fun toBoxScoreRows(homeTeam: Team, awayTeam: Team , boxScore: BoxScore, homeFinal: Int?, awayFinal: Int?) : List<BoxScoreRow> {
        val rows = mutableListOf<BoxScoreRow>()
        rows.add(BoxScoreRow(getTeamName(homeTeam),
            getPreferredTeamImage(homeTeam)?.let { getAbsoluteUrl(it) },
            boxScore.periods?.map {  it.homeScore?.points?.toString() ?: ""} ?: listOf(),
            homeFinal?.toString() ?: ""))

        rows.add(BoxScoreRow(getTeamName(awayTeam),
            getPreferredTeamImage(awayTeam)?.let { getAbsoluteUrl(it) },
            boxScore.periods?.map {  it.awayScore?.points?.toString() ?: "" } ?: listOf(),
            awayFinal?.toString() ?: ""))

        return rows
    }

    @Composable
    fun boxScoreItem(score: Score, homeTeam: Team, awayTeam: Team) {
        Div {
            val boxScore: BoxScore? = score.extendedMetadata?.baseball?.boxScore ?: score.extendedMetadata?.basketball?.boxScore ?: score.extendedMetadata?.football?.boxScore
            if (boxScore != null) {
                val boxScoreRows = toBoxScoreRows(homeTeam, awayTeam, boxScore, score.homeScore, score.awayScore)
                val columnTitles = boxScore.periods?.map { it.periodName }
                boxTable<BoxScoreRow> {
                    items(boxScoreRows)
                    column("Team") {
                        content {
                            Div({
                                style {
                                    display(DisplayStyle.Flex)
                                    flexDirection(FlexDirection.Row)
                                    alignItems(AlignItems.Center)
                                }
                            }) {
                                if (it.imageUrl != null) {
                                    Img(it.imageUrl) {
                                        style {
                                            width(32.px)
                                            height(32.px)
                                        }
                                    }
                                }
                                Div({ classes(SportsStyles.metadata)}) {
                                    Div({ classes(SportsStyles.entityInfo) }) {
                                        Text(it.teamName)
                                    }
                                }
                            }
                        }
                    }

                    columnTitles?.forEachIndexed { index, columnName ->
                        column(title = columnName) {
                            content {
                                Div({ classes(SportsStyles.entityInfo) }) { Text(it.periodValues.get(index)) }
                            }
                        }
                    }

                    if (score.state == ScoreState.FINAL) {
                        // Spacing between period scores and final score
                        column("") { }

                        column("F") {
                            content {
                                Div({ classes(SportsStyles.entityInfo) }) { Text(it.finalValue) }
                            }
                        }
                    }
                }
            }
        }
    }

    @Composable
    fun scoreItem(score: Score, liveStart: Instant?) {
        Div({ style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            marginLeft(10.px)
            padding(10.px)
            alignItems(AlignItems.Center)
        } }) {
            Div({ style {
                width(120.px)
                fontWeight("bold")
            } }) { Text(score.state?.name ?: "") }
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entityInfo) }) {
                    val period = getPeriod(score.period ?: "")
                    val outs: Int? = score.extendedMetadata?.baseball?.outs
                    val periodDetail = if  (outs != null) {
                        "$period - $outs Outs"
                    } else {
                        period
                    }
                    Text(periodDetail)
                }
                Div({ classes(SportsStyles.entityInfo) }) {
                    Text(score.homeScore?.toString() ?: "" )
                }
                Div({ classes(SportsStyles.entityInfo) }) {
                    Text(score.awayScore?.toString() ?: "" )
                }
                if (score.timestamp != null) {
                    val timeDiff = getTimeDiff(liveStart, Instant.parse(score.timestamp!!))
                    val text = if (timeDiff != null) {
                        "${score.timestamp!!} - $timeDiff"
                    } else {
                        score.timestamp!!
                    }
                    Div({ classes(SportsStyles.entitySubInfo) }) {
                        Text(text)
                    }
                }
            }
        }
    }

    serviceContentView({
        title = event?.name
        subTitle = event?.state?.name ?: ""
    }) {
        if (polling) {
            button {
                title = "Stop Polling"
                action {
                    polling = false
                }
            }
        }

        if (entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $eventId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (event == null || entities.isNullOrEmpty()) {
            spinner(size = Size.LARGE)
        } else {

            val latestScore = entities?.lastScoreOrNull()
            if (latestScore != null && homeTeam != null && awayTeam != null) {
                boxScoreItem(score = latestScore, homeTeam!!, awayTeam!!)
            }
            val liveStart = entities?.firstOrNull { it.state == ScoreState.IN_PROGRESS }?.timestamp?.let { timestamp ->
                Instant.parse(timestamp)
            }

            boxTable {
                items(entities?.reversed())
                column {
                    content { score ->
                        scoreItem(score, liveStart)
                    }
                }
            }
        }
    }
}

fun eventIsFinal(event: SportingEvent?, entities: List<Score>?): Boolean {
    if (entities == null) return false
    if (event == null) return false

    if (event.state == ScoreState.FINAL) {
        return true
    }
    return entities.lastScoreOrNull()?.state == ScoreState.FINAL
}

private fun getTimeDiff(liveStart: Instant?, current: Instant): String? {
    return if (liveStart != null) {
        val duration = Duration.between(liveStart, current)
        if (duration.isNegative()) {
            null
        } else {
            duration.asString()
        }
    } else {
        null
    }
}

private fun Duration.asString(): String {
    val hours = toHours().toString().padStart(2, '0')
    val minutes = (toMinutes().toInt() % 60).toString().padStart(2, '0')
    val seconds = (seconds().toInt() % 60).toString().padStart(2, '0')

    return "$hours:$minutes:$seconds"
}

private fun List<Score>.lastScoreOrNull(): Score? {
    return this.reversed().firstOrNull { it.extendedMetadata != null }
}

private fun getPeriod(period: String): String {
    val parsedPeriod = parsePeriod(period)
    val num = parsedPeriod.second.toString()
    val ordinal = getOrdinal(parsedPeriod.second)

    return when (parsedPeriod.first) {
        "q" -> "$ordinal Quarter"
        "ht" -> "Halftime"
        "numot" -> "${num}OT"
        "f" -> "Final"
        "fot" -> "Final/${num}OT"
        "p" -> "{ordinal} Period"
        "i" -> "{ordinal} Intermission"
        "so" -> "Shootout"
        "fso" -> "Final/Shootout"
        "h" -> "$ordinal Half"
        "t" -> "Top $ordinal"
        "m" -> "Middle $ordinal"
        "b" -> "Bottom $ordinal"
        "e" -> "End $ordinal"
        "fnum" -> "Final/$num"
        else -> period
    }
}

private fun parsePeriod(input: String): Pair<String, Int> {
    var letters = ""
    var number = 0

    input.forEach { char ->
        if (char.isDigit()) {
            number = number * 10 + char.digitToInt()
        } else if (char.isLetter()) {
            letters += char
        }
    }

    // Return a Pair containing the letters and the number
    return Pair(mapKey(letters, number), number)
}

private fun mapKey(key: String, num: Int): String {
    return when {
        key == "OT" -> "numot"
        key == "F" && num > 0 -> "fnum"
        else -> key.lowercase()
    }
}

private fun getOrdinal(number: Int): String {
    val suffix = when {
        number % 100 in 11..13 -> "th" // Special case for 11th, 12th, 13th
        number % 10 == 1 -> "st"       // 1st, 21st, 31st, etc.
        number % 10 == 2 -> "nd"       // 2nd, 22nd, 32nd, etc.
        number % 10 == 3 -> "rd"       // 3rd, 23rd, 33rd, etc.
        else -> "th"                   // 4th, 5th, 6th, etc.
    }
    return "$number$suffix"
}