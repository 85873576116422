package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.ChannelDetailSummary
import com.siriusxm.pia.rest.epg.EPGApiClient
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.unifiedmodel.*

val uuidRegex = "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}${'$'}".toRegex()

/**
 * Channel Routing
 */
@Composable
fun Route.channelGuideDashboard(epgApi: EPGApiClient, aggregatorClient: UnifiedAggregatorClient) {
    var index by remember { mutableStateOf(1) }
    val epgContext by remember {
        mutableStateOf(
            EPG(
                api = epgApi,
                aggregatorClient = aggregatorClient,
                reloader = {
                    index++
                }
            )
        )
    }
    val aggregatorContext by remember {
        mutableStateOf(
            AggregatorService(
                api = aggregatorClient,
                reloader =  {
                    index++
                }
            )
        )
    }

    serviceView({
        simpleNavSidebar(
            true, "Channels", listOf(
                NavigationItem("All", "channelguide"),
                NavigationItem("By Lineup", "channelguide/channel/lineup"),
                NavigationItem("By Category", "channelguide/channel/category")
            )
        )
    }) {
        switch {
            select("channel") {
                switch {
                    select("lineup") {
                        val lineupId = parameter("lineupId")
                        switch {
                            channelsByLineupBrowser(epgContext, lineupId)
                        }
                    }
                    select("category") {
                        val categoryId = parameter("categoryId")
                        switch {
                            channelsByCategoryBrowser(epgContext, categoryId)
                        }
                    }

                    select(uuidRegex) {
                        channel(epgContext, match, this)
                    }
                }
            }

            select("show") {
                switch {
                    select(uuidRegex) {
                        show(epgContext, this.match, this)
                    }
                }
            }

            select("episode") {
                switch {
                    select(uuidRegex) {
                        episode(epgContext, this.match, this)
                    }
                }
            }

            default {
                primaryContentArea {
                    channelsBrowser(epgContext)
                }
            }
        }
    }
}

fun ChannelDetailSummary.hasPreferredImage(): Boolean {
    return this.getPreferredImage() != null
}

fun IncludesImages.hasPreferredImage(): Boolean {
    return this.getPreferredImage() != null
}

fun ChannelDetailSummary.getPreferredImage(): Image? {
    return this.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: this.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: this.images?.get(ImagePurpose.LOGO)?.get(ImageAspectRatio.ASPECT_1X1)?.default
}
fun IncludesImages.getPreferredImage(): Image? {
    return this.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: this.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: this.images?.get(ImagePurpose.LOGO)?.get(ImageAspectRatio.ASPECT_1X1)?.default
}

fun Nameable?.shortName(): String? {
    return this?.additionalNames?.get(NameType.SHORT.name)?.name
}

@Composable
fun DetailGridConfig.pillDetailBox(title: String, pills: List<String>?) {
    if (pills?.isNotEmpty() == true) {
        detail(title) {
            pills.forEach {
                pill(it)
            }
        }
    }
}

