package com.siriusxm.pia.views.unifiedaggregator.smithy

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.entityType
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.copyOrBuild
import com.siriusxm.smithy4kt.*
import kotlinx.datetime.TimeZone
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

class SmithyViewOptions {
    var showEmptyFields: Boolean = false
    var labelWidth = 200.px

    /**
     * When rendering structures, these fields will always appear first, followed
     * by the remaining fields in the order they are received.
     */
    var priorityKeys = listOf("id", "type", "version", "name", "description", "additionalNames")

    /**
     * The timezone to render timestamps.
     */
    var timezone: TimeZone = TimeZone.currentSystemDefault()

    /**
     * Retrieves a custom renderer for a shape. Callers can install their own
     * logic to determine the renderer.
     */
    var rendererFactory: (SmithyEntity) -> SmithyViewer<SmithyEntity>? = { null }

    /**
     * Show the copy icon next to values where appropriate.
     */
    var allowValueCopy: Boolean = true
}

val smithyViewDecoder = Json {
    ignoreUnknownKeys = true
}

/**
 * Base class for all Smithy editors
 */
abstract class SmithyViewer<T : SmithyEntity>(val shape: T) {
    lateinit var onUpdate: (JsonElement?) -> Unit

    @Composable
    open fun view(value: JsonElement?, options: SmithyViewOptions) {
        value?.let {
            Text(it.toString())
        }
    }

    @Composable
    open fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {

    }
}


/**
 * Create a Smithy viewer for the given Smithy Entity.
 */
fun SmithyViewer(
    entity: SmithyEntity,
    options: SmithyViewOptions,
    onUpdate: (JsonElement?) -> Unit,
): SmithyViewer<*>? {
    val renderer = options.rendererFactory(entity) ?: when (entity) {
        is SmithyStructure -> SmithyStructEditor(entity)
        is SmithyMember -> SmithyMemberEditor(entity)
        is SmithyEnum -> SmithyEnumEditor(entity)
        is SmithyMap -> SmithyMapEditor(entity)
        is SmithyString -> SmithyStringEditor(entity)
        is SmithyBoolean -> SmithyBooleanViewer(entity)
        is SmithyList -> SmithyListViewer(entity)
        is SmithyNumber<*> -> SmithyNumberViewer(entity)

        else -> null
    }
    renderer?.onUpdate = onUpdate
    return renderer
}

/**
 * Viewer/editor for a smithy string
 */
open class SmithyStringEditor(shape: SmithyString) : SmithyViewer<SmithyString>(shape) {
    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                gap(3.px)
                padding(6.px)
            }
        }) {
            val isValid = modification?.takeIf { it !is JsonNull }?.let {
                try {
                    shape.validate(it, true)
                    true
                } catch (e: Throwable) {
                    false
                }
            } ?: true

            Input(InputType.Text) {
                if (!isValid) {
                    classes("input-error")
                }
                value(modification?.jsonPrimitive?.contentOrNull.orEmpty())
                onInput {
                    val newValue = it.value.ifBlank { null }
                    onUpdate(newValue?.let { JsonPrimitive(newValue) } ?: JsonNull)
                }
            }
            baseValue?.let {
                Div({
                    style {
                        color(SXMUI.disabledTextColor.value())
                        fontStyle("italic")
                    }
                }) {
                    Div({ classes(SmithyViewerStyles.fieldValue) }) {
                        view(baseValue, options)
                    }
                }
            }
        }
    }

    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        (value as? JsonPrimitive)?.contentOrNull?.let {
            Text(it)
            if (options.allowValueCopy) {
                copyContentIcon(it)
            }
        }
    }
}

/**
 * Editor/viewer for a Smithy enum
 */
class SmithyEnumEditor(shape: SmithyEnum) : SmithyViewer<SmithyEnum>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        (value as? JsonPrimitive)?.contentOrNull?.let {
            Text(it)
        }
    }

    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        val selection = (modification as? JsonPrimitive)?.contentOrNull
        val value = shape.allEntries.find { it.value == selection }
        simpleSelectNullable(
            value?.value,
            shape.allEntries.map {
                it.key to it.value
            }) {
            onUpdate(JsonPrimitive(it))
        }
    }
}

/**
 * Viewer for a member of a struct. Largely just defers to the shape.
 */
class SmithyMemberEditor(shape: SmithyMember) : SmithyViewer<SmithyMember>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        SmithyViewer(shape.shape, options) { }?.view(value, options)
    }

    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        val shape = shape.shape
        SmithyViewer(shape, options, onUpdate)?.edit(baseValue, modification, options)
    }
}

class SmithyStructEditor(shape: SmithyStructure) : SmithyViewer<SmithyStructure>(shape) {
    private var includedFields: List<String> = emptyList()

    /**
     * Set specific fields to be included
     */
    fun includeFields(fields: List<String>) {
        includedFields = fields
    }

    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val valueObj = value as? JsonObject ?: return

        val members = shape.allMembers.entries.toList().filter {
            includedFields.isEmpty() || includedFields.contains(it.key)
        }.filter {
            val fieldValue = valueObj[it.key]

            // check for blank strings and don't show those.
            val isBlank = (fieldValue is JsonPrimitive && fieldValue.contentOrNull == "")
            options.showEmptyFields || (fieldValue != null && fieldValue != JsonNull && !isBlank)
        }.let { entries ->
            options.priorityKeys
                .mapNotNull { key -> entries.find { it.key == key } } +
                    entries.filterNot { it.key in options.priorityKeys }
        }.map {
            it.key to it.value
        }

        smithyTableView(members, options, valueObj)
    }


    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        val members = shape.allMembers.entries.toList().filter {
            includedFields.isEmpty() || includedFields.contains(it.key)
        }
        table<Map.Entry<String, SmithyMember>> {
            items(members)
            column {
                content { (_, member) ->
                    fieldLabel(member, options)
                }
            }
            column {
                style {
                    padding(0.px)
                }
                content { (name, member) ->
                    val fieldValue = (baseValue as? JsonObject)?.get(name)
                    val modificationValue = (modification as? JsonObject)?.get(name)
                    val editor = SmithyViewer(member, options) { updatedValue ->
                        val update = (modification as? JsonObject).copyOrBuild {
                            put(name, updatedValue ?: JsonNull)
                        }
                        onUpdate(update)
                    }
                    editor?.edit(fieldValue, modificationValue, options)
                }
            }
        }
    }
}

/**
 * Renders a table field label for a Smithy Member.
 */
@Composable
fun fieldLabel(member: SmithyMember, options: SmithyViewOptions) {
    val documentation = member.documentation
    Span({
        classes(SmithyViewerStyles.memberLabel)
    }) {
        Text(member.name)
        if (documentation != null) {
            Span({
                classes(SmithyViewerStyles.memberDocumentation)
                this.title(documentation)
            }) {
                helpIcon()
            }
        }
    }
}

/**
 * A default table view, anywhere it's useful to render a table of content.
 */
@Composable
fun smithyTableView(
    members: List<Pair<String, SmithyMember>>,
    options: SmithyViewOptions,
    valueObj: JsonObject
) {
    table<Pair<String, SmithyMember>> {
        items(members)
        column {
            width = options.labelWidth
            style {
                property("vertical-align", "top")
            }
            content { (_, member) ->
                fieldLabel(member, options)
            }
        }
        column {
            style {
                padding(0.px)
            }
            content { (name, member) ->
                val fieldValue = valueObj[name]
                val viewer = SmithyViewer(member, options) {}
                Div({
                    classes(SmithyViewerStyles.fieldValue)
                }) {
                    viewer?.view(fieldValue, options)
                }
            }
        }
    }
}

/**
 * A viewer for a Smithy map
 */
open class SmithyMapEditor(shape: SmithyMap) : SmithyViewer<SmithyMap>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val mapValue = value as? JsonObject ?: return

        table<Map.Entry<String, JsonElement>> {
            items(mapValue.entries.toList())
            column {
                width = options.labelWidth
                content { (name, _) ->
                    Span({
                        style {
                            fontWeight(700)
                        }
                    }) {
                        Text(name)
                    }
                }
            }
            column {
                style {
                    padding(0.px)
                }
                content { (_, member) ->
                    Div({
                        classes(SmithyViewerStyles.fieldValue)
                    }) {
                        val viewer = SmithyViewer(shape.value, options) {}
                        viewer?.view(member, options)
                    }
                }
            }
        }
    }

    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        val keyShape = shape.key
        val memberShape = shape.value

        val modifiedMap = (modification as? JsonObject) ?: buildJsonObject { }
        val entries = modifiedMap.entries.map { (key, value) -> key to value }
        Div {
            table<Pair<String, JsonElement>> {
                items(entries + ("___new" to JsonPrimitive("")))
                column {
                    content { (name, _) ->
                        if (name == "___new") {
                            SmithyViewer(keyShape, options) { updatedValue ->
                                val value = (updatedValue as? JsonPrimitive)?.contentOrNull
                                if (value != null && !modifiedMap.containsKey(value)) {
                                    onUpdate(modifiedMap.copyOrBuild {
                                        put(value, JsonNull)
                                    })
                                }
                            }?.edit(null, null, options)
                        } else {
                            Text(name)
                        }
                    }
                }
                column {
                    content { (name, member) ->
                        if (name != "___new") {
                            val fieldValue = (baseValue as? JsonObject)?.get(name)
                            SmithyViewer(memberShape, options) { updatedValue ->
                                val update = modifiedMap.copyOrBuild {
                                    put(name, updatedValue ?: JsonNull)
                                }
                                onUpdate(update)
                            }?.edit(fieldValue, member, options)
                        }
                    }
                }
                column {
                    content { (_, _) ->
                        // put operations here
                    }
                }
            }
        }
    }
}

/**
 * Display Smithy boolean as a checkbox.
 */
class SmithyBooleanViewer(shape: SmithyBoolean) : SmithyViewer<SmithyBoolean>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val boolValue = (value as? JsonPrimitive)?.booleanOrNull ?: return
        icon(if (boolValue) "check" else "close") {
            size = IconSize.SMALL
            style {
                if (boolValue) {
                    color(SXMUI.goColor.value())
                }
            }
        }
    }

    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        val checked = (modification as? JsonPrimitive)?.booleanOrNull == true
        Span({
            style {
                cursor("pointer")
            }
            onClick {
                onUpdate(JsonPrimitive(!checked))
            }
        }) {
            if (checked) {
                icon("check_box")
            } else {
                icon("check_box_outline_blank")
            }
        }
    }
}

/**
 * A viewer of lists.
 */
class SmithyListViewer(shape: SmithyList) : SmithyViewer<SmithyList>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val list = (value as? JsonArray) ?: return
        if (shape.member is SmithyString || shape.member is SmithyEnum) {
            // special rendering for lists of strings and enums
            Div({
                style {
                    display(DisplayStyle.Flex)
                    margin(3.px, 0.px)
                    gap(6.px)
                    flexWrap(FlexWrap.Wrap)
                }
            }) {
                list.forEach {
                    val str = (it as? JsonPrimitive)?.contentOrNull
                    if (str != null) {
                        pill(str)
                    }
                }
            }


        } else {
            Div({ classes(SmithyViewerStyles.listViewerContainer) }) {
                list.forEach {
                    Div({
                        classes(SmithyViewerStyles.listViewerItem)
                    }) {
                        SmithyViewer(shape.member, options) {}?.view(it, options)
                    }
                }
            }
        }
    }

    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
    }
}

/**
 * Viewer for numbers
 */
class SmithyNumberViewer(shape: SmithyNumber<*>) : SmithyViewer<SmithyNumber<*>>(shape) {
    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
    }

    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val primitive = value as? JsonPrimitive ?: return
        primitive.intOrNull?.let {
            Text(it.toString())
            return
        }
        primitive.doubleOrNull?.let {
            Text(it.toString())
            return
        }
        primitive.floatOrNull?.let {
            Text(it.toString())
            return
        }
    }
}

@Composable
fun smithyEntityView(model: SmithyEntity, entity: JsonElement, options: SmithyViewOptions = SmithyViewOptions()) {
    val viewer = SmithyViewer(model, options) {}
    viewer?.view(entity, options)
}

@Composable
fun smithyView(model: SmithyShape, entity: JsonElement, options: SmithyViewOptions = SmithyViewOptions()) {
    var view by remember { mutableStateOf("table") }

    Div {
        Div({
            style {
                textAlign("right")
                margin(.3.em, 0.px)
            }
        }) {
            buttonRadioGroup(view) {
                option("Table", "table")
                option("JSON", "json")
                onChange {
                    view = it
                }
            }
        }

        box({
            paddedContent = false
        }) {
            if (view == "table") {
                smithyEntityView(model, entity, options)
            } else if (view == "json") {
                jsonTextView(entity)
            } else {
                // nothing
            }
        }
    }
}

/**
 * Our custom list of shape renderers
 */
val shapeRenderers = mapOf(
    "contentingestion.unifiedmodel#ImagePurposeMap" to ::ImagePurposeMapViewer,
    "contentingestion.unifiedmodel#NameMap" to ::NamesMapViewer,
    "contentingestion.unifiedmodel#Color" to ::ColorViewer,
    "contentingestion.unifiedmodel#PlaylistList" to ::PlaylistViewer,
    "contentingestion.unifiedmodel#Image" to ::ImageViewer,
    "contentingestion.unifiedmodel#EntityAccessControls" to ::AccessControlViewer,
    "contentingestion.unifiedmodel#iso8601Timestamp" to ::Iso8601TimestampViewer,
    "contentingestion.unifiedmodel#LegacyImageList" to ::LegacyImagesViewer,
    "contentingestion.unifiedmodel#Milliseconds" to ::MillisecondsViewer,
)

/**
 * Get the default rendering options for aggregator content
 */
fun aggregatorOptions(aggregator: AggregatorService): SmithyViewOptions {
    return SmithyViewOptions().apply {
        rendererFactory = { smithyEntity ->
            (smithyEntity as? SmithyShape)?.id?.let {
                when (it) {
                    "contentingestion.unifiedmodel#EntityType" -> EntityTypeViewer(aggregator, smithyEntity)
                    "contentingestion.unifiedmodel#entityId" -> EntityIdViewer(aggregator, smithyEntity)
                    else -> shapeRenderers[it]?.invoke(smithyEntity)
                }
            }
        }
    }
}

@Composable
fun aggregatorSmithyEntityView(
    aggregator: AggregatorService,
    entity: JsonElement,
    options: SmithyViewOptions = aggregatorOptions(aggregator)
) {
    val model = aggregator.entityModel
    val shape = entity.entityType?.let {
        aggregator.entityType(it)
    }?.shapeId?.let {
        model?.getShape(it)
    }
    if (shape != null) {
        smithyView(shape, entity, options)
    }
}