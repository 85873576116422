package com.siriusxm.pia.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px


interface ExpandingTableConfig<T> {
    var items: List<T>
    fun collapsedContent(block: @Composable (T) -> Unit)
    fun expandedContent(block: @Composable (T) -> Unit)

    /**
     * If true, renders the collapsed content even when expanded. The expanded
     * callback will then be called.
     */
    var alwaysShowCollapsed: Boolean
}

@Composable
fun <T> expandingRowTable(block: ExpandingTableConfig<T>.() -> Unit) {
    var collapsed: @Composable (T) -> Unit = { }
    var expandedRender: @Composable (T) -> Unit = { }
    var tableItems: List<T>? = null
    var expanded by remember { mutableStateOf<Set<T>>(emptySet()) }
    var alwaysShowCollapsed = false

    object : ExpandingTableConfig<T> {
        override var items: List<T>
            get() = tableItems.orEmpty()
            set(value) {
                tableItems = value
            }

        override fun collapsedContent(block: @Composable (T) -> Unit) {
            collapsed = block
        }

        override fun expandedContent(block: @Composable (T) -> Unit) {
            expandedRender = block
        }

        override var alwaysShowCollapsed: Boolean
            get() = alwaysShowCollapsed
            set(value) {
                alwaysShowCollapsed = value
            }
    }.block()

    table<T> {
        items(tableItems)
        column {
            width = 20.px
            style {
                property("vertical-align", "top")
                paddingRight(0.px)
                paddingLeft(0.px)
            }
            content { item ->
                if (expanded.contains(item)) {
                    icon("arrow_drop_down") {
                        action {
                            expanded = expanded - item
                        }
                    }
                } else {
                    icon("arrow_right") {
                        action {
                            expanded = expanded + item
                        }
                    }
                }
            }
        }
        column {
            style {
                paddingLeft(0.px)
            }
            content { item ->
                if (expanded.contains(item)) {
                    if (alwaysShowCollapsed) {
                        collapsed(item)
                    }
                    expandedRender(item)
                } else {
                    collapsed(item)
                }
            }
        }
    }
}