package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.primaryContentArea
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.configuration.addContentFlag
import com.siriusxm.pia.views.configuration.contentFlag
import com.siriusxm.pia.views.configuration.contentFlags
import com.siriusxm.pia.views.helpPage
import org.jetbrains.compose.web.dom.Text

@Composable
fun Route.podcasts() {
    switch {
        select("show") {
            primaryContentArea {
                switch {
                    select("errors") {
                        Text("All show errors")
                    }
                    default {
                        val showId = this.match

                        switch {
                            // select to show a specific episode
                            var episodeId: String?  = null
                            var section: String? = null
                            select("episodes") {
                                section = "episodes"
                                switch {
                                    default {
                                        episodeId = this.match.takeIf { it.isNotBlank() }
                                    }
                                }
                            }

                            default {
                                section = this.match
                            }

                            when (parameter("action")) {
                                "edit" -> {
                                    editShow(showId)
                                }

                                else -> {
                                    showInfo(showId, section?.ifBlank { null }, episodeId)
                                }
                            }
                        }
                    }
                }
            }
        }
        select("feeds") {
            primaryContentArea {
                if (parameter("action") == "add") {
                    editFeed(null, parameter("showId"), parameter("return"))
                } else {
                    switch {
                        default {
                            val feedUrl = this.match

                            when (parameter("action")) {
                                "edit" -> {
                                    editFeed(feedUrl, null, parameter("return"))
                                }

                                "detach" -> {
                                    removeFeed(feedUrl, parameter("return"))
                                }

                                else -> {
                                    feed(feedUrl)
                                }
                            }
                        }
                    }
                }
            }
        }
        select("contentLinks") {
            if (parameter("action") == "edit") {
                editContentLinks(parameter("showId")!!, null, parameter("return"))
            }
        }
        select("status") {
            Text("Status")
        }

        select("flags") {
            switch {
                select("add") {
                    addContentFlag()
                }

                select(Regex("[a-z-]+")) {
                    contentFlag(match)
                }
                default {
                    contentFlags()
                }
            }
        }

        select("help") {
            helpPage()
        }

        default {
            primaryContentArea {
                podcastsDashboard()
            }
        }
    }
}