package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.aggregator.CloudEvent
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.jsonObject

/**
 * Describes data changes for a given entity.
 */
data class CloudEventChanges(
    val changedKeys: List<String>,
    val changes: List<CloudEventChange>,
    val previousValues: Map<String, JsonElement?> = changes.associateBy { it.key }.mapValues { (_, value) ->
        value.oldValue
    }
)

/**
 * Describes a content change.
 * @param key the root key that has changed
 * @param value the new value
 * @param oldValue the old value
 */
data class CloudEventChange(
    val key: String,
    val value: JsonElement?,
    val oldValue: JsonElement?
)

/**
 * Create a change description of two cloud events. This searches only at the root of the object and
 * compares the tree of each key.
 */
fun diffEvents(current: CloudEvent, previous: CloudEvent): CloudEventChanges {
    val currentJson = current.data?.jsonObject
    val previousJson = previous.data?.jsonObject

    val allKeys = currentJson?.keys.orEmpty() + previousJson?.keys.orEmpty()
    val changes = allKeys.mapNotNull { key ->
        val currentFieldValue = currentJson?.get(key)?.toCanonical()
        val previousFieldValue = previousJson?.get(key)?.toCanonical()

        if (currentFieldValue?.toString() != previousFieldValue?.toString()) {
            CloudEventChange(key, currentFieldValue, previousFieldValue)
        } else {
            null
        }
    }

    return CloudEventChanges(
        changes.map { it.key }.distinct(),
        changes
    )
}

/**
 * Convert a JsonElement to a canonical form by sorting object keys.
 */
fun JsonElement.toCanonical(): JsonElement {
    return when (this) {
        is JsonObject -> {
            this.jsonObject.map { (key, value) ->
                key to value
            }.sortedBy { it.first }.let {
                buildJsonObject {
                    it.forEach {
                        put(it.first, it.second.toCanonical())
                    }
                }
            }
        }

        else -> this
    }
}