package com.siriusxm.pia.rest.unifiedaggregator

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject

@Serializable
data class AppConfigFeatureFlagsDocument(
    val flags: Map<String, AppConfigFeatureFlag>,
    val values: Map<String, JsonObject>,
    val version: String
)

@Serializable
data class AppConfigFeatureFlag(
    val name: String,
    val description: String? = null,
    val attributes: Map<String, AppConfigAttribute>? = null
)

@Serializable
data class AppConfigAttribute(
    val constraints: AppConfigConstraints
)

@Serializable
data class AppConfigConstraints(
    val type: String
)


@Serializable
class FeatureFlagsUpdate(
    val flags: List<FeatureFlagUpdate>,
    val versionLabel: String? = null,
    val deploymentDescription: String? = null
)

@Serializable
data class FeatureFlagUpdate(
    val id: String,
    val enabled: Boolean,
    val attributes: Map<String, JsonElement>?
)
