package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.utils.isTypedEntity
import com.siriusxm.pia.utils.prettyPrint
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.offsetAt
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.seconds

object JsonViewStyles : StyleSheet() {
    val copyContentIcon by style {
        display(DisplayStyle.InlineBlock)
        marginLeft(5.px)
        property("visibility", "hidden")
        cursor("pointer")
    }

    val copyableContentContainer by style {
        self + hover style {
            className(copyContentIcon) style {
                property("visibility", "visible")
            }
        }
    }

    val fadeOut by keyframes {
        from {
            opacity(1)
        }
        to {
            opacity(0)
        }
    }
    val fadeIn by keyframes {
        from {
            opacity(0)
        }
        to {
            opacity(1)
        }
    }

}

/**
 * A view of JSON data that can either be shown as a table or as raw data
 */
@Composable
fun jsonView(
    entity: JsonObject, hideEmptyOrNull: Boolean = false,
    renderer: JsonViewRenderer = DefaultJsonViewRenderer(hideEmptyOrNull)
) {
    Style(JsonViewStyles)

    var view by remember { mutableStateOf("table") }

    Div {
        Div({
            style {
                textAlign("right")
                margin(.3.em, 0.px)
            }
        }) {
            buttonRadioGroup(view) {
                option("Table", "table")
                option("JSON", "json")
                onChange {
                    view = it
                }
            }
        }

        box({
            paddedContent = false
        }) {
            if (view == "table") {
                renderer.renderRoot(entity)
            } else if (view == "json") {
                jsonTextView(entity)
            } else {
                // nothing
            }
        }
    }
}

interface JsonViewRenderer {
    @Composable
    fun renderElement(parent: JsonElement, name: String?, element: JsonElement)

    @Composable
    fun renderFieldLabel(parent: JsonElement, name: String, value: JsonElement?)

    @Composable
    fun renderPrimitive(parent: JsonElement?, name: String?, element: JsonPrimitive)

    @Composable
    fun renderRoot(element: JsonElement)

    @Composable
    fun renderObject(parent: JsonElement?, name: String?, element: JsonObject)

    @Composable
    fun renderArray(parent: JsonElement?, name: String?, element: JsonArray)

    @Composable
    fun renderNull(parent: JsonElement?, name: String?, element: JsonNull)
}

open class DefaultJsonViewRenderer(
    private val hideEmptyOrNull: Boolean = true
) : JsonViewRenderer {
    @Composable
    override fun renderRoot(element: JsonElement) {
        (element as? JsonObject)?.let {
            renderObject(null, null, it)
        }
    }

    @Composable
    override fun renderObject(parent: JsonElement?, name: String?, element: JsonObject) {
        val elements = element.entries.map { it.key to it.value }.filterNot {
            hideEmptyOrNull && it.second == JsonNull
        }

        table<Pair<String, JsonElement>> {
            items(elements)
            column {
                style {
                    this.property("vertical-align", "top")
                    whiteSpace("nowrap")
                    fontWeight(700)
                }
                content {
                    renderFieldLabel(element, it.first, it.second)
                }
            }
            column {
                width = 100.percent
                style {
                    padding(0.px)
                }
                content {
                    renderElement(element, it.first, it.second)
                }
            }
        }
    }

    @Composable
    override fun renderArray(parent: JsonElement?, name: String?, element: JsonArray) {
        element.forEach { el ->
            Div {
                renderElement(element, null, el)
            }
        }
    }

    @Composable
    override fun renderElement(parent: JsonElement, name: String?, element: JsonElement) {
        when (element) {
            is JsonPrimitive -> {
                renderPrimitive(parent, name, element)
            }

            is JsonObject -> {
                renderObject(parent, name, element)
            }

            is JsonArray -> {
                renderArray(parent, name, element)
            }

            is JsonNull -> {
                renderNull(parent, name, element)
            }
        }
    }

    @Composable
    override fun renderNull(parent: JsonElement?, name: String?, element: JsonNull) {
        // default do nothing
    }

    @Composable
    override fun renderPrimitive(parent: JsonElement?, name: String?, element: JsonPrimitive) {
        Div({ classes(JsonViewStyles.copyableContentContainer) }) {
            val content = element.content
            if (content.isTypedEntity()) {
                A(href = "#aggregator/entity/${content}") {
                    Text(element.content)
                }
            } else {
                val ts = content.timestamp()
                if (ts != null) {
                    Text(ts.toLocalDateTimeString() + " ${TimeZone.currentSystemDefault().offsetAt(ts)}")
                } else {
                    Text(element.content)
                }
            }
            copyContentIcon(element.content)

            if (content.isHexColorValue()) {
                colorSwatch(content.toCSSColorValue(), height = 25, width = 25)
            }
        }
    }

    @Composable
    override fun renderFieldLabel(parent: JsonElement, name: String, value: JsonElement?) {
        Text(name)
    }
}

/**
 * JSON data as a table
 */
@Composable
fun jsonTable(
    obj: JsonObject, hideEmptyOrNull: Boolean = false,
    renderer: JsonViewRenderer = DefaultJsonViewRenderer(hideEmptyOrNull)
) {
    renderer.renderRoot(obj)
}


fun String.timestamp(): Instant? {
    return try {
        Instant.parse(this)
    } catch (t: Throwable) {
        null
    }
}

@Composable
fun copyContentIcon(content: String? = null) {
    if (!content.isNullOrBlank()) {
        Span({ classes(JsonViewStyles.copyContentIcon) }) {
            icon("content_copy") {
                size = IconSize.TINY

                action {
                    window.navigator.clipboard.writeText(content)
                }
            }
        }
    }
}

@Composable
fun jsonTextView(element: JsonElement) {
    var copied by remember { mutableStateOf(false) }

    LaunchedEffect(copied) {
        if (copied) {
            delay(6.seconds)
            copied = false
        }
    }

    Div({
        style {
            position(Position.Relative)
        }
    }) {
        Pre({
            style {
                backgroundColor(SXMUI.containerContentBackgroundColor.value())
                padding(1.em)
                whiteSpace("pre-wrap")
            }
        }) {
            Text(element.toString().prettyPrint())
        }

        Span({
            style {
                position(Position.Absolute)
                top(5.px)
                right(5.px)
            }
        }) {
            if (copied) {
                Div({
                    style {
                        val animations = listOf(
                            CSSAnimation(JsonViewStyles.fadeOut.name).apply {
                                duration(500.ms)
                                delay(5.s)
                                timingFunction(AnimationTimingFunction.EaseOut)
                                fillMode(AnimationFillMode.Forwards)
                            },
                            CSSAnimation(JsonViewStyles.fadeIn.name).apply {
                                duration(200.ms)
                                timingFunction(AnimationTimingFunction.EaseIn)
                                fillMode(AnimationFillMode.Forwards)
                            }
                        )
                        property("animation", animations.joinToString(","))
                    }
                }) {
                    Text("copied to clipboard")
                }
            } else {
                icon("content_copy") {
                    size = IconSize.TINY

                    action {
                        window.navigator.clipboard.writeText(element.toString())
                        copied = true
                    }
                }
            }
        }
    }
}