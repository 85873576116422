package com.siriusxm.pia.rest.transcription

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class VocabularyResponse(
    val id: String? = null,
    val vocabularyEntityId: String,
    val created: Instant? = null,
    val status: DictionaryStatus? = null,
    val summary: VocabularySummary? = null
)

enum class DictionaryStatus {
    Completed, Failed
}

@Serializable
data class VocabularySummary(
    val vocabularyPhrasesCount: Int,
    val vocabularyPhrases: List<String>? = null,
    var vocabularyPhrasesLink: String? = null
)

@Serializable
data class VocabularyUpdateRequest(
    val customVocabularyPhrases: List<String>
)

@Serializable
data class VocabularyRequest(
    val vocabularyEntityId: String,
    val customVocabularyPhrases: List<String> ){
}