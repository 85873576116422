package com.siriusxm.pia.views.artistradio

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.artistradio.ArtistStationHolder
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.cancellation.CancellationException

@Composable
fun artistRadioBulkEdit(artistStationIdStr: String, action: String, returnPath: String? = null) {

    var loading by mutableStateOf(false)

    var confirmation by remember { mutableStateOf("") }

    var artistRadioStations  by remember { mutableStateOf(emptyList<ArtistStationHolder>()) }

    LaunchedEffect(artistStationIdStr) {
        try {
            loading = true

            console.log("Begin looking up artist radio station by id")
            val artistStations = Application.artistStationAPI.fetchArtistStation(artistStationIdStr)
            val results = artistStations.values.toList()

            if (results.isNotEmpty()) {
                artistRadioStations = artistStations.values.mapNotNull {
                    it.artist?.let { artist -> ArtistStationHolder(it.artistStationId, artist).also {
                        holder ->
                            if (action == "show") {
                                holder.show()
                            } else  if (action == "hide") {
                                holder.hide()
                            }
                        }
                    }
                }
            }
            console.log("Done looking up artist radio stations by ids artistRadioStations: $artistRadioStations")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            artistRadioStations = emptyList()
        } finally {
            loading = false
        }
    }


    dialogView("Bulk $action Artist Radio Stations") {
        action {
            title = "Cancel"
            action {
                Application.navigation.navigate(returnPath ?: "artist-radio/artistradio-dashboard")
            }
        }

        if (confirmation == "confirm") {
            action {
                title = "Confirm"
                primary = true
                showProgressOnAction = true
                action {
                    try {
                        bulkChange(artistRadioStations)
                        Application.navigation.navigate(returnPath ?: "artist-radio/artistradio-dashboard")
                    } catch (t: Throwable) {

                    }
                }
            }
        }

        content {
            messageBox(
                """When you bulk edit artist radio stations, they will ALL be ${getPluralActonName(action)}."""
            )

            box({
                title = "Bulk $action artist stations?"
            }) {
                P {
                    Text("This operation will bulk edit the following Artist Stations to be ${getPluralActonName(action).uppercase()}:")
                }

                P {
                    Text("To confirm, type 'confirm' in the field below")
                }
                Input(InputType.Text) {
                    onInput { event ->
                        confirmation = event.value
                    }
                }

                Div({
                    classes(ServiceViewStyles.subHead)
                }) {
                    table<ArtistStationHolder> {
                        items(artistRadioStations)

                        column {
                            title = "TypedEntityId"
                            content { summary ->
                                Div {
                                    Text(summary.entityId)
                                }
                            }
                        }
                        column {
                            title = "Name"
                            content { summary ->
                                Div {
                                    Text(summary.artistName ?: "")
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

suspend fun bulkChange(artistRadioStations: List<ArtistStationHolder>) {
    artistRadioStations.forEach {station ->
        station.changedValues().forEach {(flag, value) ->
            Application.artistStationAPI.setFlags(station.entityId, flag.label.lowercase(), value.toString())
        }
    }
}

private fun getPluralActonName(action: String) : String {
    return if (action == "show") {
        "shown"
    } else {
        "hidden"
    }
}
