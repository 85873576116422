package com.siriusxm.pia.views.channelguide

import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*

object ChannelGuideStyles : StyleSheet() {

    val channelList by style {
        display(DisplayStyle.Block)
        gap(20.px)
        padding(20.px)
        overflowY("auto")
    }

    val listItem by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        marginRight(10.px)
        minHeight(120.px)
        padding(10.px)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        border {
            color = SXMUI.borderDividerDefault.value()
            style(LineStyle.Solid)
        }
        borderWidth(0.px, 0.px, 1.px, 0.px)

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
            textDecorationStyle("none")
        }
        alignItems(AlignItems.Center)
    }

}