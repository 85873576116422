package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toAMPMTimeString
import com.siriusxm.pia.utils.toLocalDateTime
import contentingestion.aggregator.*
import kotlinx.browser.document
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.seconds

/**
 * Renders the main data types view, which includes entity types and partials.
 */
@Composable
fun dataTypes(
    context: AggregatorService,
    route: Route
) {
    var selection = "entities"
    var initialSelect: String? = null
    route.switch {
        select("entities") {
            switch {
                select(Regex(".+")) {
                    initialSelect = match.takeIf { it.isNotBlank() }
                }
                default {
                }
            }
        }
        select("partials") {
            selection = "partials"
            switch {
                select(Regex(".*")) {
                    initialSelect = match.takeIf { it.isNotBlank() }
                }
                default {
                }
            }
        }
    }

    serviceContentView({
        title = "Data Types"
        breadcrumbs {
            crumb("Aggregator", "aggregator")
            crumb("Types", null)
        }
    }) {
        tabView {
            this.onSelect {
                context.navigate("aggregator/types/${it}")
                false
            }
            this.selection = selection
            tab("Entity Types", "entities") {
                entityTypes(context, initialSelect = initialSelect)
            }
            if (context.partials.isNotEmpty()) {
                tab("Partial Schemas", "partials") {
                    partials(context, initialSelect = initialSelect)
                }
            }
        }
    }
}

/**
 * Renders the entity types
 */
@Composable
fun entityTypes(
    context: AggregatorService,
    initialSelect: String? = null
) {
    val consumers = context.consumers
    val producers = context.producers

    splitSelectionView<EntityTypeConfiguration> {
        items = context.entityTypes
        selection = initialSelect?.let {
            context.entityTypes.find { it.type == initialSelect }
        }

        renderListItem {
            Text(it.name)
        }

        render {
            entityType(context, it, producers, consumers)
        }

        onSelect {
            Application.navigation.navigate("aggregator/types/entities/${it.type}")
        }

        column {
            title = "Version"
            content {
                Text(it.version)
            }
        }
    }
}

data class Period(
    val name: String,
    val duration: Duration,
    val period: Int
)

val periods = listOf(
    Period("1h", 1.hours, 60),
    Period("3h", 3.hours, 300),
    Period("6h", 6.hours, 600),
    Period("12h", 12.hours, 600),
    Period("1d", 24.hours, 900),
    Period("1w", 7.days, 3600),
)

fun Period.end(ts: Instant = Clock.System.now()): Instant {
    val epochSeconds = ts.epochSeconds
    val truncatedEpochSeconds = epochSeconds - (epochSeconds % this.period)
    return Instant.fromEpochSeconds(truncatedEpochSeconds)
}

fun Period.start(ts: Instant = Clock.System.now()): Instant {
    return end(ts) - duration
}

fun Period.range(ts: Instant = Clock.System.now()): ClosedRange<Instant> {
    return start(ts)..end(ts)
}

data class DataPoint(val ts: Instant, val value: Double)

fun MetricData.allData(period: Period): List<DataPoint> {
    val range = period.range()
    var instant = range.endInclusive
    val results = arrayListOf<DataPoint>()
    do {
        val index = this.timestamps?.indexOfFirst { it == instant }?.takeIf { it >= 0 }
        val value = index?.let { this.values?.get(index) }

        // we don't include the most recent data point if it's null
        if (value != null || instant != range.endInclusive) {
            results += DataPoint(instant, value ?: 0.0)
        }
        instant -= period.period.seconds
    } while (instant > range.start)

    return results.toList().reversed()
}

fun sumMetricData(period: Period, metrics: MetricDataResponseList): List<DataPoint> {
    return metrics.map {
        it.allData(period)
    }.flatten().groupBy {
        it.ts
    }.map { (ts, points) ->
        DataPoint(ts, points.sumOf { it.value })
    }
}

val colors = mapOf(
    "updateEvents" to arrayOf("rgba(200,0,0,1)"),
    "addEvents" to arrayOf("rgba(0, 200, 0, 1)"),
    "removeEvents" to arrayOf("rgba(0, 0, 200, 1)")
)

@Composable
fun entityType(
    aggregator: AggregatorService,
    entityType: EntityTypeConfiguration,
    producers: List<ProducerDetails>,
    consumers: List<ConsumerDetails>
) {
    var metricsPeriod by remember { mutableStateOf(periods.first()) }
    var metrics by remember { mutableStateOf<List<MetricData>?>(null) }

    LaunchedEffect(entityType.type, metricsPeriod) {
        metrics = null
        val range = metricsPeriod.range()
        try {
            metrics = aggregator.api.getMetrics(
                GetMetricDataRequest(
                    range.start, range.endInclusive, listOf("update", "add", "remove").map { updateType ->
                        MetricDataRequest(
                            "${updateType}Events",
                            null,
                            MetricStat(
                                "AtlasEntityPublished",
                                "aggregator/${Application.configuration.stage}",
                                listOf(
                                    Dimension("EntityType", entityType.type),
                                    Dimension("UpdateType", "com.siriusxm.unifiedcontentbus.${updateType}")
                                ),
                                metricsPeriod.period,
                                "Sum"
                            )
                        )
                    }
                )
            ).data
        } catch (t: Throwable) {
            t.printStackTrace()
        }
    }

    entityView({
        title = entityType.name
        subTitle = entityType.type + ":" + entityType.version
    }) {
        box("Producers", {
            paddedContent = false
        }) {
            val matchedProducers =
                producers.filter { it.allowedTypes.orEmpty().contains(entityType.type) }
            if (matchedProducers.isEmpty()) {
                boxMessage("No producers found for this type")
            } else {
                table<ProducerDetails> {
                    items(matchedProducers)
                    column {
                        content {
                            A("#aggregator/producers/${encodeURIComponent(it.id)}") {
                                Text(it.name.orEmpty())
                            }
                        }
                    }
                }
            }
        }

        box("Consumers", {
            paddedContent = false
        }) {
            val matchedConsumers = consumers.filter { it.types.orEmpty().contains(entityType.type) }
            if (matchedConsumers.isEmpty()) {
                boxMessage("No consumers found for this type.")
            } else {
                table<ConsumerDetails> {
                    items(matchedConsumers)
                    column {
                        content {
                            A("#aggregator/consumers/${encodeURIComponent(it.id)}") {
                                Text(it.name)
                            }
                        }
                    }
                }
            }
        }

        if (metrics != null) {
            box({
                title = "Publishing Metrics"
                header({
                    actionContent {
                        Div({
                            classes(AggregatorStyles.timePeriodSelectionBox)
                        }) {
                            periods.forEach { availablePeriod ->
                                Span({
                                    classes(AggregatorStyles.timePeriod)
                                    if (metricsPeriod == availablePeriod) {
                                        classes(AggregatorStyles.timePeriodSelected)
                                    } else {
                                        onClick {
                                            it.preventDefault()
                                            metricsPeriod = availablePeriod
                                        }
                                    }
                                }) {
                                    Text(availablePeriod.name)
                                }
                            }
                        }
                    }
                }) {}
            }) {


                Canvas(attrs = {
                    id("myChart")
                    style {
                        width(100.percent)
                        height(300.px)
                    }
                })
            }
        }

        if (metrics != null) {
            DisposableEffect(metrics) {
                Chart.register(
                    ChartComponents.LineController,
                    ChartComponents.LineElement,
                    ChartComponents.PointElement,
                    ChartComponents.TimeScale,
                    ChartComponents.LinearScale,
                    ChartComponents.Legend,
                    ChartComponents.Tooltip,
                )

                val canvas = document.getElementById("myChart") as? HTMLCanvasElement
                val ctx = canvas?.getContext("2d") as? CanvasRenderingContext2D

                val config = new<ChartConfiguration> {
                    type = "bar"
                    data = new {
                        datasets = metrics?.map {
                            new<Dataset> {
                                label = it.id.replace("Events", "")
                                data = it.allData(metricsPeriod).map { value ->
                                    new<DatasetPoint> {
                                        y = value.value.toInt()
                                        x = value.ts.toLocalDateTime().toAMPMTimeString(false, false)
                                    }
                                }.toTypedArray()
                            }
                        }.orEmpty().toTypedArray()
                    }
                    options = new {
                        responsive = true
                        maintainAspectRatio = false
                        devicePixelRatio = 4
                        plugins = new {
                            legend = new {
                                display = true
                            }
                        }
                        scales = new {
                            x = new {
                                stacked = true
                            }
                            y = new {
                                stacked = true
                            }
                        }
                    }
                }

//                val config = object : ChartConfiguration {
//                    override var type = "bar"
//                    override var data: ChartData = object : ChartData {
//                        override var datasets: Array<Dataset> = metrics?.map { metric ->
//                            object : Dataset {
//                                override var label: String? = null
//                                override var data: Array<dynamic> = metric.allData(metricsPeriod).map { value ->
//                                    val obj = object {}.asDynamic()
//                                    obj["y"] = value.value.toInt()
//                                    obj["x"] = value.ts.toLocalDateTime().toAMPMTimeString(false, false)
//                                    obj
//                                }.toTypedArray()
//                                override var borderColor: String? = colors[metric.id]!![0]
//                                override var backgroundColor: Array<String>? = colors[metric.id]
//                            }
//                        }.orEmpty().toTypedArray()
//                        override var labels: Array<String>? = null
//                    }
//                    override var options: ChartOptions? = object : ChartOptions {
//                        override var responsive: Boolean? = true
//                        override var scales: Scales? = new {
//                            x = new {
//                                stacked = true
//                            }
//                            y = new {
//                                stacked = true
//                            }
//                        }
//                        override var maintainAspectRatio: Boolean? = false
//                        override var devicePixelRatio: Number? = 4
//                        override var plugins: Plugins? = object : Plugins {
//                            override var legend: Legend? = object : Legend {
//                                override var display: Boolean? = false
//                            }
//                        }
//                    }
//                }
                val chart = Chart.Chart(ctx, config)

                onDispose {
                    chart.destroy()
                }
            }
        }
    }
}

/**
 * Renders partial schemas
 */
@Composable
fun partials(aggregator: AggregatorService, initialSelect: String? = null) {
    splitSelectionView<PartialSchema> {
        items = aggregator.partials
        selection = initialSelect?.let {
            aggregator.partials.find { it.id == initialSelect }
        }

        renderListItem {
            Text(it.name ?: it.id)
        }

        render {
            partial(aggregator, it)
        }

        onSelect {
            Application.navigation.navigate("aggregator/types/partials/${it.id}")
        }

        column {
            content {
                it.description?.let {
                    Text(it)
                }
            }
        }
    }
}

@Composable
fun partial(aggregator: AggregatorService, schema: PartialSchema) {
    entityView({
        title = schema.name ?: schema.id
        subTitle = schema.id
    }) {
        box("Producers", {
            paddedContent = false
        }) {
            val matchedProducers =
                aggregator.producers.filter { it.allowedPartials.orEmpty().contains(schema.id) }
            if (matchedProducers.isEmpty()) {
                boxMessage("No producers produce this partial")
            } else {
                table<ProducerDetails> {
                    items(matchedProducers)
                    column {
                        content {
                            A("#aggregator/producers/${encodeURIComponent(it.id)}") {
                                Text(it.name.orEmpty())
                            }
                        }
                    }
                }
            }
        }

        box("Types", {
            paddedContent = false
            header({
                instruction = "These types can be modified"
            })
        }) {
            table<String> {
                this.items(schema.types)
                column {
                    content {
                        val entityTypeId = it.substringAfterLast("/", "")
                            .substringBefore(":").ifBlank { null }
                        val entityType = entityTypeId?.let { aggregator.entityType(entityTypeId) }
                        val label = entityType?.name ?: entityTypeId
                        label?.let {
                            A(href = "#aggregator/types/entities/${entityTypeId}") {
                                Text(it)
                            }
                        }
                    }
                }
            }
        }

        box("Fields", {
            paddedContent = false
            header({
                instruction = "These fields are modified"
            })
        }) {
            table<String> {
                this.items(schema.paths)
                column {
                    content {
                        Text(it.removePrefix("$."))
                    }
                }
            }
        }
    }
}