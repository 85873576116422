package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.CloudEvent
import com.siriusxm.pia.rest.unifiedaggregator.EventSearchStatus
import com.siriusxm.pia.rest.unifiedaggregator.SearchStatusState
import com.siriusxm.pia.rest.unifiedaggregator.getString
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.coroutines.delay
import kotlinx.serialization.json.jsonObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours

val eventPeriods = listOf(
    Period("1d", 24.hours, 1440),
    Period("3d", 3.days, 4320),
    Period("1w", 7.days, 10080),
    Period("2w", 12.days, 20160),
    Period("1mo", 31.days, 43200),
    Period("3mo", 93.days, 129600),
)

/**
 * Displays events for an entity.
 */
@Composable
fun entityEvents(aggregatorContext: AggregatorService, entityId: String) {
    var loading by remember { mutableStateOf(false) }
    var result by remember { mutableStateOf<EventSearchStatus?>(null) }
    var failureMessage by remember { mutableStateOf<String?>(null) }
    var expanded by remember { mutableStateOf<Set<String>>(emptySet()) }

    var eventsPeriod by remember { mutableStateOf<Period?>(null) }

    LaunchedEffect(entityId, eventsPeriod) {
        if (eventsPeriod != null) {
            loading = true
            val startTime = eventsPeriod!!.start()
            var query = aggregatorContext.api.entityEvents(entityId, null, startTime)
            try {
                while (query.state == SearchStatusState.IN_PROGRESS) {
                    delay(500)
                    query = aggregatorContext.api.entityEvents(entityId, query.id, startTime)
                }

                if (query.state == SearchStatusState.COMPLETED) {
                    result = query
                }

            } catch (e: Throwable) {
                failureMessage = e.message ?: "The event search failed for an unknown reason."
            }
            loading = false
        }
    }

    fun getHumanReadableEventType(event: CloudEvent): String {
        return when (event.type) {
            "Content Update" -> {
                val updateType = event.tracingcontext?.getString("updateType")
                when (updateType) {
                    "ADD" -> "Add Request"
                    "REMOVE" -> "Remove Request"
                    else -> "Update Request"
                }
            }

            "com.siriusxm.unifiedcontentbus.add" -> "Add Published"
            "com.siriusxm.unifiedcontentbus.update" -> "Update Published"
            "com.siriusxm.unifiedcontentbus.remove" -> "Remove Published"
            else -> event.type
        }
    }

    box({
        paddedContent = false
    }) {
        if (loading) {
            boxSpinner("Loading events... (this can take a few seconds)")
        } else if (failureMessage != null) {
            messageBox(failureMessage!!, MessageType.WARNING)
        } else {
            box({
                paddedContent = false
                title = "Events"
                header({
                    actionContent {
                        Div({
                            classes(AggregatorStyles.timePeriodSelectionBox)
                        }) {
                            eventPeriods.forEach { availablePeriod ->
                                Span({
                                    classes(AggregatorStyles.timePeriod)
                                    if (eventsPeriod == availablePeriod) {
                                        classes(AggregatorStyles.timePeriodSelected)
                                    } else {
                                        onClick {
                                            it.preventDefault()
                                            eventsPeriod = availablePeriod
                                        }
                                    }
                                }) {
                                    Text(availablePeriod.name)
                                }
                            }
                        }
                    }
                }) {}
            }) {
                if (eventsPeriod == null) {
                    messageBox ("Please select a time period to veiw Events. The loading time will vary with each time period", MessageType.INFO)
                } else {
                table<CloudEvent> {
                    items(result?.results.orEmpty())

                    column {
                        width = 20.px
                        style {
                            property("vertical-align", "top")
                        }
                        content { event ->
                            if (expanded.contains(event.id)) {
                                icon("arrow_drop_down") {
                                    action {
                                        expanded = expanded - event.id
                                    }
                                }
                            } else {
                                icon("arrow_right") {
                                    action {
                                        expanded = expanded + event.id
                                    }
                                }
                            }
                        }
                    }

                    column {
                        content { event ->
                            Div {
                                Div({ style { margin(3.px, 0.px) } }) {
                                    Span({
                                        style {
                                            fontStyle("italic")
                                            opacity(.8)
                                        }
                                    }) {
                                        Text("${event.time.toLocalDateTimeString()}:")
                                    }
                                    Span({
                                        style {
                                            marginLeft(5.px)
                                            fontWeight(700)
                                        }
                                    }) {
                                        Text(getHumanReadableEventType(event))
                                    }
                                }
                                if (expanded.contains(event.id)) {
                                    Div {
                                        event.data?.let {
                                            Div({
                                                style {
                                                    backgroundColor(SXMUI.containerContentBackgroundColor.value())
                                                }
                                            }) {
                                                jsonTable(null, it.jsonObject, true)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            }
        }
    }
}