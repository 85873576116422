package com.siriusxm.pia.rest.unifiedaggregator

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject


@Serializable
data class BatchDescriptor(
    val batchId: String,
    val parameterType: String
)

@Serializable
data class BatchingRequest(
    val batchId: String,
    val batchParameters: JsonObject
)