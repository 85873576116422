package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.IconSize
import com.siriusxm.pia.components.IconType
import com.siriusxm.pia.components.icon
import com.siriusxm.pia.components.symbol
import org.jetbrains.compose.web.css.opacity
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div

/**
 * Render a link to navigate to the aggregator for an element.
 */
@Composable
fun aggregatorIconLink(id: String?) {
    if (id != null) {
        A(href = "#aggregator/entity/${id}", attrs = {
            classes("aggregator-icon-link")
        }) {
            icon("database_search") {
                type = IconType.SYMBOLS
                size = IconSize.TINY
                title = "View in Aggregator"

                style {
                    property("vertical-align", "top")
                }
            }
        }
    }
}

/**
 * Render icons representing flags in an entity
 */
@Composable
fun <T> flags(flags: List<T>?, icons: Map<T, String>) {
    if (flags.isNullOrEmpty()) return
    Div({ style { opacity(.5) } }) {
        flags.forEach {
            val symbol = icons[it]
            if (symbol != null) {
                symbol(symbol) {
                    size = IconSize.SMALL
                    title = it.toString().lowercase().replaceFirstChar { it.uppercase() }.replace("_", " ")
                }
            }
        }
    }
}
