package com.siriusxm.pia.views.mddb

import androidx.compose.runtime.*
import com.siriusxm.pia.EntityAugmenter
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.prettyPrintXML
import contentingestion.unifiedmodel.EntityType
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Pre
import org.jetbrains.compose.web.dom.Text

/**
 * Provides an additional tab to display raw MDDB information for an entity.
 */
open class MddbAugmenter(val api: MddbAPI) : EntityAugmenter {
    override val name: String = "MDDB"
    override fun canAugment(entityType: EntityType): Boolean {
        return listOf(
            EntityType.CATEGORY,
            EntityType.CHANNEL_LINEAR,
            EntityType.CHANNEL_XTRA,
//            EntityType.CUT_AUDIO,
//            EntityType.CUT_LINEAR,
//            EntityType.CUT_VIDEO,
            EntityType.EPISODE_AUDIO,
            EntityType.EPISODE_BASE,
            EntityType.EPISODE_VIDEO,
//            EntityType.LINEUP,  // lineup doesn't have a lookup by id yet
            EntityType.ROYALTY_INFO_REPORT,
            EntityType.SHOW,
            EntityType.XTRA_CLIP
        ).contains(entityType)
    }

    @Composable
    override fun render(entityType: EntityType, entityId: String) {
        var enhancerData by remember { mutableStateOf<String?>(null) }
        var view by remember { mutableStateOf<String>("xml") }

        LaunchedEffect(entityId) {
            enhancerData = lookupEntityAugmentation(entityType, entityId)
        }

        if (enhancerData != null) {
            val xml = enhancerData
            if (xml != null) {
                Div({
                    style {
                        textAlign("right")
                        margin(.3.em, 0.px)
                    }
                }) {
                    buttonRadioGroup(view) {
                        option("Table", "table")
                        option("XML", "xml")
                        onChange {
                            view = it
                        }
                    }
                }

                Div {

                    if (view == "table") {
                        Style(JsonViewStyles)
                        var entity: JsonElement? = entityFromXml(xml.prettyPrintXML())

                        if (entity != null) {
                            entity = removeSingleItemElements(entity)

                            if (entity is JsonObject) {
                                entity = JsonObject(entity.filter { it.key != "messages" })
                            }

                            entity = removeSingleItemElements(entity)

                            if (entity is JsonObject) {
                                box({ paddedContent = false }) {
                                    DefaultJsonViewRenderer(true)
                                    jsonTable(entity, true)
                                }
                            }
                        }
                    } else if (view == "xml") {
                        box({ paddedContent = false }) {
                            xmlTextView(xml.prettyPrintXML())
                        }
                    } else {
                        // nothing
                    }
                }
            }
        } else {
            spinner(size = Size.NORMAL)
        }
    }

    suspend fun lookupEntityAugmentation(entityType: EntityType, entityId: String): String? {
        if (!canAugment(entityType)) return null

        return try {
            api.fetchRawEntity(entityId)
        } catch (e: Throwable) {
            null
        }
    }
}

fun removeSingleItemElements(json: JsonElement): JsonElement {
    return if (json is JsonObject) {
        if (json.size == 1) {
            removeSingleItemElements(json.values.first())
        } else {
            json
        }
    } else {
        json
    }
}


/**
 * Renders an XML element as text
 */
@Composable
fun xmlTextView(data: String) {
    Div {
        Pre({
            style {
                backgroundColor(SXMUI.containerContentBackgroundColor.value())
                padding(1.em)
                whiteSpace("pre-wrap")
            }
        }) {
            Text(data)
        }
    }
}

fun entityFromXml(xml: String): JsonObject {
    val cleanedXml = xml.substringAfter("?>").trim() // Remove the XML header
    val stack = mutableListOf<Pair<String, MutableMap<String, JsonElement>>>()
    var currentMap = mutableMapOf<String, JsonElement>()

    val regex = Regex("<(/?)([a-zA-Z0-9]+)>([^<]*)")
    val matches = regex.findAll(cleanedXml)

    for (match in matches) {
        val isClosing = match.groupValues[1] == "/"
        val tagName = match.groupValues[2]
        val content = match.groupValues[3].trim()

        if (isClosing) {
            // Closing tag
            val (parentTag, parentMap) = stack.removeLast()
            if (currentMap.size == 1 && currentMap.containsKey(tagName)) {
                // Collapse single-element maps into primitives
                parentMap[parentTag] = currentMap[tagName] ?: JsonNull
            } else {
                parentMap[parentTag] = JsonObject(currentMap)
            }
            currentMap = parentMap
        } else {
            // Opening tag
            stack.add(tagName to currentMap)
            currentMap = mutableMapOf()
            if (content.isNotEmpty()) {
                currentMap[tagName] = JsonPrimitive(content)
            }
        }
    }

    return JsonObject(currentMap)
}
