package com.siriusxm.pia.views.unifiedaggregator.events

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.entityType
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.smithy.SmithyViewOptions
import com.siriusxm.pia.views.unifiedaggregator.smithy.smithyEntityView
import com.siriusxm.pia.views.unifiedaggregator.smithy.smithyTableView
import com.siriusxm.smithy4kt.SmithyMember
import com.siriusxm.smithy4kt.query
import com.siriusxm.smithy4kt.resolveJsonPath
import contentingestion.aggregator.AuditContext
import contentingestion.aggregator.ContentUpdateType
import contentingestion.aggregator.PartialUpdate
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.jsonObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

/**
 * Renders a partial event.
 * @param onDelete if set, the view will include controls to allow the viewer to send a REMOVE event for this
 * partial. The callback will be invoked if the REMOVE has taken place.
 */
@Composable
fun partialEventView(
    aggregator: AggregatorService,
    partial: PartialUpdate,
    onDelete: (suspend () -> Unit)? = null
) {
    jsonView(partial.modification.jsonObject, true, object : DefaultJsonViewRenderer(true) {
        @Composable
        override fun renderRoot(element: JsonElement) {
            var deleting by remember { mutableStateOf(false) }

            Div({
                style {
                    position(Position.Relative)
                    padding(5.px)
                }
            }) {
                if (onDelete != null) {
                    Div({
                        style {
                            position(Position.Absolute)
                            top(5.px)
                            right(5.px)
                        }
                    }) {
                        icon("delete") {
                            title = "Delete this partial"
                            action {
                                deleting = true
                            }
                        }
                    }
                }

                detailGrid {
                    detail(
                        "Partial",
                        aggregator.partial(partial.partialId)?.name
                            ?: partial.partialId
                    )

                    partial.auditContext?.author?.let {
                        detail("Author", it)
                    }
                    partial.producer?.let { producerId ->
                        aggregator.producerById(producerId)?.name ?: producerId
                    }?.let {
                        detail("Producer", it)
                    }
                }
            }
            Div {
                val entity = partial.modification
                val model = aggregator.entityModel
                val shape = entity.entityType?.let {
                    aggregator.entityType(it)
                }?.shapeId?.let {
                    model?.getShape(it)
                }

                if (!partial.operations.isNullOrEmpty()) {
                    partial.operations?.forEach { operation ->
                        Div {
                            H4({
                                style { margin(0.px, 5.px) }
                            }) {
                                Text(operation.type.name)
                            }
                            val targetShape = shape?.resolveJsonPath(operation.targetPath)
                            val sourceData = (operation.sourcePath ?: operation.targetPath).let {
                                partial.modification.query(it)
                            }.firstOrNull()

                            val viewerOption = SmithyViewOptions()
                            if (targetShape != null && sourceData != null) {
                                if (targetShape is SmithyMember) {
                                    smithyTableView(
                                        listOf(targetShape.name to targetShape),
                                        viewerOption,
                                        buildJsonObject {
                                            put(targetShape.name, sourceData)
                                        }
                                    )
                                } else {
                                    smithyEntityView(targetShape, sourceData)
                                }
                            } else {
                                super.renderRoot(partial.modification)
                            }
                        }
                    }
                } else if (shape != null) {
                    smithyEntityView(shape, partial.modification)
                } else {
                    super.renderRoot(partial.modification)
                }
            }

            if (deleting) {
                confirmationDialog(
                    "Delete this partial?",
                    "Are you sure you'd like to remove this partial? There is no way to undo this. Type 'delete' in the field below to confirm.",
                    "Delete",
                    "delete"
                ) { deleted ->
                    if (deleted) {
                        aggregator.api.partialUpdate(
                            partial.copy(
                                ContentUpdateType.REMOVE,
                                auditContext = aggregator.context.viewer.email?.let {
                                    AuditContext(it)
                                }
                            )
                        )
                        onDelete?.invoke()
                    }
                    deleting = false
                }

            }
        }
    })
}