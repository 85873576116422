package com.siriusxm.pia.views

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

/**
 * The help page
 */

@Composable
fun helpPage() {

    box({
        paddedContent = true
        header({
            title = "Help"
        }) {
            Div({
                style {
                    width(100.percent)
                }
            }) {
                Div {
                    A("/") {
                        Text("Home")
                    }
                }
                Div {
                    A("/#unified-content-bus") {
                        Text("Unified Content Bus")
                    }
                }
                Div {
                    A("/#transcription-dashboard") {
                        Text("Transcriptions")
                    }
                }
                Div {
                    A("/#transcriptions/vocabulary") {
                        Text("Transcriptions - Vocabularies")
                    }
                }
                Div {
                    A("/#artistradio-dashboard") {
                        Text("Artist Radio")
                    }
                }
            }
        }
    })
}
