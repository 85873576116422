package com.siriusxm.pia.views.sports

import com.siriusxm.pia.rest.sports.SportingEventsClient
import com.siriusxm.pia.rest.sports.SportsClient
import contentingestion.unifiedmodel.League
import contentingestion.unifiedmodel.Score
import contentingestion.unifiedmodel.SportingEvent
import contentingestion.unifiedmodel.Team
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

class SportsAPI(sportsAPIBaseUrl: String,
                private val authGetter: () -> String) {
    private val json = Json { isLenient = true; ignoreUnknownKeys = true }
    private val ktorfitSportsClient = Ktorfit.Builder()
        .baseUrl(sportsAPIBaseUrl)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
        )
        .build()

    private val ktorfitSportEventsClient = Ktorfit.Builder()
        .baseUrl(sportsAPIBaseUrl)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
        )
        .build()
    private val sportsClient = ktorfitSportsClient.create<SportsClient>()
    private val sportingEventsClient = ktorfitSportEventsClient.create<SportingEventsClient>()

    private fun headers() = mapOf("Authorization" to "Bearer ${authGetter()}")

    /**
     * Fetch all of the leages to show on the ALL LEAGUES page
     */
    suspend fun fetchAllLeagues(): List<League> {
        return sportsClient.fetchAllLeagues(headers()).entities.mapNotNull {
            try {
                json.decodeFromJsonElement(League.serializer(), it)
            } catch (ex: Exception) {
                null
            }
        }
    }

    /**
     * Fetch a LEAGUE when you know its id.  This is useful on a League details page (or for a deep link)
     */
    suspend fun fetchLeague(leagueId: String) : League? {
        return sportsClient.fetchLeagueById(leagueId, headers())
    }

    /**
     * Fetch a single TEAM when you know the team id.  This is useful on a Team details page.
     */
    suspend fun fetchTeam(teamId: String) : Team? {
        return sportsClient.fetchTeamById(teamId, headers())
    }


    /**
     * This will fetch the EVENT details (SportingEvent) for the given id.
     */
    suspend fun fetchEvent(eventId: String) : SportingEvent? {
        return try {
            sportingEventsClient.fetchEventById(eventId, headers())
        } catch (e: Exception) {
            console.log("Unable to find event with id: $eventId")
            null
        }
    }

    /**
     * Fetch all of the TEAM entities for a given league.  These will be sorted alphabetically by name.
     */
    suspend fun teamsForLeague(leagueId: String) : List<Team> {
        val original  = sportsClient.fetchTeamsForLeague(leagueId, headers()).entities.map {
            json.decodeFromJsonElement(Team.serializer(), it)
        }
        return original.filter { it.id.startsWith("SP:") }.sortedBy { it.name }
    }

    /**
     * This will return the EVENTS for a given team.  The events will be returned from oldest to newest (often, furthest in the future)
     */
    suspend fun eventsForTeam(teamId: String) : List<SportingEvent> {
        val homeEvents : List<SportingEvent> = sportingEventsClient.fetchEventsForHomeTeam(teamId, headers()).entities.map {
            json.decodeFromJsonElement(SportingEvent.serializer(), it)
        }
        val awayEvents : List<SportingEvent> = sportingEventsClient.fetchEventsForAwayTeam(teamId, headers()).entities.map {
            json.decodeFromJsonElement(SportingEvent.serializer(), it)
        }
        return (homeEvents + awayEvents).sortedBy {
            it.startTime
        }
    }
    /**
     * This will return the EVENTS for a given league.  The events will be returned from oldest to newest (often, furthest in the future)
     */
    suspend fun eventsForLeagueId(leagueId: String) : List<SportingEvent> {
        val leagueEvents : List<SportingEvent> = sportingEventsClient.fetchEventsForLeague(leagueId, headers()).entities.map {
            json.decodeFromJsonElement(SportingEvent.serializer(), it)
        }
        return leagueEvents.sortedBy {
            it.startTime
        }
    }

    /**
     * This will return all of the active SCORE entities for a given event.
     */
    suspend fun scoresForEvent(eventId: String) : List<Score> {
        val scores : List<Score> = sportingEventsClient.fetchScoresForEvent(eventId, headers()).entities.map {
            json.decodeFromJsonElement(Score.serializer(), it)
        }
        return scores.sortedBy { it.timestamp }
    }
}