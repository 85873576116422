package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import kotlinx.coroutines.CancellationException
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.*

/**
 * The main transcription page.
 */

@Composable
fun deleteVocabulary(typedEntityId: String, returnPath: String? = null) {

    var deleting by mutableStateOf(false)
    var deleteConfirmation by remember { mutableStateOf("") }

    suspend fun deleteVocabulary(vocabularyId: String) {
        try {
            deleting = true

            console.log("Begin editing a vocabulary by id")
            Application.transcriptionAPI.deleteVocabulary(vocabularyId)

            // navigate to show the current state of the vocabulary
            Application.navigation.navigate(returnPath ?: "transcriptions/vocabulary")
            console.log("Done deleting vocabulary for: $vocabularyId")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            Application.notifications.showError(
                "When delete was attempted, an error was returned.",
                t.message
            )
        } finally {
            deleting = false
        }
    }

    dialogView("Delete Vocabulary") {
        action {
            title = "Cancel"
            action {
                Application.navigation.navigate(returnPath ?: "transcriptions/vocabulary/${encodeURIComponent(typedEntityId)}?action=view")
            }
        }

        if (deleteConfirmation == "delete") {
            action {
                title = "Delete"
                primary = true
                showProgressOnAction = true
                action {
                    try {
                        deleteVocabulary(typedEntityId)

                        Application.navigation.navigate(returnPath ?: "transcriptions/vocabulary/${encodeURIComponent(typedEntityId)}?action=view")
                    } catch (t: Throwable) {
                        Application.notifications.showError(
                            "Unable to delete vocabulary for $typedEntityId",
                            t.message
                        )

                    }
                }
            }
        }

        content {
            messageBox(
                """When you delete a vocabulary, it cannot be undone"""
            )

            box({
                title = "Delete Vocabulary ${typedEntityId}?"
            }) {
                P {
                    Text("This operation will delete the vocabulary.")
                }
                P {
                    A(href = "#transcriptions/vocabulary/${encodeURIComponent(typedEntityId)}", {
                        style {
                            property("line-break", "anywhere")
                        }
                    }) {
                        Text(typedEntityId)
                    }
                }
                P {
                    Text("To confirm, type 'delete' in the field below")
                }
                Input(InputType.Text) {
                    onInput { event ->
                        deleteConfirmation = event.value
                    }
                }
            }
        }
    }

}

