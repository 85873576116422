package com.siriusxm.pia.views.artistradio

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.artistradio.ArtistStationHolder
import com.siriusxm.pia.rest.artistradio.ArtistStationSimplified
import com.siriusxm.pia.rest.artistradio.ArtistStationVisibilityFlag
import com.siriusxm.pia.utils.encodeURIComponent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.cancellation.CancellationException

@Composable
fun artistRadioEdit(artistStationId: String, action: String, returnPath: String? = null) {

    if (returnPath != null) {
        console.log("returnPath: $returnPath")
    }

    var loading by mutableStateOf(false)

    var artistRadioEdits by remember { mutableStateOf<ArtistStationHolder?>(null) }

    var discoverable by remember { mutableStateOf<Boolean?>(null) }
    var visible by remember { mutableStateOf<Boolean?>(null) }
    var recommendable by remember { mutableStateOf<Boolean?>(null) }

    var pendingChanges by remember { mutableStateOf(false) }

    LaunchedEffect(artistStationId) {
        try {
            loading = true

            console.log("Begin looking up artist radio station by id")
            val artistStations = Application.artistStationAPI.fetchArtistStation(artistStationId)
            val results = artistStations.values.toList()

            if (results.isNotEmpty()) {
                val first = results.firstOrNull()

                val holder = if (first?.artist != null) {
                    ArtistStationHolder(first.artistStationId, first.artist).also {
                        if (action == "show") {
                            it.show()
                        } else  if (action == "hide") {
                            it.hide()
                        }
                    }
                } else {
                    null
                }
                artistRadioEdits = holder

                discoverable = artistRadioEdits?.discoverable
                visible = artistRadioEdits?.visible
                recommendable = artistRadioEdits?.recommendable

                artistRadioEdits?.let {
                    pendingChanges = it.isDirty()
                }
            }
            console.log("Done looking up artist radio station by id artistRadioEdits: $artistRadioEdits")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            artistRadioEdits = null
        } finally {
            loading = false
        }
    }

    box({
        paddedContent = artistRadioEdits == null || loading
        header({
            title = "Artist Radio Stations"
        }) {
            Div({
                style {
                    width(100.percent)
                }
            })
        }
    }) {
        if (loading) {
            Div {
                Text("Looking up station: $artistStationId")
            }

            spinner()
        } else if (artistRadioEdits == null) {
            Text("Station $artistStationId not found")
        } else {
            val summaries = artistStationSummaries(artistRadioEdits)
            table<ArtistStationSimplified> {
                items(summaries.filter { it.knownArtist })

                column {
                    title = "TypedEntityId"
                    content { summary ->
                        Div {
                            Text(summary.entityId)
                        }
                    }
                }
                column {
                    title = "Name"
                    content { summary ->
                        Div {
                            Text(summary.name)
                        }
                    }
                }

                column {
                    title = "Discoverable"

                    content { summary ->
                        Div {
                            if (discoverable != null) {
                                val dirtyStr = getDirtyStr(artistRadioEdits, ArtistStationVisibilityFlag.DISCOVERABLE)
                                simpleCheckBox(dirtyStr, discoverable!!) {
                                    artistRadioEdits = updateEntity(artistRadioEdits,
                                        ArtistStationVisibilityFlag.DISCOVERABLE, it).also {station ->
                                        discoverable = station?.discoverable
                                    }?.also {
                                        pendingChanges = it.isDirty()
                                    }
                                }
                            }
                        }
                    }
                }

                column {
                    title = "Recommendable"

                    content { summary ->
                        Div {
                            if (recommendable != null) {
                                Span {
                                    val dirtyStr = getDirtyStr(artistRadioEdits, ArtistStationVisibilityFlag.RECOMMENDABLE)
                                    simpleCheckBox(dirtyStr, recommendable!!) {
                                        artistRadioEdits = updateEntity(artistRadioEdits,
                                            ArtistStationVisibilityFlag.RECOMMENDABLE, it).also {station ->
                                            recommendable = station?.recommendable
                                        }?.also {
                                            pendingChanges = it.isDirty()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                column {
                    title = "Visible"

                    content { summary ->
                        Div {
                            if (visible != null) {
                                val dirtyStr = getDirtyStr(artistRadioEdits, ArtistStationVisibilityFlag.VISIBLE)
                                simpleCheckBox(dirtyStr, visible!!) {
                                    artistRadioEdits = updateEntity(artistRadioEdits,
                                        ArtistStationVisibilityFlag.VISIBLE, it).also {station ->
                                        visible = station?.visible
                                    }?.also {
                                        pendingChanges = it.isDirty()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    Div({
        classes(ServiceViewStyles.subHead)
    }){
        if (pendingChanges) {
            button("Save") {
                if (artistRadioEdits?.isDirty() == true) {
                    console.log("Save called")

                    val changes = artistRadioEdits?.changedValues()
                    console.log("Send changed values for $artistStationId changes: $changes")

                    try {
                        changes?.forEach { (flag, value) ->
                            console.log("Setting flag ${flag.label.lowercase()} to $value")
                            Application.artistStationAPI.setFlags(artistStationId, flag.label.lowercase(), value.toString())
                        }

                        Application.navigation.navigate(returnPath ?: "artist-radio/artistradio-view/${encodeURIComponent(artistStationId)}?action=view")
                    } catch (e: Exception) {
                        console.error("Unexpected error occured", e)
                    }

                } else {
                    console.log("Nothing to Save")
                }
            }
        } else {
            Div {
                Text("No pending changes for: $artistStationId")
            }
        }

        button("Cancel") {
            console.log("Cancel called")
            Application.navigation.navigate(returnPath ?: ("artist-radio/artistradio-view/${encodeURIComponent(artistStationId)}?action=view"))
        }
    }
}

private fun getDirtyStr(artistRadioEdits: ArtistStationHolder?, flag: ArtistStationVisibilityFlag) =
    if (artistRadioEdits?.changedValues()?.containsKey(flag) == true) {
        "*"
    } else {
        ""
    }

private fun artistStationSummaries(station: ArtistStationHolder?): MutableList<ArtistStationSimplified> {
    val stations = mutableListOf<ArtistStationSimplified>()
    if (station != null) {
        stations.add(
            ArtistStationSimplified(
                entityId = station.entityId,
                name = station.artistName ?: "Unknown",
                knownArtist = station.knownArtist,
                discoverable = station.discoverable,
                recommendable = station.recommendable,
                visible = station.visible
            )
        )
    }
    return stations
}

private fun updateEntity(holder: ArtistStationHolder?, flag: ArtistStationVisibilityFlag, value: Boolean) : ArtistStationHolder? {
    console.log("updateAttribute called entityId: ${holder?.entityId} attribute: $flag value: $value")
    holder?.updateValue(flag, value)
    return holder
}

