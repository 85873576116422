package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.coroutines.CancellationException
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

/**
 * The main transcription page.
 */

@Composable
fun viewVocabulary(typedEntityId: String, returnPath: String? = null) {

    var vocabularies by remember { mutableStateOf(emptyList<VocabularyResponse>()) }
    var loading by mutableStateOf(false)

    LaunchedEffect(typedEntityId) {
        try {
            loading = true

            console.log("Begin looking up vocabulary by id")
            val vocabularyResponse = Application.transcriptionAPI.fetchVocabulary(typedEntityId)
            vocabularies = listOf(vocabularyResponse)
            console.log("Done looking up vocabulary by id")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            vocabularies = emptyList()
        } finally {
            loading = false
        }
    }

    tabView {
        tab("Results") {
            if (loading) {
                spinner()
            } else if (vocabularies.isEmpty()) {
                Div {
                    Text("No Vocabulary Exists for: " + typedEntityId)
                }

                dialogField(
                    "Create Vocabulary for: $typedEntityId"
                ) {
                    button("Create") {
                        Application.navigation.navigate("transcriptions/vocabulary/${encodeURIComponent(typedEntityId)}?action=edit")
                    }
                }
            } else {
                val vocabulary = vocabularies.firstOrNull()
                if (vocabulary != null) {
                    box {
                        Div({
                            style {
                                display(DisplayStyle.Flex)
                                gap(10.px)
                            }
                        }) {
                            detailGrid {
                                detail("ID",  vocabulary.id)
                                detail("VocabularyEntityId",  vocabulary.vocabularyEntityId)
                                detail("Created At",  vocabulary.created?.toLocalDateTimeString())
                            }

                            Div({
                                style {
                                    display(DisplayStyle.Flex)
                                    gap(5.px)
                                }
                            }) {

                                iconAction("edit") {
                                    console.log("Edit button clicked")
                                    Application.navigation.navigate("transcriptions/vocabulary/${encodeURIComponent(typedEntityId)}?action=edit")
                                }

                                iconAction("delete") {
                                    console.log("Delete button clicked")
                                    Application.navigation.navigate("transcriptions/vocabulary/${encodeURIComponent(typedEntityId)}?action=delete")
                                }
                            }
                        }
                    }

                    table<String> {
                        items(vocabulary.summary?.vocabularyPhrases)

                        column {
                            title = "Phrase"
                            content { phrase ->
                                Div {
                                    Text(phrase)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

