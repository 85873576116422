package com.siriusxm.pia.views.unifiedaggregator.events

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.CloudEventChange
import com.siriusxm.pia.rest.unifiedaggregator.CloudEventChanges
import com.siriusxm.pia.rest.unifiedaggregator.entityType
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.smithy.SmithyViewOptions
import com.siriusxm.pia.views.unifiedaggregator.smithy.smithyEntityView
import contentingestion.aggregator.CloudEvent
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.buildJsonObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object CloudEventTable : StyleSheet() {
    val eventSummary by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
    }
}

/**
 * Renderer for the generic CloudEvent
 */
fun defaultCloudEventRendererLookup(
    aggregator: AggregatorService,
    event: CloudEvent
): CloudEventRenderer {
    return when {
        event.type == "Invalid Content Update" -> {
            InvalidContentUpdateRenderer(aggregator, event)
        }

        event.type == "Content Update" -> {
            ContentUpdateRenderer(aggregator, event)
        }

        event.type == "Content Update Partial" -> {
            PartialUpdateRenderer(aggregator, event)
        }

        event.type.startsWith("com.siriusxm.unifiedcontentbus.") -> {
            AtlasEventRenderer(aggregator, event)
        }

        else -> {
            DefaultCloudEventRenderer(event)
        }
    }
}

typealias RendererLookup = (CloudEvent) -> CloudEventRenderer

/**
 * Displays a series of cloud events in a table
 */
@Composable
fun cloudEventTable(
    aggregator: AggregatorService,
    events: List<CloudEvent>,
    rendererLookup: RendererLookup = {
        defaultCloudEventRendererLookup(aggregator, it)
    },
    onlyShowTimeStamps: Boolean = false,
    extraConfig: (TableBuilder<CloudEvent>.() -> Unit)? = null
) {
    Style(CloudEventTable)
    table<CloudEvent> {
        items(events)
        expandable { event ->
            val renderer = rendererLookup(event)
            Div {
                renderer.render()
            }
        }
        column {
            if (extraConfig != null) {
                width = 200.px
            }
            content { event ->
                val renderer = rendererLookup(event)
                Div {
                    Div({
                        classes(CloudEventTable.eventSummary)
                    }) {
                        Span({
                            style {
                                fontStyle("italic")
                                fontSize(0.9.em)
                            }
                        }) { Text(event.time.toLocalDateTimeString()) }

                        if (!onlyShowTimeStamps) {
                            Span({
                                style {
                                    fontWeight(700)
                                }
                            }) {
                                Text(renderer.title)
                            }
                        }
                    }
                }
            }
        }
        extraConfig?.invoke(this)
//        if (showDiffs) {
//            column {
//                title = "Changes"
//                content { event ->
//                    val changedKeys = cloudEventChangeMap?.get(event.id)?.changedKeys
//                    if (changedKeys != null) {
//                        Text(changedKeys.joinToString(","))
//                    }
//                }
//            }
//        }
//        column {
//            content {
//                if (showNames) {
//                    it.data?.getString("name")?.let { name ->
//                        Text(name)
//                    }
//                }
//            }
//        }
    }
}


@Composable
fun diffableSmithyView(
    aggregator: AggregatorService, entity: JsonElement, changes: CloudEventChanges? = null,
    options: SmithyViewOptions = SmithyViewOptions()
) {
    var view by remember { mutableStateOf("table") }

    val model = aggregator.entityModel
    val shape = entity.entityType?.let {
        aggregator.entityType(it)
    }?.shapeId?.let {
        model?.getShape(it)
    }
    if (shape != null) {
        println(shape.id)
        Div {
            Div({
                style {
                    textAlign("right")
                    margin(.3.em, 0.px, 0.5.em)
                }
            }) {
                buttonRadioGroup(view) {
                    option("Table", "table")
                    option("JSON", "json")
                    if (changes != null) {
                        option("Diff", "diff")
                    }
                    onChange {
                        view = it
                    }
                }
            }

            box({
                paddedContent = false
            }) {
                if (view == "table") {
                    smithyEntityView(shape, entity, options)
                } else if (view == "json") {
                    jsonTextView(entity)
                } else if (view == "diff") {
                    if (changes != null) {
                        diffView(changes)
                    }
                } else {
                    // nothing
                }
            }
        }
    }
}

@Composable
fun diffView(changes: CloudEventChanges) {
    table<CloudEventChange> {
        items(changes.changes)

        column {
            width = 50.percent - 75.px
            content {
                val newValue = it.value
                if (newValue != null) {
                    Div({
                        style {
                            textAlign("right")
                        }
                    }) {
                        DefaultJsonViewRenderer(false).renderElement(
                            buildJsonObject { },
                            null, newValue
                        )
                    }
                }
            }
        }
        column {
            width = 150.px
            content {
                Div({
                    style {
                        fontWeight(700)
                        textAlign("center")
                    }
                }) {
                    Text(it.key)
                }
            }
        }
        column {
            width = 50.percent - 75.px
            content { change ->
                val previous = change.oldValue
                if (previous != null) {
                    DefaultJsonViewRenderer(false).renderElement(
                        buildJsonObject { },
                        null, previous
                    )
                }
            }
        }
    }
}
