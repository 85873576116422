package com.siriusxm.pia.views.channelguide

import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*

/**
 * Styles for the channel guide.
 */
object ChannelGuideStyles : StyleSheet() {
    val epgListItem by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.SpaceBetween)
        width(100.percent)

        padding((0.4).cssRem, (0.8).cssRem)
        margin((0.8).cssRem, 0.px)
        backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
        borderRadius(5.px)
        gap(8.px)

        self + hover style {
            backgroundColor(SXMUI.containerContentBackgroundColor.value())
        }

        self + ".cut-flag-INTERSTITIAL" style {
            backgroundColor(SXMUI.mainLayoutBackgroundColor.value())
            fontSize(0.8.cssRem)
        }
    }

    val epgListItemLeading by style {
        width(50.px)
        textAlign("center")
        fontWeight(800)

    }
    val epgListItemImage by style {
        width(128.px)
        textAlign("center")

        "img" {
            width(100.percent)
            display(DisplayStyle.Block)
        }
    }

    val epgListItemTrailing by style {
        width(64.px)

    }
    val epgListItemMain by style {
        flex(1)
        display(DisplayStyle.Flex)

        "h3" style {
            margin(2.px, 0.px)
        }
        "h4" style {
            margin(0.px)
        }
        "p" {
            margin(0.px)
            fontSize(0.8.cssRem)
        }
    }

    val epgHeader by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.SpaceBetween)
        width(100.percent)
        gap(8.px)
        alignItems(AlignItems.FlexStart)
        marginBottom(2.cssRem)
    }

    val epgHeaderImage by style {
    }

    val epgHeaderContent by style {
        flex(1)

        "h1" {
            margin(0.px)
            fontWeight(800)
        }
        "h4" {
            margin(0.px)
        }
    }

    val epgChannelNowPlaying by style {
        display(DisplayStyle.Flex)
        gap(5.px)
    }

    val epgIconContainer by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        gap(4.px)
        marginTop(4.px)
    }

    val filterAutoComplete by style {
        position(Position.Absolute)
        width(100.percent)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        border(1.px, LineStyle.Solid, SXMUI.borderDividerDefault.value())
    }

    val filterAutoCompleteItem by style {
        padding(0.4.cssRem)
        cursor("pointer")

        self + hover style {
            backgroundColor(SXMUI.mainLayoutBackgroundColor.value())
        }
    }
    val filterAutoCompleteItemSelected by style {
        backgroundColor(SXMUI.mainLayoutBackgroundColor.value())
    }

    val filterItemsContainer by style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        gap(3.px)
        marginTop(3.px)
    }

    val filteredItem by style {
        border(1.px, LineStyle.Solid, SXMUI.defaultDivider.value())
        padding(.4.cssRem)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        borderRadius(5.px)
    }

    init {
        ".${epgListItemMain} > div" style {
            width(50.percent)
        }

        ".${epgChannelNowPlaying} > .${epgHeaderImage}" style {
            width(50.px)
        }
    }

}