package com.siriusxm.pia.views.unifiedaggregator.types

import androidx.compose.runtime.*
import com.siriusxm.cmc.smithy.EntityModelStyles
import com.siriusxm.cmc.smithy.Shape
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toAMPMTimeString
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration

/**
 * Renders the main data types view, which includes entity types and partials.
 */
@Composable
fun dataTypes(
    context: AggregatorService,
    route: Route
) {
    var selection = "entities"
    var initialSelect: String? = null
    route.switch {
        select("entities") {
            switch {
                select(Regex(".+")) {
                    initialSelect = match.takeIf { it.isNotBlank() }
                }
                default {
                }
            }
        }
        select("partials") {
            selection = "partials"
            switch {
                select(Regex(".*")) {
                    initialSelect = match.takeIf { it.isNotBlank() }
                }
                default {
                }
            }
        }
    }

    serviceContentView({
        title = "Data Types"
        breadcrumbs {
            crumb("Aggregator", "aggregator")
            crumb("Types", null)
        }
    }) {
        tabView {
            this.onSelect {
                context.navigate("aggregator/types/${it}")
                false
            }
            this.selection = selection
            tab("Entity Types", "entities") {
                entityTypes(context, initialSelect = initialSelect)
            }
            if (context.partials.isNotEmpty()) {
                tab("Partial Schemas", "partials") {
                    partials(context, initialSelect = initialSelect)
                }
            }
        }
    }
}

/**
 * Renders the entity types
 */
@Composable
fun entityTypes(
    context: AggregatorService,
    initialSelect: String? = null
) {
    val consumers = context.consumers
    val producers = context.producers

    splitSelectionView<EntityTypeConfiguration> {
        items = context.entityTypes
        selection = initialSelect?.let {
            context.entityTypes.find { it.type == initialSelect }
        }

        renderListItem {
            Text(it.name)
        }

        render {
            entityType(context, it, producers, consumers)
        }

        onSelect {
            context.context.navigate("aggregator/types/entities/${it.type}")
        }

        column {
            title = "Version"
            content {
                Text(it.version)
            }
        }
    }
}

val periods = listOf(
    PeriodType.Hours.periodOf(1),
    PeriodType.Hours.periodOf(3),
    PeriodType.Hours.periodOf(6),
    PeriodType.Hours.periodOf(12),
    PeriodType.Days.periodOf(1),
    PeriodType.Weeks.periodOf(1)
)

data class DataPoint(val ts: Instant, val value: Double)

fun MetricData.allData(period: TimeRange, loadTime: Instant?, groupPeriod: Duration): List<DataPoint> {
    val range = period.range(loadTime ?: Clock.System.now())
    var instant = range.endInclusive
    val results = arrayListOf<DataPoint>()
    do {
        val index = this.timestamps?.indexOfFirst {
            it <= instant && it >= instant - groupPeriod
        }?.takeIf { it >= 0 }
        val value = index?.let { this.values?.get(index) }

        // we don't include the most recent data point if it's null
        if (value != null || instant != range.endInclusive) {
            results += DataPoint(instant, value ?: 0.0)
        }
        instant -= groupPeriod
    } while (instant > range.start)
    return results.toList().reversed()
}

val colors = mapOf(
    "updateEvents" to arrayOf("rgba(200,0,0,1)"),
    "addEvents" to arrayOf("rgba(0, 200, 0, 1)"),
    "removeEvents" to arrayOf("rgba(0, 0, 200, 1)")
)

/**
 * Display information about an entity type
 */
@Composable
fun entityType(
    aggregator: AggregatorService,
    entityType: EntityTypeConfiguration,
    producers: List<ProducerDetails>,
    consumers: List<ConsumerDetails>
) {
    entityView({
        title = entityType.name
        subTitle = entityType.type + ":" + entityType.version
    }) {
        tabView {
            if (aggregator.entityModel != null) {
                tab("Definition") {
                    box({
                        paddedContent = false
                    }) {
                        EntityModel(aggregator, entityType)
                    }
                }
            }

            tab("Roles") {
                box("Producers", {
                    paddedContent = false
                }) {
                    val matchedProducers =
                        producers.filter { it.allowedTypes.orEmpty().contains(entityType.type) }
                    if (matchedProducers.isEmpty()) {
                        boxMessage("No producers found for this type")
                    } else {
                        table<ProducerDetails> {
                            items(matchedProducers)
                            column {
                                content {
                                    A("#aggregator/producers/${encodeURIComponent(it.id)}") {
                                        Text(it.name.orEmpty())
                                    }
                                }
                            }
                        }
                    }
                }

                box("Consumers", {
                    paddedContent = false
                }) {
                    val matchedConsumers = consumers.filter { it.types.orEmpty().contains(entityType.type) }
                    if (matchedConsumers.isEmpty()) {
                        boxMessage("No consumers found for this type.")
                    } else {
                        table<ConsumerDetails> {
                            items(matchedConsumers)
                            column {
                                content {
                                    A("#aggregator/consumers/${encodeURIComponent(it.id)}") {
                                        Text(it.name ?: "")
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tab("Events") {
                EntityTypeEvents(aggregator, entityType)
            }

            tab("Metrics") {
                EntityTypeMetrics(aggregator, entityType)
            }
        }
    }
}

fun Instant.roundToSeconds(): Instant {
    return Instant.fromEpochSeconds(this.epochSeconds)
}

data class LoadedDataSet(
    val loadTime: Instant,
    val period: TimeRange,
    var data: MetricDataResponseList
) {
    val grouped: Map<MetricData, List<DataPoint>> by lazy {
        data.map {
            it to it.allData(period, loadTime, period.groupingAmount(25))
        }.toMap()
    }

    val dataSet: List<Dataset> by lazy {
        val includeDay = period.duration().inWholeHours >= 23
        grouped.map { (metricData, dataPoints) ->
            new<Dataset> {
                label = metricData.id.replace("Events", "")
                data = dataPoints.map { value ->
                    new<DatasetPoint> {
                        y = value.value.toInt()
                        x = if (includeDay) {
                            value.ts.toLocalDateTime().let {
                                "${it.date} ${it.toAMPMTimeString(includeMinutes = false)}"
                            }
                        } else {
                            value.ts.toLocalDateTime().toAMPMTimeString(false, false)
                        }
                    }
                }.toTypedArray()
            }
        }
    }
}

@Composable
fun EntityTypeMetrics(aggregator: AggregatorService, entityType: EntityTypeConfiguration) {
    var metricsPeriod: TimeRange by remember {
        mutableStateOf(TimeRange(PeriodType.Days.periodOf(1)))
    }
    var loadedMetrics by remember { mutableStateOf<List<MetricData>?>(null) }
    var loadedData: LoadedDataSet? by remember { mutableStateOf(null) }

    LaunchedEffect(entityType.type, metricsPeriod) {
        val period = metricsPeriod
        loadedMetrics = null
        val now = Clock.System.now().roundToSeconds()
        val range = period.range(now)
        try {
            val result = aggregator.api.getMetrics(
                GetMetricDataRequest(
                    range.start, range.endInclusive, listOf("update", "add", "remove").map { updateType ->
                        MetricDataRequest(
                            "${updateType}Events",
                            null,
                            MetricStat(
                                "AtlasEntityPublished",
                                "aggregator/${aggregator.context.configuration.stage}",
                                listOf(
                                    Dimension("EntityType", entityType.type),
                                    Dimension("UpdateType", "com.siriusxm.unifiedcontentbus.${updateType}")
                                ),
                                period.groupingAmount(25).inWholeSeconds.toInt(),
                                "Sum"
                            )
                        )
                    }
                )
            ).data

            loadedMetrics = result
            loadedData = LoadedDataSet(now, period, result)
        } catch (t: Throwable) {
            t.printStackTrace()
        }
    }

    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.FlexEnd)
            width(100.percent)
            padding(5.px, 0.px)
        }
    }) {
        timeRangeSelect(selected = metricsPeriod) {
            metricsPeriod = it ?: TimeRange(PeriodType.Days.periodOf(1))
        }
    }

    waitUntilNonNull(loadedData) { metrics ->
        box({
            title = "Published Entities"

            header({
                actionContent {
                    if (loadedMetrics == null) {
                        // we're in the process of loading a new dataset
                        spinner(size = Size.SMALL)
                    }
                }
            })
        }) {
            Chart(metrics.dataSet)
        }
    }
}

/**
 * Display the documentation for an entity Smithy model
 */
@Composable
fun EntityModel(context: AggregatorService, type: EntityTypeConfiguration) {
    Style(EntityModelStyles)

    val shapeId = type.shapeId
    val model = context.entityModel
    if (shapeId != null && model != null) {
        val shape = model.getShape(shapeId)
        if (shape != null) {
            Shape(shape)
        }
    }
}