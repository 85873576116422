package com.siriusxm.pia

import com.siriusxm.pia.cognito.IdToken

/**
 * Encapsulates the application viewer.
 */
class Viewer(
    private val token: IdToken?
) {
    val editor: Boolean by lazy {
        token?.cognitoGroups?.contains("XCONTENT-EDIT") ?: false
    }

    val contentEditor: Boolean by lazy {
        isInGroup(UserGroup.AggregatorAdmin) || isInGroup(UserGroup.AggregatorContentAdmin)
    }

    val operationsUser: Boolean by lazy {
        isInGroup(UserGroup.AggregatorAdmin) || isInGroup(UserGroup.AggregatorOperations)
    }

    /**
     * Is the viewer a part of a given group
     */
    fun isInGroup(group: UserGroup): Boolean {
        return token?.cognitoGroups?.contains(group.id) ?: false
    }

    /**
     * Get the viewer's email address.
     */
    val email: String? get() = token?.email
}

enum class UserGroup(val id: String) {
    XCONTENT_EDIT("XCONTENT_EDIT"),
    XCONTENT_VIEW("XCONTENT_VIEW"),
    AggregatorAdmin("app-uni-content-aggregator-admin"),
    AggregatorContentAdmin("app-uni-content-contentadmin"),
    AggregatorOperations("app-uni-content-operations")
}