package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.math.roundToLong
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.nanoseconds
import kotlin.time.Duration.Companion.seconds
import kotlin.time.DurationUnit

/**
 * A time range that can be either relative or absolute. Either
 * relative or (start,end) must be defined.
 */
data class TimeRange(
    /**
     * The relative duration
     */
    val relative: Period? = null,
    val absolute: OpenEndedRange<Instant>? = null
) {
    init {
        if (relative == null && absolute == null) {
            throw IllegalArgumentException("Relative or absolute must be set")
        }
    }

    fun humanString(): String? {
        return relative?.humanString()
            ?: if (absolute != null) {
                "${absolute.start?.toLocalDateTimeString() ?: ""} - ${absolute.end?.toLocalDateTimeString() ?: ""}"
            } else {
                null
            }
    }

    /**
     * Get the date range. For relative or incomplete ranges, the relativeTo parameter is used.
     */
    fun range(relativeTo: Instant = Clock.System.now()): ClosedRange<Instant> {
        if (relative != null) {
            return relative.type.subtract(relativeTo, relative.count)..relativeTo
        } else if (absolute != null) {
            val start = absolute.start ?: Instant.DISTANT_PAST
            val end = absolute.end ?: relativeTo
            return start..end
        } else {
            throw IllegalStateException("one of relative or absolute must be set")
        }
    }

    /**
     * Determine the grouping amount. Attempting to organize around an
     * intelligent sections (i.e. hours, minutes, etc.)
     */
    fun groupingAmount(roughCount: Int): Duration {
        val range = range()
        val duration = range.endInclusive - range.start

        return roundGrouping(duration, roughCount)
    }

    /**
     * Get the duration of the time range
     */
    fun duration(): Duration {
        return range().let {
            it.endInclusive - it.start
        }
    }
}

/**
 * Defines a period
 */
data class Period(
    val type: PeriodType,
    val count: Int
) {
    val duration: Duration get() = type.baseDuration * count

    fun humanString(): String {
        return "${count}${type.short}"
    }
}

/**
 * A list of relative durations for display. We can likely build something more intelligent
 * so that the name is implied from the duration.
 */
val defaultRelativeDurations = listOf(
    PeriodType.Hours.periodOf(1),
    PeriodType.Hours.periodOf(3),
    PeriodType.Hours.periodOf(6),
    PeriodType.Hours.periodOf(12),
    PeriodType.Days.periodOf(1),
    PeriodType.Weeks.periodOf(1)
)

sealed class PeriodType(val type: String, val baseDuration: Duration, val short: String) {
    fun periodOf(count: Int): Period {
        return Period(this, count)
    }

    data object Minutes : PeriodType("Minutes", 1.minutes, "m")
    data object Hours : PeriodType("Hours", 1.hours, "h")
    data object Days : PeriodType("Days", 1.days, "d")
    data object Weeks : PeriodType("Weeks", 7.days, "w")
    data object Months : PeriodType("Months", 30.days, "mo")

    open fun add(instant: Instant, count: Int): Instant {
        return instant + (baseDuration.inWholeNanoseconds * count).nanoseconds
    }

    open fun subtract(instant: Instant, count: Int): Instant {
        return instant - (baseDuration.inWholeNanoseconds * count).nanoseconds
    }
}

/**
 * The relative durations that are available in the advanced selection
 */
private val advancedRelativeDurations: List<Period> = listOf(
    PeriodType.Minutes.periodOf(5),
    PeriodType.Minutes.periodOf(10),
    PeriodType.Minutes.periodOf(15),
    PeriodType.Minutes.periodOf(30),
    PeriodType.Minutes.periodOf(45),

    PeriodType.Hours.periodOf(1),
    PeriodType.Hours.periodOf(2),
    PeriodType.Hours.periodOf(3),
    PeriodType.Hours.periodOf(6),
    PeriodType.Hours.periodOf(12),

    PeriodType.Days.periodOf(1),
    PeriodType.Days.periodOf(2),
    PeriodType.Days.periodOf(3),
    PeriodType.Days.periodOf(4),
    PeriodType.Days.periodOf(5),
    PeriodType.Days.periodOf(6),

    PeriodType.Weeks.periodOf(1),
    PeriodType.Weeks.periodOf(2),
    PeriodType.Weeks.periodOf(3),
    PeriodType.Weeks.periodOf(4),
)

/**
 * A component for selecting a time range that includes options for relative periods.
 */
@Composable
fun timeRangeSelect(
    topLineOptions: List<Period> = defaultRelativeDurations,
    selected: TimeRange? = null,
    onUpdate: (TimeRange?) -> Unit
) {
    var advancedSelection: AdvancedSelectionType? by remember { mutableStateOf(null) }

    Style(TimeRangeStyles)
    Div({
        classes(TimeRangeStyles.timePeriodSelectionBox)
    }) {
        var hasSelection = false
        if (selected?.absolute != null) {
            Span {
                Text(selected.humanString() ?: "")

                icon("close") {
                    action {
                        onUpdate(null)
                    }
                }
            }
        } else {
            topLineOptions.forEach { availablePeriod ->
                Span({
                    classes(TimeRangeStyles.timePeriod)
                    if (selected?.relative == availablePeriod) {
                        classes(TimeRangeStyles.timePeriodSelected)
                        hasSelection = true
                    } else {
                        onClick {
                            it.preventDefault()
                            onUpdate(TimeRange(availablePeriod))
                        }
                    }
                }) {
                    Text(availablePeriod.humanString())
                }
            }

            Span({
                classes(TimeRangeStyles.timePeriod)
                if (!hasSelection && selected != null) {
                    classes(TimeRangeStyles.timePeriodSelected)
                }
                onClick {
                    advancedSelection = if (selected != null && selected.relative == null) {
                        AdvancedSelectionType.Absolute
                    } else {
                        AdvancedSelectionType.Relative
                    }
                }
            }) {
                Text(buildString {
                    append("Custom")
                    if (!hasSelection) {
                        selected?.humanString()?.let {
                            append(" (${it})")
                        }
                    }
                })
                icon("calendar_month") {
                    size = IconSize.SMALL
                }
            }
        }

        if (advancedSelection != null) {
            Div({
                classes(TimeRangeStyles.advancedSelection)
                onClickOutside {
                    advancedSelection = null
                }
            }) {
                var advancedTimeRange by remember { mutableStateOf<TimeRange?>(null) }
                Div {
                    buttonRadioGroup(advancedSelection?.name ?: "") {
                        AdvancedSelectionType.entries.forEach { selectionType ->
                            option(selectionType.name, selectionType.name)
                        }
                        onChange {
                            advancedSelection = it.let {
                                try {
                                    AdvancedSelectionType.valueOf(it)
                                } catch (t: Throwable) {
                                    AdvancedSelectionType.Relative
                                }
                            }
                        }
                    }
                }

                Div {
                    if (advancedSelection == AdvancedSelectionType.Absolute) {
                        var rangeSelected: OpenEndedRange<Instant> by remember {
                            mutableStateOf(OpenEndedRange(selected?.absolute?.start, selected?.absolute?.end))
                        }

                        LaunchedEffect(rangeSelected) {
                            if (rangeSelected.start != null && rangeSelected.end != null) {
                                advancedTimeRange = TimeRange(absolute = rangeSelected)
                            }
                        }

                        Div({ classes(TimeRangeStyles.advancedAbsoluteDurationSelection) }) {
                            Div {
                                Label {
                                    Text("Start")
                                }
                                instantPicker(rangeSelected.start) {
                                    rangeSelected = OpenEndedRange(it, rangeSelected.end)
                                }
                            }
                            Div {
                                Label {
                                    Text("End")
                                }
                                instantPicker(rangeSelected.end) {
                                    rangeSelected = OpenEndedRange(rangeSelected.start, it)
                                }
                            }
                        }
                    } else if (advancedSelection == AdvancedSelectionType.Relative) {
                        Div({
                            classes(TimeRangeStyles.advancedRelativeDurationSelection)
                        }) {
                            advancedRelativeDurations.groupBy { it.type }.map {
                                it.key to it.value
                            }.sortedBy {
                                it.first.baseDuration
                            }.map {
                                it.first to it.second.sortedBy { it.duration }
                            }.forEach { (type, options) ->
                                Div({ classes(TimeRangeStyles.advancedRelativeDurationType) }) {
                                    Label {
                                        Text(type.type)
                                    }
                                    Ul {
                                        options.forEach { option ->
                                            Li({
                                                onClick {
                                                    onUpdate(TimeRange(option))
                                                    advancedSelection = null
                                                }
                                                if (selected?.relative == option) {
                                                    classes(TimeRangeStyles.advancedSelectionPeriodSelected)
                                                }
                                            }) {
                                                Text(option.humanString())
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Div({
                    classes(TimeRangeStyles.advancedSelectionActions)
                }) {
                    button {
                        enabled = true
                        action {
                            advancedSelection = null
                        }
                        title = "Cancel"
                    }
                    button {
                        primary = true
                        enabled = advancedTimeRange != null
                        action {
                            advancedTimeRange?.let {
                                onUpdate(it)
                                advancedSelection = null
                            }
                        }
                        title = "Apply"
                    }
                }
            }
        }
    }
}

enum class AdvancedSelectionType {
    Absolute,
    Relative,
}

object TimeRangeStyles : StyleSheet() {
    val timePeriodSelectionBox by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        gap(10.px)
        padding(6.px, 12.px)
        borderRadius(5.px)
        shadowBorder()
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        height(36.px)
        minWidth(180.px)
        position(Position.Relative)
    }

    val timePeriod by style {
        display(DisplayStyle.Flex)
        padding(8.px)
        gap(3.px)
        alignItems(AlignItems.Center)

        self + hover style {
            backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
            cursor("pointer")
        }
    }

    val timePeriodSelected by style {
        fontWeight(700)
        color(SXMUI.buttonBackgroundPrimary.value())
    }

    val advancedSelection by style {
        position(Position.Absolute)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        gap(1.em)
        top(100.percent)
        right(0.px)
        shadowBorder()
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        padding(1.em)
        marginTop(2.px)
        minWidth(33.em)
    }

    val advancedSelectionActions by style {

    }

    val advancedSelectionPeriodSelected by style {
        fontWeight(700)
        color(SXMUI.messageInfoBorder.value())
        backgroundColor(SXMUI.messageInfoBackground.value())
        border(1.px, LineStyle.Solid, SXMUI.messageInfoBorder.value())
    }

    val advancedRelativeDurationSelection by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        gap(1.em)
    }

    val advancedAbsoluteDurationSelection by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        gap(1.em)

        child(self, type("div")).style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            gap(1.em)
            alignItems(AlignItems.Center)

            type("label").style {
                width(100.px)
                fontWeight(700)
            }
        }
    }

    val advancedRelativeDurationType by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        gap(1.em)
        alignItems(AlignItems.Center)

        type("label").style {
            width(100.px)
            fontWeight(700)
        }

        type("ul").style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            padding(0.px)
            margin(0.px)
            listStyle("none")
            gap(10.px)

            type("li").style {
                width(3.5.em)
                margin(0.px)
                shadowBorder()
                borderRadius(5.px)
                textAlign("center")
                padding(.6.em)
                fontSize(.9.em)
                lineHeight(.9.em)
                cursor("pointer")

                (self + hover) style {
                    backgroundColor(SXMUI.mainLayoutBackgroundColor.value())
                }

                (self + className("selected")) style {
                    fontWeight(700)
                }
            }
        }
    }
}

data class AllowedIntervals(
    val days: List<Int> = listOf(1, 2, 3, 5, 7),
    val hours: List<Int> = listOf(1, 2, 4, 6, 8, 12),
    val minutes: List<Int> = listOf(1, 5, 10, 15, 30)
)

fun snapToAllowed(value: Double, allowed: List<Int>): Int {
    return allowed.minByOrNull { kotlin.math.abs(it - value) } ?: allowed.first()
}

/**
 * For a given duration, calculates the length of the grouping for graphed data.
 * @param groupCount the suggested group count. We'll attempt to return a duration that is close to duration/groupCount
 * @param allowed the set of allowed intervals.
 */
fun roundGrouping(
    duration: Duration,
    groupCount: Int = 25,
    allowed: AllowedIntervals = AllowedIntervals()
): Duration {
    val group = duration / groupCount

    val groupDays = group.toDouble(DurationUnit.DAYS)
    if (groupDays >= 1.0) {
        // Here you could also snap to allowed days if you want even finer control.
        return (groupDays.roundToLong()).days
    }

    val groupHours = group.toDouble(DurationUnit.HOURS)
    if (groupHours >= 1.0) {
        val bestHour = snapToAllowed(groupHours, allowed.hours)
        return bestHour.hours
    }

    val groupMinutes = group.toDouble(DurationUnit.MINUTES)
    if (groupMinutes >= 1.0) {
        val bestMinute = snapToAllowed(groupMinutes, allowed.minutes)
        return bestMinute.minutes
    }

    val groupSeconds = group.toDouble(DurationUnit.SECONDS)
    return (groupSeconds.roundToLong()).seconds
}