package com.siriusxm.pia.rest.unifiedaggregator

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject

@Serializable
data class CloudEvent(
    /**
     * This attribute contains a value describing the type of event related to the originating occurrence.
     */
    val type: String,

    /**
     * Identifies the event
     */
    val id: String,

    /**
     * Identifies the context in which an event happened.
     */
    val source: String,

    /**
     * This describes the subject of the event in the context of the event producer (identified by source)
     */
    val subject: String? = null,

    /**
     * The event payload. This specification does not place any restriction on the type of this information.
     * In our case it will always be JSON.
     */
    val data: JsonElement? = null,

    /**
     * Timestamp of when the occurrence happened.
     */
    val time: Instant = Clock.System.now(),

    /**
     * The version of the CloudEvents specification which the event uses.
     */
    val specversion: String = "1.0",

    /**
     * Content type of data value.
     */
    val datacontenttype: String = "application/json",

    /**
     * Description: Identifies the schema that data adheres to.
     */
    val dataschema: String? = null,

    /**
     * The source region of the event
     */
    val sourceregion: String? = null,

    /**
     * Extra data for tracing.
     */
    val tracingcontext: JsonObject? = null
)