package com.siriusxm.pia.views.unifiedaggregator.events

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.jsonView
import com.siriusxm.pia.rest.unifiedaggregator.CloudEventChanges
import com.siriusxm.pia.utils.getString
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.smithy.aggregatorOptions
import com.siriusxm.pia.views.unifiedaggregator.smithy.aggregatorSmithyEntityView
import com.siriusxm.pia.views.unifiedaggregator.smithy.smithyViewDecoder
import contentingestion.aggregator.CloudEvent
import contentingestion.aggregator.ContentUpdateError
import contentingestion.aggregator.ContentUpdateType
import contentingestion.aggregator.PartialUpdate
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.jsonObject
import org.jetbrains.compose.web.dom.Div

/**
 * Base interface for rendering cloud events.
 */
interface CloudEventRenderer {
    /**
     * The title of the event
     */
    val title: String

    /**
     * Render the event
     */
    @Composable
    fun render()
}

private val cloudEventDecoder = Json {
    ignoreUnknownKeys = true
}

/**
 * Default implementation of a CloudEventRenderer, just takes the event type for
 * the title and renders a generic JSON view.
 */
open class DefaultCloudEventRenderer(val event: CloudEvent) : CloudEventRenderer {
    override val title: String = event.type

    @Composable
    override fun render() {
        (event.data as? JsonObject)?.let {
            jsonView(it, true)
        }
    }
}

class ContentUpdateRenderer(
    val aggregator: AggregatorService,
    val event: CloudEvent
) : CloudEventRenderer {
    override val title: String
        get() {
            val updateType = event.tracingcontext?.getString("updateType")?.let {
                ContentUpdateType.fromName(it)
            }
            return when (updateType) {
                ContentUpdateType.ADD -> "Add"
                ContentUpdateType.REMOVE -> "Remove"
                ContentUpdateType.UPDATE -> "Update"
                else -> "Unknown"
            }
        }

    @Composable
    override fun render() {
        event.data?.let {
            Div {
                aggregatorSmithyEntityView(aggregator, it.jsonObject)
            }
        }
    }
}

class PartialUpdateRenderer(
    val aggregator: AggregatorService,
    val event: CloudEvent
) : CloudEventRenderer {
    override val title: String
        get() {
            val updateType = event.data?.jsonObject?.getString("type")?.let {
                ContentUpdateType.fromName(it)
            }
            return when (updateType) {
                ContentUpdateType.ADD -> "Partial Add"
                ContentUpdateType.REMOVE -> "Partial Remove"
                ContentUpdateType.UPDATE -> "Partial Update"
                else -> "Unknown"
            }
        }

    @Composable
    override fun render() {
        (event.data as? JsonObject)?.let {
            val partial = try {
                smithyViewDecoder.decodeFromJsonElement<PartialUpdate>(it)
            } catch (t: Throwable) {
                t.printStackTrace()
                null
            }
            if (partial != null) {
                partialEventView(aggregator, partial)
            } else {
                jsonView(it, true)
            }
        }
    }
}

class AtlasEventRenderer(
    val aggregator: AggregatorService,
    val event: CloudEvent,
    val changes: CloudEventChanges? = null
) : CloudEventRenderer {
    override val title: String
        get() {
            return event.type.substringAfterLast(".")
                .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
        }

    @Composable
    override fun render() {
        event.data?.let {
            Div {
                diffableSmithyView(aggregator, it.jsonObject, changes, aggregatorOptions(aggregator))
            }
        }
    }
}

class InvalidContentUpdateRenderer(val aggregator: AggregatorService, event: CloudEvent) :
    DefaultCloudEventRenderer(event) {
    val error: ContentUpdateError? by lazy {
        event.data?.let {
            cloudEventDecoder.decodeFromJsonElement(it)
        }
    }

    override val title: String = "Invalid content update: ${error?.reason}"

    @Composable
    override fun render() {
        val data = event.data?.jsonObject?.get("update") ?: event.data?.jsonObject?.get("partial")

        if (data != null) {
            jsonView(data.jsonObject, true)
        }
    }
}