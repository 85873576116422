package com.siriusxm.pia.views.epg

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.views.sports.*
import contentingestion.unifiedmodel.*
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun showsForLinearChannel(context: EpgService, channelId: String) {
    var channel by remember { mutableStateOf<Channel?>(null) }
    var entities by remember { mutableStateOf<List<Show>?>(null) }

    LaunchedEffect(channelId) {
        channel = context.api.fetchChannel(channelId)
        entities = context.api.showsForChannel(channelId)
    }

    serviceContentView({
        title = channel?.name
        subTitle = channel?.shortName()
    }) {
        if (entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $channelId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (entities.isNullOrEmpty()) {
            spinner(size = Size.LARGE)
        } else {
            entities?.let { shows ->
                showsGrid(shows)
            }
        }
    }
}

fun getPreferredImage(show: Show): Image? {
    return show.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: show.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: show.images?.get(ImagePurpose.LOGO)?.get(ImageAspectRatio.ASPECT_1X1)?.default
}

@Composable
fun showContent(show: Show) {
    Div({ classes(SportsStyles.listItem) }) {
        Div {
            val preferredImage = getPreferredImage(show)
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(preferredImage)) {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                }
            }
        }

        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.additionalNames?.get("abbrevation")?.name ?: "")
            }
            Div({ classes(SportsStyles.entityInfo) }) { Text(getShowName(show)) }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.id)
            }
        }
    }

}

private fun Channel?.shortName(): String? {
    return this?.additionalNames?.get(NameType.SHORT.name)?.name
}


fun getShowName(show: Show): String {
    val nickname = show.additionalNames?.get(NameType.NICKNAME.name)?.name
    val name = show.additionalNames?.get(NameType.LONG.name)?.name
    return if (nickname != null && name != null) {
        "$name $nickname"
    } else {
        show.name
    }
}

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun showItem(show: Show, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    if (includeLink) {
        A(href = "#epg/show-by-id/${show.id}") {
            showContent(show)
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(show.additionalNames?.get("abbrevation")?.name ?: "")
                }
                Div({ classes(SportsStyles.entityInfo) }) { Text(getShowName(show)) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(show.id)
                }
            }
        }
    }
}


/**
 * Render a shows grid.
 */
@Composable
fun showsGrid(
    entities: List<Show>,
    includeLinks: Boolean = true,
    onClick: ((Show) -> Unit)? = null
) {
    Div({ classes(SportsStyles.gridList) }) {
        entities.forEach { entity ->
            showItem(entity, includeLinks && onClick == null) {
                onClick?.invoke(entity)
            }
        }
    }
}