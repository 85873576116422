package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.json
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.AggregatorStyles
import contentingestion.aggregator.*
import contentingestion.unifiedmodel.League
import contentingestion.unifiedmodel.NameType
import contentingestion.unifiedmodel.Team
import contentingestion.unifiedmodel.TeamColors
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun teamById(context: SportsService, aggregatorContext: AggregatorService, teamId: String) {
    var team by remember { mutableStateOf<Team?>(null) }
    var league by remember { mutableStateOf<League?>(null) }

    var saving by remember { mutableStateOf(false) }
    var fetchedColorList by remember { mutableStateOf<List<ColorizableField>?>(null) }
    val coroutineScope = rememberCoroutineScope()

    // var events by remember { mutableStateOf<List<SportingEvent>?>(null) }

    suspend fun saveColors(colors: List<ColorizableField>): Boolean {
        try {
            saving = true

           val partialUpdate = team?.getColorPartialUpdate(colors)
            if (partialUpdate != null) {
                aggregatorContext.api.partialUpdate(
                    partialUpdate
                )

                team = team?.copy(
                    colors = toTeamColors(colors)
                )
                fetchedColorList = colors

                Application.notifications.info("Colors saved")
            }
            return true
        } catch (t: Throwable) {
            Application.notifications.showError(
                "When save was attempted, an error was returned.",
                t.message
            )
        } finally {
            saving = false
        }
        return false
    }


    LaunchedEffect(teamId) {
        team = context.api.fetchTeam(teamId)
        val leagueId = team?.league
        if (leagueId != null) {
            league = context.api.fetchLeague(leagueId)
        }
    }

    val t = team

    if (t == null) {
        spinner(size = Size.LARGE)
    } else {
        serviceContentView({
            title = getTeamName(t)
            subTitle = t.getAbbreviation()
        }) {
            val leagueId = league?.id
            Div({ classes(ServiceViewStyles.subHead) } ) {
                if (leagueId != null) {
                    A(href = "#sports/teams-by-league/${encodeURIComponent(leagueId)}") {
                        Text(league?.name ?: "League")
                    }
                    Text(" -> ")
                }
                val entityId = team?.id
                if (entityId != null) {
                    A(href = "/#aggregator/entity/${encodeURIComponent(entityId)}") {
                        Text(entityId)
                    }
                }
            }

            val images = getDefaultTeamImages(t)

            images.forEach {
                Div({ classes(AggregatorStyles.imageBox) }) {
                    Div({ classes(AggregatorStyles.imageContainer) }) {
                        Img(getAbsoluteUrl(it.image))
                    }
                    Div({ classes(AggregatorStyles.imageLabel) }) {
                        Text("${it.purpose} ${it.aspectRatio.name.removePrefix("aspect_")}")
                    }
                }
            }

            // show the colors for the team
            val colorList = t.getColorList()
            if (colorList != null) {
                colorListViewer(colorList, Application.viewer.contentEditor) {
                    coroutineScope.launch {
                        saveColors(it)
                    }
                }
            }
        }
    }
}

private fun Team?.getAbbreviation(): String {
    return this?.additionalNames?.get("abbrevation")?.name ?: ""
}

private fun Team?.shortName(): String? {
    return this?.additionalNames?.get(NameType.SHORT.name)?.name
}

private fun Team?.longName(): String? {
    return this?.additionalNames?.get(NameType.LONG.name)?.name
}

fun Team.getColorList(): List<ColorizableField>? {
    val teamColors = this.colors
    val fields = mutableListOf<ColorizableField>()
    fields.addAll(
        listOf(
            ColorizableField("primary", teamColors?.primary?.hex, teamColors?.primary?.opacity),
            ColorizableField("secondary", teamColors?.secondary?.hex, teamColors?.secondary?.opacity),
            ColorizableField("background", teamColors?.background?.hex, teamColors?.background?.opacity),
        )
    )

    // this will never be null as we have defined it, but the logic is here so that if something changes, we
    // produce `an empty list as `null`
    return if (fields.isEmpty()) {
        null
    } else {
        fields
    }
}

/**
 * This should return null if no partial update is required (ie the data is unchanged).
 *
 * If the partial is to be removed, that would be its own partial update flavor
 */
fun Team.getColorPartialUpdate(newColors: List<ColorizableField>?): PartialUpdate? {
    val team = this

    val existingColors = team.getColorList() ?: listOf()

    val existingColorsKeyed = existingColors.associateBy { it.field }
    val newColorsKeyed = newColors?.associateBy { it.field } ?: mapOf()

    val resultingMap = existingColorsKeyed.toMutableMap()
    newColorsKeyed.forEach { (k, v) ->
        resultingMap[k] = v
    }

    var hasChanges = false
    resultingMap.forEach {
        val prevValue = existingColorsKeyed[it.key]
        val currValue = it.value

        if (currValue != prevValue) {
            hasChanges = true
        }
    }

    if (!hasChanges) {
        return null
    }

    val colors = TeamColors(
        primary = resultingMap["primary"]?.toColor(),
        secondary = resultingMap["secondary"]?.toColor(),
        background = resultingMap["background"]?.toColor(),
        // TODO: implement accent colors
    )

    val viewerEmail = Application.viewer.email
    val partialUpdate = PartialUpdate(
        type = ContentUpdateType.UPDATE,
        timestamp = Clock.System.now(),
        modification = buildJsonObject {
            put("id", json.encodeToJsonElement(team.id))
            put("type", json.encodeToJsonElement(team.type))
            put("version", json.encodeToJsonElement(team.version))
            put("colors", json.encodeToJsonElement(colors))
        },
        partialId = "cmc-team-colors",
        operations = listOf(
            PartialUpdateOperation(
                type = PartialUpdateOperationType.REPLACE,
                sourcePath = "$.colors",
                targetPath = "$.colors"
            )
        ),
        auditContext = viewerEmail?.let {
            AuditContext(viewerEmail)
        }
    )
    return partialUpdate
}

fun colorValueNoHash(colorValue: String): String {
    return if (colorValue.startsWith("#")) {
        colorValue.substring(1)
    } else {
        colorValue
    }
}

fun colorValueWithHash(colorValue: String): String {
    return if (colorValue.startsWith('#')) {
        colorValue
    } else {
        "#$colorValue"
    }
}

fun toTeamColors(colors: List<ColorizableField>?) : TeamColors? {
    val resultingMap = colors?.associateBy { it.field } ?: mapOf()

    if (resultingMap.isEmpty()) return null

    return TeamColors(
        primary = resultingMap["primary"]?.toColor(),
        secondary = resultingMap["secondary"]?.toColor(),
        background = resultingMap["background"]?.toColor(),
    )
}
