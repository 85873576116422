package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.tabView

@Composable
fun transcriptionsDashboardPage() {
    box(
        {
            paddedContent = true
            header({
                title = "Transcriptions"
            })
        }
    ) {
        tabView {
            tab("Completed") {
                transcriptionsCompletedPage("/#transcription-dashboard")
            }
            tab("Duplicated") {
                transcriptionsDuplicatedPage("/#transcription-dashboard")
            }
            tab("Active") {
                transcriptionsActivePage("/#transcription-dashboard")
            }
            tab("Failed") {
                transcriptionsFailedPage("/#transcription-dashboard")
            }
            tab("Search") {
                transcriptionsPage("/#transcription-dashboard")
            }
        }
    }
}
