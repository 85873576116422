package com.siriusxm.pia.views.artistradio

import com.siriusxm.pia.rest.artistradio.ArtistStationClient
import com.siriusxm.pia.rest.artistradio.ArtistStationSummary
import com.siriusxm.pia.utils.entityIds
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

class ArtistStationAPI(url: String, private val authGetter: () -> String) {
    private val ktorfit = Ktorfit.Builder()
        .baseUrl(url)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
        )
        .build()
    private val artistRadioClient = ktorfit.create<ArtistStationClient>()

    suspend fun fetchArtistStation(entitySearchString: String) : Map<String, ArtistStationSummary> {

        val results = mutableMapOf<String, ArtistStationSummary>()

        entityIds(entitySearchString).forEach { entityId ->
            try {
                artistRadioClient.fetchArtistStation(entityId, headers())?.let {
                    results[entityId] = it
                }
            } catch (e: Exception) {
                console.log("fetchArtistStation(${entityId}) had an exception: $e")
            }
        }

        return results
    }

    suspend fun setFlags(entityId: String, attribute: String, attributeValue: String) : String? {
        return try {
            artistRadioClient.setFlags(entityId, attribute, attributeValue, headers())
        } catch (e: Exception) {
            console.log("setFlags(${entityId}) had an exception: $e")
            null
        }
    }

    suspend fun hideArtistStation(entityId: String) : Boolean {
        return try {
            artistRadioClient.hideArtistStation(entityId, headers())
            true
        } catch (e: Exception) {
            console.log("hideArtistStation(${entityId}) had an exception: $e")
            false
        }
    }

    suspend fun showArtistStation(entityId: String) : Boolean {
        return try {
            artistRadioClient.showArtistStation(entityId, headers())
            true
        } catch (e: Exception) {
            console.log("showArtistStation(${entityId}) had an exception: $e")
            false
        }
    }

    suspend fun status() : String? {
        return try {
            artistRadioClient.status()
        } catch (e: Exception) {
            console.log("status() had an exception: $e")
            null
        }
    }

    suspend fun statusAuthenticated() : String? {
        return try {
            artistRadioClient.statusAuthenticated(headers())
        } catch (e: Exception) {
            console.log("statusAuthenticated() had an exception: $e")
            null
        }
    }

    private fun headers() = mapOf("Authorization" to "Bearer ${authGetter()}")
}