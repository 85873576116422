package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.buttonRadioGroup
import com.siriusxm.pia.components.pill
import com.siriusxm.pia.components.table
import com.siriusxm.pia.rest.unifiedaggregator.EntityAccessControl
import com.siriusxm.pia.rest.unifiedaggregator.decodeImages
import com.siriusxm.pia.rest.unifiedaggregator.flatten
import com.siriusxm.pia.rest.unifiedaggregator.getString
import com.siriusxm.pia.utils.prettyPrint
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*


/**
 * A view of JSON data that can either be shown as a table or as raw data
 */
@Composable
fun jsonView(entity: JsonObject, hideEmptyOrNull: Boolean = false) {
    var view by remember { mutableStateOf("table") }

    Div {
        buttonRadioGroup(view) {
            option("Table", "table")
            option("JSON", "json")
            onChange = {
                view = it
            }
        }

        if (view == "table") {
            jsonTable(null, entity, hideEmptyOrNull)
        } else if (view == "json") {
            jsonTextView(entity)
        } else {
            // nothing
        }
    }
}

/**
 * JSON data as a table
 */
@Composable
fun jsonTable(parent: JsonElement?, obj: JsonObject, hideEmptyOrNull: Boolean = false) {
    val orderedRowKeys =
        listOf("id", "type", "version", "name", "additionalNames", "description", "additionalDescriptions")

    // reorder
    val orderedRows = buildList {
        orderedRowKeys.forEach { key ->
            obj[key]?.let {
                add(key to it)
            }
        }
        obj.filterKeys {
            !it.startsWith("_")
        }.filterKeys {
            !orderedRowKeys.contains(it)
        }.forEach {
            add(it.key to it.value)
        }
    }.filter {
        if (hideEmptyOrNull) {
            when (it.second) {
                is JsonNull -> false
                is JsonArray -> {
                    it.second.jsonArray.isNotEmpty()
                }

                is JsonObject -> {
                    it.second.jsonObject.isNotEmpty()
                }

                else -> true
            }
        } else {
            true
        }
    }

    table<Pair<String, JsonElement>> {
        items(orderedRows)
        column {
            width = 250.px
            style {
                this.property("vertical-align", "top")
                fontWeight(700)
            }
            content {
                Text(it.first)
            }
        }
        column {
            style {
                padding(0.px)
            }
            content {
                renderType(it.first, it.second, parent, hideEmptyOrNull)
            }
        }
    }
}

/**
 * Render an individual element type.
 */
@Composable
fun renderType(key: String, value: JsonElement, parent: JsonElement?, hideEmptyOrNull: Boolean = false) {
    if (parent == null) {
        when (key) {
            "images" -> {
                entityImages(value)
            }

            "additionalNames" -> {
                renderAdditionalNames(value)
            }

            "accessControl" -> {
                val control = try {
                    Json.decodeFromJsonElement<EntityAccessControl>(value)
                } catch (t: Throwable) {
                    null
                }
                if (control != null) {
                    accessControlRenderer(control)
                } else {
                    renderElement(parent, value, hideEmptyOrNull)
                }
            }

            "flags", "hosts", "topics", "guests", "keywords" -> {
                (value as? JsonArray)?.let {
                    pillRenderer(it)
                } ?: renderElement(parent, value, hideEmptyOrNull)
            }

            "type" -> {
                val typeId = value.jsonPrimitive.contentOrNull
                if (typeId != null) {
                    A(href = "#aggregator/types/entities/${typeId}") {
                        Text(typeId)
                    }
                }
            }

            else -> {
                renderElement(parent, value, hideEmptyOrNull)
            }
        }
    } else {
        renderElement(parent, value, hideEmptyOrNull)
    }
}

/**
 * Entity images.
 */
@Composable
fun entityImages(value: JsonElement) {
    val flattened = value.decodeImages()?.flatten()

    flattened?.forEach {
        println("flattened: ${it.image.absoluteUrl()}")
    }

    Div({
        style {
        }
    }) {
        flattened?.forEach {
            Div({ classes(AggregatorStyles.imageBox) }) {
                Div({ classes(AggregatorStyles.imageContainer) }) {
                    Img(it.image.absoluteUrl())
                }
                Div({ classes(AggregatorStyles.imageLabel) }) {
                    Text("${it.purpose} ${it.ratio.name.removePrefix("aspect_")}")
                }
            }
        }
    }
}

@Composable
fun pillRenderer(value: JsonArray) {
    Div({
        style {
            display(DisplayStyle.Flex)
            margin(3.px, 0.px)
            gap(6.px)
            flexWrap(FlexWrap.Wrap)
        }
    }) {
        value.forEach {
            val str = (it as? JsonPrimitive)?.contentOrNull
            if (str != null) {
                pill(str)
            } else {
                renderElement(it, value)
            }
        }
    }
}

/**
 * Displays the additional names content.
 */
@Composable
fun renderAdditionalNames(value: JsonElement) {
    val names = value.jsonObject.values.mapNotNull { it as? JsonObject }

    Div({ classes(AggregatorStyles.additionalNames) }) {
        names.forEach {
            Div({ classes(AggregatorStyles.additionalName) }) {
                Div({
                    style {
                        fontSize(11.px)
                        color(SXMUI.subtleTextColor.value())
                    }
                }) {
                    Text(it.getString("type").orEmpty())
                }
                Div {
                    Text(it.getString("name").orEmpty())
                }
            }
        }
    }
}

/**
 * Renders a json element as text.
 */
@Composable
fun jsonTextView(element: JsonElement) {
    Div {
        Pre({
            style {
                backgroundColor(SXMUI.containerContentBackgroundColor.value())
                padding(1.em)
            }
        }) {
            Text(element.toString().prettyPrint())
        }
    }
}

/**
 * Custom renderer for the entity access control field
 */
@Composable
fun accessControlRenderer(controls: EntityAccessControl?) {
    val statuses = mutableStateListOf<String>()
    if (controls?.visible != false) {
        statuses += "Visible"
    }
    if (controls?.discoverable != false) {
        statuses += "Discoverable"
    }
    if (controls?.recommendable != false) {
        statuses += "Recommendable"
    }
    if (statuses.isEmpty()) {
        statuses += "Active"
    }
    Text(statuses.joinToString(", "))
}