package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.utils.toLocalExtendedDateTimeString
import com.siriusxm.unifiedcontent.sports.Airing
import com.siriusxm.unifiedcontent.sports.CompetitionParticipantStatus
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import com.siriusxm.unifiedcontent.sports.ReplayCompetitionRequest
import contentingestion.unifiedmodel.AiringCoverage
import contentingestion.unifiedmodel.Score
import contentingestion.unifiedmodel.ScoreState
import contentingestion.unifiedmodel.iso8601Timestamp
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.random.Random

@Serializable
data class InProgressAiring(
    val tempId: Int = Random.nextInt(),
    var channelId: String? = null,
    val previousChannelId: String,
    val previousChannelNumber: Int?,
    val previousChannelName: String?,
    var coverageType: AiringCoverage
)

fun CompetitiveEvent.calculateDurationSeconds(scores: List<Score>): Int? {
    val start = this.start
    val end = calculateEnd(scores)
    if (start != null && end != null && end > start) {
        return end.minus(start).inWholeSeconds.toInt()
    }
    return null
}

private fun calculateEnd(scores: List<Score>) : Instant? {
    val endTs = scores.first { it.state == ScoreState.FINAL }.timestamp
    if (endTs != null) {
        return Instant.parse(endTs)
    }
    return null
}


fun friendlyDuration(seconds: Int) : String {
    val hours = seconds / 3600
    val minutes = (seconds % 3600) / 60
    val remainingSeconds = seconds % 60

    val hoursStr = if (hours > 0) {
        "$hours hours"
    } else {
        ""
    }

    val minutesStr = if (minutes > 0) {
        "$minutes minutes"
    } else {
        ""
    }

    val secondsSr =  if (remainingSeconds > 0) {
        "$remainingSeconds seconds"
    } else {
        ""
    }

    return listOf(hoursStr, minutesStr, secondsSr).joinToString(" ")
}

/**
 * Display a confirmation dialog that requires the viewer to enter some confirmation text.
 * @param cb if called with true, the action should be completed, otherwise, just close the dialog.
 */
@Composable
fun replayDialog(app: SportsApplication,
                 eventId: String,
                 cb: suspend (ReplayCompetitionRequest?) -> Unit) {

    var event by remember { mutableStateOf<CompetitiveEvent?>(null) }
    var homeTeam by remember { mutableStateOf<CompetitionParticipantStatus?>(null) }
    var awayTeam by remember { mutableStateOf<CompetitionParticipantStatus?>(null) }
    var scores by remember { mutableStateOf<List<Score>?>(null) }
    var originalEventDurationInSeconds by remember { mutableStateOf<Int?>(null) }

    // all of the fields needed to schedule the replay
    var airings: List<InProgressAiring>  by remember { mutableStateOf(emptyList()) }
    var startTime: iso8601Timestamp? by remember { mutableStateOf(null) }
    var durationInSeconds: Int? by remember { mutableStateOf(null) }
    var allowOverlap: Boolean by remember { mutableStateOf(true) }

    LaunchedEffect(eventId) {
        event = app.client.getCompetitionEvent(eventId)

        if (event != null) {
            homeTeam = event?.homeTeam()
            awayTeam = event?.awayTeam()

        }
        scores = app.client.getCompetitionScoreEvents(eventId).entities
        if (scores != null) {
            originalEventDurationInSeconds = event?.calculateDurationSeconds(scores!!)
        }
        val existingAirings = event?.distinctAirings() ?: listOf()
        airings = existingAirings.map {
            InProgressAiring(previousChannelId = it.channelId, previousChannelNumber = it.channelNumber, previousChannelName = it.channelName,
                coverageType = it.coverageType)
        }
    }

    fun isReplayConfigurationComplete(): Boolean {
        return airings.count { it.channelId != null } == airings.size &&
                startTime != null && durationInSeconds != null
    }

    fun buildRequest() : ReplayCompetitionRequest {
        return ReplayCompetitionRequest(
            airings = airings.mapNotNull {
                it.channelId?.let { channelId ->
                    // TODO: we need to get the actual channel name and number
                    Airing(channelId, coverageType = it.coverageType)
                }
            },
            startTime = Instant.parse(startTime!!),
            durationInSeconds = durationInSeconds!!,
            allowOverlap = allowOverlap
        )
    }

    fun CompetitiveEvent?.eventName(): String {
        if (this == null) return ""
        return "${awayTeam()?.competitor?.name} @ ${homeTeam()?.competitor?.name}"
    }


    dialogOverlay {
        this.title = "Replay Sporting Event"
        this.action {
            primary = true
            this.title = "Replay"
            enabled = isReplayConfigurationComplete()
            showProgressOnAction = true
            action {
                cb(buildRequest())
            }
        }
        this.content {
            if (event == null && scores?.isEmpty() == true) {
                messageBox(
                    "No entity with id $eventId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                    MessageType.WARNING
                )
            } else if (event == null) {
                spinner(size = Size.LARGE)
            } else {
                // refactor this to a component (use by both Event.kt and this)
                Div({
                    classes(SportsStyles.fullEvent)
                }) {
                    participantHeader(app, awayTeam!!, false)
                    Div({
                        style {
                            textAlign("center")
                        }
                    }) {
                        Div({
                            style {
                                fontSize(32.px)
                                fontWeight(700)
                                marginTop(50.px)
                                marginBottom(20.px)
                            }
                        }) {
                            Text("@")
                        }
                    }
                    participantHeader(app, homeTeam!!, false)
                }

                Div({
                    classes(SportsStyles.subheader)
                }) {
                    Text("Replay Summary")
                }
                boxContent {
                    detailGrid {
                        detail("Original Event Start") {
                            Div {
                                Text(event?.start?.toLocalExtendedDateTimeString(false).toString())
                            }
                        }
                        if (scores != null) {
                            detail("Original Event End") {
                                Div {
                                    Text(calculateEnd(scores!!)?.toLocalDateTimeString(false) ?: "")
                                }
                            }
                            detail("Duration") {
                                Text(originalEventDurationInSeconds?.let { friendlyDuration(it) } ?: "")
                            }
                            detail("Num Events") {
                                if (scores?.isNotEmpty() == true) {
                                    Text(scores!!.size.toString())
                                } else {
                                    Text("Event has no scores to replay")
                                }
                            }
                        }
                    }
                }

                if (airings.isNotEmpty()) {
                    Div({
                        classes(SportsStyles.subheader)
                    }) {
                        Text("Replay Airings")
                    }
                    boxTable<InProgressAiring> {
                        items(airings)
                        column("Replay Channel") {
                            content {
                                // TODO: switch with a complete channel picker, for now, we will only replay on the same channel or one of the hardcoded test channels
                                val channels = mutableMapOf<String, String>()
                                channels[it.previousChannelId] = "<Original Channel>"
                                channels["d831e944-f46e-8a8b-a04d-41ef5acce739"] = "Test Channel 01"
                                channels["1a0ff3bd-0f10-7079-b3de-c9a1fb0387af"] = "Test Channel 02"
                                channels["75985640-e351-dddf-79eb-454cbc826377"] = "Test Channel 03"
                                channels["d28674c2-740f-24af-1916-2a52f032f74e"] = "Test Channel 04"
                                channels["cc85f948-7d50-9103-d54b-c0c69b97fcfb"] = "Test Channel 05"

                                Div {
                                    buildSelect(it.channelId, channels.keys, channels, false) { selectedId ->
                                        val original = it
                                        console.log("airings: $airings")

                                        val index = airings.indexOfFirst { airing -> airing.tempId == original.tempId}
                                        console.log("index: $index")

                                        val updatedAirings = airings.toMutableList()
                                        updatedAirings.removeAt(index)

                                        console.log("updatedAirings: $updatedAirings")
                                        val newAiring = original.copy(
                                            channelId = selectedId
                                        )
                                        updatedAirings.add(index, newAiring)

                                        console.log("setting airings: $updatedAirings")
                                        airings = updatedAirings
                                    }
                                }
                            }
                        }
                        column("Original Channel Id") {
                            content {
                                Text(it.previousChannelId)
                            }
                        }
                        column("Original Channel Name") {
                            content {
                                if (it.previousChannelName != null) {
                                    Text(it.previousChannelName)
                                }
                            }
                        }
                        column("Original Channel Number") {
                            content {
                                if (it.previousChannelNumber != null) {
                                    Text(it.previousChannelNumber.toString())
                                }
                            }
                        }
                        column("Coverage") {
                            content {
                                simpleSelect(it.coverageType.name, listOf(
                                    AiringCoverage.HOME.name to AiringCoverage.HOME.name,
                                    AiringCoverage.AWAY.name to AiringCoverage.AWAY.name,
                                    AiringCoverage.NATIONAL.name to AiringCoverage.NATIONAL.name)
                                ) { changed ->
                                    if (it.coverageType.name != changed) {
                                        val airing = airings.find { airing -> airing.tempId == it.tempId}
                                        if (airing != null) {
                                            airing.coverageType = AiringCoverage.fromName(changed)
                                        }
                                    }
                                }
                            }
                        }
                        column("Action") {
                            content {
                                if (airings.size > 1) {
                                    iconAction("close") {
                                        val index = airings.indexOfFirst { airing -> airing.tempId == it.tempId }
                                        if (index >= 0) {
                                            val updatedAirings = airings.toMutableList()
                                            updatedAirings.removeAt(index)
                                            airings = updatedAirings
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                dialogField(
                    "Schedule Replay Start Time",
                    instruction = "Required"
                ) {
                    instantPicker(startTime?.let { Instant.parse(it) }) { selectedInstant ->
                        startTime = selectedInstant?.toString()
                    }
                }

                // show the duration picker
                if (originalEventDurationInSeconds != null) {
                    val durations = mutableMapOf<String, String>()
                    durations[originalEventDurationInSeconds.toString()] = "<Real Time>"

                    val fiveMinutesInSeconds = 300
                    var timeInSeconds = fiveMinutesInSeconds
                    while (timeInSeconds < originalEventDurationInSeconds!!) {
                        durations[timeInSeconds.toString()] = friendlyDuration(timeInSeconds)
                        timeInSeconds += fiveMinutesInSeconds
                    }

                    dialogField(
                        "Schedule Replay Duration",
                        instruction = "Required"
                    ) {
                        buildSelect(durationInSeconds?.toString(), durations.keys, durations, false) { durationInSecondsStr ->
                            durationInSeconds = durationInSecondsStr?.toIntOrNull()
                        }
                    }
                }
            }
        }
        onClose {
            cb(null)
        }
    }
}