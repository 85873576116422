package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.*
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.atlas.ResizeParams
import contentingestion.unifiedmodel.*
import kotlinx.coroutines.Job
import kotlinx.coroutines.async
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.seconds

/**
 * Renders a channel page, complete with channel details, shows, cuts, etc.
 */
@Composable
fun channel(epg: EPG, channelId: String, route: Route) {
    var channel by remember { mutableStateOf<ChannelSummary?>(null) }

    val refreshScope = rememberCoroutineScope()
    var refreshJob: Job? by remember { mutableStateOf(null) }

    LaunchedEffect(channelId) {
        refreshJob?.cancel()
        refreshJob = refreshScope.launch {
            while (true) {
                val newChannel = epg.fetchChannel(channelId)
                val netNewCuts = newChannel.cuts.orEmpty() - channel?.cuts.orEmpty().toSet()
                if (channel == null || netNewCuts.isNotEmpty()) {
                    channel = newChannel
                }
                delay(5.seconds)
            }
        }
    }

    serviceContentView({
        breadcrumbs {
            crumb("Channels", "channelguide/channels")
            channel?.channel?.channelNumber?.let {
                crumb("${channel?.channel?.name} (${it})", "channelguide/channels/${channel?.id}")
            }
        }
    }) {
        if (channel == null) {
            spinner(size = Size.LARGE)
        } else {
            Div({
                classes(ChannelGuideStyles.epgHeader)
            }) {
                Div({
                    classes(ChannelGuideStyles.epgHeaderImage)
                }) {
                    val image = channel?.mainImage()
                    if (image != null) {
                        defaultImage(image, ResizeParams(800, 450), 186.px)
                    }
                }

                Div({
                    classes(ChannelGuideStyles.epgHeaderContent)
                }) {
                    H1 {
                        channel?.channel?.name?.let {
                            Text(it)
                        }
                        aggregatorIconLink(channel?.id)
                    }

                    H4({
                        title("channel description, channel number, channel streaming id")
                    }) {
                        Text(listOfNotNull(channel?.channel?.description, channel?.channel?.channelNumber?.let {
                            "Ch $it"
                        }, channel?.channel?.streamingId).joinToString(" • "))
                    }
                }

                if (channel?.channel?.businessOnly == true) {
                    icon("apartment") {
                        title = "Business Only"
                        size = IconSize.NORMAL
                    }
                }

                val accessControls = channel!!.channel.accessControls?.default

                Div({
                    classes(ChannelGuideStyles.epgIconContainer)
                }) {
                    if (accessControls?.visible == false) {
                        icon("visibility_off") {
                            title = "Not visible"
                            size = IconSize.SMALL
                        }
                    }
                    if (accessControls?.discoverable == false) {
                        icon("hide_image") {
                            title = "Not discoverable"
                            size = IconSize.SMALL
                        }
                    }
                    if (accessControls?.visible == false) {
                        icon("thumb_down") {
                            title = "Not recommandable"
                            size = IconSize.SMALL
                        }
                    }
                }
            }

            channel?.let {
                nowPlaying(epg, it)
            }

            channel?.let { ch ->
                var section: String? = null
                route.switch {
                    default {
                        section = match
                    }
                }
                tabView {
                    allowNoSelect = true
                    selection = section

                    onSelect {
                        epg.navigate("channels/${channelId}/${it}")
                        false
                    }

                    tab("Shows", "shows") {
                        channelShows(epg, ch)
                    }

                    tab("Upcoming", "upcoming-episodes") {
                        upcomingChannelEpisodes(epg, ch)
                    }

                    tab("On Demand", "episodes") {
                        channelEpisodes(epg, ch)
                    }

                    if (channel?.channel?.type == EntityType.CHANNEL_LINEAR.name) {
                        tab("Cuts", "cuts") {
                            channelCuts(epg, ch)
                        }
                    }

                    tab("Lineups", "lineups") {
                        channelLineups(epg, ch)
                    }
                }
            }
        }
    }
}

/**
 * Now playing for the channel, includes show and episode information
 */
@Composable
fun nowPlaying(epg: EPG, channel: ChannelSummary) {
    var currentShow: Show? by remember { mutableStateOf(null) }
    var currentEpisode: Episode? by remember { mutableStateOf(null) }

    LaunchedEffect(channel.id) {
        val showJob = async {
            channel.show?.id?.let {
                try {
                    epg.fetchShow(it)
                } catch (t: Throwable) {
                    null
                }
            }
        }

        val episodeJob = async {
            channel.episode?.id?.let {
                try {
                    epg.fetchEpisode(it)
                } catch (t: Throwable) {
                    null
                }
            }
        }

        val show = showJob.await()
        val episode = episodeJob.await()
        currentShow = show
        currentEpisode = episode
    }

    val currentCut = channel.cuts?.firstOrNull()
    if (currentShow != null || currentCut != null || channel.show != null || channel.cuts?.firstOrNull() != null) {
        box(title = "Now playing") {
            val image = (currentShow?.images ?: currentCut?.images)?.get(ImagePurpose.TILE)
                ?.get(ImageAspectRatio.ASPECT_1X1)?.default
            val title = currentShow?.name ?: channel.show?.name ?: currentCut?.name ?: channel.cuts?.firstOrNull()?.name
            if (title != null) {
                detail(image) {
                    H2({ style { marginTop(0.px) } }) {
                        Text(currentShow?.name ?: currentCut?.name ?: "")
                        aggregatorIconLink(currentShow?.id)
                    }

                    val episodeId = currentEpisode?.id ?: channel.episode?.id
                    (currentEpisode?.name ?: channel.episode?.name)?.let { episodeName ->
                        if (episodeName != title) {
                            H3({ style { marginTop(0.px) } }) {
                                Text(episodeName)
                                aggregatorIconLink(episodeId)
                            }
                        }
                    }

                    currentShow?.description?.let {
                        P {
                            Text(it)
                        }
                    }

                    val cut = channel.cuts?.firstOrNull()
                    if (cut != null) {
                        Div({
                            classes(ChannelGuideStyles.epgChannelNowPlaying)
                        }) {
                            val cutImage = cut.images?.get(ImagePurpose.TILE)
                                ?.get(ImageAspectRatio.ASPECT_1X1)?.default
                            if (cutImage != null) {
                                defaultImage(cutImage, ResizeParams(300, 300), 88.px)
                            }
                            Div {
                                Div({
                                    style {
                                        fontWeight(700)
                                        marginTop(2.px)
                                    }
                                }) {
                                    Text(cut.artist ?: cut.name)
                                    aggregatorIconLink(cut.id)
                                }
                                Div({
                                    style {
                                        fontSize(.8.cssRem)
                                    }
                                }) {
                                    if (cut.artist != null && cut.artist != cut.name) {
                                        Text(listOfNotNull(cut.name, cut.album).joinToString(" • "))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun <T> itemsOrMissing(items: List<T>, missingMessage: String, block: @Composable (List<T>) -> Unit) {
    if (items.isNotEmpty()) {
        block(items)
    } else {
        Div({
            style {
                fontWeight(600)
                padding(2.cssRem)
            }
        }) {
            Text(missingMessage)
        }
    }
}

/**
 * Displays details about shows of the channel
 */
@Composable
fun channelShows(epg: EPG, channel: ChannelSummary) {
    var shows: List<Show>? by remember { mutableStateOf(null) }

    LaunchedEffect(channel.show?.id) {
        launch {
            shows = try {
                epg.fetchShowsForChannel(channel.id)
            } catch (e: Throwable) {
                emptyList()
            }
        }
    }

    waitUntilNonNull(shows) { showList ->
        itemsOrMissing(showList, "This channel does not have any shows") {
            it.forEach {
                showSummaryItem(it)
            }
        }
    }
}

@Composable
fun detail(image: Image?, contentBlock: @Composable () -> Unit) {
    Div({
        style {
            display(DisplayStyle.Flex)
            gap(8.px)
        }
    }) {
        if (image != null) {
            defaultImage(image, ResizeParams(600, 600), 186.px)
        }

        Div({
            style {
                flex(1)
            }
        }) {
            contentBlock()
        }
    }
}

@Composable
fun upcomingChannelEpisodes(epg: EPG, channel: ChannelSummary) {
    var upcoming: List<Episode>? by remember { mutableStateOf(null) }

    LaunchedEffect(channel.show?.id) {
        launch {
            upcoming = try {
                epg.fetchLinearEpisodesForChannel(channel.id).filter {
                    val start = it.startTimestamp?.let {
                        Instant.parse(it)
                    }
                    start != null && start > Clock.System.now()
                }.sortedBy { it.startTimestamp }
            } catch (e: Throwable) {
                emptyList()
            }
        }
    }

    waitUntilNonNull(upcoming) { episodes ->
        itemsOrMissing(episodes, "This channel does not have any upcoming episodes") {
            it.forEach {
                episodeSummaryItem(it)
            }
        }
    }
}

/**
 * Displays details about episodes of the channel
 */
@Composable
fun channelEpisodes(epg: EPG, channel: ChannelSummary) {
    var aod: List<Episode>? by remember { mutableStateOf(null) }

    LaunchedEffect(channel.show?.id) {
        launch {
            aod = try {
                epg.fetchAudioEpisodesForChannel(channel.id)
            } catch (e: Throwable) {
                emptyList()
            }
        }
    }
    waitUntilNonNull(aod) { episodes ->
        itemsOrMissing(episodes, "This channel does not have any on-demand episodes") {
            it.forEach {
                episodeSummaryItem(it)
            }
        }
    }
}

/**
 * A view of recent cuts for a channel
 */
@Composable
fun channelCuts(epg: EPG, channel: ChannelSummary) {
    var cuts: List<LinearCut>? by remember { mutableStateOf(null) }

    LaunchedEffect(Unit) {
        try {
            cuts = epg.api.getChannelCuts(channel.id, 30, order = SortOrder.desc).decode<LinearCut>().entities
        } catch (e: Throwable) {
            e.printStackTrace()
            Application.notifications.showError("Unable to load cuts")
        }
    }

    waitUntilNonNull(cuts, delay = 500) { cutList ->
        itemsOrMissing(cutList, "This channel does not have any cuts") {
            it.forEach {
                cutSummaryItem(it)
            }
        }
    }
}

@Composable
fun channelLineups(epg: EPG, channel: ChannelSummary) {
    var lineups by remember { mutableStateOf<List<LineupSummary>?>(null) }
    LaunchedEffect(channel.id) {
        try {
            lineups = epg.api.getChannelLineups(channel.id).decode<LineupSummary>().entities
        } catch (t: Throwable) {
            Application.notifications.showError("Unable to load lineups")
        }
    }

    waitUntilNonNull(lineups) { lineupList ->
        lineupList.forEach { lineup ->
            Div({
                classes(ChannelGuideStyles.epgListItem)
                style {
                    alignItems(AlignItems.FlexStart)
                }
            }) {
                Div({
                    classes(ChannelGuideStyles.epgListItemLeading)
                }) {
                    A(href = "#channelguide/lineups/${lineup.id}") {
                        Text(lineup.lineupId.toString())
                    }
                }
                Div({
                    classes(ChannelGuideStyles.epgListItemMain)
                }) {
                    lineup.name?.let {
                        Text(it)
                    }
                }
                Div({
                    classes(ChannelGuideStyles.epgListItemTrailing)
                })
            }
        }
    }
}