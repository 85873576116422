package com.siriusxm.cmc.smithy

import androidx.compose.runtime.Composable
import androidx.compose.runtime.key
import com.siriusxm.pia.components.expandingRowTable
import com.siriusxm.pia.components.pill
import com.siriusxm.smithy4kt.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object EntityModelStyles : StyleSheet() {
    val entityMemberName by style {
        fontWeight(700)
        display(DisplayStyle.InlineBlock)
        marginRight(1.em)
    }

    val entityMemberType by style {
        fontSize(.8.em)
    }

    val shapeDocumentation by style {
        fontSize(.8.em)
    }

    val nestedShape by style {
        paddingTop(5.px)
    }

    val shapeConstraint by style {
        fontSize(.8.em)
        fontWeight(700)
    }

    val serviceDocs by style {
        margin(1.em, 0.px)
    }

    val serviceHttp by style {
        marginTop(5.px)
    }

    val serviceOperationDocs by style {
        marginTop(5.px)
    }

    val serviceOperationDetails by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        gap(1.em)
    }

    val serviceOperationSection by style {
        type("h3").style {
            margin(0.px, 0.px, 3.px, 0.px)
        }
    }

    val serviceMethod by style {
        fontWeight(700)
        padding(3.px, 5.px)
        display(DisplayStyle.InlineBlock)
        marginRight(1.em)
        borderRadius(3.px)
        color(Color.white)

        backgroundColor(Color("#999"))
        (self + className("POST")).style {
            backgroundColor(Color("#49cc90"))
        }
        (self + className("GET")).style {
            backgroundColor(Color("#61affe"))
        }
        (self + className("DELETE")).style {
            backgroundColor(Color("#f93e3e"))
        }
        (self + className("PUT")).style {
            backgroundColor(Color("#fca130"))
        }
    }
    val serviceUri by style {
        fontFamily("monospace")
    }
}

/**
 * Renders a Smithy shape documentation view.
 */
@Composable
fun Shape(shape: SmithyShape) {
    when (shape) {
        is SmithyStructure -> {
            Structure(shape)
        }

        is SmithyMap -> {
            SmithyMapView(shape)
        }

        is SmithyList -> {
            SmithyListView(shape)
        }

        is SmithyEnum -> {
            EnumShape(shape)
        }

        is SmithyString -> SmithyStringView(shape)
        is SmithyNumber<*> -> SmithyNumberView(shape)
        else -> {

        }
    }
}

/**
 * Renders a Smithy Structure definition view.
 */
@Composable
fun Structure(structure: SmithyStructure) {
    expandingRowTable<SmithyMember> {
        items = structure.allMembers.values.toList()

        alwaysShowCollapsed = true
        collapsedContent { member ->
            Span({ classes(EntityModelStyles.entityMemberName) }) {
                Text(member.name)
            }
            Span({ classes(EntityModelStyles.entityMemberType) }) {
                val shapeName = when (val memberShape = member.shape) {
                    is SmithyList -> {
                        "list of ${memberShape.member.id.substringAfter("#")}"
                    }

                    is SmithyMap -> {
                        "map of ${memberShape.key.id.substringAfter("#")} to ${memberShape.value.id.substringAfter("#")}"
                    }

                    else -> {
                        memberShape.id.substringAfter("#")
                    }
                }
                Text(shapeName)
                if (member.required) {
                    Text("*")
                }
            }
        }
        expandedContent { member ->
            val docs = member.documentation ?: member.shape.documentation
            docs?.let {
                Div({ classes(EntityModelStyles.shapeDocumentation) }) {
                    Text(it)
                }
            }
            Div({ classes(EntityModelStyles.nestedShape) }) {
                key(member.name) {
                    Shape(member.shape)
                }
            }
        }
    }
}

fun plural(count: Int?, base: String): String {
    return "${base}${if (count != 1) "s" else ""}"
}

/**
 * A smithy list documentation view.
 */
@Composable
fun SmithyListView(list: SmithyList) {
    Div {
        list.length?.let {
            shapeConstraint(
                if (it.min != null && it.max != null) {
                    if (it.min == it.max) {
                        "Exactly ${it.min} ${plural(it.min, "item")}"
                    }
                    "Between ${it.min} and ${it.max} ${plural(it.min, "item")}"
                } else if (it.min != null) {
                    "At least ${it.min} ${plural(it.min, "item")}"
                } else if (it.max != null) {
                    "At most ${it.max} ${plural(it.min, "item")}"
                } else null
            )
        }

        Shape(list.member)
    }
}

/**
 * A smithy map documentation view.
 */
@Composable
fun SmithyMapView(map: SmithyMap) {
    Shape(map.value)
}

/**
 * A Smithy String documentation view.
 */
@Composable
fun SmithyStringView(shape: SmithyString) {
    shape.pattern?.let {
        shapeConstraint("Pattern: ${it.pattern}")
    }
    shape.length?.let {
        shapeConstraint(
            if (it.min != null && it.max != null) {
                if (it.min == it.max) {
                    "Exactly ${it.min} ${plural(it.min, "character")} long"
                } else {
                    "Between ${it.min} and ${it.max} characters long"
                }
            } else if (it.min != null) {
                "At least ${it.min} ${plural(it.min, "character")} long"
            } else if (it.max != null) {
                "At most ${it.max} ${plural(it.max, "character")} long"
            } else null
        )
    }
}

@Composable
fun shapeConstraint(message: String?) {
    if (message != null) {
        Div({ classes(EntityModelStyles.shapeConstraint) }) {
            Text(message)
        }
    }
}

/**
 * Displays documentation for a Smithy number definition.
 */
@Composable
fun SmithyNumberView(shape: SmithyNumber<*>) {
    shapeConstraint(
        if (shape.min != null && shape.max != null) {
            "Must be between ${shape.min} and ${shape.max}"
        } else if (shape.min != null) {
            "Must greater than or equal to ${shape.min}"
        } else if (shape.max != null) {
            "Must be less than or equal to ${shape.max}"
        } else {
            null
        }
    )
}

/**
 * Displays documentation for a Smithy enumeration.
 */
@Composable
fun EnumShape(shape: SmithyEnum) {
    Div({
        style {
            display(DisplayStyle.Flex)
            margin(3.px, 0.px)
            gap(6.px)
            flexWrap(FlexWrap.Wrap)
        }
    }) {
        shape.allEntries.forEach {
            pill(it.value)
        }
    }
}