package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.siriusxm.pia.SXMUI
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

/**
 * Configuration for the paginator.
 */
class PaginatorConfig {
    var pages: Int = 1
    var currentPage: Int = 0
    internal var navigate: suspend (Int) -> Unit = {}

    fun onNavigate(cb: suspend (Int) -> Unit) {
        navigate = cb
    }
}

object PaginatorStyles : StyleSheet() {
    val nav by style {
        cursor("pointer")
    }

    val navDisabled by style {
        color(SXMUI.disabledTextColor.value())
        cursor("default")
    }

    val pageNumber by style {
        margin(0.px, 5.px)
        lineHeight((1.7).cssRem)
        display(DisplayStyle.InlineBlock)
        height((1.7).cssRem)
        property("vertical-align", "top")
    }

    val selectedPage by style {
        fontWeight("bold")
    }
}

/**
 * Renders a standard pagination control. Supports indefinite pagination when the total
 * number of items may not be known.
 */
@Composable
fun paginator(init: PaginatorConfig.() -> Unit) {
    val coroutineScope = rememberCoroutineScope()
    val config = PaginatorConfig().apply(init)

    val start = maxOf(config.currentPage - 5, 0)

    val end = if (config.pages == Int.MAX_VALUE) {
        config.currentPage
    } else {
        minOf(config.pages - 1, start + 6)
    }

    if (config.pages == 0) {
        return
    }

    Div {
        Span({
            classes(PaginatorStyles.nav)
            if (config.currentPage == 0) {
                classes(PaginatorStyles.navDisabled)
            }
        }) {
            icon("chevron_left") {
                if (config.currentPage == 0) {
                    action {
                        config.navigate(config.currentPage - 1)
                    }
                }
            }
        }

        (start..end).forEach { page ->
            Span({
                classes(PaginatorStyles.nav)
                classes(PaginatorStyles.pageNumber)

                if (config.currentPage == page) {
                    classes(PaginatorStyles.selectedPage)
                }

                onClick {
                    it.preventDefault()
                    coroutineScope.launch {
                        if (page != config.currentPage) {
                            config.navigate(page)
                        }
                    }
                }

            }) {
                Text((page + 1).toString())
            }
        }

        if (config.pages == Int.MAX_VALUE) {
            Span({
                classes(PaginatorStyles.pageNumber)
            }) {
                Text("…")
            }
        }

        Span({
            classes(PaginatorStyles.nav)
            if (config.currentPage >= config.pages - 1) {
                classes(PaginatorStyles.navDisabled)
            }
        }) {
            icon("chevron_right") {
                if (config.currentPage < config.pages - 1) {
                    action {
                        config.navigate(config.currentPage + 1)
                    }
                }
            }
        }
    }
}