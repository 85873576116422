package com.siriusxm.pia.components

import androidx.compose.runtime.DisposableEffectResult
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Node
import org.w3c.dom.events.Event

/**
 * Installs a document wide click handler that will be called if there is a click anywhere
 * EXCEPT this element. Useful for popups. Handles installing and uninstalling the handler via
 * disposable effects.
 */
fun AttrsScope<*>.onClickOutside(handler: () -> Unit) {
    ref { thisElement ->
        val listener: (Event) -> Unit = { event ->
            val target = event.target as? Node
            if (target != null) {
                val isInsidePopup = thisElement.contains(target)
                if (!isInsidePopup) {
                    event.preventDefault()
                    event.stopPropagation()
                    handler()
                }
            }
        }
        // Attach the listener
        document.addEventListener("click", listener)
        object : DisposableEffectResult {
            override fun dispose() {
                document.removeEventListener("click", listener)
            }
        }
    }
}