package com.siriusxm.pia.views

import androidx.compose.runtime.Composable
import com.siriusxm.pia.Application
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.border
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.serviceContentView
import com.siriusxm.pia.components.serviceView
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

object HomeStyles : StyleSheet() {
    val applicationList by style {

        backgroundColor(SXMUI.containerContentBackgroundColor.value())
    }

    val application by style {
        padding(1.cssRem)
        border(bottom = 1.px, color = SXMUI.borderDividerDefault.value())
        cursor("pointer")

        type("h4") style {
            fontSize((1.2).em)
            fontWeight(700)
            margin(0.px, 0.px, 4.px, 0.px)
        }

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
        }
    }

    val applicationDescription by style {
        color(SXMUI.subtleTextColor.value())
        fontSize((0.9).cssRem)
    }
}

@Composable
fun application(name: String, description: String, path: String) {
    Div({
        classes(HomeStyles.application)
        onCut {
            Application.navigation.navigate(path)
        }
    }) {
        A("/#${path}") {
            H4 {
                Text(name)
            }
        }
        Div({ classes(HomeStyles.applicationDescription) }) {
            Text(description)
        }
    }
}


@Composable
fun homeDashboard() {
    serviceView({
    }) {
        serviceContentView({}) {
            Div({
                style {
                    maxWidth(600.px)
                }
            }) {
                box({
                    title = "Applications"
                    paddedContent = false
                }) {
                    applicationsList()
                }
            }
        }
    }
}

@Composable
fun applicationsList() {
    Div({ classes(HomeStyles.applicationList) }) {
        application(
            "Content Aggregator",
            "Central clearinghouse of all content data at SiriusXM",
            "aggregator"
        )
        application(
            "Podcasts",
            "The podcast catalog, with tools for configuring and troubleshooting",
            "podcasts"
        )
        application(
            "Transcriptions",
            "Provides transcriptions for content throughout SiriusXM",
            "transcriptions"
        )
        application("MDDB", "Provides programming and lineup data.", "mddb")
        application("Sports", "Sports data and information", "sports")

        if (Application.viewer.contentEditor || Application.viewer.operationsUser) {
            application("EPG", "Channel data and information (existing)", "epg")
        }
        if (Application.viewer.contentEditor || Application.viewer.operationsUser) {
            application("Channel Guide", "Channel data and information", "channelguide")
        }
    }
}