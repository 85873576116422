package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.aggregator.AuditContext
import contentingestion.aggregator.ScheduledUpdatesList
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*
import kotlin.time.Duration

@Serializable
class IncomingEntityResult(
    val entity: IncomingEvent,
    val partials: List<IncomingEvent>? = null,
    val scheduled: ScheduledUpdatesList? = null,
)

@Serializable
class IncomingEvent(
    val data: JsonObject,
    val source: String? = null,
    val partialId: String? = null,
    val ts: Instant? = null,
    val auditContext: AuditContext? = null
)


@Serializable
data class EntityListResponse(
    val entities: List<JsonObject>,
    val paginationToken: String? = null
)

@Serializable
data class BackfillStatus(
    /**
     * The status of the job
     */
    val status: String,

    /**
     * The job id.
     */
    val jobId: String,

    /**
     * The timestamp being used for the job.
     */
    val timestamp: String? = null,

    /**
     * A map of counts for each type
     */
    val counts: Map<String, Int>? = null,

    /**
     * A count of the number of items submitted in this batch.
     */
    val count: Int = 0,

    val jobData: JsonElement? = null,
    val targetArn: String? = null,
    val roleArn: String? = null,

    /**
     * The set of filtering rules of this job
     */
    val rules: Set<String>? = null,

    /**
     * This is the time the job started, not the time the data is based on
     */
    val startTime: Instant? = null,
    /**
     * If the job is in a terminal state, this is the time at which the job completed
     */
    val completeTime: Instant? = null,

    /**
     * The progress of the job for each type
     */
    val typeProgress: Map<String, TypeProgress>
) {
    fun elapsedTime(): Duration {
        val now = Clock.System.now()
        return (completeTime ?: now) - (startTime ?: now)
    }
}

@Serializable
data class TypeProgress(
    val count: Int,
    val totalSegments: Int,
    val currentSegment: Int
) {
    val progress: Double = currentSegment.toDouble() / totalSegments.toDouble()
    val isComplete: Boolean = totalSegments == currentSegment
}

/**
 * Get the string value with the given key.
 */
fun JsonObject.getString(key: String): String? {
    return get(key)?.jsonPrimitive?.contentOrNull
}

fun JsonElement.getString(key: String): String? {
    return (this as? JsonObject)?.getString(key)
}

/**
 * Get the float value with the given key.
 */
fun JsonObject.getFloat(key: String): Float? {
    return get(key)?.jsonPrimitive?.floatOrNull
}

fun JsonElement.getFloat(key: String): Float? {
    return (this as? JsonObject)?.getFloat(key)
}

/**
 * Shorthand to get the entity id from entity Json
 */
val JsonElement.entityId: String? get() = jsonObject.getString("id")

/**
 * Shorthand to get the entity type from entity Json
 */
val JsonElement.entityType: String? get() = jsonObject.getString("type")

/**
 * Shorthand to get the entity type from entity Json
 */
val JsonElement.entityVersion: String? get() = jsonObject.getString("version")
