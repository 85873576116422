package com.siriusxm.pia.views.unifiedaggregator.events

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.TimeRange
import com.siriusxm.pia.components.simpleSelect
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.components.timeRangeSelect
import com.siriusxm.pia.views.unifiedaggregator.defaultTimeRanges
import contentingestion.aggregator.CloudEventList
import contentingestion.aggregator.EventSearchStatus
import contentingestion.aggregator.EventSearchStatusState
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * Styles for the events view
 */
object EntityEventsStyles : StyleSheet() {
    val eventsHeader by style {
        display(DisplayStyle.Flex)
        width(100.percent)
        padding(5.px, 0.px)
        gap(5.px)
    }

    val loadingBlock by style {
        display(DisplayStyle.Flex)
        flex(1)
        gap(5.px)
        padding(5.px)
        alignItems(AlignItems.Center)
        fontWeight(700)
    }
}

/**
 * A view, typically displayed above events, to allow selection of query options
 */
@Composable
fun eventsSelectionHeader(
    loading: Boolean, loadingText: String = "Loading",
    selectedTimeRange: TimeRange? = null,
    selectedQueryOption: String? = null,
    queryOptions: List<Pair<String, String>>? = null,
    onUpdate: (range: TimeRange?, queryOption: String?) -> Unit
) {
    Div({
        classes(EntityEventsStyles.eventsHeader)
    }) {
        Div({
            classes(EntityEventsStyles.loadingBlock)
        }) {
            if (loading) {
                spinner()
                if (loadingText.isNotBlank()) {
                    Text(loadingText)
                }
            }
        }

        if (queryOptions != null) {
            Div {
                simpleSelect(selectedQueryOption, queryOptions) {
                    onUpdate(selectedTimeRange, it)
                }
            }
        }

        Div {
            timeRangeSelect(defaultTimeRanges, selected = selectedTimeRange) { range ->
                onUpdate(range, selectedQueryOption)
            }
        }
    }
}

/**
 * Executes a poll to load events. Since each events API has a different endpoint, the caller needs to
 * manage the actual call. The query id is passed to the caller.
 */
suspend fun loadEvents(
    call: suspend (queryId: String?) -> EventSearchStatus,
    onUpdate: suspend (CloudEventList) -> Unit = {}
): EventSearchStatus {
    var status = call(null)
    while (status.state == EventSearchStatusState.IN_PROGRESS) {
        onUpdate(status.results.orEmpty())
        delay(500)
        status = call(status.id)
    }
    return status
}