// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.epg

import com.siriusxm.pia.rest.sports.EntityResponse
import contentingestion.unifiedmodel.Channel
import contentingestion.unifiedmodel.Show
import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlin.collections.Map

@OptIn(InternalKtorfitApi::class)
public class _EpgClientImpl : EpgClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun fetchAllLinearChannels(headerMap: Map<String, String>,
      paginationToken: String?): EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/types/channel-linear/all")
        paginationToken?.let{ parameter("paginationToken", "$it") }
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchAllXtraChannels(headerMap: Map<String, String>,
      paginationToken: String?): EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/types/channel-xtra/all")
        paginationToken?.let{ parameter("paginationToken", "$it") }
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchLinearChannelById(channelId: String, headerMap: Map<String, String>):
      Channel? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/${"$channelId".encodeURLPath()}/channel-linear/0.1")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.Channel?",
    typeInfo = typeInfo<contentingestion.unifiedmodel.Channel?>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.Channel?,
        contentingestion.unifiedmodel.Channel?>(_typeData,_ext)
  }

  override suspend fun fetchXtraChannelById(channelId: String, headerMap: Map<String, String>):
      Channel? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/${"$channelId".encodeURLPath()}/channel-xtra/0.1")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.Channel?",
    typeInfo = typeInfo<contentingestion.unifiedmodel.Channel?>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.Channel?,
        contentingestion.unifiedmodel.Channel?>(_typeData,_ext)
  }

  override suspend fun fetchShowsForLinearChannel(channelId: String,
      headerMap: Map<String, String>): EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/channel-linear-show/object/${"$channelId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchShowsForXtraChannel(channelId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/channel-xtra-show/object/${"$channelId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchShowById(showId: String, headerMap: Map<String, String>): Show? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/${"$showId".encodeURLPath()}/show/0.1")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.Show?",
    typeInfo = typeInfo<contentingestion.unifiedmodel.Show?>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.Show?,
        contentingestion.unifiedmodel.Show?>(_typeData,_ext)
  }

  override suspend fun fetchAllLineups(headerMap: Map<String, String>, paginationToken: String?):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/types/lineup/all")
        paginationToken?.let{ parameter("paginationToken", "$it") }
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createEpgClient(): EpgClient = this.create(_EpgClientImpl().apply { _converter=
    KtorfitConverterHelper(this@createEpgClient) })
