package com.siriusxm.pia.rest.epg

import contentingestion.unifiedmodel.*
import de.jensklingenberg.ktorfit.http.GET
import de.jensklingenberg.ktorfit.http.Path
import de.jensklingenberg.ktorfit.http.Query
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.decodeFromJsonElement

enum class SortOrder {
    desc,
    asc
}

/**
 * Client for the EPG API.
 */
interface EPGApiClient {
    @GET("channels/{channelId}")
    suspend fun getChannel(@Path("channelId") channelId: String): ChannelSummary

    @GET("shows/{showId}")
    suspend fun getShow(@Path("showId") showId: String): Show

    /**
     * Get all channels, optionally filtered by lineup or category
     */
    @GET("channels")
    suspend fun getChannels(
        @Query("lineup") lineupId: String? = null, @Query("category") categoryId: String? = null,
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
        /**
         * A timestamp. If set, only channels that have received a cut update since that time will be returned.
         */
        @Query("cutSince") cutSince: String? = null,
    ): ChannelResult

    @GET("lineups")
    suspend fun getLineups(
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
    ): PaginatedResult

    @GET("channels/{id}/shows")
    suspend fun getChannelShows(
        @Path("id") channelId: String,
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
    ): PaginatedResult

    /**
     * Get the list of episodes for this channel
     */
    @GET("channels/{id}/episodes")
    suspend fun getChannelEpisodes(
        @Path("id") channelId: String,
        @Query("type") episodeType: String = "episode-audio",
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
        @Query("order") order: SortOrder? = null,
    ): PaginatedResult

    /**
     * Get a list of recent linear cuts for this channel
     */
    @GET("channels/{id}/cuts")
    suspend fun getChannelCuts(
        @Path("id") channelId: String,
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
        @Query("order") order: SortOrder? = null,
    ): PaginatedResult

    /**
     * Get the list of lineups that a show is part of.
     */
    @GET("channels/{id}/lineups")
    suspend fun getChannelLineups(
        @Path("id") channelId: String
    ): PaginatedResult

    @GET("shows/{id}/episodes")
    suspend fun getShowEpisodes(
        @Path("id") showId: String,
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
    ): PaginatedResult

    @GET("episodes/{id}/cuts")
    suspend fun getEpisodeCuts(
        @Path("id") episodeId: String,
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
    ): PaginatedResult

    @GET("channels/categories")
    suspend fun getChannelCategories(
        @Query("max") max: Int? = null,
        @Query("token") paginationToken: String? = null,
    ): PaginatedResult
}

/**
 * Defines the channel summary, which is the current state of a channel
 * at a moment in time.
 */
@Serializable
data class ChannelSummary(
    val channel: ChannelDetailSummary,
    val cuts: List<LinearCut>? = null,
    val show: ShowSummary? = null,
    val episode: EpisodeSummary? = null,
    /**
     * The list of tag categories of this channel.
     */
    val categories: List<entityId>? = null
)

val ChannelSummary.id: String get() = channel.id

@Serializable
data class ChannelDetailSummary(
    val id: String,
    val type: String,
    val name: String,
    val description: String? = null,
    val images: ImagePurposeMap? = null,
    val flags: ChannelFlags? = null,
    val accessControls: EntityAccessControls? = null,
    val channelNumber: Int,
    val businessOnly: Boolean? = null,
    val relatedChannelId: entityId? = null,
    val streamingId: String? = null,
    /**
     * The list of tag categories of this channel.
     */
    val categories: List<entityId>? = null
)

@Serializable
data class ShowSummary(
    val id: String,
    val name: String
)

@Serializable
data class EpisodeSummary(
    val id: String,
    val name: String
)

@Serializable
data class Category(
    val id: String,
    val term: String,
    val description: String? = null
) {
    val terms: List<String> get() = term.split("::")

    /**
     * Get the ID of the parent category.
     */
    val parentId: String?
        get() {
            return terms.dropLast(1).ifEmpty { null }?.joinToString("::")
        }
}

@Serializable
data class LineupSummary(
    val id: String,
    val lineupId: Int,
    val name: String? = null,
    val region: String,
    val platform: String
)

@Serializable
data class ChannelResult(
    val channels: List<ChannelSummary>,
    val paginationToken: String? = null
)

@Serializable
data class PaginatedResult(
    val entities: List<JsonElement>,
    val token: String? = null
)

data class EntityResult<T>(
    val entities: List<T>,
    val token: String? = null
)

val epgJson = Json {
    ignoreUnknownKeys = true
}

inline fun <reified T> PaginatedResult.decode(): EntityResult<T> {
    return EntityResult(entities.map {
        epgJson.decodeFromJsonElement<T>(it)
    }, token)
}