// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.views.podcasts

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress

@OptIn(InternalKtorfitApi::class)
public class _IngestionClientImpl : IngestionClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun auditShows(requestedBy: String): AuditShowResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "audit/shows/${"$requestedBy".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.views.podcasts.AuditShowResponse",
    typeInfo = typeInfo<com.siriusxm.pia.views.podcasts.AuditShowResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.views.podcasts.AuditShowResponse,
        com.siriusxm.pia.views.podcasts.AuditShowResponse>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createIngestionClient(): IngestionClient =
    this.create(_IngestionClientImpl().apply { _converter=
    KtorfitConverterHelper(this@createIngestionClient) })
