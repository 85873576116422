// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.epg

import contentingestion.unifiedmodel.Show
import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.Int
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress

@OptIn(InternalKtorfitApi::class)
public class _EPGApiClientImpl : EPGApiClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun getChannel(channelId: String): ChannelSummary {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "channels/${"$channelId".encodeURLPath()}")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.ChannelSummary",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.ChannelSummary>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.ChannelSummary,
        com.siriusxm.pia.rest.epg.ChannelSummary>(_typeData,_ext)!!
  }

  override suspend fun getShow(showId: String): Show {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "shows/${"$showId".encodeURLPath()}")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.Show",
    typeInfo = typeInfo<contentingestion.unifiedmodel.Show>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.Show,
        contentingestion.unifiedmodel.Show>(_typeData,_ext)!!
  }

  override suspend fun getChannels(
    lineupId: String?,
    categoryId: String?,
    max: Int?,
    paginationToken: String?,
  ): ChannelResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "channels")
        lineupId?.let{ parameter("lineup", "$it") }
        categoryId?.let{ parameter("category", "$it") }
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.ChannelResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.ChannelResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.ChannelResult,
        com.siriusxm.pia.rest.epg.ChannelResult>(_typeData,_ext)!!
  }

  override suspend fun getLineups(max: Int?, paginationToken: String?): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "lineups")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }

  override suspend fun getChannelShows(
    channelId: String,
    max: Int?,
    paginationToken: String?,
  ): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "channels/${"$channelId".encodeURLPath()}/shows")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }

  override suspend fun getChannelEpisodes(
    channelId: String,
    episodeType: String,
    max: Int?,
    paginationToken: String?,
  ): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "channels/${"$channelId".encodeURLPath()}/episodes")
        episodeType?.let{ parameter("type", "$it") }
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }

  override suspend fun getChannelCuts(
    channelId: String,
    max: Int?,
    paginationToken: String?,
  ): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "channels/${"$channelId".encodeURLPath()}/cuts")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }

  override suspend fun getShowEpisodes(
    showId: String,
    max: Int?,
    paginationToken: String?,
  ): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "shows/${"$showId".encodeURLPath()}/episodes")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }

  override suspend fun getEpisodeCuts(
    episodeId: String,
    max: Int?,
    paginationToken: String?,
  ): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "episodes/${"$episodeId".encodeURLPath()}/cuts")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }

  override suspend fun getChannelCategories(max: Int?, paginationToken: String?): PaginatedResult {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "channels/categories")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.epg.PaginatedResult",
    typeInfo = typeInfo<com.siriusxm.pia.rest.epg.PaginatedResult>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.epg.PaginatedResult,
        com.siriusxm.pia.rest.epg.PaginatedResult>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createEPGApiClient(): EPGApiClient = this.create(_EPGApiClientImpl().apply {
    _converter= KtorfitConverterHelper(this@createEPGApiClient) })
