package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.json
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.decode
import com.siriusxm.pia.utils.recode
import com.siriusxm.pia.utils.toDurationHumanString
import com.siriusxm.pia.utils.toLocalDateTimeString
import contentingestion.unifiedmodel.*
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.milliseconds

/**
 * Renders and episode page, with all details including cuts, etc.
 */
@Composable
fun episode(epg: EPG, episodeId: String) {
    var loading by remember { mutableStateOf<Boolean?>(null) }
    var episode by remember { mutableStateOf<Episode?>(null) }
    var cuts by remember { mutableStateOf<List<AudioCut>?>(null) }

    LaunchedEffect(episodeId) {
        loading = true

        val episodeJob = async {
            epg.fetchEpisode(episodeId)
        }
        val cutsJob = async {
            epg.api.getEpisodeCuts(episodeId).decode<AudioCut>().entities.sortedBy {
                it.offset
            }
        }
        awaitAll(episodeJob, cutsJob)
        cuts = cutsJob.await()
        episode = episodeJob.await()
        loading = false
    }

    serviceContentView({
    }) {
        if (loading == true) {
            spinner(size = Size.LARGE)
        } else {
            if (episode != null) {
                Div({
                    classes(ChannelGuideStyles.epgHeader)
                }) {
                    Div({
                        classes(ChannelGuideStyles.epgHeaderImage)
                    }) {
                        defaultImage(episode?.images, ImageAspectRatio.ASPECT_16X9, 186.px)
                    }

                    Div({
                        classes(ChannelGuideStyles.epgHeaderContent)
                    }) {
                        H1 {
                            episode?.name?.let {
                                Text(it)
                            }
                        }
                        episode?.description?.let {
                            P {
                                Text(it)
                            }
                        }

                        episode?.duration?.let {
                            Text(it.milliseconds.toDurationHumanString())
                        }
                    }
                }
            }
            tabView {
                tab("Details") {
                    box {
                        episode?.hosts?.ifEmpty { null }?.let { hosts ->
                            episodeDetailSection("Hosts") {
                                Text(hosts.joinToString(", "))
                            }
                        }
                        episode?.guests?.ifEmpty { null }?.let { hosts ->
                            episodeDetailSection("Guests") {
                                Text(hosts.joinToString(", "))
                            }
                        }
                        episode?.topics?.ifEmpty { null }?.let { hosts ->
                            episodeDetailSection("Topics") {
                                Text(hosts.joinToString(", "))
                            }
                        }
                        episode?.expiration?.ifEmpty { null }?.let { expiration ->
                            episodeDetailSection("Expiration") {
                                Text(Instant.parse(expiration).toLocalDateTimeString())
                            }
                        }
                    }
                }
                tab("Cuts") {
                    val hasImages = cuts?.any { it.images?.isNotEmpty() == true } == true
                    var offset = 0
                    cuts?.forEach {
                        val offsetDiff = it.offset - offset
                        if (offsetDiff < -1000 || offsetDiff > 0) {
                            Div({
                                style {
                                    fontSize(0.7.cssRem)
                                    margin((-0.8).cssRem, 0.px)
                                    padding(0.4.cssRem, 0.8.cssRem)
                                    backgroundColor(Color("#fbf6dc"))
                                }
                            }) {
                                if (offsetDiff < -1000) {
                                    icon("join_inner") {
                                        title = "Overlap"
                                        size = IconSize.TINY
                                    }
                                    Text(" ${offset - it.offset}ms")
                                } else if (offsetDiff > 0) {
                                    icon("expand") {
                                        title = "Gap"
                                        size = IconSize.TINY
                                    }
                                    Text(" ${it.offset - offset}ms")
                                }
                            }
                        }
                        offset = it.offset

                        cutSummaryItem(it, hasImages)
                        offset += it.duration ?: 0
                    }
                }
            }
        }
    }
}

@Composable
fun episodeDetailSection(title: String, content: @Composable () -> Unit) {
    Div({
        style {
            marginBottom(1.cssRem)
        }
    }) {
        H4({
            style {
                margin(0.px)
            }
        }) { Text(title) }
        Div {
            content()
        }
    }
}

@Composable
fun episodeSummaryItem(episode: LiveEpisode) {
    episodeSummaryItem(json.recode<LiveEpisode, Episode>(episode))
}

@Composable
fun episodeSummaryItem(episode: AudioEpisode) {
    episodeSummaryItem(json.recode<AudioEpisode, Episode>(episode))
}

@Composable
fun episodeSummaryItem(episode: VideoEpisode) {
    episodeSummaryItem(json.recode<VideoEpisode, Episode>(episode))
}

/**
 * A list item for an episode
 */
@Composable
fun episodeSummaryItem(episode: Episode) {
    val episodeUrl = "#channelguide/episodes/${episode.id}"

    Div({
        classes(ChannelGuideStyles.epgListItem)
        style {
            alignItems(AlignItems.FlexStart)
        }
    }) {
        Div({
            classes(ChannelGuideStyles.epgListItemImage)
        }) {
            defaultImage(episode.images, ImageAspectRatio.ASPECT_1X1, 128.px)
        }
        Div({
            classes(ChannelGuideStyles.epgListItemMain)
        }) {
            Div {
                val timestamp = if (episode.type == EntityType.EPISODE_LINEAR) {
                    episode.startTimestamp
                } else {
                    episode.originalAirTimestamp
                }
                timestamp?.let {
                    Instant.parse(it)
                }?.let {
                    Div({
                        style {
                            fontSize(.8.cssRem)
                        }
                    }) {
                        if (episode.type != EntityType.EPISODE_LINEAR) {
                            Text("Originally aired: ")
                        }
                        Text(it.toLocalDateTimeString(false))
                    }
                }

                H3 {
                    A(href = episodeUrl) {
                        Text(episode.name)
                    }
                    aggregatorIconLink(episode.id)
                }

                episode.duration?.let {
                    Div({
                        style {
                            fontSize(.8.cssRem)
                        }
                    }) {
                        Text(it.milliseconds.toDurationHumanString())
                    }
                }
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemTrailing)
        }) {
            flags(
                episode.flags, mapOf(
                    EpisodeFlag.REGEN_EPISODE to "replace_audio",
                    EpisodeFlag.PLAY_BY_PLAY to "sports_basketball",
                    EpisodeFlag.REPEAT to "replay",
                    EpisodeFlag.DOWNLOADABLE to "download",
                    EpisodeFlag.EVERGREEN to "release_alert",
                    EpisodeFlag.AUDIO to "hearing",

                    )
            )
        }
    }
}