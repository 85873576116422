package com.siriusxm.pia

import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch

/**
 * Main entry point of the application.
 */
fun main() {
    @OptIn(DelicateCoroutinesApi::class)
    GlobalScope.launch {
        Application.run()
    }
}