@file:Suppress("ComplexRedundantLet", "SimpleRedundantLet", "unused", "UnnecessaryVariable",
    "NestedLambdaShadowedImplicitParameter", "PropertyName")

package com.siriusxm.pia.client.api.client

import com.steamstreet.graphkt.ID
import com.steamstreet.graphkt.client.GraphQLResponse
import kotlin.Boolean
import kotlin.Int
import kotlin.NullPointerException
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.booleanOrNull
import kotlinx.serialization.json.contentOrNull
import kotlinx.serialization.json.intOrNull
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

class Query(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val feed: Feed
    get() {
      response.throwIfError("feed")
      val result = element["feed"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Feed(response.forElement("feed"), it)
        }
      }
      return result ?: throw NullPointerException("Query: feed")
    }

  val show: Show
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("show"), it)
        }
      }
      return result ?: throw NullPointerException("Query: show")
    }

  val catalogShow: Show
    get() {
      response.throwIfError("catalogShow")
      val result = element["catalogShow"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("catalogShow"), it)
        }
      }
      return result ?: throw NullPointerException("Query: catalogShow")
    }

  val showBySlug: Show
    get() {
      response.throwIfError("showBySlug")
      val result = element["showBySlug"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("showBySlug"), it)
        }
      }
      return result ?: throw NullPointerException("Query: showBySlug")
    }

  val shows: ShowsResponse
    get() {
      response.throwIfError("shows")
      val result = element["shows"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowsResponse(response.forElement("shows"), it)
        }
      }
      return result ?: throw NullPointerException("Query: shows")
    }

  val showsByOwner: ShowsResponse
    get() {
      response.throwIfError("showsByOwner")
      val result = element["showsByOwner"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowsResponse(response.forElement("showsByOwner"), it)
        }
      }
      return result ?: throw NullPointerException("Query: showsByOwner")
    }

  val contentService: ContentService
    get() {
      response.throwIfError("contentService")
      val result = element["contentService"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentService(response.forElement("contentService"), it)
        }
      }
      return result ?: throw NullPointerException("Query: contentService")
    }

  val flags: ContentFlags
    get() {
      response.throwIfError("flags")
      val result = element["flags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlags(response.forElement("flags"), it)
        }
      }
      return result ?: throw NullPointerException("Query: flags")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Mutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val show: ShowMutation
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowMutation(response.forElement("show"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: show")
    }

  val feed: FeedMutation
    get() {
      response.throwIfError("feed")
      val result = element["feed"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          FeedMutation(response.forElement("feed"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: feed")
    }

  val addFeed: List<Feed>
    get() {
      response.throwIfError("addFeed")
      val result = element["addFeed"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Feed(response.forElement("addFeed"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Mutation: addFeed")
    }

  val addShow: Show
    get() {
      response.throwIfError("addShow")
      val result = element["addShow"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("addShow"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: addShow")
    }

  val resetFeeds: Boolean
    get() {
      response.throwIfError("resetFeeds")
      val result = element["resetFeeds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("Mutation: resetFeeds")
    }

  val refreshFeeds: Boolean
    get() {
      response.throwIfError("refreshFeeds")
      val result = element["refreshFeeds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("Mutation: refreshFeeds")
    }

  val validateFeeds: List<FeedValidationResponse>
    get() {
      response.throwIfError("validateFeeds")
      val result = element["validateFeeds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            FeedValidationResponse(response.forElement("validateFeeds"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Mutation: validateFeeds")
    }

  val contentService: ContentServiceMutator
    get() {
      response.throwIfError("contentService")
      val result = element["contentService"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentServiceMutator(response.forElement("contentService"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: contentService")
    }

  val domain: DomainMutation
    get() {
      response.throwIfError("domain")
      val result = element["domain"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          DomainMutation(response.forElement("domain"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: domain")
    }

  val updateFeedLicense: Boolean?
    get() {
      response.throwIfError("updateFeedLicense")
      val result = element["updateFeedLicense"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val updateFeedOwner: Boolean?
    get() {
      response.throwIfError("updateFeedOwner")
      val result = element["updateFeedOwner"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val flags: ContentFlagsMutation
    get() {
      response.throwIfError("flags")
      val result = element["flags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlagsMutation(response.forElement("flags"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: flags")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowMutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val feeds: List<FeedMutation>
    get() {
      response.throwIfError("feeds")
      val result = element["feeds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            FeedMutation(response.forElement("feeds"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ShowMutation: feeds")
    }

  val update: Show?
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("update"), it)
        }
      }
      return result
    }

  val transcribe: Boolean?
    get() {
      response.throwIfError("transcribe")
      val result = element["transcribe"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class FeedMutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val url: String
    get() {
      response.throwIfError("url")
      val result = element["url"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("FeedMutation: url")
    }

  val reset: Boolean
    get() {
      response.throwIfError("reset")
      val result = element["reset"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("FeedMutation: reset")
    }

  val refresh: Boolean
    get() {
      response.throwIfError("refresh")
      val result = element["refresh"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("FeedMutation: refresh")
    }

  val update: Boolean
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("FeedMutation: update")
    }

  val detach: Boolean
    get() {
      response.throwIfError("detach")
      val result = element["detach"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("FeedMutation: detach")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Podswag(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Podswag: id")
    }

  val url: String
    get() {
      response.throwIfError("url")
      val result = element["url"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Podswag: url")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Feed(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val url: String?
    get() {
      response.throwIfError("url")
      val result = element["url"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val bundleId: String?
    get() {
      response.throwIfError("bundleId")
      val result = element["bundleId"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val show: Show?
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("show"), it)
        }
      }
      return result
    }

  val bundles: List<Bundle>?
    get() {
      response.throwIfError("bundles")
      val result = element["bundles"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Bundle(response.forElement("bundles"), it)
          }
        }
      }
      return result
    }

  val rssUrl: String?
    get() {
      response.throwIfError("rssUrl")
      val result = element["rssUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val normalizedUrl: String?
    get() {
      response.throwIfError("normalizedUrl")
      val result = element["normalizedUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val normalized: Show?
    get() {
      response.throwIfError("normalized")
      val result = element["normalized"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("normalized"), it)
        }
      }
      return result
    }

  val moves: List<FeedMove>?
    get() {
      response.throwIfError("moves")
      val result = element["moves"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            FeedMove(response.forElement("moves"), it)
          }
        }
      }
      return result
    }

  val active: Boolean
    get() {
      response.throwIfError("active")
      val result = element["active"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("Feed: active")
    }

  val nextFetch: String?
    get() {
      response.throwIfError("nextFetch")
      val result = element["nextFetch"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class FeedMove(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val url: String
    get() {
      response.throwIfError("url")
      val result = element["url"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("FeedMove: url")
    }

  val date: String?
    get() {
      response.throwIfError("date")
      val result = element["date"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val moveLocation: String
    get() {
      response.throwIfError("moveLocation")
      val result = element["moveLocation"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("FeedMove: moveLocation")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Bundle(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: String
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Bundle: id")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Show(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: String
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Show: id")
    }

  val title: String?
    get() {
      response.throwIfError("title")
      val result = element["title"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val owner: Party?
    get() {
      response.throwIfError("owner")
      val result = element["owner"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Party(response.forElement("owner"), it)
        }
      }
      return result
    }

  val authors: List<Party>?
    get() {
      response.throwIfError("authors")
      val result = element["authors"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Party(response.forElement("authors"), it)
          }
        }
      }
      return result
    }

  val explicit: Boolean?
    get() {
      response.throwIfError("explicit")
      val result = element["explicit"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  val episodes: EpisodesResult
    get() {
      response.throwIfError("episodes")
      val result = element["episodes"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EpisodesResult(response.forElement("episodes"), it)
        }
      }
      return result ?: throw NullPointerException("Show: episodes")
    }

  val episode: Episode
    get() {
      response.throwIfError("episode")
      val result = element["episode"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Episode(response.forElement("episode"), it)
        }
      }
      return result ?: throw NullPointerException("Show: episode")
    }

  val keywords: List<Keyword>?
    get() {
      response.throwIfError("keywords")
      val result = element["keywords"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Keyword(response.forElement("keywords"), it)
          }
        }
      }
      return result
    }

  val categories: List<Category>?
    get() {
      response.throwIfError("categories")
      val result = element["categories"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Category(response.forElement("categories"), it)
          }
        }
      }
      return result
    }

  val language: String?
    get() {
      response.throwIfError("language")
      val result = element["language"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val content: String?
    get() {
      response.throwIfError("content")
      val result = element["content"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val csShow: ContentServiceShow?
    get() {
      response.throwIfError("csShow")
      val result = element["csShow"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentServiceShow(response.forElement("csShow"), it)
        }
      }
      return result
    }

  val configuration: ShowConfiguration
    get() {
      response.throwIfError("configuration")
      val result = element["configuration"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowConfiguration(response.forElement("configuration"), it)
        }
      }
      return result ?: throw NullPointerException("Show: configuration")
    }

  val feeds: List<Feed>?
    get() {
      response.throwIfError("feeds")
      val result = element["feeds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Feed(response.forElement("feeds"), it)
          }
        }
      }
      return result
    }

  val sources: List<Feed>?
    get() {
      response.throwIfError("sources")
      val result = element["sources"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Feed(response.forElement("sources"), it)
          }
        }
      }
      return result
    }

  val metrics: ShowMetrics
    get() {
      response.throwIfError("metrics")
      val result = element["metrics"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowMetrics(response.forElement("metrics"), it)
        }
      }
      return result ?: throw NullPointerException("Show: metrics")
    }

  val episodeSummary: EpisodeSummary?
    get() {
      response.throwIfError("episodeSummary")
      val result = element["episodeSummary"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EpisodeSummary(response.forElement("episodeSummary"), it)
        }
      }
      return result
    }

  val slugs: List<String>?
    get() {
      response.throwIfError("slugs")
      val result = element["slugs"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result
    }

  val podswag: Podswag?
    get() {
      response.throwIfError("podswag")
      val result = element["podswag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Podswag(response.forElement("podswag"), it)
        }
      }
      return result
    }

  val audit: ShowAudit
    get() {
      response.throwIfError("audit")
      val result = element["audit"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowAudit(response.forElement("audit"), it)
        }
      }
      return result ?: throw NullPointerException("Show: audit")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class EpisodesResult(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val token: String?
    get() {
      response.throwIfError("token")
      val result = element["token"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val episodes: List<Episode>
    get() {
      response.throwIfError("episodes")
      val result = element["episodes"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Episode(response.forElement("episodes"), it)
          }
        }
      }
      return result ?: throw NullPointerException("EpisodesResult: episodes")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class EpisodeSummary(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val count: Int
    get() {
      response.throwIfError("count")
      val result = element["count"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("EpisodeSummary: count")
    }

  val seasons: List<SeasonSummary>?
    get() {
      response.throwIfError("seasons")
      val result = element["seasons"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            SeasonSummary(response.forElement("seasons"), it)
          }
        }
      }
      return result
    }

  val years: List<YearSummary>?
    get() {
      response.throwIfError("years")
      val result = element["years"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            YearSummary(response.forElement("years"), it)
          }
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class SeasonSummary(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val number: Int
    get() {
      response.throwIfError("number")
      val result = element["number"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("SeasonSummary: number")
    }

  val count: Int
    get() {
      response.throwIfError("count")
      val result = element["count"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("SeasonSummary: count")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class YearSummary(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val year: Int
    get() {
      response.throwIfError("year")
      val result = element["year"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("YearSummary: year")
    }

  val count: Int
    get() {
      response.throwIfError("count")
      val result = element["count"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("YearSummary: count")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class DirectoryShowInformation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val directory: ShowDirectory
    get() {
      response.throwIfError("directory")
      val result = element["directory"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowDirectory(response.forElement("directory"), it)
        }
      }
      return result ?: throw NullPointerException("DirectoryShowInformation: directory")
    }

  val showId: String?
    get() {
      response.throwIfError("showId")
      val result = element["showId"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val showUrl: String?
    get() {
      response.throwIfError("showUrl")
      val result = element["showUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowAudit(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val entries: ShowAuditEntries
    get() {
      response.throwIfError("entries")
      val result = element["entries"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowAuditEntries(response.forElement("entries"), it)
        }
      }
      return result ?: throw NullPointerException("ShowAudit: entries")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowAuditEntries(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val entries: List<ShowAuditEntry>
    get() {
      response.throwIfError("entries")
      val result = element["entries"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ShowAuditEntry(response.forElement("entries"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ShowAuditEntries: entries")
    }

  val token: String?
    get() {
      response.throwIfError("token")
      val result = element["token"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowAuditEntry(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val type: String
    get() {
      response.throwIfError("type")
      val result = element["type"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowAuditEntry: type")
    }

  val message: String
    get() {
      response.throwIfError("message")
      val result = element["message"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowAuditEntry: message")
    }

  val updater: Party?
    get() {
      response.throwIfError("updater")
      val result = element["updater"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Party(response.forElement("updater"), it)
        }
      }
      return result
    }

  val old: ShowConfiguration?
    get() {
      response.throwIfError("old")
      val result = element["old"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowConfiguration(response.forElement("old"), it)
        }
      }
      return result
    }

  val new: ShowConfiguration
    get() {
      response.throwIfError("new")
      val result = element["new"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ShowConfiguration(response.forElement("new"), it)
        }
      }
      return result ?: throw NullPointerException("ShowAuditEntry: new")
    }

  val ts: String
    get() {
      response.throwIfError("ts")
      val result = element["ts"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowAuditEntry: ts")
    }

  val note: String?
    get() {
      response.throwIfError("note")
      val result = element["note"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowDirectory(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val directoryId: String
    get() {
      response.throwIfError("directoryId")
      val result = element["directoryId"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowDirectory: directoryId")
    }

  val directoryName: String
    get() {
      response.throwIfError("directoryName")
      val result = element["directoryName"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowDirectory: directoryName")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowMetrics(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val range: List<ShowMetricsData>
    get() {
      response.throwIfError("range")
      val result = element["range"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ShowMetricsData(response.forElement("range"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ShowMetrics: range")
    }

  val errors: List<ShowError>
    get() {
      response.throwIfError("errors")
      val result = element["errors"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ShowError(response.forElement("errors"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ShowMetrics: errors")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowError(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val ts: String
    get() {
      response.throwIfError("ts")
      val result = element["ts"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowError: ts")
    }

  val message: String?
    get() {
      response.throwIfError("message")
      val result = element["message"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val resourceArn: String?
    get() {
      response.throwIfError("resourceArn")
      val result = element["resourceArn"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val resourceName: String?
    get() {
      response.throwIfError("resourceName")
      val result = element["resourceName"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val details: String?
    get() {
      response.throwIfError("details")
      val result = element["details"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val type: String?
    get() {
      response.throwIfError("type")
      val result = element["type"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowMetricsData(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val start: String
    get() {
      response.throwIfError("start")
      val result = element["start"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowMetricsData: start")
    }

  val end: String
    get() {
      response.throwIfError("end")
      val result = element["end"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ShowMetricsData: end")
    }

  val adds: Int
    get() {
      response.throwIfError("adds")
      val result = element["adds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("ShowMetricsData: adds")
    }

  val removes: Int
    get() {
      response.throwIfError("removes")
      val result = element["removes"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("ShowMetricsData: removes")
    }

  val updates: Int
    get() {
      response.throwIfError("updates")
      val result = element["updates"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("ShowMetricsData: updates")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Episode(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val guid: String
    get() {
      response.throwIfError("guid")
      val result = element["guid"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Episode: guid")
    }

  val title: String?
    get() {
      response.throwIfError("title")
      val result = element["title"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val date: String?
    get() {
      response.throwIfError("date")
      val result = element["date"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  val media: List<Media>?
    get() {
      response.throwIfError("media")
      val result = element["media"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Media(response.forElement("media"), it)
          }
        }
      }
      return result
    }

  val keywords: List<Keyword>?
    get() {
      response.throwIfError("keywords")
      val result = element["keywords"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Keyword(response.forElement("keywords"), it)
          }
        }
      }
      return result
    }

  val categories: List<Category>?
    get() {
      response.throwIfError("categories")
      val result = element["categories"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Category(response.forElement("categories"), it)
          }
        }
      }
      return result
    }

  val season: Int?
    get() {
      response.throwIfError("season")
      val result = element["season"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val seasonEpisodeNumber: Int?
    get() {
      response.throwIfError("seasonEpisodeNumber")
      val result = element["seasonEpisodeNumber"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val typedEntityId: String?
    get() {
      response.throwIfError("typedEntityId")
      val result = element["typedEntityId"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Media(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val url: String
    get() {
      response.throwIfError("url")
      val result = element["url"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Media: url")
    }

  val duration: Int?
    get() {
      response.throwIfError("duration")
      val result = element["duration"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val bitrate: Int?
    get() {
      response.throwIfError("bitrate")
      val result = element["bitrate"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val encoder: String?
    get() {
      response.throwIfError("encoder")
      val result = element["encoder"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val contentType: String?
    get() {
      response.throwIfError("contentType")
      val result = element["contentType"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val bundles: List<Bundle>?
    get() {
      response.throwIfError("bundles")
      val result = element["bundles"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Bundle(response.forElement("bundles"), it)
          }
        }
      }
      return result
    }

  val sourceGuid: String?
    get() {
      response.throwIfError("sourceGuid")
      val result = element["sourceGuid"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val sourceDate: String?
    get() {
      response.throwIfError("sourceDate")
      val result = element["sourceDate"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val transcription: Transcription?
    get() {
      response.throwIfError("transcription")
      val result = element["transcription"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Transcription(response.forElement("transcription"), it)
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class FeedValidationResponse(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val url: String
    get() {
      response.throwIfError("url")
      val result = element["url"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("FeedValidationResponse: url")
    }

  val issues: List<FeedValidationIssue>?
    get() {
      response.throwIfError("issues")
      val result = element["issues"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            FeedValidationIssue(response.forElement("issues"), it)
          }
        }
      }
      return result
    }

  val show: Show?
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("show"), it)
        }
      }
      return result
    }

  val matches: List<ShowMatch>?
    get() {
      response.throwIfError("matches")
      val result = element["matches"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ShowMatch(response.forElement("matches"), it)
          }
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowMatch(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val confidence: Int
    get() {
      response.throwIfError("confidence")
      val result = element["confidence"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result ?: throw NullPointerException("ShowMatch: confidence")
    }

  val show: Show
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("show"), it)
        }
      }
      return result ?: throw NullPointerException("ShowMatch: show")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Transcription(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val vtt: String?
    get() {
      response.throwIfError("vtt")
      val result = element["vtt"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowConfiguration(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val active: Boolean?
    get() {
      response.throwIfError("active")
      val result = element["active"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val transcribe: Boolean
    get() {
      response.throwIfError("transcribe")
      val result = element["transcribe"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("ShowConfiguration: transcribe")
    }

  val transcriptionsPublished: Boolean
    get() {
      response.throwIfError("transcriptionsPublished")
      val result = element["transcriptionsPublished"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("ShowConfiguration: transcriptionsPublished")
    }

  val directories: List<String>?
    get() {
      response.throwIfError("directories")
      val result = element["directories"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result
    }

  val directoryDetails: List<DirectoryShowInformation>
    get() {
      response.throwIfError("directoryDetails")
      val result = element["directoryDetails"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            DirectoryShowInformation(response.forElement("directoryDetails"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ShowConfiguration: directoryDetails")
    }

  val license: String?
    get() {
      response.throwIfError("license")
      val result = element["license"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val owner: Party?
    get() {
      response.throwIfError("owner")
      val result = element["owner"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Party(response.forElement("owner"), it)
        }
      }
      return result
    }

  val sources: List<Feed>?
    get() {
      response.throwIfError("sources")
      val result = element["sources"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Feed(response.forElement("sources"), it)
          }
        }
      }
      return result
    }

  val streamOnly: Boolean
    get() {
      response.throwIfError("streamOnly")
      val result = element["streamOnly"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("ShowConfiguration: streamOnly")
    }

  val audioBook: Boolean
    get() {
      response.throwIfError("audioBook")
      val result = element["audioBook"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("ShowConfiguration: audioBook")
    }

  val linkedEntities: List<String?>?
    get() {
      response.throwIfError("linkedEntities")
      val result = element["linkedEntities"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.contentOrNull
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class FeedValidationIssue(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val type: String
    get() {
      response.throwIfError("type")
      val result = element["type"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("FeedValidationIssue: type")
    }

  val id: String
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("FeedValidationIssue: id")
    }

  val note: String?
    get() {
      response.throwIfError("note")
      val result = element["note"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Party(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val email: String?
    get() {
      response.throwIfError("email")
      val result = element["email"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val uri: String?
    get() {
      response.throwIfError("uri")
      val result = element["uri"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val identifier: String?
    get() {
      response.throwIfError("identifier")
      val result = element["identifier"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Image(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val src: String?
    get() {
      response.throwIfError("src")
      val result = element["src"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val color: String?
    get() {
      response.throwIfError("color")
      val result = element["color"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val sizes: List<String>
    get() {
      response.throwIfError("sizes")
      val result = element["sizes"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result ?: throw NullPointerException("Image: sizes")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Keyword(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val value: String
    get() {
      response.throwIfError("value")
      val result = element["value"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Keyword: value")
    }

  val namespace: String?
    get() {
      response.throwIfError("namespace")
      val result = element["namespace"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Category(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Category: name")
    }

  val namespace: String?
    get() {
      response.throwIfError("namespace")
      val result = element["namespace"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val subs: List<Category>?
    get() {
      response.throwIfError("subs")
      val result = element["subs"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Category(response.forElement("subs"), it)
          }
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ShowsResponse(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val shows: List<Show>
    get() {
      response.throwIfError("shows")
      val result = element["shows"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Show(response.forElement("shows"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ShowsResponse: shows")
    }

  val token: String?
    get() {
      response.throwIfError("token")
      val result = element["token"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentService(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val show: ContentServiceShow
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentServiceShow(response.forElement("show"), it)
        }
      }
      return result ?: throw NullPointerException("ContentService: show")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentServiceMutator(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val show: ContentServiceShowMutator
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentServiceShowMutator(response.forElement("show"), it)
        }
      }
      return result ?: throw NullPointerException("ContentServiceMutator: show")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentServiceShow(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val csId: String
    get() {
      response.throwIfError("csId")
      val result = element["csId"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ContentServiceShow: csId")
    }

  val sync: Boolean
    get() {
      response.throwIfError("sync")
      val result = element["sync"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("ContentServiceShow: sync")
    }

  val show: Show?
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("show"), it)
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentServiceShowMutator(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val update: Boolean?
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val sync: ContentServiceSyncResult
    get() {
      response.throwIfError("sync")
      val result = element["sync"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentServiceSyncResult(response.forElement("sync"), it)
        }
      }
      return result ?: throw NullPointerException("ContentServiceShowMutator: sync")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentServiceSyncResult(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val adds: List<String>
    get() {
      response.throwIfError("adds")
      val result = element["adds"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result ?: throw NullPointerException("ContentServiceSyncResult: adds")
    }

  val updates: List<String>
    get() {
      response.throwIfError("updates")
      val result = element["updates"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result ?: throw NullPointerException("ContentServiceSyncResult: updates")
    }

  val backfills: List<String>
    get() {
      response.throwIfError("backfills")
      val result = element["backfills"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result ?: throw NullPointerException("ContentServiceSyncResult: backfills")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class DomainMutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val domain: String
    get() {
      response.throwIfError("domain")
      val result = element["domain"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("DomainMutation: domain")
    }

  val mediaLock: Boolean
    get() {
      response.throwIfError("mediaLock")
      val result = element["mediaLock"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("DomainMutation: mediaLock")
    }

  val update: DomainMutation
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          DomainMutation(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("DomainMutation: update")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentFlags(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val flags: List<ContentFlag>
    get() {
      response.throwIfError("flags")
      val result = element["flags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ContentFlag(response.forElement("flags"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ContentFlags: flags")
    }

  val flag: ContentFlag
    get() {
      response.throwIfError("flag")
      val result = element["flag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlag(response.forElement("flag"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlags: flag")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentFlag(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val flag: String
    get() {
      response.throwIfError("flag")
      val result = element["flag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ContentFlag: flag")
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val matchers: List<ContentFlagMatcher>
    get() {
      response.throwIfError("matchers")
      val result = element["matchers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ContentFlagMatcher(response.forElement("matchers"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ContentFlag: matchers")
    }

  val matches: ContentFlagMatchResult
    get() {
      response.throwIfError("matches")
      val result = element["matches"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlagMatchResult(response.forElement("matches"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlag: matches")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentFlagMatcher(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val flag: ContentFlag
    get() {
      response.throwIfError("flag")
      val result = element["flag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlag(response.forElement("flag"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlagMatcher: flag")
    }

  val regex: String
    get() {
      response.throwIfError("regex")
      val result = element["regex"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("ContentFlagMatcher: regex")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentFlagMatchResult(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val matches: List<ContentFlagMatch>
    get() {
      response.throwIfError("matches")
      val result = element["matches"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            ContentFlagMatch(response.forElement("matches"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ContentFlagMatchResult: matches")
    }

  val token: String?
    get() {
      response.throwIfError("token")
      val result = element["token"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentFlagMatch(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val flag: ContentFlag
    get() {
      response.throwIfError("flag")
      val result = element["flag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlag(response.forElement("flag"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlagMatch: flag")
    }

  val show: Show
    get() {
      response.throwIfError("show")
      val result = element["show"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Show(response.forElement("show"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlagMatch: show")
    }

  val episode: Episode?
    get() {
      response.throwIfError("episode")
      val result = element["episode"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Episode(response.forElement("episode"), it)
        }
      }
      return result
    }

  val match: String?
    get() {
      response.throwIfError("match")
      val result = element["match"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ContentFlagsMutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val addFlag: ContentFlag
    get() {
      response.throwIfError("addFlag")
      val result = element["addFlag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlag(response.forElement("addFlag"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlagsMutation: addFlag")
    }

  val addMatcher: ContentFlagMatcher
    get() {
      response.throwIfError("addMatcher")
      val result = element["addMatcher"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ContentFlagMatcher(response.forElement("addMatcher"), it)
        }
      }
      return result ?: throw NullPointerException("ContentFlagsMutation: addMatcher")
    }

  val removeMatcher: Boolean?
    get() {
      response.throwIfError("removeMatcher")
      val result = element["removeMatcher"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}
