package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.TableBuilder.ColumnBuilder
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

object SplitSelectionViewStyles : StyleSheet() {
    val container by style {
        display(DisplayStyle.Flex)

        child(self, type("div")) style {
            flex(1)
        }

        "> div:nth-child(1):nth-last-child(2)" style {
            flex(0, 0, 250.px)
        }

        "> div:nth-child(1):nth-last-child(2) ~ div" {
            flex(1)
        }
    }

    val itemMain by style {
        padding(8.px)
        display(DisplayStyle.Flex)
        gap(5.px)
        cursor("pointer")
        border(1.px, LineStyle.Solid, rgba(0, 0, 0, 0))

        child(self, type("div")) style {
            display(DisplayStyle.InlineBlock)
        }

        type("input") style {
            property("height", "inherit")
            property("vertical-align", "-2px")
            cursor("pointer")
        }
    }

    val itemMainSelected by style {
        fontWeight("bold")
        backgroundColor(SXMUI.messageInfoBackground.value())
        border(1.px, LineStyle.Solid, SXMUI.messageInfoBorder.value())
    }
}

class SplitSelectionViewProperties<T> {
    var title: String? = null
    var items: List<T>? = null
    var selection: T? = null
    internal var render: (@Composable (T) -> Unit)? = null
    internal var listItemRender: (@Composable (T) -> Unit) = {
        Text(it.toString())
    }
    internal var onSelectCb: ((T) -> Unit)? = null
    internal val extraColumns = arrayListOf<ColumnBuilder<T>.() -> Unit>()

    internal val actions = mutableListOf<ButtonConfiguration>()

    fun render(block: @Composable (T) -> Unit) {
        this.render = block
    }

    fun renderListItem(block: @Composable (T) -> Unit) {
        this.listItemRender = block
    }

    fun onSelect(block: (T) -> Unit) {
        onSelectCb = block
    }

    fun column(columnBuilder: ColumnBuilder<T>.() -> Unit) {
        extraColumns += columnBuilder
    }

    fun action(title: String, config: ButtonConfiguration.() -> Unit) {
        actions += ButtonConfiguration(title).apply(config)
    }
}

@Composable
fun <T> splitSelectionView(props: SplitSelectionViewProperties<T>) {
    var selection by mutableStateOf(props.selection)

    if (props.items != null) {
        Div({
            classes(SplitSelectionViewStyles.container)
        }) {
            Div {
                box({
                    paddedContent = false
                    title = props.title

                    if (selection == null) {
                        props.actions.forEach {
                            this.action(it)
                        }
                    }
                }) {
                    table<T> {
                        items(props.items)

                        column {
                            style {
                                padding(0.px)
                            }
                            content { item ->
                                Div({
                                    classes(SplitSelectionViewStyles.itemMain)
                                    if (item == props.selection) {
                                        classes(SplitSelectionViewStyles.itemMainSelected)
                                    }
                                    onClick {
                                        if (props.onSelectCb != null) {
                                            props.onSelectCb?.invoke(item)
                                        } else {
                                            selection = item
                                        }
                                    }
                                }) {
                                    Div {
                                        Input(type = InputType.Radio) {
                                            this.checked(item == props.selection)
                                        }
                                    }

                                    Div {
                                        props.listItemRender(item)

                                    }
                                }
//                            Span({
//                                style {
//                                    display(DisplayStyle.Block)
//                                    padding(4.px, 1.cssRem)
//                                    cursor("pointer")
//
//                                    if (item == props.selection) {
//                                        fontWeight(800)
//                                    }
//                                }
//                                onClick {
//                                    if (props.onSelectCb != null) {
//                                        props.onSelectCb?.invoke(item)
//                                    } else {
//                                        selection = item
//                                    }
//                                }
//                            }) {
//                                props.listItemRender(item)
//                            }
                            }
                        }

                        if (selection == null) {
                            props.extraColumns.forEach {
                                column(null, it)
                            }
                        }
                    }
                }
            }
            if (selection != null) {
                Div({
                    style {
                        padding(0.px, 10.px, 10.px, 10.px)
                        flex(1, 1)
                    }
                }) {
                    selection?.let {
                        props.render?.invoke(it)
                    }
                }
            }
        }
    }
}

@Composable
fun <T> splitSelectionView(config: SplitSelectionViewProperties<T>.() -> Unit) {
    splitSelectionView(SplitSelectionViewProperties<T>().apply(config))
}