package com.siriusxm.pia.rest.epg

import com.siriusxm.pia.rest.sports.EntityResponse
import contentingestion.unifiedmodel.Channel
import contentingestion.unifiedmodel.Show
import de.jensklingenberg.ktorfit.http.*

interface EpgClient {
    @Headers("Content-Type: application/json")
    @GET("/entities/types/channel-linear/all")
    suspend fun fetchAllLinearChannels(@HeaderMap headerMap : Map<String,String>,
                                       @Query("paginationToken") paginationToken: String? = null) : EntityResponse

    @Headers("Content-Type: application/json")
    @GET("/entities/types/channel-xtra/all")
    suspend fun fetchAllXtraChannels(@HeaderMap headerMap : Map<String,String>,
                                     @Query("paginationToken") paginationToken: String? = null) : EntityResponse

    @Headers("Content-Type: application/json")
    @GET("/entities/{channelId}/channel-linear/0.1")
    suspend fun fetchLinearChannelById(@Path("channelId") channelId: String, @HeaderMap headerMap : Map<String,String>) : Channel?

    @Headers("Content-Type: application/json")
    @GET("/entities/{channelId}/channel-xtra/0.1")
    suspend fun fetchXtraChannelById(@Path("channelId") channelId: String, @HeaderMap headerMap : Map<String,String>) : Channel?

    @Headers("Content-Type: application/json")
    @GET("/entities/relationships/channel-linear-show/object/{channelId}")
    suspend fun fetchShowsForLinearChannel(@Path("channelId") channelId: String, @HeaderMap headerMap : Map<String,String>) : EntityResponse

    @Headers("Content-Type: application/json")
    @GET("/entities/relationships/channel-xtra-show/object/{channelId}")
    suspend fun fetchShowsForXtraChannel(@Path("channelId") channelId: String, @HeaderMap headerMap : Map<String,String>) : EntityResponse

    @Headers("Content-Type: application/json")
    @GET("/entities/{showId}/show/0.1")
    suspend fun fetchShowById(@Path("showId") showId: String, @HeaderMap headerMap : Map<String,String>) : Show?

    @Headers("Content-Type: application/json")
    @GET("/entities/types/lineup/all")
    suspend fun fetchAllLineups(@HeaderMap headerMap : Map<String,String>,
                                     @Query("paginationToken") paginationToken: String? = null) : EntityResponse
}
