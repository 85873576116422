package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.EpisodeSummary
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.sports.SportsStyles
import com.siriusxm.pia.views.sports.getAbsoluteUrl
import com.siriusxm.pia.views.unifiedaggregator.timestamp
import contentingestion.unifiedmodel.*
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * Renders and episode page, with all details including cuts, etc.
 */
@Composable
fun episode(epg: EPG, episodeId: String, route: Route) {
    var loading by remember { mutableStateOf<Boolean?>(null) }
    var episode by remember { mutableStateOf<Episode?>(null) }
    var cuts by remember { mutableStateOf<List<Cut>?>(null) }

    LaunchedEffect(episodeId) {
        loading = true

        episode = epg.fetchEpisode(episodeId)
        if (episode?.type == EntityType.EPISODE_LINEAR) {
            cuts = epg.fetchCutsForEpisode(episodeId)
        }
        loading = false
    }

    serviceContentView({
        title = episode?.name
        subTitle = episode?.shortName()
    }) {
        if (loading == true) {
            spinner(size = Size.LARGE)
        } else {
            if (episode != null) {
                episodeDetail(episode!!)
            }
            if (cuts?.isEmpty() == true) {
                messageBox(
                    "No cuts available for this episode",
                    MessageType.WARNING
                )
            } else {
                cutsGrid(cuts.orEmpty())
            }
        }
    }
}

@Composable
fun episodeDetail(episode: Episode) {
    val preferredImage = episode.getPreferredImage()
    if (preferredImage != null) {
        Div {
            Img(src = getAbsoluteUrl(preferredImage)) {
                style {
                    width(100.px)
                    height(100.px)
                }
            }
        }
    }
    Div({ classes(SportsStyles.metadata) }) {
        Div({ classes(SportsStyles.entitySubInfo) }) {
            Text(episode.additionalNames?.get("abbreviation")?.name ?: "")
        }
        Div({ classes(SportsStyles.entityInfo) }) { Text(getEpisodeName(episode)) }
        Div({ classes(SportsStyles.entitySubInfo) }) {
            A(href = "#aggregator/entity-by-outgoing-id/${episode.id}", { target(ATarget.Blank) }) {
                Text(episode.id)
            }
        }
        Div {
            detailGrid {
                if (episode.description != null) {
                    detail("Description") {
                        Text(episode.description!!)
                    }
                }
            }
        }
        Div {
            detailGrid {
                val originalTimestampStr = episode.originalAirTimestamp?.timestamp()?.toLocalDateTimeString(false)
                val startTimestampStr = episode.startTimestamp?.timestamp()?.toLocalDateTimeString(false)

                if (originalTimestampStr != null && originalTimestampStr != startTimestampStr) {
                    detail("Original Air Date") {
                        Text(originalTimestampStr)
                    }
                }
                if (startTimestampStr != null) {
                    detail("Start") {
                        Text(startTimestampStr)
                    }
                }
                if (episode.expiration != null) {
                    detail("Expires") {
                        Text(episode.expiration.toString())
                    }
                }
                pillDetailBox("Hosts", episode.hosts)
                pillDetailBox("Topics", episode.topics)
                pillDetailBox("Flags", episode.flags?.map { it.name })
            }
        }
    }
}


@Composable
fun episodeContent(episode: Episode) {
    Div({ classes(SportsStyles.listItem) }) {
        Div {
            val preferredImage = episode.getPreferredImage()
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(preferredImage)) {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                }
            }
        }

        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(episode.additionalNames?.get("abbreviation")?.name ?: "")
            }
            Div({ classes(SportsStyles.entityInfo) }) { Text(getEpisodeName(episode)) }
            val localTimestamp = episode.startTimestamp?.timestamp()?.toLocalDateTimeString(false)
            if (localTimestamp != null) {
                Div({ classes(SportsStyles.entityInfo) }) { Text(localTimestamp) }
            }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(episode.id)
            }
        }
    }
}

private fun getEpisodeName(episode: Episode): String {
    val nickname = episode.additionalNames?.get(NameType.NICKNAME.name)?.name
    val name = episode.additionalNames?.get(NameType.LONG.name)?.name
    return if (nickname != null && name != null) {
        "$name $nickname"
    } else {
        episode.name
    }
}

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun episodeItem(episode: Episode, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    if (includeLink) {
        A(href = "#channelguide/episode/${episode.id}") {
            episodeContent(episode)
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(episode.additionalNames?.get("abbrevation")?.name ?: "")
                }
                Div({ classes(SportsStyles.entityInfo) }) { Text(getEpisodeName(episode)) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(episode.id)
                }
            }
        }
    }
}

/**
 * Render an episode summary list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun episodeSummaryItem(episode: EpisodeSummary, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    if (includeLink) {
        A(href = "#channelguide/episode/${episode.id}") {
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entityInfo) }) { Text(episode.name) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(episode.id)
                }
            }
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entityInfo) }) { Text(episode.name) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(episode.id)
                }
            }
        }
    }
}


/**
 * Render a shows grid.
 */
@Composable
fun episodesGrid(
    entities: List<Episode>,
    includeLinks: Boolean = true,
    onClick: ((Episode) -> Unit)? = null
) {
    Div({ classes(SportsStyles.gridList) }) {
        entities.forEach { entity ->
            episodeItem(entity, includeLinks && onClick == null) {
                onClick?.invoke(entity)
            }
        }
    }
}