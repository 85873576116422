package com.siriusxm.pia.utils

import kotlinx.datetime.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

/**
 * Get a standard h:mm a formatted time string.
 */
fun LocalDateTime.toAMPMTimeString(
    includeSeconds: Boolean = false, includeAmPm: Boolean = true,
    includeMinutes: Boolean = true
): String {
    val isPM = this.hour >= 12
    val hour = this.hour.let {
        if (it == 0) {
            12
        } else if (it > 12) {
            it - 12
        } else {
            it
        }
    }.toString()
    val minutes = minute.toString().padStart(2, '0')

    val seconds =
        this.second.toString().padStart(2, '0')

    val time = buildList {
        add(hour)
        if (includeMinutes) {
            add(minutes)
            if (includeSeconds) {
                add(seconds)
            }
        }
    }.joinToString(":")

    return if (includeAmPm) {
        "$time ${if (isPM) "pm" else "am"}"
    } else {
        time
    }
}

/**
 * Return the instant as a string with the date and time in the system local time.
 */
fun Instant.toLocalDateTimeString(includeSeconds: Boolean = false): String {
    return toLocalDateTime().toLocalDateTimeString(includeSeconds)
}

fun LocalDateTime.toLocalDateTimeString(includeSeconds: Boolean = false): String {
    return "$date ${toAMPMTimeString(includeSeconds)}"
}

/**
 * Return the instant as a string with the day of week, month day and year  and time in the system local time.
 */
fun Instant.toLocalExtendedDateTimeString(includeSeconds: Boolean = false): String {
    return toLocalDateTime().let {
        "${it.dayOfWeek.name.camelcase() } ${it.month.toString().camelcase() } ${it.dayOfMonth} ${it.toAMPMTimeString(includeSeconds)}"
    }
}

fun Instant.toLocalDayVerbose() : String {
    return toLocalDateTime().date.toLocalDayVerbose()
}

fun LocalDate.toLocalDayVerbose() : String {
    return "${this.dayOfWeek.name.camelcase() } ${this.month.toString().camelcase() } ${this.dayOfMonth}, ${this.year}"
}

/**
 * Get a local date as Monday, Jun 23
 */
fun LocalDate.toLocalDayString(): String {
    return "${this.dayOfWeek.name.camelcase()} ${this.month.toString().camelcase().take(3)} ${this.dayOfMonth}"
}

fun String.camelcase() : String {
    return this.lowercase().replaceFirstChar { it.uppercase() }
}

/**
 * Get the local date/time in the system timezone
 */
fun Instant.toLocalDateTime(): LocalDateTime {
    return toLocalDateTime(TimeZone.currentSystemDefault())
}

/**
 * Get a human-readable duration string that looks like "3 hours, 23 minutes, 14 seconds"
 */
fun Duration.toDurationHumanString(includeMilliseconds: Boolean = false): String {
    val str = arrayListOf<String>()
    val d = this
    if (d.inWholeHours > 0) {
        str += "${d.inWholeHours} hours"
    }
    val withoutHours = (d - d.inWholeHours.hours)
    if (withoutHours.inWholeMinutes > 0) {
        str += "${withoutHours.inWholeMinutes} minutes"
    }
    val withoutMinutes = withoutHours - withoutHours.inWholeMinutes.minutes
    if (withoutMinutes.inWholeSeconds > 0) {
        str += "${withoutMinutes.inWholeSeconds} seconds"
    }

    if (includeMilliseconds) {
        val withoutSeconds = withoutMinutes - withoutMinutes.inWholeSeconds.seconds
        if (withoutSeconds.inWholeMilliseconds > 0) {
            str += "${withoutMinutes.inWholeSeconds} ms"
        }
    }

    return str.joinToString(", ")
}