package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.Composable
import com.siriusxm.pia.utils.atlas.ImageParams
import com.siriusxm.pia.views.sports.SportsStyles
import com.siriusxm.pia.views.sports.getAbsoluteUrl
import contentingestion.unifiedmodel.Cut
import contentingestion.unifiedmodel.NameType
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun cutContent(cut: Cut, imageSize: ImageParams? = null,
               includeArtistStation: Boolean = true, includeId: Boolean = true) {
    val width = imageSize?.width ?: 250
    val height = imageSize?.height ?: 250

    Div({ classes(SportsStyles.metadata) }) {
        Div {
            val preferredImage = cut.getPreferredImage()
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(preferredImage)) {
                    style {
                        width(width.px)
                        height(height.px)
                    }
                }
            }
        }

        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(cut.additionalNames?.get("abbreviation")?.name ?: "")
            }
            Div({ classes(SportsStyles.entityInfo) }) { Text(getCutName(cut)) }
            cut.artist?.let {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text("Artist: $it")
                }
            }
            cut.album?.let {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text("Album: $it")
                }
            }

            if (includeArtistStation) {
                cut.artistStationId?.let {
                    Div({ classes(SportsStyles.entitySubInfo) }) {
                        Text("Artist Station: ")
                        A(href = "#aggregator/entity-by-outgoing-id/$it", { target(ATarget.Blank) }) {
                            Text("Link")
                        }
                    }
                }
            }
            cut.track?.let {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    A(href = "#aggregator/entity-by-outgoing-id/$it") {
                        Text("Track")
                    }
                }
            }
            if (includeId) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    A(href = "#aggregator/entity-by-outgoing-id/${cut.id}") { Text("id: ${cut.id}") }
                }
            }
        }
    }
}

private fun getCutName(cut: Cut): String {
    val nickname = cut.additionalNames?.get(NameType.NICKNAME.name)?.name
    val name = cut.additionalNames?.get(NameType.LONG.name)?.name
    return if (nickname != null && name != null) {
        "$name $nickname"
    } else {
        cut.name
    }
}

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun cutItem(cut: Cut,  imageSize: ImageParams? = null, includeLink: Boolean,
            includeArtistStation: Boolean = false, includeId: Boolean = false,
            onClick: (() -> Unit)? = null) {
    if (includeLink) {
        A(href = "#channelguide/cut/${cut.id}") {
            cutContent(cut, includeArtistStation = includeArtistStation, includeId = includeId)
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            cutContent(cut, imageSize = imageSize,  includeArtistStation = includeArtistStation, includeId = includeId)
        }
    }
}


/**
 * Render a shows grid.
 */
@Composable
fun cutsGrid(
    entities: List<Cut>,
    includeLinks: Boolean = true,
    includeArtistStation: Boolean = true, includeId: Boolean = true,
    imageSize: ImageParams = ImageParams(width = 100, height = 100),
    onClick: ((Cut) -> Unit)? = null
) {
    Div({ classes(SportsStyles.gridList) }) {
        entities.forEach { entity ->
            cutItem(entity, imageSize = imageSize,
                includeLinks && onClick == null,
                includeArtistStation = includeArtistStation, includeId = includeId) {
                onClick?.invoke(entity)
            }
        }
    }
}