package com.siriusxm.cmc.smithy

import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.serviceContentView
import com.siriusxm.pia.utils.getString
import com.siriusxm.smithy4kt.Model
import com.siriusxm.smithy4kt.SmithyModel
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.contentOrNull
import kotlinx.serialization.json.jsonPrimitive
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

/**
 * Displays documentation for a Smithy service.
 */
@Composable
fun ServiceDocs(model: Model, serviceId: String) {
    val smithyModel by remember { derivedStateOf { SmithyModel(model) } }
    val service by remember { derivedStateOf { model.shapes[serviceId]!! } }

    Style(EntityModelStyles)

    serviceContentView({
        title = serviceId.substringAfterLast("#")
    }) {
        val serviceDocs = service.traits["smithy.api#documentation"]?.jsonPrimitive?.contentOrNull
        if (serviceDocs != null) {
            Div({
                classes(EntityModelStyles.serviceDocs)
            }) {
                Text(serviceDocs)
            }
        }
        Div {
            service.operations.forEach {
                val operationId = it.target
                val operation = model.shapes[operationId]
                if (operation != null) {
                    val http = operation.traits["smithy.api#http"] as? JsonObject
                    val method = http?.getString("method")
                    val uri = http?.getString("uri")

                    box({
                        title = operationId.substringAfterLast("#").let {
                            it.replace(Regex("([A-Z])"), " $1").trim()
                        }
                        header({

                        }) {
                            val docs = operation.traits["smithy.api#documentation"]?.jsonPrimitive?.contentOrNull
                            if (docs != null) {
                                Div({ classes(EntityModelStyles.serviceOperationDocs) }) {
                                    Text(docs)
                                }
                            }
                            Div({ classes(EntityModelStyles.serviceHttp) }) {
                                if (method != null) {
                                    Span({ classes(EntityModelStyles.serviceMethod, method) }) {
                                        Text(method)
                                    }
                                }
                                if (uri != null) {
                                    Span({ classes(EntityModelStyles.serviceUri) }) {
                                        Text(uri)
                                    }
                                }
                            }
                        }
                    }) {
                        val input = operation.input?.let {
                            smithyModel.getShape(it.target)
                        }
                        val output = operation.output?.let {
                            smithyModel.getShape(it.target)
                        }

                        Div({
                            classes(EntityModelStyles.serviceOperationDetails)
                        }) {
                            if (input != null) {
                                Div({ classes(EntityModelStyles.serviceOperationSection) }) {
                                    H3 {
                                        Text("Input")
                                    }
                                    Div {
                                        Shape(input)
                                    }
                                }
                            }
                            if (output != null) {
                                Div({ classes(EntityModelStyles.serviceOperationSection) }) {
                                    H3 {
                                        Text("Output")
                                    }
                                    Div {
                                        Shape(output)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}