package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.*
import com.siriusxm.pia.utils.encodeURIComponent
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.*

/**
 * The main transcription vocabulary page.
 */
@Composable
fun vocabularyPage() {

    val vocabularies by remember { mutableStateOf(emptyList<VocabularyResponse>()) }
    val loading by mutableStateOf(false)

    var activeVocabularyId by remember { mutableStateOf<String?>(null) }

    box({
        paddedContent = vocabularies.isEmpty() || loading
        header({
            title = "Vocabulary"
        })
    }) {
        dialogView("Vocabulary Menu") {
            content {
                box {
                    dialogField(
                        "Vocabulary Id"
                    ) {
                        Input(InputType.Text) {
                            activeVocabularyId?.let {
                                defaultValue(it)
                            }
                            onInput {
                                activeVocabularyId = it.value.ifBlank { null }
                            }
                        }
                        button("Lookup") {
                            activeVocabularyId?.let {
                                Application.navigation.navigate("transcriptions/vocabulary/${encodeURIComponent(activeVocabularyId!!)}?action=view")
                            }
                        }
                    }
                }
            }
        }
    }
}

