package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import kotlinx.serialization.Serializable

@Serializable
data class FlattenedImage(
    val purpose: ImagePurpose,
    val ratio: ImageAspectRatio,
    val image: Image,
    val related: RelatedImages? = null
)

fun flattenImages(images: Map<ImagePurpose, Map<ImageAspectRatio, ImageVariant>>): List<FlattenedImage> {
    return images.flatMap {
        val purpose = it.key
        it.value.map {
            val ratio = it.key
            FlattenedImage(purpose, ratio, it.value.default, it.value.related)
        }
    }
}

fun Map<ImagePurpose, Map<ImageAspectRatio, ImageVariant>>.flatten(): List<FlattenedImage> {
    return flattenImages(this)
}


@Serializable
data class EntityAccessControl(
    val visible: Boolean?,
    val discoverable: Boolean?,
    val recommendable: Boolean?
)

@Serializable
data class ImageVariant(
    val default: Image,
    val related: RelatedImages? = null
)

@Serializable
data class Image(
    val width: Int? = null,
    val height: Int? = null,
    val url: String
)

@Serializable
data class RelatedImages(
    val show: Image? = null,
    val channel: Image? = null,
    val league: Image? = null,
)


@Serializable
class MappingResponse(
    val sourceId: String,
    val atlasId: String,
)