@file:Suppress("JSON_FORMAT_REDUNDANT_DEFAULT")

package com.siriusxm.pia.client.api

import com.steamstreet.graphkt.ID
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json

@Serializable
data class ShowOwnerInput(
  val externalId: String,
  val name: String
)

/**
 *  Data or updating a show. Fields that are not to be updated should not be included.
 */
@Serializable
data class ShowUpdateInput(
  val active: Boolean? = null,
  val podswagId: ID? = null,
  val directories: List<String>? = null,
  val connectedCatalogId: Long? = null,
  val slug: String? = null,
  val transcribe: Boolean? = null,
  val publishTranscriptions: Boolean? = null,
  val owner: ShowOwnerInput? = null,
  val license: String? = null,
  val note: String? = null,
  val streamOnly: Boolean? = null,
  val audioBook: Boolean? = null,
  val linkedEntities: List<String?>? = null
)

@Serializable
data class FeedLicenseInput(
  val url: String,
  val license: String
)

@Serializable
data class FeedOwnerInput(
  val url: String,
  val externalId: String,
  val name: String
)

/**
 *  A special way to add feeds that are linked to the content service.
 */
@Serializable
data class CSFeedInput(
  val freeUrl: String? = null,
  val premiumUrl: String? = null,
  val csId: String,
  val csSync: Boolean? = null
)

/**
 *  Input for a number of the feed related calls
 */
@Serializable
data class FeedInput(
  val url: String? = null,
  val showId: String? = null,
  val bundleId: String? = null,
  val active: Boolean? = null,
  val scheduled: Boolean? = null
)

@Serializable
data class ValidateFeedsInput(
  val feeds: List<String>
)

@Serializable
data class ImageSpecInput(
  val width: Int,
  val height: Int,
  val quality: Int
)

/**
 *  Input to request a list of shows
 */
@Serializable
data class ShowsRequest(
  val query: String? = null,
  val startingChar: String? = null,
  val token: String? = null,
  val limit: Int? = null,
  val includePrivate: Boolean? = null,
  val owner: String? = null,
  val license: String? = null
)

/**
 *  Input for content service show updates.
 */
@Serializable
data class CSShowUpdate(
  val sync: Boolean
)

/**
 *  Input for configuring a domain.
 */
@Serializable
data class DomainUpdateInput(
  val mediaLock: Boolean? = null
)

@Serializable
data class ContentFlagUpdateInput(
  val description: String? = null
)

@Serializable
data class ContentFlagMatcherInput(
  val regex: String
)

typealias AWSDateTime = String

typealias AWSDate = String

typealias AWSTimestamp = String

typealias AWSURL = String

internal val json: Json = Json {
  ignoreUnknownKeys = true
}

