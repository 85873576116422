package com.siriusxm.pia.rest.sports

import contentingestion.unifiedmodel.League
import contentingestion.unifiedmodel.Team
import de.jensklingenberg.ktorfit.http.GET
import de.jensklingenberg.ktorfit.http.HeaderMap
import de.jensklingenberg.ktorfit.http.Headers
import de.jensklingenberg.ktorfit.http.Path
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

interface SportsClient {
    @Headers("Content-Type: application/json")
    @GET("/entities/types/league/all")
    suspend fun fetchAllLeagues(@HeaderMap headerMap : Map<String,String>) : EntityResponse

    @Headers("Content-Type: application/json")
    @GET("/entities/{leagueId}/league/0.1")
    suspend fun fetchLeagueById(@Path("leagueId") leagueId: String, @HeaderMap headerMap : Map<String,String>) : League?

    @Headers("Content-Type: application/json")
    @GET("/entities/{teamId}/team/0.1")
    suspend fun fetchTeamById(@Path("teamId") teamId: String, @HeaderMap headerMap : Map<String,String>) : Team?

    @Headers("Content-Type: application/json")
    @GET("/entities/relationships/team-league/object/{leagueId}")
    suspend fun fetchTeamsForLeague(@Path("leagueId") leagueId: String, @HeaderMap headerMap : Map<String,String>) : EntityResponse

}

/**
 * Import from SDK
 */
@Serializable
data class EntityResponse(
    val entities: List<JsonObject>,
    val paginationToken: String? = null
)