package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.views.sports.colorValueNoHash
import com.siriusxm.pia.views.sports.colorValueWithHash
import io.ktor.util.*
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text


@Serializable
data class ColorizableField(
    val field: String,
    val color: String?, // Color value with `#`
    val opacity: Float? // 0.0 to 1.0
) {
    fun toCSSColorValue(): CSSColorValue {
        return color?.toCSSColorValue(opacity ?: 1.0f)
            ?: rgba(255, 255, 255, 1.0)
    }

    fun toColor() : contentingestion.unifiedmodel.Color? {
        return color?.let {c ->
            contentingestion.unifiedmodel.Color(
                label = null, // we dont need to save the label
                hex = colorValueWithHash(c),
                opacity = opacity
            )
        }
    }
}

fun String?.isHexColorValue() : Boolean {
    if (this == null)  return false
    return isValidColor(this)
}

fun String?.toCSSColorValue(opacity: Float? = 1.0f ) : CSSColorValue {
    val colorNoHash = this?.let { colorValueNoHash(it) }

    return colorNoHash?.let { hex ->
        // Parse the hexadecimal string into three components
        val r = hex.substring(0, 2).toInt(16)  // Red component
        val g = hex.substring(2, 4).toInt(16)  // Green component
        val b = hex.substring(4, 6).toInt(16)  // Blue component

        console.log("hex: $hex to ($r,$g,$b)")

        rgba(r, g, b, opacity ?: 1.0)
    } ?: throw IllegalArgumentException("Not a valid color: $this")
}

@Composable
fun colorSwatch(colorValue: CSSColorValue, height: Int = 20, width: Int = 20) {
    Div({
        style {
            backgroundColor(colorValue)
            width(width.px)
            height(height.px)
            border(1.px, LineStyle.Solid, Color("black"))

        }
    }) {
        Text("")
    }
}

@Composable
fun colorListViewer(savedColors: List<ColorizableField>, editable: Boolean, callback: (saved: List<ColorizableField>) -> Unit) {
    var updatedColors by remember { mutableStateOf<List<ColorizableField>?>(null) }
    var editedColors by remember { mutableStateOf<MutableMap<String, ColorizableField>?>(null) }
    var saving by remember { mutableStateOf(false) }
    var dirty by remember { mutableStateOf(false) }
    var override by remember { mutableStateOf(false) }

    val debug = false

    if (editable) {
        simpleCheckBox(
            "Override colors?", override
        ) { selected ->
            override = selected
            console.log("Override set to: " + override)

            editedColors = buildColorizableMap(savedColors)
            updatedColors = copyList(savedColors)
        }
    }

    // we are just showing the colors
    Div({
        style {
            marginLeft(2.em)
            marginTop(5.px)
        }
    }) {

        detailGrid {
            val colorizableFields = if (override) {
                updatedColors
            } else {
                savedColors
            }
            colorizableFields?.forEach { color ->

                detail(color.field) {
                    if (override) {
                        Div {
                            colorPicker(color, true, width = 100, height = 100) { updatedColor, save ->
                                editedColors?.set(updatedColor.field, updatedColor)
                                if (save) {
                                    val savedColor = editedColors?.get(color.field)
                                    console.log("Color should  be set to $savedColor")

                                    val changedColors = editedColors?.values?.toList()
                                    console.log("Colors changed to: " + changedColors?.joinToString(",") {
                                        it.color ?: "NONE"
                                    })

                                    updatedColors = changedColors
                                    dirty = true
                                }
                                true
                            }
                        }
                    } else {
                        if (color.color != null) {
                            colorSwatch(color.toCSSColorValue(), height = 100, width = 100)
                        } else {
                            Text("NONE")
                        }
                    }
                }
            }
        }
    }

    if (debug) {
        Div {
            Text("Dirty: " + dirty + "colors: " + updatedColors?.joinToString(",") { it.color ?: "NONE" })
        }
    }

    if (editable && override && updatedColors != null) {
        if (dirty) {
            Div({
                style {
                    margin(1.em, 0.px)
                }
            }) {
                button {
                    primary = true
                    title = "Save"
                    showProgressOnAction = true
                    enabled = !saving
                    action {
                        updatedColors?.let { colors ->
                            saving = true
                            callback(colors)
                            saving = false
                            dirty = false
                        }
                    }
                }

                button ("Reset") {
                    if (!saving) {
                        val resetList = mutableListOf<ColorizableField>()
                        resetList.addAll(savedColors)
                        updatedColors = resetList
                        override = savedColors.isNotEmpty()
                        dirty = false
                    }
                }
            }
        }

    }

}


fun isValidColor(colorString: String) : Boolean = Regex("^#[0-9A-Fa-f]{6}$").matches(colorString)

fun copyList(list: List<ColorizableField>?): List<ColorizableField> {
    val newList = mutableListOf<ColorizableField>()
    list?.forEach {
        newList.add(it.copy())
    }
    return newList
}

fun buildColorizableMap(list: List<ColorizableField>?): MutableMap<String, ColorizableField> {
    val colorMap = mutableMapOf<String, ColorizableField>()
    list?.forEach {
        colorMap[it.field] = it
    }
    return colorMap
}

/**
 * Color picker widget
 *
 * The callback take in the revised colorizable and a boolean which tells if it is
 * in-progress change or permanent.  This doesn't indicate whether it is saved to the
 * server, only whether the component is closed (so we have picked a color)
 */
@Composable
fun colorPicker(color: ColorizableField, showHexTextField: Boolean, width: Int, height: Int,
                callback: (color: ColorizableField, finalized: Boolean) -> Boolean) {
    var updatedColor by remember { mutableStateOf(color.color) }
    var updatedHex by remember { mutableStateOf<String?>(null) }
    var errorMsg by remember { mutableStateOf<String?>(null) }

    Div {
        Div {
            Input(InputType.Color) {
                onInput {
                    console.log("Color input: " + it.value + " for ${color.field}")
                    updatedColor = colorValueWithHash( it.value.toUpperCasePreservingASCIIRules())
                    callback(color.copy(color = updatedColor), false)
                }
                onBlur {
                    console.log("On blur")
                    if (updatedColor != color.color) {
                        callback(color.copy(color = updatedColor), true)
                    }
                }
                title("Click to change color")
                value(color.color ?: "#FFFFFF")
                style {
                    width(width.px)
                    height(height.px)
                }
            }
        }

        if (showHexTextField) {
            Div {
                Text("#")
                Input(InputType.Text) {
                    onInput {
                        val colorValue = colorValueWithHash(it.value.toUpperCasePreservingASCIIRules())
                        updatedHex = colorValueNoHash(colorValue)
                        if (isValidColor(colorValue)) {
                            callback(color.copy(color = updatedColor), true)
                            errorMsg = null
                        } else {
                            errorMsg = "Must be a valid 6-digit hexidecimal color"
                            this.style {
                                // set the border to red if there is an error with the data
                                border(
                                    color = rgb(255, 0, 0),
                                    style = LineStyle.Solid
                                )
                            }
                        }
                    }
                    onBlur {
                        console.log("On blur")
                        if (updatedHex != null && isValidColor(updatedHex!!) && updatedHex != color.color) {
                            callback(color.copy(color = updatedHex), true)
                            updatedHex = null
                        }
                    }
                    title("Hex")
                    value(updatedHex ?: color.color?.let { colorValueNoHash(it) } ?: "")
                    style {
                        width(100.px)
                    }
                }
            }

            if (errorMsg != null) {
                Div({
                    style {
                        color(rgb(255, 0, 0))
                    }
                }) {
                    Text(errorMsg!!)
                }
            }
        }
    }
}
