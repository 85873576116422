package com.siriusxm.pia

import org.jetbrains.compose.web.css.*

/**
 * Constants for the SiriusXM UI
 */
object SXMUI {
    val mainHeaderBackgroundColor by variable<CSSColorValue>()

    val mainLayoutBackgroundColor by variable<CSSColorValue>()
    val containerHeaderBackgroundColor by variable<CSSColorValue>()
    val containerContentBackgroundColor by variable<CSSColorValue>()
    val borderDividerDefault by variable<CSSColorValue>()

    val textColor by variable<CSSColorValue>()
    val subtleTextColor by variable<CSSColorValue>()
    val disabledTextColor by variable<CSSColorValue>()
    val tabsBorder by variable<CSSColorValue>()

    val brandColor by variable<CSSColorValue>()
    val highlightColor by variable<CSSColorValue>()

    val defaultDivider by variable<CSSColorValue>()

    val buttonBackgroundNormal by variable<CSSColorValue>()
    val buttonBackgroundNormalHover by variable<CSSColorValue>()
    val buttonForegroundNormal by variable<CSSColorValue>()
    val buttonForegroundNormalHover by variable<CSSColorValue>()
    val buttonBorderNormal by variable<CSSColorValue>()
    val buttonBackgroundPrimary by variable<CSSColorValue>()
    val buttonBackgroundPrimaryHover by variable<CSSColorValue>()
    val buttonForegroundPrimary by variable<CSSColorValue>()

    // message boxes
    val messageInfoBorder by variable<CSSColorValue>()
    val messageInfoBackground by variable<CSSColorValue>()

    val messageWarnBorder by variable<CSSColorValue>()
    val messageWarnBackground by variable<CSSColorValue>()

    val messageAlertBorder by variable<CSSColorValue>()
    val messageAlertBackground by variable<CSSColorValue>()

    val smallFontSize by variable<CSSNumeric>()
}

object AppStylesheet : StyleSheet() {
    init {
        val brandColor = rgb(27, 17, 122)
        universal style {
            SXMUI.mainHeaderBackgroundColor(Color.black)
            SXMUI.mainLayoutBackgroundColor(Color("#f2f3f3"))
            SXMUI.borderDividerDefault(Color("#eaeded"))
            SXMUI.containerHeaderBackgroundColor(Color("#fafafa"))
            SXMUI.containerContentBackgroundColor(Color("#ffffff"))

            SXMUI.tabsBorder(Color("#aab7b8"))

            SXMUI.textColor(rgb(22, 25, 31))
            SXMUI.subtleTextColor(rgb(84, 91, 100))
            SXMUI.disabledTextColor(Color("#aab7b8"))

            SXMUI.brandColor(brandColor)
            SXMUI.highlightColor(Color("#C4169C"))
            SXMUI.defaultDivider(Color("#eaeded"))

            SXMUI.smallFontSize((0.9).cssRem)

            SXMUI.buttonBackgroundNormal(Color.white)
            SXMUI.buttonBackgroundNormalHover(Color("#fafafa"))
            SXMUI.buttonForegroundNormal(Color("#545b64"))
            SXMUI.buttonForegroundNormalHover(Color("#16191f"))
            SXMUI.buttonBorderNormal(Color("#545b64"))
            SXMUI.buttonBackgroundPrimary(brandColor)
            SXMUI.buttonBackgroundPrimaryHover(Color("#0051E6"))
            SXMUI.buttonForegroundPrimary(Color.white)

            SXMUI.messageInfoBorder(Color("#0073bb"))
            SXMUI.messageInfoBackground(Color("#f1faff"))

            SXMUI.messageWarnBorder(Color("#906806"))
            SXMUI.messageWarnBackground(Color("#ffe457"))

            SXMUI.messageAlertBorder(Color("#d13212"))
            SXMUI.messageAlertBackground(Color("#fdf3f1"))

            boxSizing("border-box")
        }

        type("html") style {
            fontFamily("Helvetica Now", "Arial", "Helvetica", "sans-serif")
            color(SXMUI.textColor.value())
            fontSize(90.percent)
        }

        type("A") style {
            color(SXMUI.buttonBackgroundPrimary.value())

        }

        (type("A") + hover) style {
            color(SXMUI.buttonBackgroundPrimaryHover.value())
        }

        "input, textarea, select" style {
            border(1.px, LineStyle.Solid, SXMUI.tabsBorder.value())
            borderRadius(2.px)
            padding((0.4).cssRem, (0.6).cssRem)
        }

        "input[type=text], textarea, select" style {
            height(2.5.em)
        }

        ".aggregator-icon-link" {
            display(DisplayStyle.None)
        }

        "*:hover > .aggregator-icon-link" {
            display(DisplayStyle.Inline)
        }
    }
}