package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.NavigationItem
import com.siriusxm.pia.components.serviceView
import com.siriusxm.pia.components.simpleNavSidebar
import com.siriusxm.pia.rest.epg.Category
import com.siriusxm.pia.rest.epg.ChannelDetailSummary
import com.siriusxm.pia.rest.epg.EPGApiClient
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.Route

val uuidRegex = "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}${'$'}".toRegex()

/**
 * Channel Routing
 */
@Composable
fun Route.channelGuideDashboard(epgApi: EPGApiClient, aggregatorClient: UnifiedAggregatorClient) {
    var categories: List<Category>? by remember { mutableStateOf(null) }
    var channels: List<ChannelDetailSummary>? by remember { mutableStateOf(null) }

    val epgContext by remember {
        mutableStateOf(
            EPG(
                api = epgApi,
                aggregatorClient = aggregatorClient
            )
        )
    }

    LaunchedEffect(true) {
        categories = epgContext.categories
        channels = epgContext.channelDetails
        epgContext.initData { newChannels, newCategories ->
            channels = newChannels
            categories = newCategories
        }
    }

    serviceView({
        simpleNavSidebar(
            true, "EPG", listOf(
                NavigationItem("Channels", "channelguide"),
                NavigationItem("Lineups", "channelguide/lineups"),
                NavigationItem("Shows", "channelguide/shows")
            )
        )
    }) {
        switch {
            select("channels") {
                switch {
                    select(uuidRegex) {
                        channel(epgContext, match, this)
                    }

                    default {
                        val categoryId = parameter("categoryId")
                        if (channels != null && categories != null) {
                            channelsBrowser(channels!!, categories!!, epgContext, categoryId)
                        }
                    }
                }
            }

            select("lineups") {
                switch {
                    select(uuidRegex) {
                        lineups(epgContext, match)
                    }
                    default {
                        lineups(epgContext)
                    }
                }

            }

            select("shows") {
                switch {
                    select(uuidRegex) {
                        show(epgContext, this.match)
                    }
                    default {
                        shows(epgContext, this)
                    }
                }
            }

            select("episodes") {
                switch {
                    select(uuidRegex) {
                        episode(epgContext, this.match)
                    }
                }
            }

            default {
                if (channels != null && categories != null) {
                    channelsBrowser(channels!!, categories!!, epgContext)
                }
            }
        }
    }
}