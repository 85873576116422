package com.siriusxm.pia.utils

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

object ContentFetch {
    private val http = HttpClient {

    }

    /**
     * Fetch content from a URL.
     */
    suspend fun fetchContent(url: String): String {
        return http.get(url).body()
    }
}