// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.aggregator.BackfillRequest
import contentingestion.aggregator.BackfillTestRequest
import contentingestion.aggregator.BackfillTestResponse
import contentingestion.aggregator.ConsumerDetails
import contentingestion.aggregator.EntityTypeConfiguration
import contentingestion.aggregator.GetMetricDataRequest
import contentingestion.aggregator.GetMetricDataResponse
import contentingestion.aggregator.PartialSchema
import contentingestion.aggregator.PartialUpdate
import contentingestion.aggregator.ProducerDetails
import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.Boolean
import kotlin.Int
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject

@OptIn(InternalKtorfitApi::class)
public class _UnifiedAggregatorClientImpl : UnifiedAggregatorClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun entityTypes(): List<EntityTypeConfiguration> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities/types")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename =
        "kotlin.collections.List<contentingestion.aggregator.EntityTypeConfiguration>",
    typeInfo =
        typeInfo<kotlin.collections.List<contentingestion.aggregator.EntityTypeConfiguration>>())

    return _converter.suspendRequest<kotlin.collections.List<contentingestion.aggregator.EntityTypeConfiguration>,
        contentingestion.aggregator.EntityTypeConfiguration>(_typeData,_ext)!!
  }

  override suspend fun partialSchemas(): List<PartialSchema> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "partials")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.collections.List<contentingestion.aggregator.PartialSchema>",
    typeInfo = typeInfo<kotlin.collections.List<contentingestion.aggregator.PartialSchema>>())

    return _converter.suspendRequest<kotlin.collections.List<contentingestion.aggregator.PartialSchema>,
        contentingestion.aggregator.PartialSchema>(_typeData,_ext)!!
  }

  override suspend fun fetchEntity(
    id: String,
    type: String,
    version: String,
  ): JsonObject {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "entities/${"$id".encodeURLPath()}/${"$type".encodeURLPath()}/${"$version".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlinx.serialization.json.JsonObject",
    typeInfo = typeInfo<kotlinx.serialization.json.JsonObject>())

    return _converter.suspendRequest<kotlinx.serialization.json.JsonObject,
        kotlinx.serialization.json.JsonObject>(_typeData,_ext)!!
  }

  override suspend fun fetchEntityById(id: String, includeRemoved: String?): List<JsonObject> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities/${"$id".encodeURLPath()}")
        includeRemoved?.let{ parameter("includeRemoved", "$it") }
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.collections.List<kotlinx.serialization.json.JsonObject>",
    typeInfo = typeInfo<kotlin.collections.List<kotlinx.serialization.json.JsonObject>>())

    return _converter.suspendRequest<kotlin.collections.List<kotlinx.serialization.json.JsonObject>,
        kotlinx.serialization.json.JsonObject>(_typeData,_ext)!!
  }

  override suspend fun incomingEntity(
    id: String,
    type: String,
    version: String,
    fields: String?,
  ): IncomingEntityResult? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "incoming/${"$id".encodeURLPath()}/${"$type".encodeURLPath()}/${"$version".encodeURLPath()}")
        fields?.let{ parameter("fields", "$it") }
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.IncomingEntityResult?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.IncomingEntityResult?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.IncomingEntityResult?,
        com.siriusxm.pia.rest.unifiedaggregator.IncomingEntityResult?>(_typeData,_ext)
  }

  override suspend fun partialUpdate(partial: PartialUpdate) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "incoming/partial")
        }
        setBody(partial)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun outgoingById(id: String): JsonArray? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "outgoing/${"$id".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlinx.serialization.json.JsonArray?",
    typeInfo = typeInfo<kotlinx.serialization.json.JsonArray?>())

    return _converter.suspendRequest<kotlinx.serialization.json.JsonArray?,
        kotlinx.serialization.json.JsonArray?>(_typeData,_ext)
  }

  override suspend fun outgoing(
    id: String,
    type: String,
    version: String,
  ): JsonObject? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "outgoing/${"$id".encodeURLPath()}/${"$type".encodeURLPath()}/${"$version".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlinx.serialization.json.JsonObject?",
    typeInfo = typeInfo<kotlinx.serialization.json.JsonObject?>())

    return _converter.suspendRequest<kotlinx.serialization.json.JsonObject?,
        kotlinx.serialization.json.JsonObject?>(_typeData,_ext)
  }

  override suspend fun republish(
    id: String,
    type: String,
    version: String,
    updateTimestamp: Boolean,
  ) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl +
            "outgoing/${"$id".encodeURLPath()}/${"$type".encodeURLPath()}/${"$version".encodeURLPath()}?republish=1")
        updateTimestamp?.let{ parameter("updateTimestamp", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun searchEntities(
    queryTerm: String?,
    types: String?,
    from: Int?,
    size: Int?,
    visible: Boolean?,
  ): EntityListResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities")
        queryTerm?.let{ parameter("q", "$it") }
        types?.let{ parameter("types", "$it") }
        from?.let{ parameter("from", "$it") }
        size?.let{ parameter("size", "$it") }
        visible?.let{ parameter("visible", "$it") }
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse,
        com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse>(_typeData,_ext)!!
  }

  override suspend fun lookupSourceId(id: String): MappingResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities/mappings/${"$id".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.MappingResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.MappingResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.MappingResponse,
        com.siriusxm.pia.rest.unifiedaggregator.MappingResponse>(_typeData,_ext)!!
  }

  override suspend fun producers(): List<ProducerDetails> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "management/producers")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.collections.List<contentingestion.aggregator.ProducerDetails>",
    typeInfo = typeInfo<kotlin.collections.List<contentingestion.aggregator.ProducerDetails>>())

    return _converter.suspendRequest<kotlin.collections.List<contentingestion.aggregator.ProducerDetails>,
        contentingestion.aggregator.ProducerDetails>(_typeData,_ext)!!
  }

  override suspend fun newProducer(producerDetails: ProducerDetails) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "management/producers")
        }
        setBody(producerDetails)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun updateProducer(id: String, producerDetails: ProducerDetails) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("PUT")
        url{
        takeFrom(_converter.baseUrl + "management/producers/${"$id".encodeURLPath()}")
        }
        setBody(producerDetails)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun consumers(): List<ConsumerDetails> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "management/consumers")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.collections.List<contentingestion.aggregator.ConsumerDetails>",
    typeInfo = typeInfo<kotlin.collections.List<contentingestion.aggregator.ConsumerDetails>>())

    return _converter.suspendRequest<kotlin.collections.List<contentingestion.aggregator.ConsumerDetails>,
        contentingestion.aggregator.ConsumerDetails>(_typeData,_ext)!!
  }

  override suspend fun newConsumer(producerDetails: ConsumerDetails) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "management/consumers")
        }
        setBody(producerDetails)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun updateConsumer(id: String, producerDetails: ConsumerDetails) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("PUT")
        url{
        takeFrom(_converter.baseUrl + "management/consumers/${"$id".encodeURLPath()}")
        }
        setBody(producerDetails)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun backfills(since: String?, until: String?): List<BackfillStatus> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "backfill")
        since?.let{ parameter("since", "$it") }
        until?.let{ parameter("until", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename =
        "kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>",
    typeInfo =
        typeInfo<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>>())

    return _converter.suspendRequest<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>,
        com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>(_typeData,_ext)!!
  }

  override suspend fun activeBackfills(): List<BackfillStatus> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "backfill/active")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename =
        "kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>",
    typeInfo =
        typeInfo<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>>())

    return _converter.suspendRequest<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>,
        com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>(_typeData,_ext)!!
  }

  override suspend fun backfill(id: String): BackfillStatus {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "backfill/${"$id".encodeURLPath()}")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus,
        com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>(_typeData,_ext)!!
  }

  override suspend fun startBackfill(request: BackfillRequest): BackfillStatus {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "backfill")
        }
        setBody(request)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus,
        com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus>(_typeData,_ext)!!
  }

  override suspend fun cancelBackfill(id: String) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("DELETE")
        url{
        takeFrom(_converter.baseUrl + "backfill/${"$id".encodeURLPath()}")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun relationshipSchemas(): List<RelationshipSchema> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities/relationships")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename =
        "kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.RelationshipSchema>",
    typeInfo =
        typeInfo<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.RelationshipSchema>>())

    return _converter.suspendRequest<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.RelationshipSchema>,
        com.siriusxm.pia.rest.unifiedaggregator.RelationshipSchema>(_typeData,_ext)!!
  }

  override suspend fun relationshipsForEntity(id: String): List<EntityRelationship> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities/${"$id".encodeURLPath()}/relationships")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename =
        "kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.EntityRelationship>",
    typeInfo =
        typeInfo<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.EntityRelationship>>())

    return _converter.suspendRequest<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.EntityRelationship>,
        com.siriusxm.pia.rest.unifiedaggregator.EntityRelationship>(_typeData,_ext)!!
  }

  override suspend fun relationshipSubjects(relationshipSchemaId: String, id: String):
      EntityListResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "entities/relationships/${"$relationshipSchemaId".encodeURLPath()}/object/${"$id".encodeURLPath()}")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse,
        com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse>(_typeData,_ext)!!
  }

  override suspend fun relationshipObjects(relationshipSchemaId: String, id: String):
      EntityListResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "entities/relationships/${"$relationshipSchemaId".encodeURLPath()}/subject/${"$id".encodeURLPath()}")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse,
        com.siriusxm.pia.rest.unifiedaggregator.EntityListResponse>(_typeData,_ext)!!
  }

  override suspend fun entityEvents(
    id: String,
    queryId: String?,
    startTime: Instant?,
  ): EventSearchStatus {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entities/${"$id".encodeURLPath()}/events")
        queryId?.let{ parameter("queryId", "$it") }
        startTime?.let{ parameter("start", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.EventSearchStatus",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.EventSearchStatus>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.EventSearchStatus,
        com.siriusxm.pia.rest.unifiedaggregator.EventSearchStatus>(_typeData,_ext)!!
  }

  override suspend fun submitRelationship(relationshipSchemaId: String,
      update: IncomingRelationshipUpdate) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl +
            "incoming/relationships/${"$relationshipSchemaId".encodeURLPath()}")
        }
        setBody(update)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun features(): AppConfigFeatureFlagsDocument {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "management/features")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.unifiedaggregator.AppConfigFeatureFlagsDocument",
    typeInfo = typeInfo<com.siriusxm.pia.rest.unifiedaggregator.AppConfigFeatureFlagsDocument>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.unifiedaggregator.AppConfigFeatureFlagsDocument,
        com.siriusxm.pia.rest.unifiedaggregator.AppConfigFeatureFlagsDocument>(_typeData,_ext)!!
  }

  override suspend fun updateFeatures(update: FeatureFlagsUpdate) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "management/features")
        }
        setBody(update)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun testBackfill(backfillTestRequest: BackfillTestRequest):
      BackfillTestResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "backfill/test")
        }
        setBody(backfillTestRequest)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.aggregator.BackfillTestResponse",
    typeInfo = typeInfo<contentingestion.aggregator.BackfillTestResponse>())

    return _converter.suspendRequest<contentingestion.aggregator.BackfillTestResponse,
        contentingestion.aggregator.BackfillTestResponse>(_typeData,_ext)!!
  }

  override suspend fun availableBatchJobs(): List<BatchDescriptor> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "batch")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename =
        "kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BatchDescriptor>",
    typeInfo =
        typeInfo<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BatchDescriptor>>())

    return _converter.suspendRequest<kotlin.collections.List<com.siriusxm.pia.rest.unifiedaggregator.BatchDescriptor>,
        com.siriusxm.pia.rest.unifiedaggregator.BatchDescriptor>(_typeData,_ext)!!
  }

  override suspend fun startBatchJob(request: BatchingRequest) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "batch")
        }
        setBody(request)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun getMetricsImage(
    period: Int,
    type: String?,
    width: Int?,
    height: Int?,
  ): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "incoming/metrics/image")
        period?.let{ parameter("period", "$it") }
        type?.let{ parameter("type", "$it") }
        width?.let{ parameter("width", "$it") }
        height?.let{ parameter("height", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun getMetrics(request: GetMetricDataRequest): GetMetricDataResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "metrics")
        }
        setBody(request)
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.aggregator.GetMetricDataResponse",
    typeInfo = typeInfo<contentingestion.aggregator.GetMetricDataResponse>())

    return _converter.suspendRequest<contentingestion.aggregator.GetMetricDataResponse,
        contentingestion.aggregator.GetMetricDataResponse>(_typeData,_ext)!!
  }

  override suspend fun deleteScheduledPartial(
    entityId: String,
    entityType: String,
    entityVersion: String,
    partialId: String,
    timestamp: String,
  ) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("DELETE")
        url{
        takeFrom(_converter.baseUrl +
            "incoming/${"$entityId".encodeURLPath()}/${"$entityType".encodeURLPath()}/${"$entityVersion".encodeURLPath()}/partial/${"$partialId".encodeURLPath()}/scheduled/${"$timestamp".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun deleteScheduledUpdate(
    entityId: String,
    entityType: String,
    entityVersion: String,
    timestamp: String,
  ) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("DELETE")
        url{
        takeFrom(_converter.baseUrl +
            "incoming/${"$entityId".encodeURLPath()}/${"$entityType".encodeURLPath()}/${"$entityVersion".encodeURLPath()}/scheduled/${"$timestamp".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createUnifiedAggregatorClient(): UnifiedAggregatorClient =
    this.create(_UnifiedAggregatorClientImpl().apply { _converter=
    KtorfitConverterHelper(this@createUnifiedAggregatorClient) })
