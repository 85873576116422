package com.siriusxm.pia.views.unifiedaggregator.smithy

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.getFloat
import com.siriusxm.pia.utils.getString
import com.siriusxm.pia.utils.toDurationHumanString
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.AggregatorStyles
import com.siriusxm.pia.views.unifiedaggregator.styles.AggregatorAnimation
import com.siriusxm.smithy4kt.SmithyEntity
import com.siriusxm.smithy4kt.SmithyMap
import com.siriusxm.smithy4kt.SmithyString
import com.siriusxm.smithy4kt.SmithyStructure
import contentingestion.unifiedmodel.EntityAccessControl
import contentingestion.unifiedmodel.EntityAccessControls
import contentingestion.unifiedmodel.Playlist
import kotlinx.datetime.Instant
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.milliseconds

open class CustomRenderer(shape: SmithyEntity) : SmithyViewer<SmithyEntity>(shape) {
    /**
     * Helper to decode a value
     */
    inline fun <reified T> decode(value: JsonElement?): T? {
        if (value == null) return null

        return smithyViewDecoder.decodeFromJsonElement<T>(value)
    }
}

abstract class CustomTypeRenderer<T>(shape: SmithyEntity, val serializer: KSerializer<T>) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val typeValue = value?.let {
            smithyViewDecoder.decodeFromJsonElement(serializer, it)
        }
        viewType(typeValue, options)
    }

    @Composable
    abstract fun viewType(value: T?, options: SmithyViewOptions)
}

class EntityIdViewer(val aggregatorService: AggregatorService, shape: SmithyEntity) :
    SmithyViewer<SmithyEntity>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        var resolvedId by remember { mutableStateOf(value?.jsonPrimitive?.contentOrNull) }
        var loading by remember { mutableStateOf(false) }

        val baseId = value?.jsonPrimitive?.contentOrNull
        val id = resolvedId ?: return

        A("#aggregator/entity/${baseId}") {
            Text(id)
        }

        Span({
            classes(JsonViewStyles.copyContentIcon)
            if (loading) classes(AggregatorAnimation.spin)
        }) {
            icon("autorenew") {
                size = IconSize.TINY
                title = "Lookup mapped identifier"
                style { lineHeight("inherit") }

                action {
                    if (baseId != resolvedId) {
                        resolvedId = baseId
                    } else if (baseId != null) {
                        loading = true
                        try {
                            val response = aggregatorService.api.lookupSourceId(baseId)
                            resolvedId = if (response.sourceId == baseId) {
                                response.atlasId
                            } else {
                                response.sourceId
                            }
                        } catch (e: Throwable) {
                            // ignored.
                        }
                        loading = false
                    }
                }
            }
        }

        copyContentIcon(id)
    }
}


/**
 * A rendered that displays a map as a set of labels and content instead of a table
 */
class LabelledFieldsMapViewer(shape: SmithyEntity) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val obj = value as? JsonObject ?: return
        val smithyMap = shape as? SmithyMap ?: return

        Div({ classes(AggregatorStyles.additionalNames) }) {
            obj.entries.forEach {
                Div({ classes(AggregatorStyles.additionalName) }) {
                    Div({
                        style {
                            fontSize(11.px)
                            color(SXMUI.subtleTextColor.value())
                        }
                    }) {
                        Text(it.key)
                    }
                    Div {
                        SmithyViewer(smithyMap.value, options) {}?.view(it.value, options)
                    }
                }
            }
        }

        super.view(value, options)
    }
}

/**
 * Renders the additional names component
 */
class NamesMapViewer(shape: SmithyEntity) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val names = value?.jsonObject?.values?.mapNotNull { it as? JsonObject } ?: return

        Div({ classes(AggregatorStyles.additionalNames) }) {
            names.forEach {
                Div({ classes(AggregatorStyles.additionalName) }) {
                    Div({
                        style {
                            fontSize(11.px)
                            color(SXMUI.subtleTextColor.value())
                        }
                    }) {
                        Text(it.getString("type").orEmpty())
                    }
                    Div {
                        Text(it.getString("name").orEmpty())
                    }
                }
            }
        }
    }
}

/**
 * Render a color as a swatch
 */
class ColorViewer(shape: SmithyEntity) : CustomRenderer(shape) {
    fun hexToRgb(hex: String): Triple<Int, Int, Int> {
        // Remove '#' if present.
        val cleanedHex = hex.removePrefix("#")

        // Validate that we have exactly 6 characters.
        require(cleanedHex.length == 6) { "Invalid hex color format." }

        val r = cleanedHex.substring(0, 2).toInt(16)
        val g = cleanedHex.substring(2, 4).toInt(16)
        val b = cleanedHex.substring(4, 6).toInt(16)

        return Triple(r, g, b)
    }

    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val hex = value?.getString("hex")
        val rgb = hex?.let {
            hexToRgb(it)
        } ?: return
        val opacity = value.getFloat("opacity") ?: 1.0f

        Div({
            style {
                backgroundColor(rgba(rgb.first, rgb.second, rgb.third, opacity))
                width(25.px)
                height(25.px)
                border(1.px, LineStyle.Solid, SXMUI.defaultDivider.value())
                display(DisplayStyle.Block)
            }
        })
        copyContentIcon(hex)
    }
}

class PlaylistViewer(shape: SmithyEntity) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val playlistList = value?.let {
            smithyViewDecoder.decodeFromJsonElement<List<Playlist>>(it)
        } ?: return

        table<Playlist> {
            items(playlistList)

            column {
                content {
                    Text(it.playlistCode.name)
                }
            }
            column {
                content {
                    Text(it.originId.name)
                }
            }
            column {
                content {
                    Text(it.uriPath)
                }
            }
        }
    }
}

class AccessControlViewer(shape: SmithyEntity) :
    CustomTypeRenderer<EntityAccessControls>(shape, EntityAccessControls.serializer()) {
    @Composable
    fun accessControl(control: EntityAccessControl) {
        Div {
            Span({
                title(if (control.visible) "Visible" else "Not Visible")
            }) {
                icon(if (control.visible) "visibility" else "visibility_off") {
                    type = IconType.SYMBOLS
                    size = IconSize.SMALL
                }
            }

            Span({
                title(if (control.discoverable) "Discoverable" else "Not Discoverable")
            }) {
                icon(if (control.discoverable) "search" else "hide_image") {
                    type = IconType.SYMBOLS
                    size = IconSize.SMALL
                }
            }

            Span({
                title(if (control.recommendable) "Recommendable" else "Not Recommendable")
            }) {
                icon(if (control.recommendable) "thumb_up" else "thumb_down") {
                    type = IconType.SYMBOLS
                    size = IconSize.SMALL
                }
            }
        }
    }

    @Composable
    fun label(label: String) {
        Div({
            style {
                fontSize(11.px)
                color(SXMUI.subtleTextColor.value())
            }
        }) {
            Text(label)
        }
    }

    @Composable
    override fun viewType(value: EntityAccessControls?, options: SmithyViewOptions) {
        val resolved = value ?: EntityAccessControls(EntityAccessControl(true, true, true))

        val default = resolved.default
        val auto = resolved.auto360l
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                gap(1.em)
            }
        }) {

            Div {
                if (auto != null) {
                    label("Default")
                }
                accessControl(default)
            }

            if (auto != null) {
                Div {
                    label("Auto 360L")
                    accessControl(auto)
                }
            }
        }
    }

    /**
     * For edits, just defer to the default editor for now.
     */
    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        SmithyStructEditor(shape as SmithyStructure).edit(baseValue, modification, options)
    }
}

/**
 * Viewer/editor for an iso8601 date time
 */
class Iso8601TimestampViewer(shape: SmithyEntity) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val str = (value as? JsonPrimitive)?.contentOrNull ?: return

        val ts = try {
            Instant.parse(str)
        } catch (t: Throwable) {
            return
        }

        val dateTime = ts.toLocalDateTime(options.timezone)
        Text(dateTime.toLocalDateTimeString())
    }

    @Composable
    override fun edit(baseValue: JsonElement?, modification: JsonElement?, options: SmithyViewOptions) {
        SmithyStringEditor(shape as SmithyString).edit(baseValue, modification, options)
    }
}

class EntityTypeViewer(val aggregatorService: AggregatorService, shape: SmithyEntity) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val typeId = value?.jsonPrimitive?.contentOrNull ?: return

        val entityType = aggregatorService.entityType(typeId)
        val display = entityType?.name ?: typeId
        A(href = "#aggregator/types/entities/${typeId}") {
            Text(display)
        }
    }
}

/**
 * Displays milliseconds as a duration in a more human-readable format.
 */
class MillisecondsViewer(shape: SmithyEntity) : CustomRenderer(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val milliseconds = value?.jsonPrimitive?.intOrNull ?: return

        val duration = milliseconds.milliseconds

        Text(duration.toDurationHumanString(true))
        copyContentIcon(milliseconds.toString())
    }
}

/**
 * Custom renderer for the entity access control field
 */
@Composable
fun accessControlRenderer(controls: EntityAccessControl?) {
    val statuses = mutableStateListOf<String>()
    if (controls?.visible != false) {
        statuses += "Visible"
    }
    if (controls?.discoverable != false) {
        statuses += "Discoverable"
    }
    if (controls?.recommendable != false) {
        statuses += "Recommendable"
    }
    if (statuses.isEmpty()) {
        statuses += "Active"
    }
    Text(statuses.joinToString(", "))
}