package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch

/**
 * The main transcription duplicated page.
 */

@Composable
fun transcriptionsDuplicatedPage(returnPath: String? = null) {

    var duplicatedTranscriptions by remember { mutableStateOf(emptyList<TranscriptionJob>()) }
    val coroutineScope = rememberCoroutineScope()
    var loading by mutableStateOf(false)
    var loadingJob by mutableStateOf<Job?>(null)

    suspend fun lookupDuplicatedTranscriptions() {
        try {
            loading = true

            console.log("Begin looking for duplicated transcriptions")
            val duplicated = Application.transcriptionAPI.getDuplicatedTranscriptions()
            val duplicatedJobs = mutableListOf<TranscriptionJob>()
            duplicated.jobs.let { duplicatedJobs.addAll(it) }
            duplicatedTranscriptions = duplicatedJobs

            console.log("Done looking update duplicated transcriptions")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            duplicatedTranscriptions = emptyList()
        } finally {
            loading = false
        }
    }


    box({
        paddedContent = duplicatedTranscriptions.isEmpty() || loading
        header({
            title = "Duplicated Transcriptions"
        })
    }) {
        if (loading) {
            spinner()
        } else if (duplicatedTranscriptions.isEmpty() && loadingJob == null) {
            loadingJob = coroutineScope.launch {
                lookupDuplicatedTranscriptions()
            }
        } else {
            transcriptionJobResultsTab(duplicatedTranscriptions, returnPath ?: "#transcriptions-duplicated")
        }
    }
}

