package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.SXMUI.containerHeaderBackgroundColor
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.JustifyContent.Companion.Left
import org.jetbrains.compose.web.dom.*

object TableStyles : StyleSheet() {
    val table by style {
        width(100.percent)
        property("border-spacing", 0.px)

        type("th").style {
            textAlign(Left.toString())
            padding((0.5).cssRem, (1.0).cssRem)
            backgroundColor(containerHeaderBackgroundColor.value())
            lineHeight(20.px)
            fontSize(14.px)

            border(0.px, LineStyle.Solid, SXMUI.defaultDivider.value())
            borderWidth(1.px, 0.px, 1.px, 0.px)
        }

        type("tbody").style {
            backgroundColor(SXMUI.containerContentBackgroundColor.value())
        }

        type("td").style {
            padding(4.px, 1.cssRem)
            fontSize((0.9).cssRem)
            lineHeight((1.2).cssRem)
        }

        type("tr") + className("odd") style {
            child(self, type("td")) style {
                backgroundColor(containerHeaderBackgroundColor.value())
            }
        }

        className("expanderColumn") style {
            width(25.px)
            padding(4.px, 4.px)
            textAlign("center")

            cursor("pointer")
        }
    }

    val expandableTable by style {
        // clear the padding on the first content cell, since the expander gives it plenty of breathing room.
        type("tbody") style {
            type("tr") style {
                type("td:nth-child(2)") style {
                    paddingLeft(0.px)
                }
            }
        }
    }

    val expanderRow by style {
        child(self, type("td")) style {
            padding(0.px)
        }
    }

    val expanderContent by style {
        padding(4.px, 20.px, 4.px, 0.px)
    }

    val itemRow by style {
        type("td").style {
            border(0.px, LineStyle.Solid, SXMUI.defaultDivider.value())
            borderWidth(1.px, 0.px, 0.px, 0.px)
        }
    }
}

/**
 * Configuration for a table.
 */
interface TableBuilder<T> {
    /**
     * Set the items in the table.
     */
    fun items(items: List<T>?)

    /**
     * Add a column to the table.
     */
    fun column(title: String? = null, columnBuilder: ColumnBuilder<T>.() -> Unit)

    interface ColumnBuilder<T> {
        var title: String?
        var width: CSSNumeric?

        fun style(scope: StyleScope.() -> Unit)

        fun content(renderer: @Composable (T) -> Unit)

        fun headerIcon(name: String, config: IconConfiguration.() -> Unit = {})
    }

    /**
     * Defines that rows are expandable, and provides the rendering function for the item
     */
    fun expandable(renderer: @Composable (T) -> Unit)
}

private class Column<T> : TableBuilder.ColumnBuilder<T> {
    override var title: String? = null
    override var width: CSSNumeric? = null
    var icon: IconConfiguration? = null

    var itemRenderer: (@Composable (T) -> Unit)? = null
    var cellStyle: (StyleScope.() -> Unit)? = null

    override fun content(renderer: @Composable (T) -> Unit) {
        itemRenderer = renderer
    }

    override fun headerIcon(name: String, config: IconConfiguration.() -> Unit) {
        icon = IconConfiguration(name).apply {
            size = IconSize.TINY
        }.apply(config)
    }

    override fun style(scope: StyleScope.() -> Unit) {
        cellStyle = scope
    }
}

private class Table<T> : TableBuilder<T> {
    val columns = mutableListOf<Column<T>>()
    var items: List<T>? = null
    var alternatingRows = true

    var expandingRenderer: (@Composable (T) -> Unit)? = null

    override fun items(items: List<T>?) {
        this.items = items
    }

    override fun column(title: String?, columnBuilder: TableBuilder.ColumnBuilder<T>.() -> Unit) {
        val column = Column<T>()
        column.title = title

        column.columnBuilder()

        columns.add(column)
    }

    override fun expandable(renderer: @Composable (T) -> Unit) {
        expandingRenderer = renderer
    }
}


/**
 * Render a table. The table is configured via the TableBuilder interface.
 */
@Composable
fun <T> table(builder: TableBuilder<T>.() -> Unit) {
    var expanded by mutableStateOf(emptyList<T>())

    val table = Table<T>()

    table.builder()

    if (table.columns.isNotEmpty() && !table.items.isNullOrEmpty()) {
        Table({
            classes(TableStyles.table)
            if (table.expandingRenderer != null) {
                classes(TableStyles.expandableTable)
            }
        }) {
            val hasColumnHeaders = table.columns.any { it.title != null }
            if (hasColumnHeaders) {
                Thead {
                    Tr {
                        if (table.expandingRenderer != null) {
                            // add the blank column for the expanding icon
                            Th {}
                        }
                        table.columns.forEach { column ->
                            Th({
                                style {
                                    column.width?.let {
                                        this.width(it)
                                    }

                                    if (column.title == null && column.icon != null) {
                                        textAlign("center")
                                    }
                                }
                            }) {
                                column.title?.let {
                                    Text(it)
                                }
                                column.icon?.let {
                                    icon(it)
                                }
                            }
                        }
                    }
                }
            }

            Tbody {
                table.items?.forEachIndexed { index, item ->
                    val isExpanded = expanded.contains(item)

                    Tr({
                        classes(TableStyles.itemRow)
                        if (index % 2 != 0 && table.alternatingRows) {
                            classes("odd")
                        }
                    }) {
                        if (table.expandingRenderer != null) {
                            Td({
                                classes("expanderColumn")

                                onClick {
                                    expanded = if (isExpanded) {
                                        expanded - item
                                    } else {
                                        expanded + item
                                    }
                                }
                            }) {
                                if (isExpanded) {
                                    icon("expand_more")
                                } else {
                                    icon("chevron_right")
                                }
                            }
                        }

                        table.columns.forEach { column ->
                            Td({
                                style {
                                    column.width?.let {
                                        this.width(it)
                                    }
                                    column.cellStyle?.invoke(this)
                                }
                            }) {
                                column.itemRenderer?.invoke(item)
                            }
                        }
                    }

                    if (table.expandingRenderer != null) {
                        Tr({
                            classes(TableStyles.expanderRow)
                            if (index % 2 != 0) {
                                classes("odd")
                            }
                        }) {
                            // first a blank column for the expander
                            Td {}

                            Td({
                                this.colspan(table.columns.size)
                            }) {
                                if (isExpanded) {
                                    Div({
                                        classes(TableStyles.expanderContent)
                                    }) {
                                        table.expandingRenderer?.invoke(item)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
