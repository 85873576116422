package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch

/**
 * The main transcription failed page.
 */

@Composable
fun transcriptionsFailedPage(returnPath: String? = null) {

    var failedTranscriptions by remember { mutableStateOf(emptyList<TranscriptionJob>()) }
    val coroutineScope = rememberCoroutineScope()
    var loading by mutableStateOf(false)
    var loadingJob by mutableStateOf<Job?>(null)

    suspend fun lookupFailedTranscriptions() {
        try {
            loading = true

            console.log("Begin looking for failed transcriptions")
            val failed = Application.transcriptionAPI.getFailedTranscriptions()
            val failedJobs = mutableListOf<TranscriptionJob>()
            failed.jobs.let { failedJobs.addAll(it) }
            failedTranscriptions = failedJobs

            console.log("Done looking update failed transcriptions")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            failedTranscriptions = emptyList()
        } finally {
            loading = false
        }
    }

    box({
        paddedContent = failedTranscriptions.isEmpty() || loading
        header({
            title = "Failed Transcriptions"
        })
    }) {
        if (loading) {
            spinner()
        } else if (failedTranscriptions.isEmpty() && loadingJob == null) {
            loadingJob = coroutineScope.launch {
                lookupFailedTranscriptions()
            }
        } else {
            transcriptionJobResultsTab(failedTranscriptions, returnPath ?: "#transcriptions-failed")
        }
    }
}

