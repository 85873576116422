package com.siriusxm.pia.utils

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement

inline fun <reified I> Json.recode(el: JsonElement): JsonElement {
    val decoded: I = decodeFromJsonElement<I>(el)
    return encodeToJsonElement(decoded)
}

inline fun <reified I, reified O> Json.recode(input: I): O {
    val inputEncoded = encodeToJsonElement(input)
    return decodeFromJsonElement(inputEncoded)
}
