package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.typeSelect
import contentingestion.aggregator.ConsumerDetails
import contentingestion.aggregator.Contact
import org.jetbrains.compose.web.dom.Text

@Composable
fun consumerEditor(aggregator: AggregatorService, consumer: ConsumerDetails? = null) {
    var updatedConsumer by remember { mutableStateOf(consumer ?: ConsumerDetails("", "")) }

    dialogView(if (consumer != null) "Edit Consumer" else "Create Consumer") {
        suspend fun save(): Boolean {
            return validate {
                assertField(updatedConsumer.id.length >= 5, "The id must be at least 5 characters")
                assertField(updatedConsumer.name.length >= 5, "The name must be at least 5 characters")
                orElse {
                    try {
                        if (consumer == null) {
                            aggregator.newConsumer(updatedConsumer)
                        } else {
                            aggregator.updateConsumer(updatedConsumer)
                        }
                        aggregator.refresh()
                    } catch (t: Throwable) {
                        errors.error("Unable to save consumer: ${t.message}")
                    }

                }
            }.success
        }

        content {
            dialogField(
                "ID",
                if (consumer == null) "Enter a unique id. The preferred format is `sxm/<workload-name>/<service-name>." else null
            ) {
                if (consumer == null) {
                    simpleTextField(updatedConsumer.id) {
                        updatedConsumer = updatedConsumer.copy(id = it ?: "")
                    }
                } else {
                    Text(consumer.id)
                }
            }

            dialogField("Name", "A human readable name of the consumer (i.e. Playback Services)") {
                simpleTextField(updatedConsumer.name) {
                    updatedConsumer = updatedConsumer.copy(name = it.orEmpty())
                }
            }

            dialogField("Types", "This list of types that this consumer is interested in.") {
                scrollableCheckBoxSelector(
                    items = aggregator.entityTypes,
                    selectedItems = updatedConsumer.types.orEmpty().toSet(),
                    itemLabel = { it.name },
                    itemIdentifier = { it.type},
                    onSelectionChange = { newTypes ->
                        updatedConsumer = updatedConsumer.copy(
                            types = newTypes.toList()
                        )
                    }
                )
            }
//
//            dialogField("Targets", "A list of ARNs of targets for streaming data. Include all regions.") {
//                TextArea(updatedConsumer.targets.orEmpty().joinToString("\n")) {
//                    style {
//                        height(100.px)
//                    }
//                    onInput {
//                        updatedConsumer = updatedConsumer.copy(
//                            targets = it.value.ifBlank { null }?.split("\n")?.map { it.trim() }.orEmpty()
//                        )
//                    }
//                }
//            }

            dialogField("AWS Accounts", "The AWS accounts of this service.") {
                simpleTextField(updatedConsumer.accounts?.joinToString(",")) {
                    updatedConsumer = updatedConsumer.copy(
                        accounts = it?.split(",")?.map { it.trim() }
                    )
                }
            }

            dialogField("Contact Emails", "A comma separated list of emails of contacts for this consumer.") {
                simpleTextField(updatedConsumer.contact?.joinToString(",") { it.email }) {
                    updatedConsumer = updatedConsumer.copy(contact = it?.split(",")?.map { it.trim() }?.map {
                        Contact(it)
                    })
                }
            }

        }

        action {
            title = "Submit"
            primary = true
            showProgressOnAction = true
            this.action {
                if (save()) {
                    aggregator.navigate("consumers/${encodeURIComponent(updatedConsumer.id)}")
                }
            }
        }
        action("Cancel") {
            aggregator.navigate(if (consumer != null) "consumers/${encodeURIComponent(consumer.id)}" else "consumers")
        }
    }
}