package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.client.Feed
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.dialogView
import com.siriusxm.pia.components.messageBox
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.waitForShowSourceUpdate
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun removeFeed(feedUrl: String, returnPath: String? = null) {
    var feed by remember { mutableStateOf<Feed?>(null) }
    var confirmation by remember { mutableStateOf("") }

    LaunchedEffect(feedUrl) {
        Application.api.query {
            feed(feedUrl) {
                this.url
                this.bundles {
                    id
                }
                this.active
                show {
                    id
                    title
                    sources {
                        this.url
                        bundles {
                            id
                        }
                    }
                }
            }
        }.feed.let {
            feed = it
        }
    }

    suspend fun detach() {
        Application.api.mutate {
            feed(feedUrl) {
                detach(feed?.show?.id!!)
            }
        }.feed.detach
    }

    dialogView("Detach Feed") {
        action {
            title = "Cancel"
            action {
                Application.navigation.navigate(returnPath ?: "feeds/${encodeURIComponent(feedUrl)}")
            }
        }

        if (confirmation == "detach") {
            action {
                title = "Detach"
                primary = true
                showProgressOnAction = true
                action {
                    try {
                        detach()

                        waitForShowSourceUpdate(feed?.show?.id!!, feedUrl, false)
                        Application.navigation.navigate(returnPath ?: "feeds/${encodeURIComponent(feedUrl)}")
                    } catch (t: Throwable) {

                    }
                }
            }
        }

        content {
            messageBox(
                """When you remove a feed, it will be detached from the show, but remain 
                    in the system as a disabled feed"""
            )

            box({
                title = "Detach Feed from ${feed?.show?.title}?"
            }) {
                P {
                    Text("This operation will detach the following feed from ${feed?.show?.title}:")
                }
                P {
                    A(href = "#feed/${encodeURIComponent(feedUrl)}", {
                        style {
                            property("line-break", "anywhere")
                        }
                    }) {
                        Text(feedUrl)
                    }
                }
                P {
                    Text("To confirm, type 'detach' in the field below")
                }
                Input(InputType.Text) {
                    onInput { event ->
                        confirmation = event.value
                    }
                }
            }
        }
    }
}