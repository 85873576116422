package com.siriusxm.pia.utils

import kotlinx.serialization.json.*

inline fun <reified I> Json.recode(el: JsonElement): JsonElement {
    val decoded: I = decodeFromJsonElement<I>(el)
    return encodeToJsonElement(decoded)
}

inline fun <reified I, reified O> Json.recode(input: I): O {
    val inputEncoded = encodeToJsonElement(input)
    return decodeFromJsonElement(inputEncoded)
}

/**
 * Get the string value with the given key.
 */
fun JsonObject.getString(key: String): String? {
    return get(key)?.jsonPrimitive?.contentOrNull
}

fun JsonElement.getString(key: String): String? {
    return (this as? JsonObject)?.getString(key)
}

/**
 * Get the float value with the given key.
 */
fun JsonObject.getFloat(key: String): Float? {
    return get(key)?.jsonPrimitive?.floatOrNull
}

fun JsonElement.getFloat(key: String): Float? {
    return (this as? JsonObject)?.getFloat(key)
}