package com.siriusxm.pia.views.channelguide

import com.siriusxm.pia.Application
import com.siriusxm.pia.rest.epg.*
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import contentingestion.unifiedmodel.Episode
import contentingestion.unifiedmodel.Show
import kotlinx.browser.localStorage
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.jsonObject
import org.w3c.dom.get
import org.w3c.dom.set

/**
 * Main processing logic for the EPG.
 */
data class EPG(
    val api: EPGApiClient,
    val aggregatorClient: UnifiedAggregatorClient
) {
    val json = Json {
        ignoreUnknownKeys = true
    }

    /**
     * The list of channels, which are cached locally.
     */
    var channelDetails: List<ChannelDetailSummary> = localStorage["epg-channels"]?.let {
        epgJson.decodeFromString<List<ChannelDetailSummary>>(it)
    }.orEmpty()
        set(value) {
            localStorage["epg-channels"] = epgJson.encodeToString(value)
            field = value
        }

    /**
     * The list of categories.
     */
    var categories: List<Category> = localStorage["epg-categories"]?.let {
        epgJson.decodeFromString<List<Category>>(it)
    }.orEmpty()
        set(value) {
            localStorage["epg-categories"] = epgJson.encodeToString(value)
            field = value
        }

    suspend fun initData(cb: suspend (List<ChannelDetailSummary>, List<Category>) -> Unit) {
        var changed = false
        coroutineScope {
            launch {
                val newChannelDetails = fetchAllChannels().map {
                    it.channel
                }.sortedBy { it.channelNumber }

                if (newChannelDetails != channelDetails) {
                    console.log("Channels updated")
                    channelDetails = newChannelDetails
                    changed = true
                }
            }
            launch {
                val newCategories = fetchAllCategories().sortedBy { it.term }
                if (newCategories != categories) {
                    console.log("Categories updated")
                    categories = newCategories
                    changed = true
                }
            }
        }
        if (changed) {
            cb(channelDetails, categories)
        }
    }

    /**
     * Navigate to the path (relative to the channel guide path, so don't start with "channelguide")
     */
    fun navigate(path: String) {
        Application.navigation.navigate("channelguide/${path.removePrefix("channelguide/")}")
    }

    suspend fun fetchAllChannels(cutSince: Instant? = null): List<ChannelSummary> {
        var token: String? = null

        val results = mutableListOf<ChannelSummary>()
        do {
            api.getChannels(null, null, 500, token, cutSince?.toString()).also {
                results += it.channels.map {
                    it.copy(channel = it.channel.copy(categories = it.categories))
                }
                token = it.paginationToken
            }
        } while (token != null)

        if (cutSince == null) {
            channelDetails = results.map {
                it.channel
            }
        }
        return results
    }

    private suspend fun getAllPaginated(cb: suspend (String?) -> PaginatedResult): PaginatedResult {
        var token: String? = null

        val results = mutableListOf<JsonObject>()
        do {
            cb(token).also {
                results += it.entities.map { elem -> elem.jsonObject }
                token = it.token
            }
        } while (token != null)

        return PaginatedResult(results, null)
    }

    private suspend fun getAllChannelsPaginated(cb: suspend (String?) -> ChannelResult): ChannelResult {
        var token: String? = null

        val results = mutableListOf<ChannelSummary>()
        do {
            cb(token).also {
                results += it.channels
                token = it.paginationToken
            }
        } while (token != null)

        return ChannelResult(results, null)
    }

    suspend fun fetchAllCategories(): List<Category> {
        return getAllPaginated {
            api.getChannelCategories(250, it)
        }.decode<Category>().entities
    }

    suspend fun fetchAllLineups(): List<LineupSummary> {
        return getAllPaginated {
            api.getLineups(500, it)
        }.decode<LineupSummary>().entities
    }

    suspend fun fetchChannel(channelId: String): ChannelSummary {
        return api.getChannel(channelId)
    }

    suspend fun fetchShowsForChannel(channelId: String): List<Show> {
        return api.getChannelShows(channelId).decode<Show>().entities
    }

    suspend fun fetchLinearEpisodesForChannel(channelId: String): List<Episode> {
        return getAllPaginated {
            api.getChannelEpisodes(channelId, "episode-linear", paginationToken = it)
        }.decode<Episode>().entities
    }

    suspend fun fetchAudioEpisodesForChannel(channelId: String): List<Episode> {
        return getAllPaginated {
            api.getChannelEpisodes(channelId, "episode-audio", paginationToken = it)
        }.decode<Episode>().entities
    }

    suspend fun fetchShow(showId: String): Show? {
        val showJson = aggregatorClient.outgoing(showId, "show", "0.1")
        return showJson?.let { json.decodeFromJsonElement<Show>(it) }
    }

    suspend fun fetchEpisode(episodeId: String): Episode? {
        val episodeJson = aggregatorClient.outgoingById(episodeId)?.get(0)?.jsonObject
        return episodeJson?.let { json.decodeFromJsonElement<Episode>(it) }
    }

    suspend fun fetchChannelsForLineup(lineupId: String): List<ChannelSummary> {
        return getAllChannelsPaginated {
            api.getChannels(lineupId = lineupId, paginationToken = it)
        }.channels
    }

    suspend fun fetchChannelsForCategory(categoryId: String): List<ChannelSummary> {
        return getAllChannelsPaginated {
            api.getChannels(categoryId = categoryId, paginationToken = it)
        }.channels
    }
}