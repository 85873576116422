package com.siriusxm.pia.components

import org.jetbrains.compose.web.css.*

fun StyleScope.border(
    top: CSSNumeric = 0.px,
    right: CSSNumeric = 0.px,
    bottom: CSSNumeric = 0.px,
    left: CSSNumeric = 0.px,
    color: CSSColorValue,
    style: LineStyle = LineStyle.Solid,
) {
    border {
        color(color)
        style(style)
    }
    borderWidth(top, right, bottom, left)
}