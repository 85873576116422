// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.artistradio

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlin.collections.Map

@OptIn(InternalKtorfitApi::class)
public class _ArtistStationClientImpl : ArtistStationClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun fetchArtistStation(entityId: String, headerMap: Map<String, String>):
      ArtistStationSummary? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "artistStation/${"$entityId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.artistradio.ArtistStationSummary?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.artistradio.ArtistStationSummary?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.artistradio.ArtistStationSummary?,
        com.siriusxm.pia.rest.artistradio.ArtistStationSummary?>(_typeData,_ext)
  }

  override suspend fun setFlags(
    entityId: String,
    attribute: String,
    attributeValue: String,
    headerMap: Map<String, String>,
  ): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("PUT")
        url{
        takeFrom(_converter.baseUrl +
            "artistStation/${"$entityId".encodeURLPath()}/${"$attribute".encodeURLPath()}/${"$attributeValue".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun hideArtistStation(entityId: String, headerMap: Map<String, String>): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("PUT")
        url{
        takeFrom(_converter.baseUrl + "artistStation/${"$entityId".encodeURLPath()}/hide")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun showArtistStation(entityId: String, headerMap: Map<String, String>): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("PUT")
        url{
        takeFrom(_converter.baseUrl + "artistStation/${"$entityId".encodeURLPath()}/show")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun status(): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "status")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun statusAuthenticated(headerMap: Map<String, String>): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "status_authenticated")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createArtistStationClient(): ArtistStationClient =
    this.create(_ArtistStationClientImpl().apply { _converter=
    KtorfitConverterHelper(this@createArtistStationClient) })
