package com.siriusxm.pia

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.HomeStyles
import com.siriusxm.pia.views.applicationsList
import com.siriusxm.pia.views.channelguide.ChannelGuideStyles
import com.siriusxm.pia.views.podcasts.EpisodeStyles
import com.siriusxm.pia.views.sports.SportsStyles
import com.siriusxm.pia.views.unifiedaggregator.AggregatorStyles
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

object HeaderStyles : StyleSheet() {
    val header by style {
        height(40.px)
        backgroundImage("url(/images/Blue_Purple_All.jpg)")
        color(Color.white)

        display(DisplayStyle.Flex)

        position(Position.Relative)
        boxShadow("0px 3px 6px rgba(0,0,0,.4)")
        property("z-index", "1")

        child(self, type("div")).style {
        }
    }

    val mainTitle by style {
        lineHeight(40.px)
        fontWeight("bold")
        flexGrow(1)
    }

    val servicesButton by style {
        lineHeight(40.px)
        width(40.px)
        flexGrow(0)
        cursor("pointer")
    }

    val accountActions by style {
        lineHeight(40.px)
        textAlign("right")
        paddingRight(10.px)
        flexGrow(1)

        fontSize((0.8).cssRem)

        type("a").style {
            color(Color.white)

            self + hover style {
                color(SXMUI.highlightColor.value())
                textDecoration("none")
            }
        }
    }
}

/**
 * The root view of the entire application
 */
@Composable
fun renderPage(route: Route) {
    // Install the global styles. This is a topic of improvement for Compose Web.
    Style(HeaderStyles)
    Style(HomeStyles)
    Style(EntityViewStyles)
    Style(ServiceViewStyles)
    Style(AggregatorStyles)
    Style(DialogStyles)
    Style(DetailBoxStyles)
    Style(ButtonStyles)
    Style(Icons)
    Style(PillStyles)
    Style(PaginatorStyles)
    Style(BoxStyles)
    Style(BreadCrumbStyles)
    Style(MessageBoxStyles)
    Style(AudioPlayerStyles)
    Style(EpisodeStyles)
    Style(NotificationStyles)
    Style(SplitSelectionViewStyles)
    Style(SportsStyles)
    Style(ChannelGuideStyles)

    Application.notifications = notifications()

    var showingServicesPopup by remember { mutableStateOf(false) }

    LaunchedEffect(route.fullPath) {
        showingServicesPopup = false
    }

    Div({
        style {
            paddingBottom(24.px)
        }
        onClick {
            showingServicesPopup = false
        }
    }) {
        Div({
            classes(HeaderStyles.header)
        }) {
            A(href = "#") {
                Img("images/SiriusXM-Logo-onDark.svg", attrs = {
                    style {
                        height(100.percent)
                        display(DisplayStyle.InlineBlock)
                        property("vertical-align", "middle")
                        marginRight(20.px)
                        opacity(50.percent)
                    }
                })
            }

            Div({ classes(HeaderStyles.servicesButton) }) {
                icon("apps") {
                    action { showingServicesPopup = !showingServicesPopup }
                }
            }

            Div({ classes(HeaderStyles.mainTitle) }) {
                Text("Content Management Console")
            }

            Div({ classes(HeaderStyles.accountActions) }) {
                Application.viewer.email?.let {
                    Span({
                        style {
                            marginRight(10.px)
                        }
                    }) {
                        Text(it)
                    }
                }

                A(href = "#", {
                    onClick {
                        it.preventDefault()
                        Application.cognito.logout()
                    }
                }) {
                    Text("Sign Out")
                }
            }
        }

        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                position(Position.Relative)
                minHeight("calc(100vh - 40px)")
            }
        }) {
            Div({
                style {
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Stretch)
                    flex(1, 1)
                    flexBasis("auto")
                    backgroundColor(SXMUI.mainLayoutBackgroundColor.value())
                }
            }) {
                applicationRouting(route)
            }
        }
    }

    if (showingServicesPopup) {
        Div({
            style {
                position(Position.Absolute)
                top(40.px)
                left(225.px)
                backgroundColor(Color.black)
                boxShadow("0px 0px 5px rgba(0,0,0,.4)")
            }
        }) {
            applicationsList()
        }
    }

    Div({
        style {
            position(Position.Fixed)
            bottom(0.px)
            left(0.px)
            right(0.px)
        }
    }) {
        Application.player = audioPlayer()
    }
}
