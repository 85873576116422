package com.siriusxm.pia.components


@JsModule("chart.js/auto")
@JsNonModule
external object Chart {
    fun register(vararg components: dynamic)

    class Chart(ctx: dynamic, config: ChartConfiguration) {
        fun update()
        fun destroy()
    }
}

@JsModule("chart.js")
@JsNonModule
external object ChartComponents {
    val LineController: dynamic
    val LineElement: dynamic
    val PointElement: dynamic
    val TimeScale: dynamic
    val LinearScale: dynamic
    val Legend: dynamic
    val Tooltip: dynamic
}

@JsModule("chartjs-adapter-date-fns")
@JsNonModule
external object ChartjsAdapterDateFns

external interface ChartConfiguration {
    var type: String
    var data: ChartData
    var options: ChartOptions?
}

external interface ChartData {
    var labels: Array<String>?
    var datasets: Array<Dataset>
}

external interface Dataset {
    var label: String?
    var data: Array<DatasetPoint>
    var borderColor: String?
    var backgroundColor: Array<String>?
}

external interface ChartOptions {
    var responsive: Boolean?
    var maintainAspectRatio: Boolean?
    var devicePixelRatio: Number?
    var scales: Scales?
    var plugins: Plugins?
}

external interface Plugins {
    var legend: Legend?
}

external interface Legend {
    var display: Boolean?
}

external interface Scales {
    var x: Axis?
    var y: Axis?
}

external interface Axis {
    var type: String?
    var time: TimeOptions?
    var title: AxisTitle?
    var stacked: Boolean?
}

external interface TimeOptions {
    var unit: String?
}

external interface AxisTitle {
    var display: Boolean?
    var text: String?
}

external interface DatasetPoint {
    var x: String?
    var y: Number?
}

fun <T : Any> new(init: T.() -> Unit): T {
    val result = js("({})")
    init.invoke(result)
    return result
}

