package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.channelguide.aggregatorIconLink
import com.siriusxm.unifiedcontent.sports.Airing
import com.siriusxm.unifiedcontent.sports.CompetitionParticipantStatus
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.BoxScore
import contentingestion.unifiedmodel.ExtendedMetadata
import contentingestion.unifiedmodel.Score
import contentingestion.unifiedmodel.ScoreState
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.seconds

data class BoxScoreRow(
    val teamId: String,
    val teamName: String,
    val imageUrl: String?,
    val periodValues: List<String>,
    val finalValue: String
)

@Composable
fun scoresForEvent(app: SportsApplication, eventId: String, autoPolling: Boolean = false) {
    var event by remember { mutableStateOf<CompetitiveEvent?>(null) }
    var homeTeam by remember { mutableStateOf<CompetitionParticipantStatus?>(null) }
    var awayTeam by remember { mutableStateOf<CompetitionParticipantStatus?>(null) }
    var scores by remember { mutableStateOf<List<Score>?>(null) }
    var polling by remember { mutableStateOf(autoPolling) }

    var showReplayDialog by remember { mutableStateOf(false) }
    var orchestratingReplay by remember { mutableStateOf(false) }
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(eventId) {
        event = app.client.getCompetitionEvent(eventId)

        if (event?.state == ScoreState.IN_PROGRESS) {
            polling = true
        }

        if (event != null) {
            homeTeam = event?.homeTeam()
            awayTeam = event?.awayTeam()
        }
        scores = app.client.getCompetitionScoreEvents(eventId).entities

        if (polling) {
            do {
                delay(10.seconds)

                // in case the event changed state
                val updatedEvent = app.client.getCompetitionEvent(eventId)
                homeTeam = updatedEvent.homeTeam()
                awayTeam = updatedEvent.awayTeam()
                event = updatedEvent

                // in case we have any new scores
                scores = app.client.getCompetitionScoreEvents(eventId).entities

            } while (polling && !eventIsFinal(event, scores))
            polling = false

            // fetch one more time for good measure
            event = app.client.getCompetitionEvent(eventId)
        }
    }

    @Composable
    fun boxScoreItem(score: Score) {
        Div {
            val boxScore: BoxScore? =
                score.extendedMetadata?.baseball?.boxScore ?: score.extendedMetadata?.basketball?.boxScore
                ?: score.extendedMetadata?.football?.boxScore ?: score.extendedMetadata?.hockey?.boxScore
            if (boxScore != null) {
                val boxScoreRows = event?.toBoxScoreRows(app, boxScore).orEmpty()
                val columnTitles = boxScore.periods?.map { it.periodName }
                boxTable<BoxScoreRow> {
                    items(boxScoreRows)
                    column {
                        content {
                            Div({
                                style {
                                    display(DisplayStyle.Flex)
                                    flexDirection(FlexDirection.Row)
                                    alignItems(AlignItems.Center)
                                }
                            }) {
                                Div({ classes(SportsStyles.metadata) }) {
                                    Div({ classes(SportsStyles.entityInfo) }) {
                                        A("#sports/team/${it.teamId}") {
                                            Text(it.teamName)
                                        }
                                    }
                                }
                            }
                        }
                    }

                    columnTitles?.forEachIndexed { index, columnName ->
                        column(title = columnName) {
                            content {
                                Div({ classes(SportsStyles.entityInfo) }) {
                                    Text(
                                        it.periodValues.getOrNull(index) ?: ""
                                    )
                                }
                            }
                        }
                    }

                    if (score.state == ScoreState.FINAL) {
                        // Spacing between period scores and final score
                        column("") { }

                        column("F") {
                            content {
                                Div({ classes(SportsStyles.entityInfo) }) { Text(it.finalValue) }
                            }
                        }
                    }
                }
            }
        }
    }

    @Composable
    fun participantStatus(participantStatus: CompetitionParticipantStatus) {
        Div {
            Text(participantStatus.competitor.name)
        }
        Div {
            Text(participantStatus.value?.toString() ?: "")
        }
    }

    serviceContentView({
        actionArea {
            aggregatorIconLink(eventId, IconSize.SMALL, showOnlyOnHover = false)
        }

        breadcrumbs {
            crumb("Sports", "sports")
            val leagueId = event?.participants()?.map { it.league }?.firstOrNull()
            val league = leagueId?.let { app.leagueById(it) }
            if (league != null) {
                crumb(league.name, "sports/leagues/${league.id}")
            }
        }

    }) {
        if (event == null && scores?.isEmpty() == true) {
            messageBox(
                "No entity with id $eventId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (event == null) {
            spinner(size = Size.LARGE)
        } else if (orchestratingReplay) {
            spinner(size = Size.LARGE)
        } else {
            val latestScore = scores?.latestScoreWithBoxScore()
            if (homeTeam != null && awayTeam != null) {
                Div({
                    classes(SportsStyles.fullEvent)
                }) {

                    participantHeader(app, awayTeam!!)
                    Div({
                        style {
                            textAlign("center")
                        }
                    }) {
                        Div({
                            style {
                                fontSize(32.px)
                                fontWeight(700)
                                marginTop(50.px)
                                marginBottom(20.px)
                            }
                        }) {
                            Text("@")
                        }
                        Div {
                            val status = if (event.live()) {
                                scores?.sortedByDescending { it.timestamp }?.firstOrNull()?.period
                            } else null

                            (status ?: event?.state?.name)?.let {
                                Text(it)
                            }

                            renderAccessControls(event?.accessControls)
                        }

                    }
                    participantHeader(app, homeTeam!!)
                }
            }

            val startTime = event?.start?.toLocalDateTime()?.toLocalDateTimeString(false)
            if (startTime != null) {
                detailGrid {
                    detail("Start Time") {
                        Text(startTime)
                    }
                }
            }

            if (latestScore != null && homeTeam != null && awayTeam != null) {
                boxScoreItem(score = latestScore)
            }

            val airings = event?.distinctAirings()
            if (airings != null) {
                boxTable<Airing> {
                    items(airings.sortedBy { it.channelNumber })
                    column("Airings") {
                        content {
                            A(href = "#channelguide/channels/${it.channelId}") {
                                Text(it.channelName ?: "")
                            }
                        }
                    }
                    column("Channel Number") {
                        content {
                            Text(it.channelNumber?.toString() ?: "")
                        }
                    }
                    column("Coverage") {
                        content {
                            Text(it.coverageType.name)
                        }
                    }
                }
            }

            if (scores?.isNotEmpty() == true) {
                box({
                    paddedContent = false
                }) {
                    Div({ classes(SportsStyles.scoresList) }) {
                        val sortedScores = scores?.sortedByDescending { it.timestamp }.orEmpty()
                        sortedScores.forEachIndexed { index, score ->
                            val nextScore: Score? = sortedScores.getOrNull(index + 1)
                            Div({ classes(SportsStyles.scoresListItem) }) {
                                Div({
                                    classes(SportsStyles.scoreValue)
                                    if (nextScore?.awayScore == score.awayScore || index == 0) {
                                        classes(SportsStyles.unchanged)
                                    }
                                }) {
                                    score.awayScore?.let {
                                        Text(it.toString())
                                    }
                                }
                                Div({ classes(SportsStyles.scoreStatus) }) {
                                    tooltip({
                                        score.timestamp?.let { Text(it) }
                                    }, position = TooltipLocation.RIGHT) {
                                        Div {
                                            (score.period?.ifBlank { null }?.let {
                                                getPeriod(it)
                                            } ?: score.state?.name)?.let {
                                                Text(it)
                                            }
                                            aggregatorIconLink(score.id)
                                        }
                                        Div {
                                            score.extendedMetadata?.baseball?.outs?.let {
                                                Text("$it Out")
                                            }
                                        }
                                    }
                                }
                                Div({
                                    classes(SportsStyles.scoreValue)
                                    if (nextScore?.homeScore == score.homeScore || index == 0) {
                                        classes(SportsStyles.unchanged)
                                    }
                                }) {
                                    score.homeScore?.let {
                                        Text(it.toString())
                                    }
                                }
                            }
                        }
                    }
                }

                if (showReplayDialog) {
                    replayDialog(app, eventId) { value ->
                        showReplayDialog = false
                        if (value != null) {
                            console.log("Replay dialog returned ", value)
                            orchestratingReplay = true

                            coroutineScope.launch {
                                try {
                                    app.client.replayCompetition(
                                        eventId, value
                                    )
                                } finally {
                                    orchestratingReplay = false
                                }
                            }
                        }
                    }
                } else {
                    if (event.completed()) {
                        button("Replay Event") {
                            showReplayDialog = true
                        }
                    }
                }
            } else {
                event?.status?.scores?.let { scoreStatus ->
                    boxTable {
                        items(scoreStatus)
                        column {
                            content { participantStatus ->
                                participantStatus(participantStatus)
                            }
                        }
                    }
                }
            }
        }
    }
}

fun CompetitiveEvent?.distinctAirings(): List<Airing>? {
    // deal with the fact that sometimes we get duplicates for the same channel id
    return this?.airings?.distinctBy { it.channelId }?.ifEmpty {
        null
    }
}

fun CompetitiveEvent?.homeTeam(): CompetitionParticipantStatus? {
    val homeTeamId = this?.host
    if (homeTeamId != null) {
        this?.status?.scores?.forEach {
            if (it.competitor.id == homeTeamId) {
                return it
            }
        }
        return null
    } else {
        return this?.status?.scores?.get(1)
    }
}

fun CompetitiveEvent?.awayTeam(): CompetitionParticipantStatus? {
    val homeTeamId = this?.host
    if (homeTeamId != null) {
        this?.status?.scores?.forEach {
            if (it.competitor.id != homeTeamId) {
                return it
            }
        }
        return null
    } else {
        return this?.status?.scores?.first()
    }
}

fun CompetitiveEvent?.name(): String {
    if (this == null) return ""
    return "${awayTeam()?.competitor?.name} @ ${homeTeam()?.competitor?.name}"
}

fun eventIsFinal(event: CompetitiveEvent?, entities: List<Score>?): Boolean {
    if (entities == null) return false
    if (event == null) return false

    if (event.state == ScoreState.FINAL) {
        return true
    }
    return entities.any { it.state == ScoreState.FINAL }
}


fun CompetitiveEvent?.live(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.IN_PROGRESS).contains(this.state)
}

fun CompetitiveEvent?.completed(): Boolean {
    if (this == null) return false

    if (this.state == ScoreState.FINAL || this.state == ScoreState.TERMINATED) {
        return true
    }
    return false
}

fun CompetitiveEvent?.upcoming(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.PRE_GAME, ScoreState.SCHEDULED, ScoreState.DELAYED).contains(this.state)
}

fun CompetitiveEvent?.liveAndUpcoming(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.PRE_GAME, ScoreState.IN_PROGRESS).contains(this.state)
}

fun CompetitiveEvent?.future(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.SCHEDULED, ScoreState.DELAYED).contains(this.state)
}

private fun List<Score>.latestScoreWithBoxScore(): Score? {
    return this.sortedByDescending { it.timestamp }.firstOrNull { it.extendedMetadata?.boxScore() != null }
}

private fun ExtendedMetadata?.boxScore(): BoxScore? {
    return this?.hockey?.boxScore ?: this?.baseball?.boxScore ?: this?.basketball?.boxScore ?: this?.football?.boxScore
}

private fun getPeriod(period: String): String {
    val parsedPeriod = parsePeriod(period)
    val num = parsedPeriod.second.toString()
    val ordinal = getOrdinal(parsedPeriod.second)

    return when (parsedPeriod.first) {
        "q" -> "$ordinal Quarter"
        "ht" -> "Halftime"
        "numot" -> "${num}OT"
        "f" -> "Final"
        "fot" -> "Final/${num}OT"
        "p" -> "$ordinal Period"
        "i" -> "$ordinal Intermission"
        "so" -> "Shootout"
        "fso" -> "Final/Shootout"
        "h" -> "$ordinal Half"
        "t" -> "Top $ordinal"
        "m" -> "Middle $ordinal"
        "b" -> "Bottom $ordinal"
        "e" -> "End $ordinal"
        "fnum" -> "Final/$num"
        else -> period
    }
}

private fun parsePeriod(input: String): Pair<String, Int> {
    var letters = ""
    var number = 0

    input.forEach { char ->
        if (char.isDigit()) {
            number = number * 10 + char.digitToInt()
        } else if (char.isLetter()) {
            letters += char
        }
    }

    // Return a Pair containing the letters and the number
    return Pair(mapKey(letters, number), number)
}

private fun mapKey(key: String, num: Int): String {
    return when {
        key == "OT" -> "numot"
        key == "F" && num > 0 -> "fnum"
        else -> key.lowercase()
    }
}

private fun getOrdinal(number: Int): String {
    val suffix = when {
        number % 100 in 11..13 -> "th" // Special case for 11th, 12th, 13th
        number % 10 == 1 -> "st"       // 1st, 21st, 31st, etc.
        number % 10 == 2 -> "nd"       // 2nd, 22nd, 32nd, etc.
        number % 10 == 3 -> "rd"       // 3rd, 23rd, 33rd, etc.
        else -> "th"                   // 4th, 5th, 6th, etc.
    }
    return "$number$suffix"
}

@Composable
fun participantHeader(app: SportsApplication, participantStatus: CompetitionParticipantStatus,
                      renderStatus: Boolean = true) {
    val participant = participantStatus.competitor
    Div({
        style {
            flex(1)
            textAlign("center")
            fontSize(32.px)
            fontWeight(800)
            marginBottom(24.px)
        }
    }) {
        // there is no box score, so we can render something static
        val imageUrl =
            getPreferredTeamLogo(participant)?.let { getAbsoluteUrl(app.context, it) }
        Div({ classes(SportsStyles.entityImage) }) {
            if (imageUrl != null) {
                A("#sports/team/${participant.id}") {
                    Img(imageUrl)
                }
            }
        }
        Div {
            A("#sports/team/${participant.id}") {
                Text(participant.name)
            }
        }
        if (renderStatus) {
            Div {
                participantStatus.value?.let {
                    Text(it.toString())
                }
            }
        }
    }
}
