package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import contentingestion.unifiedmodel.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun eventsForTeam(context: SportsService, teamId: String) {
    var team by remember { mutableStateOf<Team?>(null) }
    var entities by remember { mutableStateOf<List<SportingEvent>?>(null) }

    LaunchedEffect(teamId) {
        team = context.api.fetchTeam(teamId)
        entities = context.api.eventsForTeam(teamId)
    }

    val currentTeam = team
    if (currentTeam != null) {
        Div({
            style {
                paddingTop(5.px)
                paddingLeft(10.px)
                width(100.px)
                height(100.px)
            }
        }){
            val preferredImage = getPreferredTeamImage(currentTeam)
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(preferredImage)) {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                }
            }
        }
    }

    serviceContentView({
        title = team?.let { getTeamName(it) }
        subTitle = team?.leagueLongName
    }) {
        if (entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $teamId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (entities.isNullOrEmpty()) {
            spinner(size = Size.LARGE)
        } else {
            boxTable {
                items(entities)
                column {
                    content { event ->
                        eventItem(event)
                    }
                }
            }
        }
    }
}

@Composable
fun eventItem(event: SportingEvent) {
    @Composable
    fun eventContent() {
        Div({ classes(SportsStyles.eventItem) }) {
            Div({
                style {
                    width(60.px)
                    height(60.px)
                }
            }) {
                val preferredImage = getEventImage(event)
                if (preferredImage != null) {
                    Img(src = getAbsoluteUrl(preferredImage)) {
                        style {
                            width(60.px)
                            height(60.px)
                        }
                    }
                }
            }
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(event.id)
                }
                Div({ classes(SportsStyles.entityInfo) }) { Text(event.name) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(event.startTime ?: "")
                }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(event.state?.name ?: "")
                }
            }
        }
    }

    Div({ classes(SportsStyles.hoverStyle) }) {
        A("#sports/scores-for-event/${event.id}") {
            eventContent()
        }
    }
}

fun getEventImage(event: SportingEvent): Image? =
    event.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default