package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.client.Episode
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.TranscriptionVersion
import com.siriusxm.pia.utils.encodeURIComponent
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

val PAGE_SIZE = 25

/**
 * Renders a control for navigating through a show's episodes.
 */
@Composable
fun showEpisodes(showId: String) {
    var episodes by remember { mutableStateOf<List<Episode>>(emptyList()) }
    var currentEpisodes by remember { mutableStateOf<List<Episode>?>(null) }
    var preferredVersions by remember { mutableStateOf<Map<String, TranscriptionVersion?>?>(null) }
    var page by remember { mutableStateOf(0) }
    var showTitle by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(page) {
        val available = episodes.drop(page * PAGE_SIZE).take(PAGE_SIZE)
        if (available.isNotEmpty()) {
            currentEpisodes = available
            return@LaunchedEffect
        }

        val lastOfPage = episodes.getOrNull(page * PAGE_SIZE - 1)?.date
        Application.api.query {
            show(showId) {
                title
                episodes(null, lastOfPage, PAGE_SIZE, null) {
                    episodes {
                        title
                        guid
                        date
                        season
                        seasonEpisodeNumber
                        description
                        typedEntityId(showId)
                        media {
                            url
                            bitrate
                            bundles {
                                id
                            }
                        }
                    }
                }
            }
        }.show.let {
            val episodeTypedEntityIds = mutableListOf<String>()

            it.episodes.episodes.let {
                episodes = (episodes + it).sortedByDescending { it.date }
                currentEpisodes = it
            }
            showTitle = it.title

            currentEpisodes?.forEach {episode ->
                val typedEntityId = episode.typedEntityId
                if (typedEntityId != null) {
                    episodeTypedEntityIds.add(typedEntityId)
                }
            }

            if (episodeTypedEntityIds.isNotEmpty()) {
                val results = Application.transcriptionAPI.getPreferredVersions(episodeTypedEntityIds)

                if (results.isNotEmpty()) {
                    val map = mutableMapOf<String, TranscriptionVersion>()
                    results.forEach { transcriptionVersion ->
                        map[transcriptionVersion.typedEntityId] = transcriptionVersion
                    }
                    preferredVersions = map
                } else {
                    preferredVersions = mapOf()
                }
            }
        }
    }

    val hasNoEpisodes = currentEpisodes?.isEmpty() == true && page == 0
    box({
        header({
            title = "Episodes"

            if (currentEpisodes != null && !hasNoEpisodes) {
                actionContent {
                    paginator {
                        pages = if (episodes.size % PAGE_SIZE != 0) episodes.size.div(PAGE_SIZE) + 1 else Int.MAX_VALUE
                        currentPage = page

                        onNavigate {
                            page = it
                        }
                    }
                }
            }
        })
        paddedContent = episodes.isEmpty()
    }) {
        if (hasNoEpisodes) {
            boxMessage("There are no episodes for this show")
        } else {
            table<Episode> {
                items(currentEpisodes.orEmpty())

                val hasEntityId = episodes.any { it.typedEntityId != null }
                if (hasEntityId) {
                    column {
                        title = "Entity Id"

                        content {
                            A(href = "#podcasts/show/${showId}/episodes/${encodeURIComponent(it.guid)}") {
                                Text(it.typedEntityId ?: "-")
                            }
                        }
                    }
                }

                column {
                    title = "Title"

                    content {
                        A(href = "#podcasts/show/${showId}/episodes/${encodeURIComponent(it.guid)}") {
                            Text(it.title ?: "-")
                        }
                    }
                }


                column {
                    title = "SXM Exclusive"
                    content {
                        val isExclusive = it.media?.map { it.bundles }?.all {
                            it!=null && it.all {
                                    bundle -> bundle.id == "restricted"
                            }
                        }
                        width = 100.px
                        Span({
                            style {
                                whiteSpace("nowrap")
                            }
                        }) {
                            Text(isExclusive.toString())
                        }

                    }
                }

                column {
                    title = "Published"
                    width = 150.px

                    content {
                        val dateStr = it.date?.let {
                            Instant.parse(it).toLocalDateTime(TimeZone.currentSystemDefault())
                        }?.let {
                            val minutes = if (it.minute < 10) {
                                "0" + it.minute
                            } else {
                                it.minute.toString()
                            }
                            "${it.date} ${it.hour}:${minutes}"
                        }
                        Span({
                            style {
                                whiteSpace("nowrap")
                            }
                        }) {
                            Text(dateStr ?: "-")
                        }
                    }
                }

                val hasFree = episodes.any { it.media.orEmpty().any { it.bundles.isNullOrEmpty() } }
                val hasRestricted =
                    episodes.flatMap { it.media.orEmpty().flatMap { it.bundles.orEmpty() } }
                        .any { it.id == "restricted" }

                val hasTranscription =
                    episodes.map { it.typedEntityId }.any { preferredVersions?.containsKey(it) == true }

                if (hasFree) {
                    column {
                        width = 50.px

                        content { episode ->
                            episode.media?.find {
                                it.bundles.isNullOrEmpty()
                            }?.let {
                                iconAction("play_arrow") {
                                    Application.player?.play(it.url, showTitle, episode.title)
                                }
                            }
                        }
                    }
                }

                if (hasRestricted) {
                    column {
                        headerIcon("lock")
                        width = 50.px

                        content { episode ->
                            episode.media?.find {
                                it.bundles?.any { it.id == "restricted" } ?: false
                            }?.let {
                                iconAction("play_arrow") {
                                    Application.player?.play(it.url, showTitle, episode.title)
                                }
                            }
                        }
                    }
                }
                if (hasTranscription) {
                    column {
                        title = "Transcription"
                        width = 50.px

                        content { episode ->
                            if (preferredVersions?.containsKey(episode.typedEntityId) == true) {
                                iconAction("subtitles") {
                                    // transcription/PE%3A1300034702?action=view&return=%2F%23transcription-dashboard
                                    Application.navigation.navigate("transcriptions/${encodeURIComponent(episode.typedEntityId!!)}?action=view")
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}