package com.siriusxm.pia.views.epg

import com.siriusxm.pia.rest.epg.EpgClient
import com.siriusxm.pia.rest.sports.EntityResponse
import contentingestion.unifiedmodel.*
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject

class EpgAPI(
    epgAPIBaseUrl: String,
    private val authGetter: () -> String
) {
    private val json = Json { isLenient = true; ignoreUnknownKeys = true }
    private val ktorfitEpgClient = Ktorfit.Builder()
        .baseUrl(epgAPIBaseUrl)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
        )
        .build()

    private val epgClient = ktorfitEpgClient.create<EpgClient>()

    private fun headers() = mapOf("Authorization" to "Bearer ${authGetter()}")

    /**
     * Fetch all linear channels
     */
    suspend fun fetchAllLinearChannels(): List<Channel> {
        return fetchAllPaginated {
            epgClient.fetchAllLinearChannels(headers(), paginationToken = it)
        }
    }

    /**
     * Fetch all xtra channels
     */
    suspend fun fetchAllXtraChannels(): List<Channel> {
        return fetchAllPaginated {
            epgClient.fetchAllXtraChannels(headers(), paginationToken = it)
        }
    }

    private suspend fun fetchAllPaginated(paginatedLookup: suspend (paginationToken: String?) -> EntityResponse): List<Channel> {
        val allChannels = mutableListOf<Channel>()
        val pageResult = paginatedLookup(null)
        val entities = pageResult.entities
        allChannels.addAll(entitiesToChannels(entities))
        var paginationToken = pageResult.paginationToken
        while (paginationToken != null) {
            val pgResult = paginatedLookup(paginationToken)
            allChannels.addAll(entitiesToChannels(pgResult.entities))
            paginationToken = pgResult.paginationToken
        }
        return allChannels
    }

    private fun entitiesToChannels(entities: List<JsonObject>): List<Channel> {
        return entities.mapNotNull {
            try {
                json.decodeFromJsonElement(Channel.serializer(), it)
            } catch (ex: Exception) {
                null
            }
        }
    }

    /**
     * Fetch a Linear Channel when you know its id.  This is useful on a Channel details page (or for a deep link)
     */
    suspend fun fetchChannel(channelId: String): Channel? {
        return epgClient.fetchLinearChannelById(channelId, headers())
    }

    /**
     * Fetch a single Show when you know the show id.  This is useful on a Show details page.
     */
    suspend fun fetchShow(showId: String): Show? {
        return epgClient.fetchShowById(showId, headers())
    }

    /**
     * Fetch all of the TEAM entities for a given league.  These will be sorted alphabetically by name.
     */
    suspend fun showsForChannel(channelId: String): List<Show> {
        val original = epgClient.fetchShowsForLinearChannel(channelId, headers()).entities.map {
            json.decodeFromJsonElement(Show.serializer(), it)
        }
        return original.filter { it.id.startsWith("SH:") }.sortedBy { it.name }
    }

    /**
     * Fetch all of the channel lineups
     */
    suspend fun fetchAllLineups(): List<ChannelLineup> {
        val entitiesResponse = epgClient.fetchAllLineups(headers())
        return entitiesResponse.entities.mapNotNull {
            try {
                json.decodeFromJsonElement(ChannelLineup.serializer(), it)
            } catch (ex: Exception) {
                null
            }
        }
    }
}