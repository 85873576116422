package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.Composable
import com.siriusxm.pia.client.api.client.Category
import com.siriusxm.pia.client.api.client.Keyword
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.detail
import com.siriusxm.pia.components.detailGrid

@Composable
fun keywordsAndCategoriesBox(keywords: List<Keyword>?, categories: List<Category>?) {
    if (keywords != null || categories != null) {
        box {
            detailGrid {
                keywords?.takeIf { it.isNotEmpty() }?.let { keywords ->
                    detail("Keywords", keywords.joinToString { it.value })
                }

                categories?.takeIf { it.isNotEmpty() }?.let { categores ->
                    detail("Categories", categores.joinToString { it.name })
                }
            }
        }
    }
}