package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.ChannelSummary
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.atlas.ImageParams
import com.siriusxm.pia.utils.iconForBoolean
import com.siriusxm.pia.views.sports.SportsStyles
import com.siriusxm.pia.views.sports.getAbsoluteUrl
import contentingestion.unifiedmodel.Cut
import contentingestion.unifiedmodel.Episode
import contentingestion.unifiedmodel.LinearCut
import contentingestion.unifiedmodel.Show
import kotlinx.coroutines.*
import kotlinx.datetime.Clock
import kotlinx.datetime.toInstant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.seconds

/**
 * Renders a channel page, complete with channel details, shows, cuts, etc.
 */
@Composable
fun channel(epg: EPG, channelId: String, route: Route) {
    var channel by remember { mutableStateOf<ChannelSummary?>(null) }
    var nowPlayingShow by remember { mutableStateOf<Show?>(null) }
    var nowPlayingEpisode by remember { mutableStateOf<Episode?>(null) }
    var shows by remember { mutableStateOf<List<Show>?>(null) }
    var linearEpisodes by remember { mutableStateOf<List<Episode>?>(null) }
    var audioEpisodes by remember { mutableStateOf<List<Episode>?>(null) }
    var cuts by remember { mutableStateOf<List<Cut>?>(null) }

    val cutRefreshJob = CoroutineScope(Dispatchers.Default)

    val secondsDelay = 60.seconds
    fun cutIsActive(cut: LinearCut) : Boolean {
        val active = cut.timestamp.toInstant().plus(secondsDelay) < Clock.System.now()
        if (!active) {
            console.log("cutTimestamp: ", cut.timestamp, "now: " + Clock.System.now(),
                "diff: ", (Clock.System.now().toEpochMilliseconds() - cut.timestamp.toInstant().toEpochMilliseconds()).toString()
            )
        }
        return active
    }

    suspend fun checkForUpdatedCutsAsync() = coroutineScope {
        cutRefreshJob.launch(Dispatchers.Main) {
            while (true) {
                delay(5.seconds)

                val currentCuts = channel?.cuts ?: emptyList()

                val newChannel = epg.fetchChannel(channelId)
                val newCuts = newChannel.cuts ?: emptyList()
                val netNewCuts = newCuts - currentCuts.toSet()
                if (netNewCuts.isNotEmpty() && cutIsActive(netNewCuts.first())) {
                    channel = newChannel
                } else {
                    console.log("Did not get any new cuts")
                }
            }
        }
    }

    LaunchedEffect(channelId) {
        val channelJob = async {
            try {
                epg.fetchChannel(channelId)
            } catch (e: Exception) {
                Application.notifications.showError("Unable to lookup channel $channelId", e.message)
                null
            }
        }

        channel = channelJob.await()


        val nowPlayingShowId = channel?.show?.id
        if (nowPlayingShowId != null) {
            try {
                nowPlayingShow = epg.fetchShow(nowPlayingShowId)
            } catch (e: Exception) {
                Application.notifications.showError("Unable to lookup show $nowPlayingShowId", e.message)
            }
        }

        val nowPlayingEpisodeId = channel?.episode?.id
        if (nowPlayingEpisodeId != null) {
            try {
                nowPlayingEpisode = epg.fetchEpisode(nowPlayingEpisodeId)
            } catch (e: Exception) {
                Application.notifications.showError("Unable to lookup episode $nowPlayingEpisodeId", e.message)
            }
        }

        val showsJob = async {
            try {
                epg.fetchShowsForChannel(channelId)
            } catch (e: Exception) {
                console.log("Unable to lookup shows for channel $channelId", e.message)
                null
            }
        }

        val cutsJob = async {
            try {
                epg.fetchCutsForChannel(channelId)
            } catch (e: Exception) {
                console.log("Unable to lookup cuts for channel $channelId", e.message)
                null
            }
        }

        awaitAll(channelJob, showsJob, cutsJob)

        shows = showsJob.await()?.filterNot { it.id == nowPlayingShowId }
        cuts = cutsJob.await()?.filterNot { channel?.cuts?.map { it.id }?.contains(it.id) == true }

        async {
            try {
                linearEpisodes = epg.fetchLinearEpisodesForChannel(channelId)
                    .filterNot { it.id == nowPlayingEpisodeId }
                    .sortedBy { it.startTimestamp }
            } catch (e: Exception) {
                Application.notifications.showError("Unable to lookup episodes for channel $channelId", e.message)
                null
            }
        }

        async {
            try {
                audioEpisodes = epg.fetchAudioEpisodesForChannel(channelId)
                    .sortedByDescending { it.originalAirTimestamp }
            } catch (e: Exception) {
                Application.notifications.showError("Unable to lookup episodes for channel $channelId", e.message)
                null
            }
        }

        // spawn the job that polls every 5 seconds and updates the cuts if they have changed
        checkForUpdatedCutsAsync()
    }

    serviceContentView({
        title = "Channel"
    }) {
        if (channel == null) {
            spinner(size = Size.LARGE)
        } else {
            Div({
                classes(SportsStyles.gridList)
            }) {
                channelItem(channel!!, false, emptyList()) {
                    // epg.navigate("channelguide/channel/${channel!!.channel.id}")
                }
            }

            Div({ classes(SportsStyles.entityInfo) }) {
                Text("Now Playing:")
            }
            Div {
                val summaryCuts = channel?.cuts
                if (summaryCuts == null) {
                    Text("No cuts playing.")
                } else {
                    cutsGrid(summaryCuts, false, imageSize = ImageParams(width =200, height = 200))
                }
            }

            if (!cuts.isNullOrEmpty()) {
                detailGrid {
                    detail("Upcoming Cuts") {
                        Div {
                            cutsGrid(cuts!!, false)
                        }
                    }
                }
            }

            Div({ classes(SportsStyles.entityInfo) }) {
                Text("Show:")
            }
            Div {
                if (nowPlayingShow == null) {
                    val showSummary = channel?.show
                    if (showSummary == null) {
                        Text("No show playing.")
                    } else {
                        showSummaryItem(showSummary, true)
                    }
                } else {
                    showItem(nowPlayingShow!!, false) {
                        epg.navigate("channelguide/show/${nowPlayingShow?.id}")
                    }
                }
            }

            Div({ classes(SportsStyles.entityInfo) }) {
                Text("Episode:")
            }
            Div {
                if (nowPlayingEpisode == null) {
                    val episodeSummary = channel?.episode
                    if (episodeSummary == null) {
                        Text("No episode playing.")
                    } else {
                        episodeSummaryItem(episodeSummary, false) {
                            epg.navigate("channelguide/episode/${episodeSummary.id}")
                        }
                    }
                } else {
                    episodeItem(nowPlayingEpisode!!, false) {
                        epg.navigate("channelguide/episode/${nowPlayingEpisode?.id}")
                    }
                }
            }


            val channelShows = shows?.filterNot { it.id == channel?.show?.id }
            if (!(channelShows.isNullOrEmpty() && linearEpisodes.isNullOrEmpty() && audioEpisodes.isNullOrEmpty())) {
                tabView {
                    if (!channelShows.isNullOrEmpty()) {
                        tab("Shows") {
                            detailGrid {
                                Div({
                                    classes(SportsStyles.gridList)
                                }) {
                                    if (channelShows.isNullOrEmpty()) {
                                        Text("No shows found.")
                                    } else {
                                        showsGrid(channelShows, false) {
                                            epg.navigate("channelguide/show/${it.id}")
                                        }
                                    }
                                }
                            }
                        }
                    }

                    val upcomingEpisodes = linearEpisodes?.filterNot {
                        val startTime = it.startTimestamp?.toInstant()
                        startTime == null || startTime < Clock.System.now()
                    }
                    if (!upcomingEpisodes.isNullOrEmpty()) {
                        tab("Upcoming Episodes") {
                            Div({
                                classes(SportsStyles.gridList)
                            }) {
                                if (upcomingEpisodes.isNullOrEmpty()) {
                                    Text("No upcoming episodes found.")
                                } else {
                                    episodesGrid(upcomingEpisodes!!, true)
                                }
                            }
                        }
                    }

                    if (!audioEpisodes.isNullOrEmpty()) {
                        tab("On-demand Episodes") {
                            Div({
                                classes(SportsStyles.gridList)
                            }) {
                                if (audioEpisodes.isNullOrEmpty()) {
                                    Text("No on-demand episodes found.")
                                } else {
                                    episodesGrid(audioEpisodes!!, true)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun channelSummaryContent(channel: ChannelSummary) {
    Div({ classes(SportsStyles.listItem) }) {
        Span() {
            detailGrid {
                detail("Channel Number") {
                    Div({ classes(SportsStyles.entitySubInfo) }) {
                        Text(channel.channel.channelNumber.toString())
                    }
                }
            }
        }
        Span({
            style {
                width(100.percent)
            }
        }) {
            Div({ classes(SportsStyles.listItem) }) {
                val preferredImage = channel.channel.getPreferredImage()
                if (preferredImage != null) {
                    Img(src = getAbsoluteUrl(preferredImage)) {
                        style {
                            width(150.px)
                            height(150.px)
                        }
                    }
                }
                Div({ classes(SportsStyles.metadata) }) {
                    detailGrid {
                        detail(channel.channel.type) {
                            Div({
                                classes(SportsStyles.entityInfo)
                                style {
                                    width(400.px)
                                }
                            }) { Text(channel.channel.name) }
                        }
                        val streamingId = channel.channel.streamingId
                        if (streamingId != null) {
                            detail("Streaming Id") {
                                Div({ classes(SportsStyles.entitySubInfo) }) {
                                    Text(streamingId)
                                }
                            }
                        }

                    }
                }
            }
        }
        Span({
            style {
                width(300.px)
            }
        }) {
            val cut = channel.cuts?.get(0)
            if (cut != null) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    cutItem(
                        cut, imageSize = ImageParams(width = 100, height = 100),
                        false, includeArtistStation = false, includeId = false
                    )
                }
            }
        }
    }

}

/**
 * Renders a channel and its currently playing cut (if available)
 */
@Composable
fun channelSummaryItem(channel: ChannelSummary, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    Div({
        if (onClick != null) {
            style {
                cursor("pointer")
            }
            this.onClick {
                onClick()
            }
        }
    }) {
        channelSummaryContent(channel)
    }
}

/**
 * @param lineupIds if the lineup ids list is null we are still loading lineups, if it is empty the channel is not in any lineups
 */
@Composable
fun channelItem(
    channel: ChannelSummary,
    includeLink: Boolean,
    lineupIds: List<Int>?,
    onClick: (() -> Unit)? = null
) {
    @Composable
    fun channelContent() {
        val notInAChannelLineup = false// lineupIds?.isEmpty() == true
        val listItemClass = if (notInAChannelLineup) {
            SportsStyles.listItemError
        } else {
            SportsStyles.listItem
        }
        Div({ classes(listItemClass) }) {
            Div {
                val preferredImage = channel.channel.getPreferredImage()
                if (preferredImage != null) {
                    Img(src = getAbsoluteUrl(preferredImage)) {
                        style {
                            width(300.px)
                            height(300.px)
                        }
                    }
                }
            }

            Div({ classes(SportsStyles.metadata) }) {
                detailGrid {
                    detail(channel.channel.type) {
                        Div({ classes(SportsStyles.entityInfo) }) { Text(channel.channel.name) }
                    }
                    detail("Channel Id") {
                        Div({ classes(SportsStyles.entitySubInfo) }) {
                            Text(channel.channel.id)
                        }
                    }
                    detail("Channel Number") {
                        Div({ classes(SportsStyles.entitySubInfo) }) {
                            Text(channel.channel.channelNumber.toString())
                        }
                    }
                    val streamingId = channel.channel.streamingId
                    if (streamingId != null) {
                        detail("Streaming Id") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(streamingId)
                            }
                        }
                    }
                    val statusStr = getStatusStr(channel)
                    if (statusStr != null) {
                        detail("Status") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(statusStr)
                            }
                        }
                    }
                    val businessOnly = channel.channel.businessOnly
                    if (businessOnly == true) {
                        detail("Business Only") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                iconForBoolean(businessOnly == true)
                            }
                        }
                    }


                    if (lineupIds?.isNotEmpty() == true) {
                        detail("Lineups") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                if (lineupIds.size < 10) {
                                    Text(lineupIds.joinToString(", "))
                                } else {
                                    Text("In ${lineupIds.size} lineups")
                                }
                            }
                        }
                    } else {
                        if (lineupIds != null) {
                            detail("Lineups") {
                                Div({ classes(SportsStyles.entitySubInfo) }) {
                                    Text("NOT IN ANY LINEUP")
                                }
                            }
                        } else {
                            detail("Lineups") {
                                Div({ classes(SportsStyles.entitySubInfo) }) {
                                    Text("Loading...")
                                }
                            }
                        }
                    }
                    val cutId = channel.cuts?.get(0)?.id
                    if (channel.cuts?.isNotEmpty() == true) {
                        detail("Cut Id") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(cutId.toString())
                            }
                        }
                    } else {
                        detail("Cut Id") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text("No Cuts")
                            }
                        }
                    }
                    val showId = channel.show?.id
                    if (showId != null) {
                        detail("Show Id") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(showId)
                            }
                        }
                    }
                    val showName = channel.show?.name
                    if (showName != null) {
                        detail("Show Name") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(showName)
                            }
                        }
                    }
                    val episodeId = channel.episode?.id
                    if (episodeId != null) {
                        detail("Episode Id") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(episodeId)
                            }
                        }
                    }
                    val episodeName = channel.episode?.name
                    if (episodeName != null) {
                        detail("Episode Name") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(episodeName)
                            }
                        }
                    }
                }
            }
        }
    }

    if (includeLink) {
//        val url = "#epg/shows-by-channel/${channel.id}"
        val url = "#aggregator/entity/${channel.channel.id}"
        A(url) {
            channelContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            channelContent()
        }
    }
}

private fun getStatusStr(channel: ChannelSummary): String? {
    val accessControls = channel.channel.accessControls?.default
    val statuses = mutableStateListOf<String>()
    if (accessControls?.visible != false) {
        statuses += "Visible"
    }
    if (accessControls?.discoverable != false) {
        statuses += "Discoverable"
    }
    if (accessControls?.recommendable != false) {
        statuses += "Recommendable"
    }
    if (statuses.isEmpty()) {
        statuses += "Active"
    }

    return statuses.joinToString(", ").ifEmpty { null }
}