package com.siriusxm.pia.views.artistradio

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.artistradio.ArtistStationSimplified
import com.siriusxm.pia.rest.artistradio.ArtistStationSummary
import com.siriusxm.pia.utils.encodeURIComponent
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.cancellation.CancellationException

@Composable
fun viewArtistRadio(artistStationIdStr: String, returnPath: String? = null) {
    var loading by mutableStateOf(false)

    var artistRadioStations  by remember { mutableStateOf(emptyList<ArtistStationSummary>()) }

    LaunchedEffect(artistStationIdStr) {
        try {
            loading = true

            console.log("Begin looking up artist radio station by id")
            val artistStations = Application.artistStationAPI.fetchArtistStation(artistStationIdStr)
            val results = artistStations.values.toList()

            if (results.isNotEmpty()) {
                artistRadioStations = artistStations.values.toList()
            }
            console.log("Done looking up artist radio stations by ids artistRadioStations: $artistRadioStations")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            artistRadioStations = emptyList()
        } finally {
            loading = false
        }
    }

    viewArtistRadioResults(artistRadioStations, returnPath)
}

@Composable
fun viewArtistRadioResults(searchResults: List<ArtistStationSummary>, returnPath: String? = null) {
    table<ArtistStationSimplified> {
        val versions = artistStationSummaries(searchResults)

        items(versions)

        column {
            title = "TypedEntityId"
            content { summary ->
                Div {
                    Text(summary.entityId)
                }
            }
        }
        column {
            title = "Name"
            content { summary ->
                Div {
                    Text(summary.name)
                }
            }
        }

        column {
            title = "Known Artist"
            content { summary ->
                Text("${summary.knownArtist}")
            }
        }


        column {
            title = "Discoverable"

            content { summary ->
                Div {
                    if (summary.knownArtist) {
                        iconForBoolean(summary.discoverable)
                    } else {
                        Text("")
                    }
                }
            }
        }

        column {
            title = "Recommendable"

            content { summary ->
                Div {
                    if (summary.knownArtist) {
                        iconForBoolean(summary.recommendable)
                    } else {
                        Text("")
                    }
                }
            }
        }

        column {
            title = "Visible"

            content { summary ->
                Div({ style {
                    textAlign(JustifyContent.Left.toString())
                } }) {
                    if (summary.knownArtist) {
                        iconForBoolean(summary.visible)
                    } else {
                        Text("")
                    }
                }
            }
        }
        column {
            title = "Action"

            content { summary ->
                Div({ style {
                    textAlign(JustifyContent.Left.toString())
                } }) {
                    val currentPath = Application.navigation.path
                    if (!summary.allVisible()) {
                        iconAction("visibility") {
                            console.log("Show button clicked")
                            Application.navigation.navigate("artist-radio/artistradio-edit/${encodeURIComponent(summary.entityId)}?action=show&return=${encodeURIComponent(currentPath)}")
                        }
                    }
                    if (!summary.allHidden()) {
                        iconAction("visibility_off") {
                            console.log("Hide button clicked")
                            Application.navigation.navigate("artist-radio/artistradio-edit/${encodeURIComponent(summary.entityId)}?action=hide&return=${encodeURIComponent(currentPath)}")
                        }
                    }
                    iconAction("edit") {
                        console.log("Edit button clicked")
                        Application.navigation.navigate("artist-radio/rtistradio-edit/${encodeURIComponent(summary.entityId)}?action=edit&return=${encodeURIComponent(currentPath)}")
                    }
                }
            }
        }
    }

    if (searchResults.size > 1) {
        Div({
            classes(ServiceViewStyles.subHead)
        }){

            val ids = searchResults.map { it.artistStationId }.joinToString(",")
            val currentPath = Application.navigation.path

            button("Bulk Show") {
                Application.navigation.navigate("artistradio-bulkedit/${encodeURIComponent(ids)}?action=show&return=${encodeURIComponent(currentPath)}")
            }
            button("Bulk Hide") {
                Application.navigation.navigate("artistradio-bulkedit/${encodeURIComponent(ids)}?action=hide&return=${encodeURIComponent(currentPath)}")
            }
        }
    }

    Div({
        classes(ServiceViewStyles.subHead)
    }){
        button("Back to Search") {
            Application.navigation.navigate(returnPath ?: "artistradio-dashboard")
        }
    }
}

@Composable
private fun iconForBoolean(value: Boolean) {
    if (value) {
        icon("check_circle")
    } else {
        icon("cancel")
    }
}

private fun artistStationSummaries(searchResults: List<ArtistStationSummary>): MutableList<ArtistStationSimplified> {
    val stations = mutableListOf<ArtistStationSimplified>()

    searchResults.forEach { result ->
        stations.add(
            ArtistStationSimplified(
                entityId = result.artistStationId,
                name = result.artist?.name ?: "Unknown",
                knownArtist = result.artist != null,
                discoverable = result.artist?.discoverable ?: true,
                recommendable = result.artist?.recommendable ?: true,
                visible = result.artist?.visible ?: true
            )
        )
    }
    return stations
}
