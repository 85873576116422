package com.siriusxm.pia.components

import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*

fun StyleScope.border(
    top: CSSNumeric = 0.px,
    right: CSSNumeric = 0.px,
    bottom: CSSNumeric = 0.px,
    left: CSSNumeric = 0.px,
    color: CSSColorValue,
    style: LineStyle = LineStyle.Solid,
) {
    border {
        color(color)
        style(style)
    }
    borderWidth(top, right, bottom, left)
}

fun StyleScope.borderTop(
    width: CSSNumericValue<out CSSUnit> = 1.px,
    style: LineStyle = LineStyle.Solid,
    color: CSSColorValue = SXMUI.borderDividerDefault.value()
) {
    border(width, 0.px, 0.px, 0.px, color, style)
}