package com.siriusxm.pia.utils

fun isValidGuid(guid: String): Boolean {
    val regex =
        Regex("^[{]?[0-9a-fA-F]{8}[-]?[0-9a-fA-F]{4}[-]?[0-9a-fA-F]{4}[-]?[0-9a-fA-F]{4}[-]?[0-9a-fA-F]{12}[}]?$")
    return regex.matches(guid)
}

private val typedEntityPrefixRegex = "^[A-Z]{2,4}$".toRegex()


/**
 * Is this a typed entity id
 */
fun String?.isTypedEntity(): Boolean {
    val prefix = this?.substringBefore(":", "").orEmpty()
    return prefix.matches(typedEntityPrefixRegex)
}

/**
 * Is this a typed entity id list?
 */
fun String?.isTypedEntityList(): Boolean {
    val typedEntityList = this?.toTypedEntityList()
    return !typedEntityList.isNullOrEmpty() && typedEntityList.size > 1
}

fun String?.isGuidList(): Boolean {
    val guidList = this?.toGuidList()
    return !guidList.isNullOrEmpty() && guidList.size > 1
}

fun String?.toGuidList(): List<String>? {
    val maybeGuids = this?.split(",")?.map { it.trim() }
    if (maybeGuids.isNullOrEmpty()) {
        return null
    }

    val guidList = maybeGuids.filter { isValidGuid(it) }
    if (guidList.isEmpty()) {
        return null
    }
    return guidList
}

/**
 * Convert a comma-separated list of typed entities into a list
 */
fun String?.toTypedEntityList(): List<String>? {
    val maybeTypedEntities = this?.split(",")?.map { it.trim() }
    if (maybeTypedEntities.isNullOrEmpty()) {
        return null
    }

    val typedEntityIds = maybeTypedEntities.filter { it.isTypedEntity() }
    if (typedEntityIds.isEmpty()) {
        return null
    }
    return typedEntityIds
}