package com.siriusxm.pia.views.mddb

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.serviceView
import com.siriusxm.pia.utils.Route

@Composable
fun Route.mddb() {
    serviceView({}) {
        mddbDashboardPage()
    }
}