package com.siriusxm.pia.rest.mddb

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class JobInfo(
    val id: String,
    val type: String,
    val startTime: Instant? = null,
    val disabledUntil: Instant? = null
) {
    val disabled : Boolean
        get() = disabledUntil != null
                &&  Clock.System.now() < disabledUntil
}

@Serializable
data class JobStatusResponse(
    val jobs: List<JobInfo>? = null
)
