package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun primaryContentArea(content: @Composable () -> Unit = {}) {
    Div({
        style {
            padding(16.px, 40.px)
            width(100.percent)
        }
    }) {
        content()
    }
}