package com.siriusxm.pia.utils

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.icon

@Composable
fun iconForBoolean(value: Boolean) {
    if (value) {
        icon("done")
    } else {
        icon("close")
    }
}
