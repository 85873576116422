package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.unifiedmodel.EntityType
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

typealias EntityType = String

@Serializable
class RelationshipSchema(
    val id: String,
    val name: String,
    val description: String,

    /**
     * Is this relationship editable manually.
     */
    val editable: Boolean,
    val subject: RelationshipActor,
    val `object`: RelationshipActor
) {
    val subjectType: EntityType get() = subject.type
    val objectType: EntityType get() = `object`.type

    /**
     * For automated relationships, the JSON path in the subject entity that contains the
     * link to the subject.
     */
    val subjectLinkToObject: String? get() = subject.fieldLinkToSibling

    /**
     * For automated relationships, the JSON path in the subject entity that contains the
     * link to the subject.
     */
    val objectLinkToSubject: String? get() = `object`.fieldLinkToSibling

    /**
     * For automated relationships, the JSON path in the subject entity where the sort
     * key can be found.
     */
    val objectSortKeyInSubject: String? get() = subject.sortKey

    /**
     * For automated relationships, the JSON path in the object entity where the sort
     * key can be found. Valid if objectLinkToSubject is not null
     */
    val subjectSortKeyInObject: String? get() = `object`.sortKey

}

@Serializable
class RelationshipActor(
    val type: EntityType,
    val fieldLinkToSibling: String? = null,
    val sortKey: String? = null,
    val aggregationField: String? = null
)

@Serializable
data class EntityRelationship(
    val schemaId: String,
    val subject: JsonObject,
    val `object`: JsonObject
) {
    val subjectEntity: Entity get() = subject.asEntity()
    val objectEntity: Entity get() = `object`.asEntity()
}

@Serializable
class IncomingRelationshipUpdate(
    val subject: String,
    val `object`: String,
    val data: JsonObject? = null
)