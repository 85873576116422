package com.siriusxm.pia.views.sports

import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*

object SportsStyles : StyleSheet() {

    val gridList by style {
        display(DisplayStyle.Grid)
        gridTemplateColumns("1fr 1fr")
        gap(20.px)
        padding(20.px)
        overflowY("auto")
    }

    val listItem by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        marginRight(10.px)
        minHeight(120.px)
        padding(10.px)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        border {
            color = SXMUI.borderDividerDefault.value()
            style(LineStyle.Solid)
        }
        borderWidth(0.px, 0.px, 1.px, 0.px)

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
            textDecorationStyle("none")
        }
        alignItems(AlignItems.Center)
    }

    val listItemError by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        marginRight(10.px)
        minHeight(120.px)
        padding(10.px)
        backgroundColor(Color.red)
        border {
            color = SXMUI.borderDividerDefault.value()
            style(LineStyle.Solid)
        }
        borderWidth(0.px, 0.px, 1.px, 0.px)

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
            textDecorationStyle("none")
        }
        alignItems(AlignItems.Center)
    }

    val eventItem by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        marginRight(10.px)
        minHeight(120.px)
        padding(10.px)
        alignItems(AlignItems.Center)
    }

    val hoverStyle by style {
        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
            textDecorationStyle("none")
        }
    }

    val metadata by style {
        marginLeft(10.px)
    }

    val entityInfo by style {
        fontSize((1.2.cssRem))
        marginBottom(3.px)
        whiteSpace("no-wrap")
    }

    val entitySubInfo by style {
        fontSize((0.8.cssRem))
        color(SXMUI.subtleTextColor.value())
        marginBottom(3.px)
    }
}