package com.siriusxm.pia.views.epg

import com.siriusxm.pia.Application

data class EpgService(
    val api: EpgAPI,
    val reloader: () -> Unit
) {
    /**
     * Navigate to the path (relative to the aggregator path, so don't start with "aggregator")
     */
    fun navigate(path: String) {
        Application.navigation.navigate("epg/${path.removePrefix("epg/")}")
    }

    fun refresh() {
        reloader()
    }
}

