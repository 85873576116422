package com.siriusxm.pia.utils

fun Any.prettyPrint(): String {

    var indentLevel = 0
    val indentWidth = 4

    fun padding() = "".padStart(indentLevel * indentWidth)

    val toString = toString()

    val stringBuilder = StringBuilder(toString.length)

    var i = 0
    while (i < toString.length) {
        when (val char = toString[i]) {
            '(', '[', '{' -> {
                indentLevel++
                stringBuilder.appendLine(char).append(padding())
            }
            ')', ']', '}' -> {
                indentLevel--
                stringBuilder.appendLine().append(padding()).append(char)
            }
            ',' -> {
                stringBuilder.appendLine(char).append(padding())
                // ignore space after comma as we have added a newline
                val nextChar = toString.getOrElse(i + 1) { char }
                if (nextChar == ' ') i++
            }
            else -> {
                stringBuilder.append(char)
            }
        }
        i++
    }

    return stringBuilder.toString()
}

fun Any.prettyPrintXML() : String {
    val xmlString = toString()

    val stringBuilder = StringBuilder()

    val addedNewLines = xmlString.replace("><", ">\n<")
    val lines = addedNewLines.split("\n")
    val tags = mutableListOf<String>()
    for (line in lines) {
        var isEndTag = false
        console.log("tags: " + tags)
        if (line.startsWith("</")) {
            stringBuilder.append("\n")
            isEndTag = true
        } else if (line.startsWith("<")) {
            if (line.startsWith("<![CDATA")) {
                // do nothing
                stringBuilder.append("\n")
            } else {
                val startTag = line.substring(1, line.indexOf('>'))
                if (!line.contains("?xml")) {
                    tags.add(startTag)
                }

                if (line.endsWith("/>")) {
                    isEndTag = true
                }

                // its both a start and end tag...we want to indent and then remove the item as its not nested
                if (line.contains("</$startTag>") ) {
                    isEndTag = true
                }
                stringBuilder.append("\n")
            }
        }

        val indentStr = if (tags.size > 1) {
            "    ".repeat(tags.size)
        } else {
            ""
        }
        stringBuilder.append(indentStr)
        stringBuilder.append(line)

        if (isEndTag && tags.isNotEmpty()) {
            tags.removeLast()
        }
    }
    return stringBuilder.toString()
}