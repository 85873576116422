package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.PeriodType
import com.siriusxm.pia.components.TimeRange
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxMessage
import com.siriusxm.pia.rest.unifiedaggregator.CloudEventChanges
import com.siriusxm.pia.rest.unifiedaggregator.diffEvents
import com.siriusxm.pia.views.unifiedaggregator.events.*
import contentingestion.aggregator.*
import kotlinx.coroutines.delay
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.jsonObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

val defaultTimeRanges = listOf(
    PeriodType.Hours.periodOf(12),
    PeriodType.Days.periodOf(1),
    PeriodType.Days.periodOf(3),
    PeriodType.Weeks.periodOf(1),
    PeriodType.Weeks.periodOf(2),
    PeriodType.Months.periodOf(1),
    PeriodType.Months.periodOf(3)
)

const val AI_ANALYSIS_QUERY = "ai-analysis"

/**
 * Displays events for an entity.
 */
@Composable
fun entityEvents(aggregatorContext: AggregatorService, entityId: String) {
    var loading by remember { mutableStateOf(false) }
    var result by remember { mutableStateOf<EventSearchStatus?>(null) }
    var analysisResult by remember { mutableStateOf<AIJobStatusDetails?>(null) }
    var failureMessage by remember { mutableStateOf<String?>(null) }

    var eventsPeriod by remember { mutableStateOf<TimeRange?>(null) }

    var cloudEventChangeMap by remember { mutableStateOf<Map<String, CloudEventChanges>?>(null) }
    var queryType by remember { mutableStateOf<String>(EventQueryOption.Output.name) }

    Style(EntityEventsStyles)

    LaunchedEffect(entityId, eventsPeriod, queryType) {
        if (eventsPeriod != null && queryType == AI_ANALYSIS_QUERY) {
            result = null
            analysisResult = null
            loading = true
            val range = eventsPeriod!!.range()
            var status = aggregatorContext.api.analyzeEntity(
                entityId = entityId,
                startTs = range.start,
                endTs = range.endInclusive
            )

            try {
                while (status.status == AIJobStatus.IN_PROGRESS) {
                    delay(500)
                    status = aggregatorContext.api.getAnalysisExecutionStatus(status.id)
                    analysisResult = status
                }
            } catch (e: Throwable) {
                failureMessage = e.message ?: "The analysis failed for an unknown reason."
            }
            loading = false
        }
    }

    LaunchedEffect(entityId, eventsPeriod, queryType) {
        if (eventsPeriod != null && queryType != AI_ANALYSIS_QUERY) {
            failureMessage = null
            loading = true
            result = null
            val range = eventsPeriod!!.range()
            var query = aggregatorContext.api.entityEvents(
                entityId, null, range.start,
                range.endInclusive, EventQueryOption.fromName(queryType)
            )
            try {
                while (query.state == EventSearchStatusState.IN_PROGRESS) {
                    delay(500)
                    query = aggregatorContext.api.entityEvents(entityId, query.id)
                    result = query
                }

                if (query.state == EventSearchStatusState.COMPLETED) {
                    result = query
                }

            } catch (e: Throwable) {
                failureMessage = e.message ?: "The event search failed for an unknown reason."
            }
            loading = false
        }
    }

    // Updates the changes map based on results.
    LaunchedEffect(result) {
        if (result?.state == EventSearchStatusState.COMPLETED) {
            val cloudEventChangesMap = mutableMapOf<String, CloudEventChanges>()
            val publishedCloudEvents = result?.results.orEmpty()
            publishedCloudEvents.windowed(size = 2, step = 1) { it[0] to it[1] }.forEach {
                val cloudEventChanges = diffEvents(it.first, it.second)
                if (cloudEventChanges.changedKeys.isNotEmpty()) {
                    cloudEventChangesMap[it.first.id] = cloudEventChanges
                }
            }
            cloudEventChangeMap = cloudEventChangesMap
        }
    }

    eventsSelectionHeader(
        loading,
        if (queryType == AI_ANALYSIS_QUERY) {
            "Analyzing events..."
        } else {
            "Loading events..."
        }, eventsPeriod, queryType, listOf(
            "Outgoing Events" to EventQueryOption.Output.name,
            "Incoming Events" to EventQueryOption.Incoming.name,
            "AI Analysis (Beta)" to "ai-analysis",
        )
    ) { range, option ->
        queryType = option!!
        eventsPeriod = range
    }

    box({
        paddedContent = false
    }) {
        if (failureMessage != null) {
            boxMessage(failureMessage!!)
        } else if (eventsPeriod == null) {
            boxMessage("Please select a time period.")
        }
        if (analysisResult != null) {
            analysisResults(analysisResult!!)
        }
        if (result != null) {
            val filteredResults = result?.results.orEmpty()
            if (filteredResults.isNotEmpty()) {
                cloudEventTable(
                    aggregatorContext,
                    filteredResults,
                    rendererLookup = { event ->
                        // a custom lookup to create a renderer with a changes record.
                        val changes = cloudEventChangeMap?.get(event.id)
                        if (changes != null) {
                            AtlasEventRenderer(aggregatorContext, event, changes)
                        } else {
                            defaultCloudEventRendererLookup(aggregatorContext, event)
                        }
                    },
                    extraConfig = if (queryType == EventQueryOption.Output.name) {
                        {
                            column("Changes") {
                                content { event ->
                                    val changes = cloudEventChangeMap?.get(event.id)
                                    if (changes != null) {
                                        Text(changes.changedKeys.joinToString(", "))
                                    }
                                }
                            }
                        }
                    } else null
                )
            }
        }
    }
}

@Composable
fun analysisResults(results: AIJobStatusDetails) {
    Div({
        style {
            backgroundColor(SXMUI.containerContentBackgroundColor.value())
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            gap(20.px)
            padding(20.px)
        }
    }) {
        val overview = results.result?.overview
        val insights = results.result?.insights
        if (overview != null) {
            Div({
                style { fontWeight("bold") }
            }) {
                Text(overview)
            }
        }
        if (insights != null) {
            Div({
                style { fontSize(0.9.cssRem) }
            }) {
                Text(insights)
            }
        }
    }
}

/**
 * Get the audit context for the event. If not found, returns an empty one.
 */
val CloudEvent.auditContext: AuditContext
    get() {
        return this.tracingcontext?.jsonObject?.get("auditContext")?.let {
            Json.decodeFromJsonElement(it)
        } ?: AuditContext()
    }