package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.Category
import com.siriusxm.pia.rest.epg.ChannelSummary
import com.siriusxm.pia.rest.epg.LineupSummary
import com.siriusxm.pia.rest.epg.decode
import com.siriusxm.pia.views.sports.SportsStyles
import contentingestion.unifiedmodel.EntityType
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun channelsBrowser(channelGuideContext: EPG) {
    var channels by remember { mutableStateOf<List<ChannelSummary>?>(null) }

    var typeFilter by remember { mutableStateOf<String?>(null) }
    var businessOnlyFilter by remember { mutableStateOf<Boolean?>(null) }
    var imageFilter by remember { mutableStateOf<Boolean?>(null) }

    LaunchedEffect("channels") {
        val channelsJob = async {
            channelGuideContext.fetchAllChannels()
        }
        channels = channelsJob.await()
    }

    serviceContentView({
        title = "Channels"
    }) {
        if (channels == null) {
            spinner(size = Size.LARGE)
        } else {
            detailGrid {
                detail("Types") {
                    buildSelect(typeFilter, setOf(EntityType.CHANNEL_LINEAR.name, EntityType.CHANNEL_XTRA.name)) {
                        typeFilter = it
                    }
                }
                detail("Business Only") {
                    buildSelect(typeFilter, setOf("true", "false")) {
                        businessOnlyFilter = it?.toBooleanStrictOrNull()
                    }
                }
                detail("Has Image") {
                    buildSelect(typeFilter, setOf("true", "false")) {
                        imageFilter = it?.toBooleanStrictOrNull()
                    }
                }
            }

            val filteredChannels = channels?.mapNotNull {
                if ((typeFilter == null || typeFilter == it.channel.type)
                    && (businessOnlyFilter == null || businessOnlyFilter == (it.channel.businessOnly == true))
                    && (imageFilter == null || imageFilter == (it.channel.hasPreferredImage()))
                ) {
                    it
                } else {
                    null
                }
            }

            Div({classes(ChannelGuideStyles.listItem)}) {
                if (filteredChannels!!.size == channels!!.size) {
                    Text("Showing ${filteredChannels.size} channels")
                } else {
                    Text("Showing ${filteredChannels.size} channels of ${channels!!.size}")
                }
            }

            Div({classes(ChannelGuideStyles.channelList)}) {
                if (filteredChannels.isNullOrEmpty()) {
                    boxMessage("No channels found that match this criteria")
                } else {
                    filteredChannels.forEach { channel ->
                        channelSummaryItem(channel, false) {
                            channelGuideContext.navigate("channel/${channel.channel.id}")
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun channelsByLineupBrowser(channelGuideContext: EPG, lineupId: String? = null) {
    var channels by remember { mutableStateOf<List<ChannelSummary>?>(null) }
    var lineups by remember { mutableStateOf<List<LineupSummary>?>(null) }

    LaunchedEffect("lineups") {
        lineups = channelGuideContext.fetchAllLineups()
    }

    LaunchedEffect(lineupId) {
        if (lineupId == null) {
            channels = channelGuideContext.fetchAllChannels()
        } else {
            try {
                channels = null
                channels = channelGuideContext.fetchChannelsForLineup(lineupId)
            } catch (e: Exception) {
                channels = emptyList()
            }
        }
    }

    serviceContentView({
        title = "Channels By Lineup"
    }) {
        detailGrid {
            if (lineups != null) {
                detail("Lineup") {
                    val sortedLineups = lineups?.sortedBy { it.lineupId }.orEmpty()
                    buildSelect(lineupId, sortedLineups.map {
                        it.id
                    }.toSet(), sortSelectOptions = false, optionTextMap = sortedLineups.associate {
                        it.id to if (it.name != null) {
                            "${it.name} (${it.lineupId})"
                        } else {
                            it.lineupId.toString()
                        }
                    }) { selectedId ->
                        Application.navigation.navigate("channelguide/channel/lineup?lineupId=$selectedId")
                    }
                }
            }
        }

        if (channels == null) {
            spinner(size = Size.LARGE)
        } else {
            Div {
                Text("Showing ${channels!!.size} channels")
            }

            Div({
                classes(SportsStyles.gridList)
            }) {
                if (channels.isNullOrEmpty()) {
                    boxMessage("No channels found that match this criteria")
                } else {
                    channels!!.forEach { channel ->
                        channelItem(channel, false, emptyList()) {
                            channelGuideContext.navigate("channel/${channel.channel.id}")
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun channelsByCategoryBrowser(channelGuideContext: EPG, categoryId: String? = null) {
    var channels by remember { mutableStateOf<List<ChannelSummary>?>(null) }

    var categories by remember { mutableStateOf<List<Category>?>(null) }

    LaunchedEffect(categoryId) {
        val categoriesJob = async {
            channelGuideContext.api.getChannelCategories()
        }

        val channelsJob = async {
            if (categoryId == null) {
                channelGuideContext.fetchAllChannels()
            } else {
                try {
                    channelGuideContext.fetchChannelsForCategory(categoryId)
                } catch (e: Exception) {
                    listOf()
                }
            }
        }
        awaitAll(channelsJob)

        categories =
            categoriesJob.await().decode<Category>().entities.filterNot { it.term.contains("::") }.sortedBy { it.term }
        channels = channelsJob.await().sortedBy { it.channel.channelNumber }
    }

    serviceContentView({
        title = "Channels by Category"
    }) {
        if (channels == null) {
            spinner(size = Size.LARGE)
        } else {
            detailGrid {
                if (categories != null) {
                    detail("Category") {
                        val idToNameMap = mutableMapOf<String, String>()
                        val idList = mutableListOf<String>()
                        categories?.forEach {
                            val id = it.id
                            idList.add(id)
                            val name = it.term
                            idToNameMap[it.id] = name
                        }
                        val linkedHashSet = linkedStringSetOf()
                        linkedHashSet.addAll(idList)
                        buildSelect(categoryId, linkedHashSet, idToNameMap, sortSelectOptions = false) {
                            Application.navigation.navigate("channelguide/channel/category?categoryId=$it")
                        }
                    }
                }
            }

            Div {
                Text("Showing ${channels!!.size} channels")
            }

            Div({
                classes(SportsStyles.gridList)
            }) {
                if (channels.isNullOrEmpty()) {
                    boxMessage("No channels found that match this criteria")
                } else {
                    channels!!.forEach { channel ->
                        channelItem(channel, false, emptyList()) {
                            channelGuideContext.navigate("channel/${channel.channel.id}")
                        }
                    }
                }
            }
        }
    }
}