// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.sports

import contentingestion.unifiedmodel.League
import contentingestion.unifiedmodel.Team
import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlin.collections.Map

@OptIn(InternalKtorfitApi::class)
public class _SportsClientImpl : SportsClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun fetchAllLeagues(headerMap: Map<String, String>): EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/types/league/all")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchLeagueById(leagueId: String, headerMap: Map<String, String>): League? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/${"$leagueId".encodeURLPath()}/league/0.1")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.League?",
    typeInfo = typeInfo<contentingestion.unifiedmodel.League?>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.League?,
        contentingestion.unifiedmodel.League?>(_typeData,_ext)
  }

  override suspend fun fetchTeamById(teamId: String, headerMap: Map<String, String>): Team? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/${"$teamId".encodeURLPath()}/team/0.1")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.Team?",
    typeInfo = typeInfo<contentingestion.unifiedmodel.Team?>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.Team?,
        contentingestion.unifiedmodel.Team?>(_typeData,_ext)
  }

  override suspend fun fetchTeamsForLeague(leagueId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/team-league/object/${"$leagueId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createSportsClient(): SportsClient = this.create(_SportsClientImpl().apply {
    _converter= KtorfitConverterHelper(this@createSportsClient) })
