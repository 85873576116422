package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object BreadCrumbStyles : StyleSheet() {
    val crumbs by style {
        fontSize(12.px)
        marginBottom(1.em)
    }

    val crumb by style {
        display(DisplayStyle.InlineBlock)
    }

    val selectedCrumb by style {
        fontWeight(700)
    }

    val crumbDivider by style {
    }
}

interface BreadcrumbConfiguration {
    fun crumb(name: String, path: String?, child: BreadcrumbConfiguration.() -> Unit = {})
}

private class Breadcrumb(
    val name: String,
    val path: String?
)

private class BreadCrumbs(
    val crumbs: MutableList<Breadcrumb> = mutableListOf(),
    val basePath: String = "#"
) : BreadcrumbConfiguration {
    override fun crumb(name: String, path: String?, child: BreadcrumbConfiguration.() -> Unit) {
        val newPath = path?.let { basePath + it }
        crumbs.add(Breadcrumb(name, path?.let { basePath + it }))

        BreadCrumbs(crumbs, newPath?.let { "$it/" } ?: "").child()
    }
}

@Composable
fun breadcrumbs(config: BreadcrumbConfiguration.() -> Unit) {
    val crumbs = BreadCrumbs()
    crumbs.config()

    Div({
        classes(BreadCrumbStyles.crumbs)
    }) {
        crumbs.crumbs.forEachIndexed { index, crumb ->
            if (index != 0) {
                Span({ classes(BreadCrumbStyles.crumbDivider) }) {
                    icon("chevron_right")
                }
            }
            Span({
                classes(BreadCrumbStyles.crumb)
                if (index == crumbs.crumbs.size - 1 && crumb.path == null) {
                    classes(BreadCrumbStyles.selectedCrumb)
                }
            }) {
                if (crumb.path != null) {
                    A(href = crumb.path) {
                        Text(crumb.name)
                    }
                } else {
                    Text(crumb.name)
                }
            }
        }
    }
}