package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Audio

/**
 * Interface to the audio player, allowing external clients to control playback. This
 * is useful because there is one global player that will have to share state.
 */
interface AudioPlayer {
    fun play(url: String, title: String? = null, subTitle: String? = null)
}

enum class PlayState {
    PLAYING,
    PAUSED,
    LOADING
}

object AudioPlayerStyles : StyleSheet() {
    val playerArea by style {
        backgroundColor(SXMUI.mainHeaderBackgroundColor.value())
        color(Color.white)
        display(DisplayStyle.Flex)
    }

    val playButton by style {
        display(DisplayStyle.InlineBlock)

        child(self, className("material-icons")).style {
            fontSize(48.px)
        }
    }

    val titleArea by style {
        padding(10.px)
        fontSize((0.9).cssRem)
    }

    val title by style {
        fontWeight(700)
    }

    val subTitle by style {
        fontSize((0.8).cssRem)
    }
}

/**
 * An audio player component. Returns an configuration object that can be used to externally
 * control playback.
 */
@Composable
fun audioPlayer(): AudioPlayer {
    var playingUrl by remember { mutableStateOf<String?>(null) }
    var player by remember { mutableStateOf<Audio?>(null) }
    var playerState by remember { mutableStateOf<PlayState>(PlayState.PAUSED) }
    var playerTitle by remember { mutableStateOf<String?>(null) }
    var playerSubtitle by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(playingUrl) {
        player?.pause()

        player = playingUrl?.let {
            Audio(it)
        }
        player?.addEventListener("pause", {
            playerState = PlayState.PAUSED
        })
        player?.addEventListener("playing", {
            playerState = PlayState.PLAYING
        })
        player?.addEventListener("waiting", {
            playerState = PlayState.LOADING
        })
        player?.play()
    }

    if (player != null) {
        Div({
            classes(AudioPlayerStyles.playerArea)
        }) {
            Div({ classes(AudioPlayerStyles.playButton) }) {
                when (playerState) {
                    PlayState.PLAYING -> {
                        iconAction("pause") {
                            player?.pause()
                        }
                    }
                    PlayState.PAUSED -> {
                        iconAction("play_arrow") {
                            player?.play()
                        }
                    }
                    PlayState.LOADING -> {
                        iconAction("pause") {
                            player?.pause()
                        }
                    }
                }
            }

            Div({ classes(AudioPlayerStyles.titleArea) }) {
                if (playerTitle != null) {
                    Div({ classes(AudioPlayerStyles.title) }) {
                        Text(playerTitle!!)
                    }
                }
                if (playerSubtitle != null) {
                    Div({ classes(AudioPlayerStyles.subTitle) }) {
                        Text(playerSubtitle!!)
                    }
                }
            }

        }
    }

    return object : AudioPlayer {
        override fun play(url: String, title: String?, subTitle: String?) {
            playingUrl = url
            playerTitle = title
            playerSubtitle = subTitle
        }
    }
}