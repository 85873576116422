package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.aggregator.*
import de.jensklingenberg.ktorfit.http.*
import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject

interface UnifiedAggregatorClient {
    @Headers("Content-Type: application/json")
    @GET("entities/types")
    suspend fun entityTypes(): List<EntityTypeConfiguration>

    @Headers("Content-Type: application/json")
    @GET("partials")
    suspend fun partialSchemas(): List<PartialSchema>

    @Headers("Content-Type: application/json")
    @GET("entities/{id}/{type}/{version}")
    suspend fun fetchEntity(
        @Path("id") id: String,
        @Path("type") type: String,
        @Path("version") version: String
    ): JsonObject

    @Headers("Content-Type: application/json")
    @GET("entities/{id}")
    suspend fun fetchEntityById(
        @Path("id") id: String,
        @Query("includeRemoved") includeRemoved: String? = null
    ): List<JsonObject>

    @Headers("Content-Type: application/json")
    @GET("incoming/{id}/{type}/{version}")
    suspend fun incomingEntity(
        @Path("id") id: String, @Path("type") type: String,
        @Path("version") version: String,
        @Query("fields") fields: String? = null
    ): IncomingEntityResult?

    @Headers("Content-Type: application/json")
    @POST("incoming/partial")
    suspend fun partialUpdate(@Body partial: PartialUpdate)

    /**
     * Get the outgoing entity
     */
    @Headers("Content-Type: application/json")
    @GET("outgoing/{id}")
    suspend fun outgoingById(
        @Path("id") id: String
    ): JsonArray?

    /**
     * Get the outgoing entity
     */
    @Headers("Content-Type: application/json")
    @GET("outgoing/{id}/{type}/{version}")
    suspend fun outgoing(
        @Path("id") id: String, @Path("type") type: String,
        @Path("version") version: String
    ): JsonObject?

    /**
     * Republish an outgoing entity.
     */
    @POST("outgoing/{id}/{type}/{version}?republish=1")
    suspend fun republish(
        @Path("id") id: String, @Path("type") type: String,
        @Path("version") version: String,
        @Query("updateTimestamp") updateTimestamp: Boolean = false
    )

    @Headers("Content-Type: application/json")
    @GET("entities")
    suspend fun searchEntities(
        @Query("q") queryTerm: String? = null,
        @Query("types") types: List<String>? = null,
        @Query("from") from: Int? = null,
        @Query("size") size: Int? = null,
        @Query("visible") visible: Boolean? = null
    ): EntityListResponse

    @Headers("Content-Type: application/json")
    @GET("entities/mappings/{id}")
    suspend fun lookupSourceId(@Path("id") id: String): MappingResponse

    /**
     * Get all producers
     */
    @GET("management/producers")
    suspend fun producers(): List<ProducerDetails>

    /**
     * Create a new producer
     */
    @POST("management/producers")
    @Headers("Content-Type: application/json")
    suspend fun newProducer(@Body producerDetails: ProducerDetails)

    /**
     * Update an existing producer
     */
    @PUT("management/producers/{id}")
    @Headers("Content-Type: application/json")
    suspend fun updateProducer(@Path("id") id: String, @Body producerDetails: ProducerDetails)
    /**
     * Get all producers
     */
    @GET("management/consumers")
    suspend fun consumers(): List<ConsumerDetails>

    /**
     * Create a new consumer
     */
    @POST("management/consumers")
    @Headers("Content-Type: application/json")
    suspend fun newConsumer(@Body producerDetails: ConsumerDetails)

    /**
     * Update an existing consumer
     */
    @PUT("management/consumers/{id}")
    @Headers("Content-Type: application/json")
    suspend fun updateConsumer(@Path("id") id: String, @Body producerDetails: ConsumerDetails)

    /**
     * Get a list of backfills
     */
    @GET("backfill")
    suspend fun backfills(
        @Query("since") since: String? = null,
        @Query("until") until: String? = null
    ): List<BackfillStatus>

    /**
     * Get all active backfills
     */
    @GET("backfill/active")
    suspend fun activeBackfills(): List<BackfillStatus>

    /**
     * Get all active backfills
     */
    @GET("backfill/{id}")
    suspend fun backfill(@Path("id") id: String): BackfillStatus

    /**
     * Start a backfill
     */
    @POST("backfill")
    @Headers("Content-Type: application/json")
    suspend fun startBackfill(@Body request: BackfillRequest): BackfillStatus

    /**
     * Cancel a backfill
     */
    @DELETE("backfill/{id}")
    suspend fun cancelBackfill(@Path("id") id: String)

    @GET("entities/relationships")
    suspend fun relationshipSchemas(): List<RelationshipSchema>


    @GET("entities/{entityId}/relationships")
    suspend fun relationshipsForEntity(@Path("entityId") id: String): List<EntityRelationship>

    @GET("entities/relationships/{relationshipId}/object/{entityId}")
    suspend fun relationshipSubjects(
        @Path("relationshipId") relationshipSchemaId: String,
        @Path("entityId") id: String
    ): EntityListResponse

    @GET("entities/relationships/{relationshipId}/subject/{entityId}")
    suspend fun relationshipObjects(
        @Path("relationshipId") relationshipSchemaId: String,
        @Path("entityId") id: String
    ): EntityListResponse

    @GET("entities/{entityId}/events")
    suspend fun entityEvents(@Path("entityId") id: String, @Query("queryId") queryId: String? = null, @Query("start") startTime: Instant? = null): EventSearchStatus

    @POST("incoming/relationships/{relationshipId}")
    @Headers("Content-Type: application/json")
    suspend fun submitRelationship(
        @Path("relationshipId") relationshipSchemaId: String,
        @Body update: IncomingRelationshipUpdate
    )

    // FEATURE FLAGS
    @GET("management/features")
    suspend fun features(): AppConfigFeatureFlagsDocument

    @POST("management/features")
    @Headers("Content-Type: application/json")
    suspend fun updateFeatures(@Body update: FeatureFlagsUpdate)

    @POST("backfill/test")
    @Headers("Content-Type: application/json")
    suspend fun testBackfill(@Body backfillTestRequest: BackfillTestRequest) : BackfillTestResponse

    @GET("batch")
    suspend fun availableBatchJobs(): List<BatchDescriptor>

    @POST("batch")
    @Headers("Content-Type: application/json")
    suspend fun startBatchJob(@Body request: BatchingRequest)

    @GET("incoming/metrics/image")
    suspend fun getMetricsImage(
        @Query("period") period: Int, @Query("type") type: String? = null,
        @Query("width") width: Int? = null, @Query("height") height: Int? = null
    ): String

    @POST("metrics")
    @Headers("Content-Type: application/json")
    suspend fun getMetrics(
        @Body request: GetMetricDataRequest
    ): GetMetricDataResponse

    @DELETE("incoming/{entityId}/{entityType}/{entityVersion}/partial/{partialId}/scheduled/{ts}")
    @Headers("Content-Type: application/json")
    suspend fun deleteScheduledPartial(
        @Path("entityId") entityId: String,
        @Path("entityType") entityType: String,
        @Path("entityVersion") entityVersion: String,
        @Path("partialId") partialId: String,
        @Path("ts") timestamp: String
    ): Unit

    @DELETE("incoming/{entityId}/{entityType}/{entityVersion}/scheduled/{ts}")
    @Headers("Content-Type: application/json")
    suspend fun deleteScheduledUpdate(
        @Path("entityId") entityId: String,
        @Path("entityType") entityType: String,
        @Path("entityVersion") entityVersion: String,
        @Path("ts") timestamp: String
    ): Unit
}
