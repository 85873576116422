package com.siriusxm.pia

import androidx.compose.runtime.*
import com.siriusxm.pia.components.IconSize
import com.siriusxm.pia.components.icon
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * Returned by the notification renderer, used to add notifications.
 */
interface NotificationsConfig {
    fun showError(message: String, description: String? = null)
    fun info(message: String, description: String? = null)
}

/**
 * Attempt a request and show a notification if there is an exception
 */
suspend fun <T> attemptRequest(errorMessage: String = "The operation could not be completed", block: suspend () -> T) {
    try {
        block()
    } catch (e: Throwable) {
        Application.notifications.showError(errorMessage, e.message)
    }
}

/**
 * Renders the notifications for the application.
 */
@Composable
fun notifications(): NotificationsConfig {
    var messages by remember { mutableStateOf<List<Notification>>(emptyList()) }

    Div({ classes(NotificationStyles.container) }) {
        messages.forEach {
            notificationBox(it) {
                messages = messages - it
            }
        }

    }

    return object : NotificationsConfig {
        override fun showError(message: String, description: String?) {
            messages = messages + Notification(NotificationStyles.errorBox, message, description)
        }

        override fun info(message: String, description: String?) {
            messages = messages + Notification(NotificationStyles.infoBox, message, description)
        }
    }
}

class Notification(
    val style: String,
    val message: String,
    val description: String?
)


@Composable
fun notificationBox(notification: Notification, remove: () -> Unit) {
    Div({ classes(NotificationStyles.box, notification.style) }) {
        Div({ classes(NotificationStyles.boxMessage) }) {
            Text(notification.message)
        }

        Div({
            classes(NotificationStyles.boxClose)
            onClick {
                remove()
            }
        }) {
            icon("close") {
                size = IconSize.SMALL
            }
        }
    }
}


object NotificationStyles : StyleSheet() {
    val container by style {
        position(Position.Fixed)
        top(40.px)
        left(0.px)
        right(0.px)
        property("z-index", "20")
        padding(5.px)
    }

    val box by style {
        backgroundColor(SXMUI.messageAlertBackground.value())
        border(1.px, LineStyle.Solid, SXMUI.messageAlertBorder.value())
        padding(1.cssRem)
        position(Position.Relative)
    }

    val errorBox by style {
        backgroundColor(SXMUI.messageAlertBackground.value())
        border(1.px, LineStyle.Solid, SXMUI.messageAlertBorder.value())
    }

    val infoBox by style {
        backgroundColor(SXMUI.messageInfoBackground.value())
        border(1.px, LineStyle.Solid, SXMUI.messageInfoBorder.value())
    }

    val boxMessage by style {
        fontWeight(700)
    }

    val boxClose by style {
        position(Position.Absolute)
        top(5.px)
        right(5.px)
        cursor("pointer")
    }
}
