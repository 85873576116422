package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.components.ButtonStyles
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.asEntities
import com.siriusxm.pia.utils.isValidGuid
import kotlinx.serialization.json.JsonObject
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

data class SearchStatus(
    val loading: Boolean,
    val results: List<Entity>,
    val typeInput: String? = null,
    val query: String? = null
)

/**
 * Renders an entity search input. Executes searches but DOES NOT render results. The caller must
 * render results how it sees fit.
 */
@Composable
fun entitySearch(
    context: AggregatorService, initSearch: String? = null, initType: String? = null,
    showTypes: Boolean = true, cb: (SearchStatus) -> Unit
) {
    var entityInput by remember { mutableStateOf(initSearch) }
    var status by remember { mutableStateOf(SearchStatus(false, emptyList(), initType, initSearch)) }

    LaunchedEffect(initType) {
        status = status.copy(typeInput = initType)
    }

    LaunchedEffect(status.typeInput, status.query) {
        if (status.query != null && status.typeInput != null) {
            status = status.copy(loading = true)
            var searchResults: List<JsonObject>? = null
            // first check for a guid
            if (isValidGuid(entityInput.orEmpty())) {
                try {
                    val sourceId = context.api.lookupSourceId(entityInput!!).sourceId
                    searchResults = context.api.fetchEntityById(sourceId)
                } catch (e: Throwable) {
                    e.printStackTrace()
                }
            } else {
                val prefix = entityInput?.substringBefore(":", "").orEmpty()
                if (prefix.length in 2..4 && prefix.uppercase() == prefix) {
                    searchResults = context.api.fetchEntityById(entityInput!!)
                } else {
                    searchResults =
                        context.api.searchEntities(
                            entityInput!!,
                            status.typeInput?.let { listOf(it) }).entities
                }
            }
            status = status.copy(
                loading = false,
                results = searchResults.orEmpty().asEntities()
            )
        } else {
            status = status.copy(results = emptyList())
        }
    }

    fun submitSearch() {
        status = status.copy(query = entityInput)
    }

    LaunchedEffect(status) {
        cb(status)
    }

    Div({
        style {
            display(DisplayStyle.Flex)
            gap(1.em)
            marginTop(1.em)
        }
    }) {
        Div({
            style {
                flexGrow(1)
            }
        }) {
            Input(InputType.Text) {
                style {
                    width(100.percent)
                }
                placeholder("Search for entities by id or text")
                value(entityInput ?: "")
                onInput {
                    entityInput = it.value.ifBlank { null }
                }
                onKeyDown { event ->
                    if (event.key == "Enter") {
                        submitSearch()
                    }
                }
            }
        }

        if (showTypes) {
            Div({
                style {
                    flex(0, 0, 250.px)
                }
            }) {
                Select({
                    style { width(100.percent) }
                    onChange {
                        status = status.copy(typeInput = it.value?.takeIf { it != "any" })
                    }
                }) {
                    Option("any", {
                        if (status.typeInput == null) selected()
                    }) {
                        Text("All Types")
                    }
                    context.entityTypes.sortedBy { it.type }.forEach {
                        Option(it.type, {
                            if (it.type == status.typeInput) selected()
                        }) {
                            Text(it.type)
                        }
                    }
                }
            }
        }

        Div({

        }) {
            Button({
                classes(ButtonStyles.button)
                classes(ButtonStyles.primaryButton)
                style {
                    height(2.7.em)
                    lineHeight(1.7.em)
                }
                onClick {
                    submitSearch()
                    it.preventDefault()
                }
            }) {
                Text("Search")
            }
        }
    }
}