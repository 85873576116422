package com.siriusxm.pia.views.configuration

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.ContentFlagMatcherInput
import com.siriusxm.pia.client.api.client.ContentFlag
import com.siriusxm.pia.client.api.client.ContentFlagMatch
import com.siriusxm.pia.client.api.client.ContentFlagMatcher
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun contentFlag(id: String) {
    var editing by remember { mutableStateOf(false) }
    var addingMatcher by remember { mutableStateOf(false) }
    var flag by remember { mutableStateOf<ContentFlag?>(null) }
    var matchers by remember { mutableStateOf<List<ContentFlagMatcher>?>(null) }
    var matches by remember { mutableStateOf<List<ContentFlagMatch>?>(null) }

    LaunchedEffect(true) {
        try {
            Application.api.query {
                flags {
                    this.flag(id) {
                        this.flag
                        this.description
                        this.matchers {
                            this.regex
                        }
                        this.matches(null) {
                            this.matches {
                                this.match
                                this.show {
                                    this.id
                                    this.title
                                }
                                this.episode {
                                    this.guid
                                    this.title
                                }
                            }
                        }
                    }
                }
            }.flags.flag.let {
                flag = it
                matches = it.matches.matches
                matchers = it.matchers
            }
        } catch (t: Throwable) {
            Application.navigation.navigate("flags")
        }
    }

    entityView({
        title = flag?.flag

        if (!editing) {
            action {
                title = "Edit"
                action {
                    editing = true
                }
            }
        } else {
            action {
                title = "View Matches"
                action {
                    editing = false
                }
            }
        }
    }) {
        flag?.description?.let {
            P {
                Text(it)
            }
        }
        if (editing) {
            box({
                title = "Matchers"
                paddedContent = flag?.matchers.isNullOrEmpty() == true

                if (!addingMatcher) {
                    action {
                        title = "Add"
                        action {
                            addingMatcher = true
                        }
                    }
                }
            }) {
                if (addingMatcher) {
                    Div({ style { padding(1.cssRem) } }) {
                        var addExpression by remember { mutableStateOf<String?>(null) }
                        var testInput by remember { mutableStateOf<String?>(null) }
                        var isValid by remember { mutableStateOf(false) }
                        var isTestMatch by remember { mutableStateOf(true) }
                        dialogField(
                            "Matcher Regular Expression",
                            if (addExpression == null || isValid)
                                "Enter a regular expression that will match against incoming show and episode descriptions"
                            else {
                                "This is not a valid regular expression"
                            }
                        ) {
                            simpleTextField(addExpression) {
                                addExpression = it
                                if (it == null) {
                                    isValid = false
                                } else {
                                    try {
                                        isTestMatch = Regex(it).containsMatchIn(testInput.orEmpty())
                                        isValid = true
                                    } catch (t: Throwable) {
                                        isValid = false
                                    }
                                }
                            }
                        }

                        dialogField(
                            "Test Input",
                            if (isTestMatch) "This input matches the expression" else "This input does NOT match the expression"
                        ) {
                            simpleTextField(testInput) {
                                testInput = it
                                isTestMatch =
                                    if (isValid) Regex(addExpression.orEmpty()).containsMatchIn(it.orEmpty()) else false
                            }
                        }

                        Div {
                            button("Save", true) {
                                if (isValid) {
                                    Application.api.mutate {
                                        flags {
                                            this.addMatcher(id, ContentFlagMatcherInput(addExpression!!)) {
                                                regex
                                            }
                                        }
                                    }.flags.addMatcher.let {
                                        val newList = matchers.orEmpty() + it
                                        matchers = newList
                                    }
                                    addingMatcher = false
                                }
                            }
                            button("Cancel") {
                                addingMatcher = false
                            }
                        }
                    }
                }
                if (matchers.isNullOrEmpty()) {
                    boxMessage("This flag has not matchers")
                } else {
                    table<ContentFlagMatcher> {
                        items(matchers)
                        column {
                            content {
                                Text(it.regex)
                            }
                        }

                        column {
                            width = 150.px
                            content { matcher ->
                                iconAction("delete") {
                                    Application.api.mutate {
                                        flags {
                                            this.removeMatcher(id, matcher.regex)
                                        }
                                    }.flags.removeMatcher.let {
                                        if (it == true) {
                                            matchers = matchers.orEmpty().filterNot { it.regex == matcher.regex }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            box({
                title = "Recently Matched"
                paddedContent = matches.isNullOrEmpty()
            }) {
                if (matches?.isEmpty() == true) {
                    boxMessage("There are no recent matches for this flag")
                } else {
                    table<ContentFlagMatch> {
                        items(matches)
                        column {
                            content {
                                A(href = "#podcasts/show/${it.show.id}") {
                                    Text(it.show.title.orEmpty())
                                }
                            }
                        }

                        column {
                            content { match ->
                                match.episode?.let {
                                    A(href = "#podcasts/show/${match.show.id}/episodes/${encodeURIComponent(it.guid)}") {
                                        Text(it.title.orEmpty())
                                    }
                                }
                            }
                        }

                        column {
                            content { match ->
                                Text(match.match ?: "-")
                            }
                        }
                    }
                }
            }
        }
    }
}