package com.siriusxm.pia

import com.siriusxm.pia.client.api.client.*
import com.siriusxm.pia.views.podcasts.AuditShowResponse
import com.siriusxm.pia.views.podcasts.IngestionClient
import com.steamstreet.graphkt.client.ktor.GraphQLKtorClient
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

/**
 * Wraps the podcast ingestion API.
 */
class IngestionAPI(url: String, private val authGetter: () -> String) {
    private val graphQL = GraphQLKtorClient(url) {
        buildMap {
            put("Authorization", authGetter())
        }
    }

    suspend fun query(name: String? = null, block: _QueryQuery.() -> Unit): Query {
        return graphQL.query(name, block)
    }

    suspend fun mutate(name: String? = null, block: _MutationQuery.() -> Unit): Mutation {
        return graphQL.mutation(name, block)
    }
}

class IngestionAuditAPI(url: String, private val authGetter: () -> String) {
    private val ktorfit = Ktorfit.Builder()
        .baseUrl(url)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
        )
        .build()
    private val ingestionClient = ktorfit.create<IngestionClient>()

    suspend fun auditShows(requestedBy: String) : AuditShowResponse? {
        return try {
            ingestionClient.auditShows(requestedBy)
        } catch (e: Exception) {
            console.log("auditShows() had an exception: $e")
            null
        }
    }
}