package com.siriusxm.pia.views.artistradio

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.tabView
import com.siriusxm.pia.utils.iconForBoolean
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.cancellation.CancellationException

@Composable
fun artistRadioDashboardPage() {

    var status by remember {  mutableStateOf<Boolean?>(null) }
    var statusAuthenticated by remember {  mutableStateOf<Boolean?>(null) }

    LaunchedEffect("status") {
        try {
            console.log("Begin looking up status_authenticated")
            status = Application.artistStationAPI.status() == "OK"
            console.log("Done looking up status_authenticated")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            status = false
        }
        try {
            console.log("Begin looking up status_authenticated")
            statusAuthenticated = Application.artistStationAPI.statusAuthenticated() == "OK"
            console.log("Done looking up status_authenticated")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            statusAuthenticated = false
        }
    }

    box(
        {
            paddedContent = true
            header({
                title = "Artist Radio"
            })
        }
    ) {
        tabView {
            tab("Search") {
                artistRadioPage("/#artist-radio/artistradio-dashboard")

                Div {
                    if (status == null) {
                        Text("Determining Artist Station API Status ...")
                    } else {
                        Text("Artist Station API Status: ")
                        iconForBoolean(status!!)
                    }
                }

                Div {
                    if (statusAuthenticated == null) {
                        Text("Determining Cognito Station API Status ...")
                    } else {
                        Text("Cognito Status: ")
                        iconForBoolean(statusAuthenticated!!)
                    }
                }
            }
        }
    }
}
