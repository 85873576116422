package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.Composable
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun entityListItem(entity: Entity, includeLink: Boolean, includeId: Boolean = false, onClick: (() -> Unit)? = null) {
    @Composable
    fun entityContent() {
        Div({
            classes(AggregatorStyles.entityListItem)
        }) {
            Div({
                style {
                    width(64.px)
                    height(64.px)
                }
            }) {
                val image = entity.thumbnail?.absoluteUrl()

                if (image != null) {
                    Img(src = image) {
                        style {
                            width(100.percent)
                            display(DisplayStyle.InlineBlock)
                        }
                    }
                }
            }

            Div {
                Div({ classes(AggregatorStyles.entitySubInfo) }) {
                    Text(entity.type)
                }
                Text(entity.label)
                if (includeId) {
                    Div({ classes(AggregatorStyles.entitySubInfo) }) {
                        Text(entity.id)
                    }
                }
            }
        }
    }

    if (includeLink) {
        A(href = "#aggregator/entity/${entity.id}") {
            entityContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            entityContent()
        }
    }

}

/**
 * Render an entity list.
 */
@Composable
fun entityList(
    entities: List<Entity>,
    includeLinks: Boolean = true,
    includeIds: Boolean = false,
    onClick: ((Entity) -> Unit)? = null
) {
    Div({ classes(AggregatorStyles.entityList) }) {
        entities.forEach { entity ->
            entityListItem(entity, includeLinks && onClick == null, includeIds) {
                onClick?.invoke(entity)
            }
        }
    }
}