package com.siriusxm.pia.components

import com.siriusxm.pia.AppStylesheet.style
import com.siriusxm.pia.AppStylesheet.type
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

data class NavigationItem(
    val name: String,
    val path: String
)

/**
 * Renders a simple navigation sidebar element with a title
 */
fun ServiceViewConfiguration.simpleNavSidebar(initVisible: Boolean, title: String, elements: List<NavigationItem>) {
    navigationSidebar(initVisible) {
        Div({
            style {
                padding(1.em)
                margin(0.px)
            }
        }) {
            H3 {
                Text(title)
            }
            Ul({
                style {
                    listStyle("none")
                    margin(0.px)
                    padding(0.px)

                    type("li") style {
                        margin((.7).em, 0.px)
                    }
                }
            }) {
                elements.forEach {
                    Li {
                        A(href = "/#${it.path}") {
                            Text(it.name)
                        }
                    }
                }
            }
        }
    }
}
