package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI.borderDividerDefault
import com.siriusxm.pia.SXMUI.containerContentBackgroundColor
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

object ServiceViewStyles : StyleSheet() {
    val entityView by style {
        type("H1") style {
            fontWeight("normal")
        }
    }

    val titleArea by style {
        marginTop(5.px)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.SpaceBetween)
        marginBottom(20.px)
    }

    val subHead by style {
        marginTop(5.px)
    }

    val sidebar by style {
        position(Position.Relative)
        flexShrink(0)
        backgroundColor(containerContentBackgroundColor.value())
        border {
            color(borderDividerDefault.value())
            style(LineStyle.Solid)
        }
    }
    val sidebarNarrow by style {
        width(40.px)
    }

    val sidebarExpanded by style {
        width(250.px)
    }

    val sidebarIcon by style {
        padding(7.px)
        textAlign("center")
    }
}

/**
 * Builder class for the entity view
 */
open class ServiceViewConfiguration {
    internal var content: (@Composable () -> Unit)? = null
    internal var sidebar: (@Composable () -> Unit)? = null
    internal var help: (@Composable () -> Unit)? = null
    internal var sidebarVisible = false

    var title: String? = null

    fun content(block: @Composable () -> Unit) {
        content = block
    }

    /**
     * Install a sidebar. Show/hide will be managed by this view.
     */
    fun navigationSidebar(defaultVisibility: Boolean = false, block: @Composable () -> Unit) {
        sidebar = block
        sidebarVisible = defaultVisibility
    }

    /**
     * Install a help sidebar. Show/hide will be managed by this view.
     */
    fun helpSidebar(block: @Composable () -> Unit) {
        help = block
    }
}

enum class SideBarSide {
    LEFT,
    RIGHT
}

@Composable
fun sideBar(icon: String, side: SideBarSide, initVisible: Boolean = false, content: @Composable () -> Unit) {
    var expanded by remember { mutableStateOf(initVisible) }

    Div({
        classes(
            ServiceViewStyles.sidebar,
            if (expanded) ServiceViewStyles.sidebarExpanded else ServiceViewStyles.sidebarNarrow
        )
        style {
            if (side == SideBarSide.LEFT) {
                borderWidth(0.px, 1.px, 0.px, 0.px)
            } else {
                borderWidth(0.px, 0.px, 0.px, 1.px)
            }
        }
    }) {
        if (!expanded) {
            Div({
                classes(ServiceViewStyles.sidebarIcon)
            }) {
                icon(icon) {
                    action {
                        expanded = true
                    }
                }
            }
        } else {
            Div {
                Div({
                    style {
                        position(Position.Absolute)
                        right(15.px)
                        top(15.px)
                    }
                }) {
                    icon("close") {
                        action {
                            expanded = false
                        }
                    }
                }
                content()
            }
        }
    }
}

/**
 * The service view is the main view for a service. It includes an optional left navigation, and
 * standard spaced content area.
 */
@Composable
fun serviceView(config: ServiceViewConfiguration) {
    Div({
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Stretch)
            flex(1, 1)
            flexBasis("auto")
        }
    }) {
        // Navigation sidebar
        config.sidebar?.let { content ->
            sideBar("menu", SideBarSide.LEFT, config.sidebarVisible, content)
        }

        // Main content
        Div({
            style {
                flex(1)
            }
        }) {
            config.content?.invoke()
        }

        // Help sidebar
        config.help?.let { helpContent ->
            sideBar("help", SideBarSide.RIGHT, false, helpContent)
        }
    }
}

/**
 * Renders a view of an entity. This generally includes a title, some actions and quite a bit
 * of content.
 */
@Composable
fun serviceView(builder: ServiceViewConfiguration.() -> Unit, content: @Composable () -> Unit) {
    val config = ServiceViewConfiguration().apply(builder)
    config.content = content
    serviceView(config)
}