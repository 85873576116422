package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.ImageSpecInput
import com.siriusxm.pia.client.api.client.Episode
import com.siriusxm.pia.client.api.client.Media
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.*
import com.siriusxm.pia.navigate
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toAMPMTimeString
import com.steamstreet.graphkt.client.GraphQLClientException
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.attributes.readOnly
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.get

object EpisodeStyles : StyleSheet() {
    val description by style {
        fontSize((0.9).cssRem)
        firstChild.style {
            marginTop(0.px)
        }
        type("p").style {
            margin((0.5).cssRem, 0.px)
        }
    }
}

@Composable
fun episode(showId: String, episodeGuid: String) {
    val coroutineScope = rememberCoroutineScope()

    var show by remember { mutableStateOf<Show?>(null) }
    var episode by remember { mutableStateOf<Episode?>(null) }
    var atlasEpisodeId by remember { mutableStateOf<String?>(null) }
    var transcription: String? by remember { mutableStateOf(null) }
    var shouldTranscribe: Boolean? by remember { mutableStateOf(null) }
    var transcribing: Boolean by remember { mutableStateOf(false) }

    LaunchedEffect(showId, episodeGuid) {
        try {
            Application.api.query {
                show(showId) {
                    id
                    title

                    episode(episodeGuid) {
                        guid
                        title
                        description
                        date
                        seasonEpisodeNumber
                        season
                        typedEntityId(showId)
                        media {
                            url
                            bitrate
                            contentType
                            encoder
                            duration

                            bundles {
                                id
                            }

                            transcription {
                                vtt
                            }
                        }

                        keywords {
                            value
                        }

                        categories {
                            name
                        }

                        images {
                            src
                            sizes(listOf(ImageSpecInput(600, 600, 100)))
                        }
                    }
                }
            }.let {
                if (it.hasField("show")) {
                    val transcriptionUrl = it.show.episode.media?.find {
                        it.transcription?.vtt != null
                    }?.transcription?.vtt

                    transcription = transcriptionUrl?.let {
                        HttpClient().get(transcriptionUrl).bodyAsText()
                    }
                    episode = it.show.episode
                    show = it.show

                    try {
                        episode?.typedEntityId?.let { typedEntityId ->
                            console.log("Fetching atlas id for: $typedEntityId")
                            atlasEpisodeId = Application.aggregator.lookupSourceId(typedEntityId).atlasId
                            console.log("Got back atlasEpisodeId $atlasEpisodeId for: $typedEntityId")

                            if (shouldTranscribe == null) {
                                val paginatedJobs = Application.transcriptionAPI.getJobsForEntityId(typedEntityId)
                                shouldTranscribe = (paginatedJobs.jobs.isEmpty())
                            }
                        }

                    } catch (e: Exception) {
                        // we get an exception from localhost and don't want to bust the UI due to CORS issues
                    }
                } else {
                    Application.navigate("podcasts/show/${showId}/episodes")
                }
            }
        } catch (e: GraphQLClientException) {
            Application.navigate("podcasts/show/${showId}/episodes")
        }
    }

    suspend fun transcribe() {
        coroutineScope.launch {
            transcribing = true
            val success = Application.api.mutate {
                show(showId) {
                    transcribe(episodeGuid)
                }
            }.show.transcribe

            if (success == true) {
                Application.notifications.info("The episode has been submitted for transcription")
            }
        }
    }

    @Composable
    fun IFrameComponent(sourceUrl: String) {
        Iframe(attrs = {
            attr("src", sourceUrl)
            attr("width", "100%")
            attr("height", "500")
        })
    }

    entityView({
        title = episode?.title

        episode?.date?.let {
            Instant.parse(it).toLocalDateTime(TimeZone.currentSystemDefault())
        }?.let {
            subTitle = "${it.date} ${it.toAMPMTimeString()}"
        }

        if (shouldTranscribe != null) {
            if (transcription == null && !transcribing) {
                if (shouldTranscribe == true) {
                    action {
                        title = "Transcribe"
                        action {
                            transcribe()
                        }
                    }
                }
            }
        }
    }) {
        box {
            Div({
                style {
                    display(DisplayStyle.Flex)
                    gap(10.px)
                }
            }) {
                val image = episode?.images?.firstOrNull()
                if (image != null) {
                    Div {
                        Img(image.sizes.firstOrNull() ?: image.src!!, attrs = {
                            style {
                                maxWidth(150.px)
                                maxHeight(150.px)
                            }
                        })
                    }
                }

                Div({
                    classes(EpisodeStyles.description)
                }) {
                    DisposableEffect(episode?.description ?: "") {
                        scopeElement.innerHTML = episode?.description ?: ""

                        onDispose { }
                    }
                }
            }
        }

        keywordsAndCategoriesBox(episode?.keywords, episode?.categories)

        if (episode?.season != null) {
            box {
                detailGrid {
                    detail("Season", episode?.season?.toString())

                    if (episode?.seasonEpisodeNumber != null) {
                        detail("Episode", episode?.seasonEpisodeNumber?.toString())
                    }
                }
            }
        }

        val hasRestricted = episode?.media.orEmpty().any {
            it.bundles.orEmpty().any { it.id == "restricted" }
        }

        boxTable<Episode>("Metadata") {
            items(listOfNotNull(episode))

            column("GUID") {
                width = 50.px
                content { e ->
                    Span({
                        style {
                            property("line-break", "anywhere")
                        }
                    }) {
                        Text(e.guid)
                    }
                }
            }
            column("TypedEntityId") {
                width = 50.px
                content { e ->
                    Span({
                        style {
                            property("line-break", "anywhere")
                        }
                    }) {
                        Text(e.typedEntityId ?: "")
                    }
                }
            }
            column("AtlasId") {
                width = 50.px
                content { e ->
                    Span({
                        style {
                            property("line-break", "anywhere")
                        }
                    }) {
                        Text(atlasEpisodeId ?: "")
                    }
                }
            }
        }

        boxTable<Media>("Media") {
            items(episode?.media.orEmpty())

            column {
                width = 50.px
                content { media ->
                    iconAction("play_arrow") {
                        Application.player?.play(media.url, show?.title, episode?.title)
                    }
                }
            }

            if (hasRestricted) {
                column {
                    width = 50.px
                    content {
                        if (it.bundles?.firstOrNull()?.id == "restricted") {
                            icon("lock")
                        }
                    }
                }
            }

            column("URL") {
                content {
                    Span({
                        style {
                            property("line-break", "anywhere")
                        }
                    }) {
                        Text(it.url)
                    }
                }
            }

            column("Duration") {
                content {
                    Text(it.duration?.let {
                        val seconds = it.div(1000)
                        val minutes = seconds.div(60)
                        val hours = minutes.div(60)

                        val secondsPart = seconds - (minutes * 60)
                        val minutesPart = minutes - (hours * 60)

                        "${if (hours > 0) "$hours:" else ""}${
                            minutesPart.toString().padStart(2, '0')
                        }:${secondsPart.toString().padStart(2, '0')}"
                    } ?: "-")
                }
            }

            column("Format") {
                content {
                    Text(it.contentType ?: "-")
                }
            }

            column("Bitrate") {
                content {
                    Text(it.bitrate?.toString() ?: "-")
                }
            }
        }

        if (transcription != null) {
            val baseUrl = Application.configuration.transcriptionEditorBaseUrl
            if (baseUrl != null && show != null && episode != null) {
                val iframeUrl =
                    determineIFrameUrl(episode!!, show!!, baseUrl)

                box("Transcription", {}) {
                    IFrameComponent(iframeUrl)
                }
            } else {
                box("Transcription", {}) {
                    TextArea(transcription) {
                        readOnly()

                        style {
                            height(250.px)
                            width(100.percent)
                        }
                    }
                }
            }
        }
    }
}

private fun determineIFrameUrl(episode: Episode, show: Show, baseUrl: String?): String {
    val cognitoPrefix = "managex"
    val token = localStorage["${cognitoPrefix}_access_token"]
    val episodeId = episode.typedEntityId?.let { encodeURIComponent(it) }
    val episodeTitle = episode.title?.let { encodeURIComponent(it) }
    val image: String? = episode.images?.firstOrNull { it.src != null }?.src
        ?: show.images?.firstOrNull { it.src != null }?.src
    val artworkUrl = image?.let { encodeURIComponent(it) }
    val audioUrl = episode.media?.firstOrNull()?.url?.let { encodeURIComponent(it) }

    val iframeUrl =
        "$baseUrl/$episodeId/editor?token=$token&audio=$audioUrl&title=$episodeTitle&artwork=$artworkUrl&hideCover"
    console.log(iframeUrl)
    return iframeUrl
}

