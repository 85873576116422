package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.client.ShowError
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxMessage
import com.siriusxm.pia.components.boxSpinner
import com.siriusxm.pia.components.table
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days

/**
 * Renders a view of the show errors for a given show Id.
 */
@Composable
fun showErrors(showId: String) {
    var errors by remember { mutableStateOf<List<ShowError>?>(null) }

    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(true) {
        coroutineScope.launch {
            val now = Clock.System.now()
            Application.api.query {
                show(showId) {
                    metrics {
                        errors(now.minus(7.days).toString(), now.toString()) {
                            ts
                            message
                            resourceArn
                            resourceName
                        }
                    }
                }

            }.show.metrics.errors.let {
                errors = it
            }
        }
    }

    box({
        paddedContent = false
        title = "Errors"
    }) {
        if (errors == null) {
            boxSpinner()
        } else if (errors.isNullOrEmpty()) {
            boxMessage("This show has no recent errors")
        } else {
            table<ShowError> {
                items(errors.orEmpty())
                column("Resource") {
                    content {
                        Text(it.resourceName ?: "-")
                    }
                }

                column("Error") {
                    content {
                        Text(it.message ?: "-")
                    }
                }

                column("Time") {
                    content { error ->
                        Text(Instant.parse(error.ts).toLocalDateTimeString())
                    }
                }
            }
        }
    }
}