package com.siriusxm.pia.views.unifiedaggregator.types

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.PartialSchema
import contentingestion.aggregator.ProducerDetails
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text

/**
 * Renders partial schemas
 */
@Composable
fun partials(aggregator: AggregatorService, initialSelect: String? = null) {
    splitSelectionView<PartialSchema> {
        items = aggregator.partials
        selection = initialSelect?.let {
            aggregator.partials.find { it.id == initialSelect }
        }

        renderListItem {
            Text(it.name ?: it.id)
        }

        render {
            partial(aggregator, it)
        }

        onSelect {
            aggregator.context.navigate("aggregator/types/partials/${it.id}")
        }

        column {
            content {
                it.description?.let {
                    Text(it)
                }
            }
        }
    }
}

@Composable
fun partial(aggregator: AggregatorService, schema: PartialSchema) {
    entityView({
        title = schema.name ?: schema.id
        subTitle = schema.id
    }) {
        box("Producers", {
            paddedContent = false
        }) {
            val matchedProducers =
                aggregator.producers.filter { it.allowedPartials.orEmpty().contains(schema.id) }
            if (matchedProducers.isEmpty()) {
                boxMessage("No producers produce this partial")
            } else {
                table<ProducerDetails> {
                    items(matchedProducers)
                    column {
                        content {
                            A("#aggregator/producers/${encodeURIComponent(it.id)}") {
                                Text(it.name.orEmpty())
                            }
                        }
                    }
                }
            }
        }

        box("Types", {
            paddedContent = false
            header({
                instruction = "These types can be modified"
            })
        }) {
            table<String> {
                this.items(schema.types)
                column {
                    content {
                        val entityTypeId = it.substringAfterLast("/", "")
                            .substringBefore(":").ifBlank { null }
                        val entityType = entityTypeId?.let { aggregator.entityType(entityTypeId) }
                        val label = entityType?.name ?: entityTypeId
                        label?.let {
                            A(href = "#aggregator/types/entities/${entityTypeId}") {
                                Text(it)
                            }
                        }
                    }
                }
            }
        }

        box("Fields", {
            paddedContent = false
            header({
                instruction = "These fields are modified"
            })
        }) {
            table<String> {
                this.items(schema.paths)
                column {
                    content {
                        Text(it.removePrefix("$."))
                    }
                }
            }
        }
    }
}