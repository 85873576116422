package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.TranscriptionJob
import com.siriusxm.pia.rest.transcription.TranscriptionJobStatus
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.cancellation.CancellationException

@Composable
fun transcriptionJobRetry(jobId: String?, returnPath: String?) {
    var loading : Boolean? by remember { mutableStateOf(null) }
    var typedEntityId : String? by remember { mutableStateOf(null) }
    var newJobId : String? by remember { mutableStateOf(null) }
    var error : String? by remember { mutableStateOf(null) }

    if (!Application.viewer.editor) {
        Div {
            Text("Insufficient permissions to retry failed jobs")
        }
    } else {
        LaunchedEffect(jobId) {
            try {
                if (jobId != null) {
                    loading = true
                    console.log("Begin retry transcription job")
                    val failedJob: TranscriptionJob? = Application.transcriptionAPI.getJobById(jobId)
                    if (failedJob != null) {
                        console.log("Done looking up transcription job by id")
                        val request = failedJob.request
                        val requestedEntityId = request.typedEntityId
                        typedEntityId = requestedEntityId
                        val inProgressStates = listOf(
                            TranscriptionJobStatus.QUEUED,
                            TranscriptionJobStatus.IN_PROGRESS,
                            TranscriptionJobStatus.TRANSCRIBED
                        )
                        val retryableStates = listOf(
                            TranscriptionJobStatus.FAILED,
                            TranscriptionJobStatus.CANCELED
                        )
                        if (retryableStates.contains(failedJob.status) ) {
                            // before we transcribe make sure we do not already have a job
                            console.log("Checking for existing jobs")
                            val jobsResponse = Application.transcriptionAPI.getJobsForEntityId(requestedEntityId)
                            val inProgressJobs = jobsResponse.jobs.sortedByDescending { it.start }
                                .filter { inProgressStates.contains(it.status)  }
                            if (inProgressJobs.isEmpty() ||  // there are no jobs in process
                                inProgressJobs.find { it.start != null && failedJob.start != null // the in progress jobs are older (ie human transcriptions)
                                        && it.start < failedJob.start } != null) {
                                console.log("Resubmitting the old job")
                                val queuedJob = Application.transcriptionAPI.submitJob(request)
                                newJobId = queuedJob?.jobId
                            } else {
                                error = "Unable to retry job because there is already a more recent job for typedEntityId: $typedEntityId"
                            }
                        } else {
                            error = "Unable to retry job because job status is: ${failedJob.status}"
                        }
                    }
                }
            } catch (e: CancellationException) {
                // ignored
                console.log("Canceled exception: $e")
            } catch (t: Throwable) {
                console.log("Got an exception: $t")
                newJobId = null
            } finally {
                loading = false
            }
        }

        if (jobId == null) {
            Div {
                Text("There appears to be no job to retry.")
            }
        } else {
            if (loading == null || loading == true) {
                Div {
                    Text("Retrying transcription for faild job with id: ${jobId}")
                }
            } else {
                if (typedEntityId != null) {
                    Div {
                        Text("Retrying transcription for typedEntityId: $typedEntityId")
                    }
                }

                if (newJobId != null) {
                    Div {
                        Text("Queued transcription. New jobId: $newJobId")
                    }
                }

                if (error != null) {
                    Div {
                        Text("Error: $error")
                    }
                }
            }
        }
    }

    button("Back") {
        Application.navigation.navigate(returnPath ?: "transcription-dashboard")
    }
}

