package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.events.EntityEventsStyles
import com.siriusxm.pia.views.unifiedaggregator.events.cloudEventTable
import com.siriusxm.pia.views.unifiedaggregator.events.eventsSelectionHeader
import com.siriusxm.pia.views.unifiedaggregator.events.loadEvents
import contentingestion.aggregator.CloudEventList
import contentingestion.aggregator.EventSearchStatus
import contentingestion.aggregator.EventSearchStatusState
import contentingestion.aggregator.ProducerDetails
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * The view for browsing and editing producers.
 */
@Composable
fun producers(aggregator: AggregatorService, selectedId: String? = null) {
    val producers = aggregator.producers

    serviceContentView({
        title = "Producers"
        if (aggregator.isAdmin() && selectedId == null) {
            this.action {
                title = "New Producer"
                primary = true
                action {
                    aggregator.navigate("producers/edit")
                }
            }
        }
    }) {
        splitSelectionView<ProducerDetails> {
            items = producers
            selection = producers.find { it.id == selectedId }
            onSelect {
                aggregator.navigate("producers/${encodeURIComponent(it.id)}")
            }
            renderListItem {
                Text(it.name ?: it.id)
            }
            render { producer ->
                producer(aggregator, producer)
            }
        }
    }
}

/**
 * A view of the configuration of a producer.
 */
@Composable
fun producer(aggregator: AggregatorService, producer: ProducerDetails) {
    entityView({
        title = producer.name

        if (aggregator.isAdmin()) {
            action("Edit", true) {
                aggregator.navigate("producers/${encodeURIComponent(producer.id)}/edit")
            }
        }
    }) {
        box {
            detailGrid {
                detail("ID", producer.id)
                producer.clientId?.let {
                    detail("OAuth ClientId", it)
                }

                if (producer.awsAccountId != null) {
                    detail("AWS Account") {
                        Text(producer.awsAccountId!!)
                    }
                }
                if (producer.contact?.isNotEmpty() == true) {
                    detail("Contact") {
                        producer.contact?.forEach {
                            Div {
                                Text(it.email)
                            }
                        }
                    }
                }
                producer.feedbackTargetArn?.let {
                    detail("Feedback Target ARN", producer.feedbackTargetArn)
                }

                producer.slackChannel?.let {
                    detail("Slack") {
                        A(
                            href = "https://siriusxm-pandora.slack.com/channels/${it}",
                            { target(ATarget.Blank) }) {
                            Img(src = "images/slack.png") {
                                style {
                                    width(20.px)
                                    marginRight(.5.em)
                                    display(DisplayStyle.InlineBlock)
                                    property("vertical-align", "middle")
                                }
                            }
                            Text(it)
                        }
                    }
                }
            }
        }

        tabView {
            tab("Configuration") {
                box({
                    title = "Types"
                    paddedContent = false
                    header({
                        instruction = "This producer is permitted to publish these types."
                    })
                }) {
                    table<String> {
                        items(producer.allowedTypes?.toList().orEmpty())

                        column {
                            content { allowedType ->
                                Div {
                                    A(href = "#aggregator/types/entities/${encodeURIComponent(allowedType)}") {
                                        Text(aggregator.entityType(allowedType)?.name ?: allowedType)
                                    }
                                }
                            }
                        }

                    }
                }

                if (!producer.allowedPartials.isNullOrEmpty()) {
                    box({
                        title = "Partials"
                        paddedContent = false
                        header({
                            instruction = "This producer is permitted to publish these partials."
                        })
                    }) {
                        table<String> {
                            items(producer.allowedPartials?.toList().orEmpty())

                            column {
                                content { allowedPartial ->
                                    Div {
                                        val partialName = aggregator.partial(allowedPartial)?.name
                                            ?: allowedPartial
                                        A(href = "#aggregator/types/partials/${encodeURIComponent(allowedPartial)}") {
                                            Text(partialName)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ServiceUsersBox(aggregator, producer.id, ServiceUsersType.PRODUCER)
            }

            tab("Events") {
                producerEvents(aggregator, producer)
            }
        }
    }
}

/**
 * The producer events view.
 */
@Composable
fun producerEvents(aggregator: AggregatorService, producer: ProducerDetails) {
    var loading by remember { mutableStateOf(false) }
    var eventsPeriod by remember { mutableStateOf<TimeRange?>(null) }
    var result by remember { mutableStateOf<EventSearchStatus?>(null) }
    var events by remember { mutableStateOf<CloudEventList?>(null) }
    var failureMessage by remember { mutableStateOf<String?>(null) }

    Style(EntityEventsStyles)

    LaunchedEffect(eventsPeriod) {
        val range = eventsPeriod?.range()
        if (range != null) {
            loading = true
            try {
                result = loadEvents({ queryId ->
                    if (queryId != null) {
                        aggregator.clientApi.getProducerEvents(encodeURIComponent(producer.id), queryId = queryId)
                    } else {
                        aggregator.clientApi.getProducerEvents(
                            encodeURIComponent(producer.id),
                            range.start, range.endInclusive
                        )
                    }
                }) {
                    events = it
                }
                if (result?.state == EventSearchStatusState.FAILED) {
                    failureMessage = "We were unable to load the events"
                }
            } catch (t: Throwable) {
                failureMessage = t.message ?: "We were unable to load the events"
            }
            loading = false
        }
    }

    eventsSelectionHeader(
        loading,
        "Loading events...", eventsPeriod, null, null
    ) { range, option ->
        eventsPeriod = range
    }

    val resultEvents = events.orEmpty()
    box({
        paddedContent = false
    }) {
        if (failureMessage != null) {
            boxMessage(failureMessage!!)
        } else if (eventsPeriod == null) {
            boxMessage("Please select a time period.")
        }

        if (resultEvents.isNotEmpty()) {
            cloudEventTable(
                aggregator,
                resultEvents
            )
        } else {
            if (!loading && eventsPeriod != null) {
                boxMessage("No producer events occurred in this period")
            }
        }
    }
}