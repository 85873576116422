package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text

object EntityViewStyles : StyleSheet() {
    val entityView by style {
        type("H1") style {
            fontWeight("normal")
        }
    }

    val titleArea by style {
        marginTop(5.px)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.SpaceBetween)
        marginBottom(20.px)
    }

    val subHead by style {
        marginTop(5.px)
    }
}

enum class EntityViewType {
    NORMAL,
    DIALOG
}

/**
 * Builder class for the entity view
 */
open class EntityViewConfiguration {
    var type: EntityViewType = EntityViewType.NORMAL
    var title: String? = null
    var subTitle: String? = null
    internal var content: (@Composable () -> Unit)? = null
    internal var breadcrumbConfig: (BreadcrumbConfiguration.() -> Unit)? = null
    internal val actions = mutableListOf<ButtonConfiguration>()
    fun breadcrumbs(config: BreadcrumbConfiguration.() -> Unit) {
        breadcrumbConfig = config
    }

    fun content(block: @Composable () -> Unit) {
        content = block
    }

    fun action(block: ButtonConfiguration.() -> Unit) {
        actions += ButtonConfiguration().apply(block)
    }

    fun action(title: String, primary: Boolean = false, impl: suspend () -> Unit) {
        actions += ButtonConfiguration(title, primary, impl)
    }
}

@Composable
fun entityView(config: EntityViewConfiguration) {
    Div({ classes(EntityViewStyles.entityView) }) {
        if (config.title == null) {
            spinner()
        } else {
            if (config.breadcrumbConfig != null) {
                breadcrumbs(config.breadcrumbConfig!!)
            }

            config.title?.let { title ->
                Div({ classes(EntityViewStyles.titleArea) }) {
                    Div {
                        H1 {
                            Text(title)
                        }
                        config.subTitle?.let {
                            Div({ classes(EntityViewStyles.subHead) }) {
                                Text(it)
                            }
                        }
                    }

                    if (config.type == EntityViewType.NORMAL && config.actions.isNotEmpty()) {
                        Div {
                            config.actions.forEach {
                                button(it)
                            }
                        }
                    }
                }
            }

            config.content?.let { content ->
                Div {
                    content()
                }
            }

            if (config.type == EntityViewType.DIALOG && config.actions.isNotEmpty()) {
                Div({
                    style {
                        textAlign("right")
                    }
                }) {
                    config.actions.forEach {
                        button(it)
                    }
                }
            }

        }
    }
}

/**
 * Renders a view of an entity. This generally includes a title, some actions and quite a bit
 * of content.
 */
@Composable
fun entityView(builder: EntityViewConfiguration.() -> Unit, content: @Composable () -> Unit) {
    val config = EntityViewConfiguration().apply(builder)
    config.content = content
    entityView(config)
}