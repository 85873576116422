package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.dom.*

/**
 * Defines a radio group option.
 */
internal class RadioGroupOption(
    val name: String,
    val value: String,
    val description: String? = null
)

/**
 * Configuration for a radio group
 */
class RadioGroupConfig {
    internal val options = mutableListOf<RadioGroupOption>()
    internal var onChange: (String) -> Unit = {}
    fun option(name: String, value: String, description: String? = null) {
        options += RadioGroupOption(name, value, description)
    }

    fun onChange(cb: (String) -> Unit) {
        onChange = cb
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun radioGroup(value: String? = null, builder: RadioGroupConfig.() -> Unit) {
    val config = RadioGroupConfig().apply(builder)

    RadioGroup(value) {
        config.options.forEach { option ->
            Div({
                classes(DialogStyles.radioGroupOption)
            }) {
                RadioInput(option.value) {
                    onChange {
                        config.onChange(option.value)
                    }
                }
                Div {
                    Span {
                        Text(option.name)
                    }
                    option.description?.let { description ->
                        Div({ classes(DialogStyles.radioGroupOptionDescription) }) {
                            Text(description)
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun buttonRadioGroup(value: String, builder: RadioGroupConfig.() -> Unit) {
    val config = RadioGroupConfig().apply(builder)

    Div({
        classes(DialogStyles.buttonRadioGroup)
    }) {
        config.options.forEach { option ->
            Button({
                classes(DialogStyles.buttonRadioGroupButton)
                if (value == option.value) {
                    classes(DialogStyles.buttonRadioGroupSelected)
                }
                onClick {
                    config.onChange(option.value)
                }
            }) {
                Text(option.name)
            }
//            button({
//
//            }) {
//                title = option.name
//                primary = (value == option.value)
//                action = {
//                    config.onChange(option.value)
//                }
//            }
        }
    }
}