package com.siriusxm.pia.views.transcription

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.NavigationItem
import com.siriusxm.pia.components.primaryContentArea
import com.siriusxm.pia.components.serviceView
import com.siriusxm.pia.components.simpleNavSidebar
import com.siriusxm.pia.utils.Route

@Composable
fun Route.transcriptions() {
    serviceView({
        simpleNavSidebar(
            true, "Transcriptions", listOf(
                NavigationItem("Jobs", "transcriptions"),
                NavigationItem("Vocabulary", "transcriptions/vocabulary")
            )
        )
    }) {
        switch {
            select("job-retry") {
                switch {
                    select(Regex(".+")) {
                        val jobId = this.match
                        val returnPath: String? = parameter("return")
                        primaryContentArea {
                            transcriptionJobRetry(jobId, returnPath)
                        }
                    }
                }
            }

            select("vocabulary") {
                switch {
                    select(Regex(".+")) {
                        val typedEntityId = this.match
                        when (parameter("action")) {
                            "view" -> {
                                primaryContentArea {
                                    viewVocabulary(typedEntityId)
                                }
                            }

                            "edit" -> {
                                primaryContentArea {
                                    editVocabulary(typedEntityId, mutableListOf())
                                }
                            }

                            "delete" -> {
                                primaryContentArea {
                                    deleteVocabulary(typedEntityId)
                                }
                            }

                            else -> {
                                primaryContentArea {
                                    viewVocabulary(typedEntityId)
                                }
                            }
                        }
                    }
                    default {
                        primaryContentArea {
                            vocabularyPage()
                        }
                    }
                }
            }

            select("fix-transcription") {
                switch {
                    select(Regex(".+")) {
                        val uuid = this.match
                        val returnPath: String? = parameter("return")
                        primaryContentArea {
                            viewTranscriptionByAtlasId(uuid, returnPath)
                        }
                    }
                    default {
                        primaryContentArea {
                            transcriptionsDashboardPage()
                        }
                    }
                }
            }

            select(Regex(".+")) {
                val typedEntityId = this.match
                val returnPath: String? = parameter("return")
                when (parameter("action")) {
                    "view" -> {
                        primaryContentArea {
                            viewTranscription(typedEntityId, returnPath)
                        }
                    }

                    else -> {
                        primaryContentArea {
                            viewTranscription(typedEntityId, returnPath)
                        }
                    }
                }
            }

            default {
                primaryContentArea {
                    transcriptionsDashboardPage()
                }
            }
        }
    }
}