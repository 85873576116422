package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.TabStyles.tabContainer
import com.siriusxm.pia.components.TabStyles.tabTitle
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object TabStyles : StyleSheet() {
    val tabContainer by style {
        border(0.px, LineStyle.Solid, SXMUI.tabsBorder.value())
        borderWidth(0.px, 0.px, 1.px, 0.px)

        child(self, type("div")).style {
            lastChild.style {
                type("span").style {
                    borderWidth(0.px)
                }
            }
        }
    }
    val tabTitle by style {
        margin((0.5).em, 0.px)
        padding((0.5).em, (1.5).em)
        fontWeight(700)
        display(DisplayStyle.InlineBlock)

        border(0.px, LineStyle.Solid, SXMUI.tabsBorder.value())
        borderWidth(0.px, 1.px, 0.px, 0.px)

    }

    val tabSelected by style {
        border(0.px, LineStyle.Solid, SXMUI.brandColor.value())
        borderWidth(0.px, 0.px, 2.px, 0.px)

        className(tabTitle).style {
            color(SXMUI.brandColor.value())
        }
    }

    val tab by style {
        display(DisplayStyle.InlineBlock)
        cursor("pointer")

        self + hover style {
            className(tabTitle).style {
                color(SXMUI.brandColor.value())
            }
        }
    }

    val tabContent by style {
        marginTop(20.px)
    }
}


interface TabViewProperties {
    var selection: String?
    var allowNoSelect: Boolean

    fun tab(title: String, id: String = title.lowercase(), content: @Composable () -> Unit)

    /**
     * Provide a function to be called when any tab is clicked. The callback will receive the id
     * of the tab. If it returns false, the tab switch will not take place.
     */
    fun onSelect(cb: (String) -> Boolean)
}

private class TabConfig(
    val title: String,
    val id: String,
    val content: @Composable () -> Unit
)

private class TabViewConfigurator : TabViewProperties {
    override var selection: String? = null
    val tabs = mutableListOf<TabConfig>()
    var selectCb: ((String) -> Boolean)? = null
    override var allowNoSelect: Boolean = false

    override fun tab(title: String, id: String, content: @Composable () -> Unit) {
        tabs += TabConfig(title, id, content)
        if (selection == null && !allowNoSelect) {
            selection = id
        }
    }

    override fun onSelect(cb: (String) -> Boolean) {
        selectCb = cb
    }
}

@Composable
fun tabView(config: TabViewProperties.() -> Unit) {
    Style(TabStyles)

    val data = TabViewConfigurator().apply(config)
    var selectedRemembered by remember {
        mutableStateOf(data.selection)
    }

    val selected = if (data.selectCb != null) data.selection else selectedRemembered

    Div({
        classes(tabContainer)
    }) {
        Div({
            style {

            }
        }) {
            data.tabs.forEach { tab ->
                Div({
                    classes(TabStyles.tab)
                    if (tab.id == selected) {
                        classes(TabStyles.tabSelected)
                    }

                    onClick {
                        if (data.selectCb != null) {
                            data.selectCb?.invoke(tab.id)
                        } else {
                            selectedRemembered = tab.id
                        }
                    }
                }) {
                    Span({
                        classes(tabTitle)

                    }) {
                        Text(tab.title)
                    }
                }
            }
        }
    }

    data.tabs.find { it.id == selected }?.let { selectedTab ->
        Div {
            selectedTab.content()
        }
    }
}

@Composable
fun tabContent(content: @Composable () -> Unit) {
    Div({
        classes(TabStyles.tabContent)
    }) {
        content()
    }
}