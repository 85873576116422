package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.ShowSummary
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.sports.SportsStyles
import com.siriusxm.pia.views.sports.getAbsoluteUrl
import contentingestion.unifiedmodel.Episode
import contentingestion.unifiedmodel.NameType
import contentingestion.unifiedmodel.Show
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * Renders a show page, complete with channel details, episodes, etc.
 */
@Composable
fun show(epg: EPG, showId: String, route: Route) {
    var loading by remember { mutableStateOf<Boolean?>(null) }
    var show by remember { mutableStateOf<Show?>(null) }
    var episodes by remember { mutableStateOf<List<Episode>?>(null) }

    LaunchedEffect(showId) {
        loading = true

        show = epg.fetchShow(showId)
        episodes = epg.fetchLinearEpisodesForShow(showId)

        loading = false
    }

    serviceContentView({
        title = "Show"
    }) {
        if (loading == true) {
            spinner(size = Size.LARGE)
        } else {

            if (show != null) {
                showDetail(show!!)
            }
            if (episodes?.isEmpty() == true) {
                messageBox(
                    "No episodes found for show $showId",
                    MessageType.WARNING
                )
            } else {
                episodes?.let { shows ->
                    episodesGrid(shows)
                }
            }
        }
    }
}

@Composable
fun showDetail(show: Show) {
    Div({ classes(SportsStyles.listItem) }) {
        Div {
            val preferredImage = show.getPreferredImage()
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(preferredImage)) {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                }
            }
        }

        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.additionalNames?.get("abbreviation")?.name ?: "")
            }
            Div({ classes(SportsStyles.entityInfo) }) { Text(getShowName(show)) }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.id)
            }
            Div {
                detailGrid {
                    if (show.description != null) {
                        detail("Description") {
                            Text(show.description!!)
                        }
                    }
                }
            }
            Div {
                detailGrid {
                    pillDetailBox("Hosts", show.hosts)
                    pillDetailBox("Topics", show.topics)
                    pillDetailBox("Flags", show.flags?.map { it.name })
                }
            }
        }
    }
}

@Composable
fun showContent(show: Show) {
    Div({ classes(SportsStyles.listItem) }) {
        Div {
            val preferredImage = show.getPreferredImage()
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(preferredImage)) {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                }
            }
        }

        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.additionalNames?.get("abbreviation")?.name ?: "")
            }
            Div({ classes(SportsStyles.entityInfo) }) { Text(getShowName(show)) }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.id)
            }
        }
    }
}

@Composable
fun showSummaryContent(show: ShowSummary) {
    Div({ classes(SportsStyles.listItem) }) {
        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entityInfo) }) { Text(show.name) }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(show.id)
            }
        }
    }
}

fun getShowName(show: Show): String {
    val nickname = show.additionalNames?.get(NameType.NICKNAME.name)?.name
    val name = show.additionalNames?.get(NameType.LONG.name)?.name
    return if (nickname != null && name != null) {
        "$name $nickname"
    } else {
        show.name
    }
}

@Composable
fun showSummaryItem(show: ShowSummary, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    if (includeLink) {
        A(href = "#channelguide/show/${show.id}") {
            showSummaryContent(show)
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            showSummaryContent(show)
        }
    }
}

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun showItem(show: Show, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    if (includeLink) {
        A(href = "#channelguide/show/${show.id}") {
            showContent(show)
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(show.additionalNames?.get("abbrevation")?.name ?: "")
                }
                Div({ classes(SportsStyles.entityInfo) }) { Text(getShowName(show)) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(show.id)
                }
            }
        }
    }
}


/**
 * Render a shows grid.
 */
@Composable
fun showsGrid(
    entities: List<Show>,
    includeLinks: Boolean = true,
    onClick: ((Show) -> Unit)? = null
) {
    Div({ classes(SportsStyles.gridList) }) {
        entities.forEach { entity ->
            showItem(entity, includeLinks && onClick == null) {
                onClick?.invoke(entity)
            }
        }
    }
}