package com.siriusxm.pia.views.channelguide

import com.siriusxm.pia.Application
import com.siriusxm.pia.rest.epg.*
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.asEntities
import contentingestion.unifiedmodel.Cut
import contentingestion.unifiedmodel.Episode
import contentingestion.unifiedmodel.Show
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.serialization.json.*

/**
 * Main processing logic for the EPG.
 */
data class EPG(
    val api: EPGApiClient,
    val aggregatorClient: UnifiedAggregatorClient,
    val reloader: () -> Unit
) {
    val json = Json {
        ignoreUnknownKeys = true
    }

    /**
     * Navigate to the path (relative to the channelguide path, so don't start with "channelguide")
     */
    fun navigate(path: String) {
        Application.navigation.navigate("channelguide/${path.removePrefix("channelguide/")}")
    }

    fun refresh() {
        reloader()
    }

    suspend fun fetchAllChannels(): List<ChannelSummary> {
        var token: String? = null

        val results = mutableListOf<ChannelSummary>()
        do {
            api.getChannels(null, null, 500, token).also {
                results += it.channels
                token = it.paginationToken
            }
        } while (token != null)

        return results
    }

    private suspend fun getAllPaginated(cb: suspend (String?) -> PaginatedResult): PaginatedResult {
        var token: String? = null

        val results = mutableListOf<JsonObject>()
        do {
            cb(token).also {
                results += it.entities.map { elem -> elem.jsonObject }
                token = it.token
            }
        } while (token != null)

        return PaginatedResult(results, null)
    }

    private suspend fun getAllChannelsPaginated(cb: suspend (String?) -> ChannelResult): ChannelResult {
        var token: String? = null

        val results = mutableListOf<ChannelSummary>()
        do {
            cb(token).also {
                results += it.channels
                token = it.paginationToken
            }
        } while (token != null)

        return ChannelResult(results, null)
    }

    suspend fun fetchAllCategories(): List<Category> {
        return getAllPaginated {
            api.getChannelCategories(250, it)
        }.decode<Category>().entities
    }

    suspend fun fetchAllLineups(): List<LineupSummary> {
        return getAllPaginated {
            api.getLineups(500, it)
        }.decode<LineupSummary>().entities
    }

    suspend fun fetchCategories(categoryIds: Set<String>): List<Entity> {
        val categories = coroutineScope {
            categoryIds.map {
                async {
                    aggregatorClient.fetchEntityById(it).asEntities()
                }
            }.awaitAll()
        }.flatten().sortedBy { it.name }
        return categories
    }

    suspend fun fetchChannel(channelId: String): ChannelSummary {
        return api.getChannel(channelId)
    }

    suspend fun fetchShowsForChannel(channelId: String): List<Show> {
        return api.getChannelShows(channelId).decode<Show>().entities
    }

    suspend fun fetchLinearEpisodesForChannel(channelId: String): List<Episode> {
        return getAllPaginated {
            api.getChannelEpisodes(channelId, "episode-linear", paginationToken = it)
        }.decode<Episode>().entities
    }

     suspend fun fetchAudioEpisodesForChannel(channelId: String): List<Episode> {
        return getAllPaginated {
            api.getChannelEpisodes(channelId, "episode-audio", paginationToken = it)
        }.decode<Episode>().entities
    }

    suspend fun fetchCutsForChannel(channelId: String): List<Cut> {
        return getAllPaginated {
            api.getChannelCuts(channelId, 500, it)
        }.decode<Cut>().entities
    }

    suspend fun fetchShow(showId: String): Show? {
        val showJson = aggregatorClient.outgoing(showId, "show", "0.1")
        return showJson?.let { json.decodeFromJsonElement<Show>(it) }
    }

    suspend fun fetchLinearEpisodesForShow(showId: String): List<Episode> {
        return api.getShowEpisodes(showId).decode<Episode>().entities
    }

    suspend fun fetchEpisode(episodeId: String): Episode? {
        val episodeJson = aggregatorClient.outgoingById(episodeId)?.get(0)?.jsonObject
        return episodeJson?.let { json.decodeFromJsonElement<Episode>(it) }
    }

    suspend fun fetchCutsForEpisode(episodeId: String): List<Cut> {
        return api.getEpisodeCuts(episodeId).decode<Cut>().entities
    }

    suspend fun fetchChannelsForLineup(lineupId: String): List<ChannelSummary> {
        return getAllChannelsPaginated {
            api.getChannels(lineupId = lineupId, paginationToken = it)
        }.channels
    }

    suspend fun fetchChannelsForCategory(categoryId: String): List<ChannelSummary> {
        return getAllChannelsPaginated {
            api.getChannels(categoryId = categoryId, paginationToken = it)
        }.channels
    }
}