package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberUpdatedState
import com.siriusxm.pia.components.IconSize
import com.siriusxm.pia.components.icon
import com.siriusxm.pia.utils.toAMPMTimeString
import com.siriusxm.pia.utils.toLocalDateTimeString
import contentingestion.unifiedmodel.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.minutes

/**
 * A list item to display a channel summary.
 */
@Composable
fun cutSummaryItem(
    cut: Cut,
    withImages: Boolean = true
) {
    val currentChannel by rememberUpdatedState(cut)

    val now = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())

    val cutUrl = "#aggregator/entity/${cut.id}"

    val artistRadioUrl = cut.artistStationId?.let { "#aggregator/entity/$it" }

    Div({
        classes(ChannelGuideStyles.epgListItem)
        cut.flags?.map { "cut-flag-${it.name}" }?.let {
            classes(it)
        }
        style {
            alignItems(AlignItems.FlexStart)
        }
    }) {
        if (withImages) {
            Div({
                classes(ChannelGuideStyles.epgListItemImage)
            }) {
                defaultImage(cut.images, ImageAspectRatio.ASPECT_1X1, 128.px)
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemMain)
        }) {
            Div {
                (cut as? LinearCut)?.let {
                    Div({
                        style {
                            fontSize(0.7.cssRem)
                        }
                    }) {
                        val cutInstant = Instant.parse(it.timestamp)
                        val cutTime = cutInstant.toLocalDateTime(TimeZone.currentSystemDefault())
                        if (cutTime.date == now.date) {
                            Text(cutTime.toAMPMTimeString(false))
                        } else {
                            Text(cutInstant.toLocalDateTimeString(false))
                        }
                    }
                }

                (cut as? AudioCut)?.let {
                    Div({
                        style {
                            fontSize(0.7.cssRem)
                        }
                    }) {
                        val offsetDuration = it.offset.milliseconds
                        val hours = offsetDuration.inWholeHours
                        val minutes = (offsetDuration - hours.hours).inWholeMinutes
                        val seconds = (offsetDuration - hours.hours - minutes.minutes).inWholeSeconds

                        val offsetTimeCode = listOf(
                            hours, minutes, seconds
                        ).map {
                            it.toString()
                        }.map {
                            it.padStart(2, '0')
                        }.joinToString(":")
                        Text(offsetTimeCode)
                    }
                }

                H3 {
                    A(href = cutUrl) {
                        Text(cut.name)
                        aggregatorIconLink(cut.id)
                    }
                }
                currentChannel.artist?.let { artist ->
                    Div {
                        if (currentChannel.artistStationId != null) {
                            A(href = artistRadioUrl, {
                                title("Artist Radio: ${artist}")
                            }) {
                                Span({
                                    style {
                                        paddingRight(5.px)
                                    }
                                }) {
                                    Text(artist)
                                }
                                icon("radio") {
                                    size = IconSize.TINY
                                }
                            }
                        } else {
                            Text(artist)
                        }
                    }
                }
                currentChannel.album?.let {
                    Div {
                        Text(it)
                    }
                }
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemTrailing)
        }) {
            flags(
                cut.flags, mapOf(
                    CutFlags.INTERSTITIAL to "format_letter_spacing",
                    CutFlags.SONG to "music_note",
                    CutFlags.NAVIGABLE to "map",
                )
            )
        }
    }
}