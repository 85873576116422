// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.sports

import contentingestion.unifiedmodel.SportingEvent
import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlin.collections.Map

@OptIn(InternalKtorfitApi::class)
public class _SportingEventsClientImpl : SportingEventsClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun fetchEventById(eventId: String, headerMap: Map<String, String>):
      SportingEvent? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/${"$eventId".encodeURLPath()}/event/0.1")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "contentingestion.unifiedmodel.SportingEvent?",
    typeInfo = typeInfo<contentingestion.unifiedmodel.SportingEvent?>())

    return _converter.suspendRequest<contentingestion.unifiedmodel.SportingEvent?,
        contentingestion.unifiedmodel.SportingEvent?>(_typeData,_ext)
  }

  override suspend fun fetchEventsById(eventId: String, headerMap: Map<String, String>):
      List<SportingEvent> {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/entities/${"$eventId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.collections.List<contentingestion.unifiedmodel.SportingEvent>",
    typeInfo = typeInfo<kotlin.collections.List<contentingestion.unifiedmodel.SportingEvent>>())

    return _converter.suspendRequest<kotlin.collections.List<contentingestion.unifiedmodel.SportingEvent>,
        contentingestion.unifiedmodel.SportingEvent>(_typeData,_ext)!!
  }

  override suspend fun fetchTeamsForLeague(leagueId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/team-league/object/${"$leagueId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchEventsForHomeTeam(teamId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/event-home-team/object/${"$teamId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchEventsForAwayTeam(teamId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/event-away-team/object/${"$teamId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchEventsForLeague(leagueId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/event-league/object/${"$leagueId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun fetchScoresForEvent(eventId: String, headerMap: Map<String, String>):
      EntityResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/entities/relationships/score-event/object/${"$eventId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.sports.EntityResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.sports.EntityResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.sports.EntityResponse,
        com.siriusxm.pia.rest.sports.EntityResponse>(_typeData,_ext)!!
  }

  override suspend fun replayEvent(request: SportingEventsClient.BatchingRequest,
      headerMap: Map<String, String>) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "/batch")
        }
        setBody(request)
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createSportingEventsClient(): SportingEventsClient =
    this.create(_SportingEventsClientImpl().apply { _converter=
    KtorfitConverterHelper(this@createSportingEventsClient) })
