package com.siriusxm.pia.utils

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

/**
 * Get a standard h:mm a formatted time string.
 */
fun LocalDateTime.toAMPMTimeString(includeSeconds: Boolean = false, includeAmPm: Boolean = true): String {
    val isPM = this.hour >= 12
    val hour = this.hour.let {
        if (it == 0) {
            12
        } else if (it > 12) {
            it - 12
        } else {
            it
        }
    }.toString()
    val minutes = minute.toString().padStart(2, '0')

    val seconds = if (includeSeconds) {
        this.second.toString().padStart(2, '0')
    } else null

    val time = listOfNotNull(hour, minutes, seconds).joinToString(":")

    return if (includeAmPm) {
        "$time ${if (isPM) "pm" else "am"}"
    } else {
        time
    }
}

/**
 * Return the instant as a string with the date and time in the system local time.
 */
fun Instant.toLocalDateTimeString(includeSeconds: Boolean = false): String {
    return toLocalDateTime().let {
        "${it.date} ${it.toAMPMTimeString(includeSeconds)}"
    }
}

fun Instant.toLocalDateTime(): LocalDateTime {
    return toLocalDateTime(TimeZone.currentSystemDefault())
}