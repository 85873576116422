package com.siriusxm.pia.views.unifiedaggregator.types

import androidx.compose.runtime.*
import com.siriusxm.pia.components.TimeRange
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxMessage
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.events.EntityEventsStyles
import com.siriusxm.pia.views.unifiedaggregator.events.cloudEventTable
import com.siriusxm.pia.views.unifiedaggregator.events.eventsSelectionHeader
import contentingestion.aggregator.EntityTypeConfiguration
import contentingestion.aggregator.EventQueryOption
import contentingestion.aggregator.EventSearchStatus
import contentingestion.aggregator.EventSearchStatusState
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.Style

/**
 * A view for events for a given entity type
 */
@Composable
fun EntityTypeEvents(aggregator: AggregatorService, entityType: EntityTypeConfiguration) {
    var loading by remember { mutableStateOf(false) }
    var result by remember { mutableStateOf<EventSearchStatus?>(null) }
    var failureMessage by remember { mutableStateOf<String?>(null) }

    var eventsPeriod by remember { mutableStateOf<TimeRange?>(null) }

    var queryType by remember { mutableStateOf<EventQueryOption>(EventQueryOption.Output) }

    Style(EntityEventsStyles)

    LaunchedEffect(entityType, eventsPeriod, queryType) {
        val range = eventsPeriod?.range()
        if (range != null) {
            failureMessage = null
            loading = true
            result = null
            var query = aggregator.api.entityTypeEvents(
                entityType.type, null,
                range.start, range.endInclusive, queryType, limit = 100
            )
            try {
                while (query.state == EventSearchStatusState.IN_PROGRESS) {
                    delay(500)
                    query = aggregator.api.entityTypeEvents(entityType.type, query.id)
                    result = query
                }

                if (query.state == EventSearchStatusState.COMPLETED) {
                    result = query
                }

            } catch (e: Throwable) {
                failureMessage = e.message ?: "The event search failed for an unknown reason."
            }
            loading = false
        }
    }

    eventsSelectionHeader(
        loading, "Loading events...", eventsPeriod,
        queryType.name, listOf(
            "Outgoing Events" to EventQueryOption.Output.name,
            "Incoming Events" to EventQueryOption.Incoming.name
        )
    ) { range, option ->
        eventsPeriod = range
        queryType = option?.let { EventQueryOption.fromName(it) } ?: EventQueryOption.Output

    }

    box({
        paddedContent = false
    }) {
        if (failureMessage != null) {
            boxMessage(failureMessage!!)
        } else if (eventsPeriod == null) {
            boxMessage("Please select a time period.")
        }

        val resultEvents = result?.results.orEmpty()

        if (resultEvents.isNotEmpty()) {
            cloudEventTable(
                aggregator,
                resultEvents
            )
        } else {
            if (!loading && eventsPeriod != null) {
                boxMessage("No ${entityType.name} events were published in this period")
            }
        }
    }
}