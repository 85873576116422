package com.siriusxm.pia.rest.artistradio

import kotlinx.serialization.Serializable

@Serializable
data class ArtistStationSummary(
    /** The Artist Station ID of the request and response. */
    val artistStationId: String,
    /** If the API request included an Attribute name, that name will be returned in the Response. */
    val attribute: String?,
    /**
     * During a GET (fetch) request, this value will be populated with the current value for the Artist Station
     * attribute matching the requested [attribute] name.
     * During a PUT (update) request, this value will be populated with the value which was requested as
     * the "updated" value of [attribute].
     */
    val attribValue: String?,
    /** For an API call which returns the ArtistStation object, this value will hold the fetched Artist data. */
    val artist: Artist?,
    /** An optional message to be returned to the client. */
    val message: String?,
    /** The HTTP Status Code returned to the client. This value is not serialized. */
) {

}

data class ArtistStationHolder(val entityId: String, private val artist: Artist) {

    private var _discoverable: Boolean = artist.discoverable ?: true
    private var _recommendable: Boolean = artist.recommendable ?: true
    private var _visible: Boolean = artist.visible ?: true

    val discoverable: Boolean
        get() =_discoverable

    val recommendable: Boolean
        get() = _recommendable

    val visible: Boolean
        get() = _visible

    val artistName: String? 
        get() = artist.name

    val knownArtist: Boolean 
        get() = true

    fun show() {
        _discoverable = true
        _recommendable = true
        _visible = true
    }

    fun hide() {
        _discoverable = false
        _recommendable = false
        _visible = false
    }

    fun updateValue(attribute: ArtistStationVisibilityFlag, value: Boolean) {
        when (attribute) {
            ArtistStationVisibilityFlag.DISCOVERABLE -> _discoverable = value
            ArtistStationVisibilityFlag.RECOMMENDABLE -> _recommendable = value
            ArtistStationVisibilityFlag.VISIBLE -> _visible = value
        }
    }

    fun isDirty() : Boolean =
        changedValues().isNotEmpty()

    fun changedValues() : Map<ArtistStationVisibilityFlag, Boolean> {
        val result = mutableMapOf<ArtistStationVisibilityFlag, Boolean>()

        if (artist.discoverable != _discoverable) {
            result[ArtistStationVisibilityFlag.DISCOVERABLE] = _discoverable
        }

        if (artist.recommendable != _recommendable) {
            result[ArtistStationVisibilityFlag.RECOMMENDABLE] = _recommendable
        }

        if (artist.visible != _visible) {
            result[ArtistStationVisibilityFlag.VISIBLE] = _visible
        }

        return result
    }
}

/** Enumeration of allowed Visibility Flags for Artist Stations. */
enum class ArtistStationVisibilityFlag(val label: String) {

    DISCOVERABLE("discoverable"),
    RECOMMENDABLE("recommendable"),
    VISIBLE("visible");

    companion object {

        /**
         * Given a label, return the matching ArtistStationFlag, or null if no match is found
         */
        fun fromLabel(label: String?): ArtistStationVisibilityFlag? {
            return ArtistStationVisibilityFlag.entries.firstOrNull { it.label == label }
        }
    }

}

@Serializable
data class Artist(
    val id: String,
    val name: String,
    val additionalNames: Map<NameType, Name>? = null,
    val bio: String? = null,
    val image: String? = null,
    val similarArtists: List<String>? = null,
    val similarArtistImages: List<String>? = null,
    val similarArtistNames: Map<String, String>? = null,
    var discoverable: Boolean? = null,
    var recommendable: Boolean? = null,
    var visible: Boolean? = null

)

@Serializable
enum class NameType {
    short,
    medium,
    long,
    slug,
    abbreviation,
    nickname
}

@Serializable
class Name(
    val type: NameType,
    val name: String
)

@Serializable
data class ArtistStationSimplified(
    val entityId: String,
    val name: String,
    val knownArtist: Boolean,
    val discoverable: Boolean,
    val recommendable: Boolean,
    val visible: Boolean
) {
    fun allVisible(): Boolean {
        return discoverable && recommendable && visible
    }
    
    fun allHidden(): Boolean {
        return !discoverable && !recommendable && !visible
    }
}