package com.siriusxm.pia

import com.siriusxm.pia.cognito.CognitoClient
import com.siriusxm.pia.components.AudioPlayer
import com.siriusxm.pia.rest.epg.EPGApiClient
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.HashNavigator
import com.siriusxm.pia.views.artistradio.ArtistStationAPI
import com.siriusxm.pia.views.epg.EpgAPI
import com.siriusxm.pia.views.mddb.MddbAPI
import com.siriusxm.pia.views.sports.SportsAPI
import com.siriusxm.pia.views.transcription.TranscriptionAPI
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
data class Configuration(
    val stage: String,
    val authUrl: String,
    val clientId: String,
    val apiUrl: String,
    val transcriptionApiUrl: String,
    val transcriptionEditorBaseUrl: String?,
    val unifiedContentBusApiUrl: String,
    val atlasImageServerBaseUrl: String,
    val artistStationApiUrl: String,
    val mddbApiUrl: String,
    val unifiedAggregatorApiUrl: String,
    val epgApiUrl: String,
    val ingestionAuditUrl: String,
    val datadogLookupAtlasBusById: String
)

/**
 * The main application.
 */
object Application {
    lateinit var configuration: Configuration
    lateinit var cognito: CognitoClient
    lateinit var notifications: NotificationsConfig
    var player: AudioPlayer? = null

    private val origin = window.location.origin

    val navigation: HashNavigator = HashNavigator {
        renderPage(it)
    }


    /**
     * Access to the API
     */
    lateinit var api: IngestionAPI

    /**
     * Access to podcast ingestion audit API
     */
    lateinit var ingestionAuditAPI: IngestionAuditAPI

    /**
     * Access to the Transcription API
     */
    lateinit var transcriptionAPI: TranscriptionAPI

    /**
     * Access to the Unified Aggregator API
     */
    lateinit var aggregator: UnifiedAggregatorClient

    /**
     * Access to the Unified Content Bus API
     */
    lateinit var artistStationAPI: ArtistStationAPI

    /**
     * Access to the MDDB API
     */
    lateinit var mddbAPI: MddbAPI

    /**
     * Access to the Sports APIs
     */
    lateinit var sportsAPI: SportsAPI

    /**
     * Access to the EPG APIs
     */
    lateinit var epgAPI: EPGApiClient

    /**
     * Access to the EPG APIs
     */
    lateinit var epgAggregatorAPI: EpgAPI

    suspend fun run() {
        configuration = config()
        cognito = CognitoClient(
            configuration.authUrl,
            configuration.clientId,
            origin,
            "managex",
            "phone openid email Transcription/rest-api UnifiedContentBus/rest-api DynamicRadio/visibility-flags MDDBEventBus/rest-api UnifiedCatalogAPI/sxm:unified-catalog:entities:read EPG/sxm:unified-catalog:entities:read UnifiedCatalogAPI/sxm:unified-catalog:entities:publish UnifiedCatalogAPI/sxm:unified-catalog:management:read UnifiedCatalogAPI/sxm:unified-catalog:management:write UnifiedCatalogAPI/sxm:unified-catalog:backfill:start"
        )
        cognito.init()

        if (!cognito.isLoggedIn()) {
            withContext(Dispatchers.Main) {
                cognito.login()
            }
        } else {
            api = IngestionAPI(configuration.apiUrl) {
                cognito.accessToken!!
            }
            ingestionAuditAPI = IngestionAuditAPI(configuration.ingestionAuditUrl) {
                cognito.accessToken!!
            }
            transcriptionAPI = TranscriptionAPI(configuration.transcriptionApiUrl) {
                cognito.accessToken!!
            }
            sportsAPI = SportsAPI(configuration.unifiedAggregatorApiUrl) {
                cognito.accessToken!!
            }

            val httpClient = HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
                expectSuccess = true
                install(Auth) {
                    bearer {
                        loadTokens {
                            BearerTokens(cognito.accessToken!!, cognito.refreshToken!!)
                        }
                    }
                }
            }

            epgAggregatorAPI = EpgAPI(configuration.unifiedAggregatorApiUrl) {
                cognito.accessToken!!
            }

            aggregator = Ktorfit.Builder()
                .baseUrl(configuration.unifiedAggregatorApiUrl)
                .httpClient(httpClient)
                .build().create()

            epgAPI = Ktorfit.Builder()
                .baseUrl(configuration.epgApiUrl)
                .httpClient(httpClient)
                .build().create()

            artistStationAPI = ArtistStationAPI(configuration.artistStationApiUrl) {
                cognito.accessToken!!
            }

            mddbAPI = MddbAPI(configuration.mddbApiUrl) {
                cognito.accessToken!!
            }

            navigation.run()
        }
    }

    /**
     * Load configuration
     */
    private suspend fun config(): Configuration {
        val configParse = Json {
            ignoreUnknownKeys = true
        }
        val configString = HttpClient().get("$origin/config.json").body<String>()
        return configParse.decodeFromString(configString)
    }

    /**
     * Get the viewer of the application.
     */
    val viewer: Viewer by lazy {
        Viewer(cognito.idToken)
    }
}


/**
 * Shorthand for navigation
 */
fun Application.navigate(path: String) = navigation.navigate(path)