// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.views.sports

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.Int
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlinx.datetime.Instant

@OptIn(InternalKtorfitApi::class)
public class _SportsClientImpl : SportsClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun leagues(): LeagueResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/leagues")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.views.sports.LeagueResponse",
    typeInfo = typeInfo<com.siriusxm.pia.views.sports.LeagueResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.views.sports.LeagueResponse,
        com.siriusxm.pia.views.sports.LeagueResponse>(_typeData,_ext)!!
  }

  override suspend fun leagueParticipants(leagueId: String): ParticipantResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/leagues/${"$leagueId".encodeURLPath()}/participants")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.views.sports.ParticipantResponse",
    typeInfo = typeInfo<com.siriusxm.pia.views.sports.ParticipantResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.views.sports.ParticipantResponse,
        com.siriusxm.pia.views.sports.ParticipantResponse>(_typeData,_ext)!!
  }

  override suspend fun leagueCompetitions(
    leagueId: String,
    start: Instant,
    end: Instant,
    max: Int?,
    paginationToken: String?,
  ): CompetitionResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/leagues/${"$leagueId".encodeURLPath()}/competitions")
        start?.let{ parameter("start", "$it") }
        end?.let{ parameter("end", "$it") }
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.views.sports.CompetitionResponse",
    typeInfo = typeInfo<com.siriusxm.pia.views.sports.CompetitionResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.views.sports.CompetitionResponse,
        com.siriusxm.pia.views.sports.CompetitionResponse>(_typeData,_ext)!!
  }

  override suspend fun participantCompetitions(
    participantId: String,
    start: Instant,
    end: Instant,
    max: Int?,
    paginationToken: String?,
  ): CompetitionResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "/participants/${"$participantId".encodeURLPath()}/competitions")
        start?.let{ parameter("start", "$it") }
        end?.let{ parameter("end", "$it") }
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.views.sports.CompetitionResponse",
    typeInfo = typeInfo<com.siriusxm.pia.views.sports.CompetitionResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.views.sports.CompetitionResponse,
        com.siriusxm.pia.views.sports.CompetitionResponse>(_typeData,_ext)!!
  }

  override suspend fun competitionScoreEvents(
    competitionId: String,
    max: Int?,
    paginationToken: String?,
  ): ScoreResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "/competition/${"$competitionId".encodeURLPath()}/scores")
        max?.let{ parameter("max", "$it") }
        paginationToken?.let{ parameter("token", "$it") }
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.views.sports.ScoreResponse",
    typeInfo = typeInfo<com.siriusxm.pia.views.sports.ScoreResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.views.sports.ScoreResponse,
        com.siriusxm.pia.views.sports.ScoreResponse>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createSportsClient(): SportsClient = this.create(_SportsClientImpl().apply {
    _converter= KtorfitConverterHelper(this@createSportsClient) })
