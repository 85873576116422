package com.siriusxm.pia.views.artistradio

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.primaryContentArea
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.rest.artistradio.ArtistStationHolder
import com.siriusxm.pia.rest.artistradio.ArtistStationSummary
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.encodeURIComponent
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

/**
 * The main transcription page.
 */

@Composable
fun artistRadioPage(returnPath: String? = null) {

    var searchText by remember { mutableStateOf("") }
    var searchResults by remember { mutableStateOf(emptyList<ArtistStationSummary>()) }
    val coroutineScope = rememberCoroutineScope()
    var searching by mutableStateOf(false)
    var searchJob by mutableStateOf<Job?>(null)

    var artistRadioEdits by remember { mutableStateOf((emptyMap<String, ArtistStationHolder>())) }

    suspend fun search(text: String) {
        try {
            searching = true

            val artistStations = Application.artistStationAPI.fetchArtistStation(text)
            searchResults = artistStations.values.toList()

            if (searchResults.isNotEmpty()) {
                val edits = mutableMapOf<String, ArtistStationHolder>()
                searchResults.forEach {
                    if (it.artist != null) {
                        edits[it.artistStationId] = ArtistStationHolder(it.artistStationId, it.artist)
                    }
                }
                artistRadioEdits = edits
            }

            console.log("Done looking up artist radio stations for $text artistRadioEdits: $artistRadioEdits")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            searchResults = emptyList()
        } finally {
            searching = false
        }
    }

    box({
        paddedContent = searchResults.isEmpty() || searching
        header({
            title = "Artist Radio Stations"
        }) {
            Div({
                style {
                    width(100.percent)
                }
            }) {
                Div {
                    Input(InputType.Text, attrs = {
                        defaultValue(searchText)
                        placeholder("Enter an Artist Radio entityId an Artist Radio Station")
                        onInput { event ->
                            searchText = event.value

                            searchJob?.cancel("Starting new search")
                            searchJob = null
                            if (event.value.length >= 4) {
                                searchJob = coroutineScope.launch {
                                    search(event.value)
                                }
                            }
                        }

                        style {
                            width(100.percent)
                        }
                    })
                }
            }
        }
    }) {
        if (searching) {
            spinner()
        } else if (searchResults.isEmpty()) {
            if (searchText.length >= 4) {
                Text("No stations have been found")
            } else {
                Text("Enter search text to find artist radio stations...")
            }
        } else {
            val ids = searchResults.map { element -> element.artistStationId }.joinToString(",")
            Application.navigation.navigate("artist-radio/artistradio-view/${encodeURIComponent(ids)}?action=view")
        }
    }
}

/**
 * Artist Radio Routing
 */
@Composable
fun Route.artistRadio() {
    primaryContentArea {
        switch {
            select("edit") {
                select(Regex(".+")) {
                    val typedEntityId = this.match
                    val action: String = parameter("action") ?: "edit"
                    val returnPath: String? = parameter("return")
                    artistRadioEdit(typedEntityId, action, returnPath)
                }
            }
            select("bulkedit") {
                select(Regex(".+")) {
                    val typedEntityId = this.match
                    val action: String = parameter("action") ?: "hide"
                    val returnPath: String? = parameter("return")
                    artistRadioBulkEdit(typedEntityId, action, returnPath)
                }
            }
            select("view") {
                select(Regex(".+")) {
                    val typedEntityId = this.match
                    val returnPath: String? = parameter("return")
                    viewArtistRadio(typedEntityId, returnPath)
                }
            }
            default {
                primaryContentArea {
                    artistRadioDashboardPage()
                }
            }
        }

    }
}

