package com.siriusxm.pia.views.sports

import com.siriusxm.pia.Application

data class SportsService(
    val api: SportsAPI,
    val reloader: () -> Unit
) {
    /**
     * Navigate to the path (relative to the aggregator path, so don't start with "aggregator")
     */
    fun navigate(path: String) {
        Application.navigation.navigate("sports/${path.removePrefix("sports/")}")
    }

    fun refresh() {
        reloader()
    }
}